import React from 'react'
import { Formik, Form } from "formik";
import { AutoCompleteSelect, Button, FormControl } from '../../../../components';
import {getStyle} from '../../../../utils/helpers/errorMessage'
import API from '../../../../config/api';
import { tahun_bulan_tanggal } from '../../../../utils/helpers/date';
import * as Yup from "yup";
import { HR } from '../../../../config/api/ResourceURL';
import axios from 'axios';


const schemaValidation = Yup.object().shape({
  leave_no: Yup.string().required('Tidak boleh kosong'),
  approver_id: Yup.object()
      .shape({
          label: Yup.string(),
          value: Yup.string()
      })
      .nullable()
      .required("Yang Menyetujui Tidak Boleh Kosong"),

});




const ChangeRequestStatusForm = ({employeeOptions, token, closeModalChangeRequestStatus, isAddOrEdit, initialValues}) => {
  

  const handleSubmit = (values, { resetForm, setSubmitting }) => {

    const data = {
      leave_no: values.leave_no,
      employee_id : values.employee_id.value,
      leave_type : values.leave_type.value,
      request_date_start : values.request_date_start,
      request_date_end : values.request_date_end,
      approved_date_start : values.request_date_start, //karena langsung disetujui, jadi tanggal requestnya sama dengan yang disetujui
      periode : new Date().getFullYear(),
      approved_date_end : values.request_date_end,
      employee_note : values.employee_note,
      address_during_leave : values.address_during_leave,
      contact_during_leave : values.contact_during_leave,
      approver_id : values.approver_id.value,
      approver_note : values.approver_note,
      attachment : values.attachment,
      status : 1,
    }
    // console.log(data)
    let url = HR + '/v1/leaves';

  
    let formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('id', initialValues.id);
    formData.append('leave_no', data.leave_no);
    formData.append('employee_id', data.employee_id);
    formData.append('leave_type', data.leave_type);
    formData.append('periode', data.periode);
    formData.append('request_date_start', data.request_date_start);
    formData.append('request_date_end', data.request_date_end);
    formData.append('approved_date_start', data.approved_date_start);
    formData.append('approved_date_end', data.approved_date_end);
    formData.append('employee_note', data.employee_note);
    formData.append('address_during_leave', data.address_during_leave);
    formData.append('contact_during_leave', data.contact_during_leave);
    formData.append('approver_id', data.approver_id);
    formData.append('approver_note', data.approver_note);
    formData.append('attachment', data.attachment);
    formData.append('status', data.status);
    // console.log(formData);
    axios
    .post(`${url}/${initialValues.id}`, formData, {
        headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
        },
    })
    .then(res => {
      swal({
        title: "Cuti Berhasil Disetujui",
        text: res.data.message,
        icon: "success",
    });
    
      return closeModalChangeRequestStatus();


    }).catch(err => {
        // console.log(err);
        swal({
            title: "Cuti Gagal Disetujui",
            text: err.message,
            icon: "error",
        });
        return closeModalChangeRequestStatus();
    });

  };
  const declinedLeaveRequest = () => {
      // console.log('delete jadwal in');

      const {id} = initialValues;
      const data = {
        leave_no: null,
        employee_id : initialValues.employee_id.value,
        leave_type : initialValues.leave_type.value,
        request_date_start : initialValues.request_date_start,
        request_date_end : initialValues.request_date_end,
        approved_date_start : initialValues.request_date_start,
        periode : new Date().getFullYear(),
        approved_date_end : initialValues.request_date_end,
        employee_note : initialValues.employee_note,
        address_during_leave : initialValues.address_during_leave,
        contact_during_leave : initialValues.contact_during_leave,
        approver_id : null,
        approver_note : null,
        attachment : initialValues.attachment,
        status : 3,
      }
      // console.log(data)
      let url = HR + '/v1/leaves';
  
    
      let formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('id', initialValues.id);
      formData.append('leave_no', data.leave_no);
      formData.append('employee_id', data.employee_id);
      formData.append('leave_type', data.leave_type);
      formData.append('periode', data.periode);
      formData.append('request_date_start', data.request_date_start);
      formData.append('request_date_end', data.request_date_end);
      formData.append('approved_date_start', data.approved_date_start);
      formData.append('approved_date_end', data.approved_date_end);
      formData.append('employee_note', data.employee_note);
      formData.append('address_during_leave', data.address_during_leave);
      formData.append('contact_during_leave', data.contact_during_leave);
      formData.append('approver_id', data.approver_id);
      formData.append('approver_note', data.approver_note);
      formData.append('attachment', data.attachment);
      formData.append('status', data.status);
      // console.log(formData);
      axios
      .post(`${url}/${initialValues.id}`, formData, {
          headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          },
      })
      .then(res => {
        swal({
          title: "Cuti Berhasil Ditolak",
          text: res.data.message,
          icon: "success",
      });
      
        return closeModalChangeRequestStatus();
  
  
      }).catch(err => {
          // console.log(err);
          swal({
              title: "Cuti Gagal Ditolak",
              text: err.message,
              icon: "error",
          });
          return closeModalChangeRequestStatus();
      });
    }


    return (
      <Formik
          initialValues={initialValues}
          onSubmit={(values, props) => handleSubmit(values, props)}
          validationSchema={() => schemaValidation}
          >
      {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
            isValid,
          }) => (
            <div className="modal-body">
            <Form>
                <p style={{paddingLeft: '46px', fontStyle: 'italic', fontSize: '11px'}}>Isi No. Cuti Apabila cuti disetujui</p>
                <FormControl control="input" type="text" label="Nomor Cuti" name="leave_no"
                    style={getStyle(errors, touched, 'leave_no')} placeholder="Nomor Cuti"
                />
              <AutoCompleteSelect
                id="approver_id"
                name="approver_id"
                label="Yang Menyetujui"
                placeholder="Pilih Yang Menyetujui..."
                options={employeeOptions}
                value={values.approver_id}
                isMulti={false}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                touched={touched.approver_id}
                error={errors.approver_id}
                isClearable={true}
                backspaceRemovesValue={true}
              />

              <FormControl
                control='textarea'
                label='Catatan yg Menyetujui'
                placeholder="Catatan yg Menyetujui"
                name='approver_note'
                style={getStyle(errors, touched, 'approver_note')}

              />
                 <div className="modal-footer" style={{marginBottom: 'auto'}}>
                    <div className="delete-button" onClick={declinedLeaveRequest}>Tolak</div>
                    <Button buttonFull buttonColor='var(--green)' align="right" buttonHover type="submit" 
                    disabled={!isValid}>Setujui</Button>
                    {/* disabled={!isValid || props.isLoading} */}
                </div>
            </Form>
            </div>
          )}
        </Formik>
    );
}

export default ChangeRequestStatusForm

