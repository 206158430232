import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { iconUser } from "../../../assets";
import { Gap, PageHeader } from "../../../components";

import { RecordAttendanceWrapper } from "./attendance.elements";
import {
  hmsToSeconds,
  jam_menit_detik,
  tahun_bulan_tanggal,
} from "../../../utils/helpers/date";
import { getDistance } from "../../../utils/helpers/attendance";
import API from "../../../config/api";
import { isArraySame } from "../../../utils/helpers/array";
import AttendanceMap from "./AttendanceMap";
import GetPicture from "../../../utils/helpers/GetPicture";
import {HR} from "../../../config/api/ResourceURL";
import Axios from "axios";

const DEFAULT_LATITUDE = -3.339414495831897;
const DEFAULT_LONGITUDE = 114.60118618319521;
const default_location = [DEFAULT_LATITUDE, DEFAULT_LONGITUDE];

const RecordAttendance = (props) => {
  // console.log(props)
  // const location = useGeoLocation();
  const { history, user } = props;

  const [location, setLocation] = useState(default_location);
  const [time, setTime] = useState(jam_menit_detik());
  const [attendance, setAttendance] = useState(null);
  const [attendanceStatus, setAttendanceStatus] = useState(0);
  const [overtimeAttendance, setOvertimeAttendance] = useState(null);
  const [isHaveScheduleToday, setIsHaveScheduleToday] = useState(false);
  const [scheduleData, setScheduleData] = useState({
    attLocation: [0, 0],
    attLocation2: [0, 0],
    attRadius: 50,
    attRadius2: 50,
    attLocationName: '',
    attLocationCode: '',
    attLocationName2: '',
    attLocationCode2: '',
    attTimeIn: "",
    attTimeOut: "",
    attCode: "",
    attLateTolerance: 5,
    attBreakStart: "00:00:00",
    attType: "shift",
  });
  const [overtimeData, setOvertimeData] = useState({
    attLocation: [0, 0],
    attLocation2: [0, 0],
    attRadius: 50,
    attRadius2: 50,
    attLocationName: '',
    attLocationCode: '',
    attLocationName2: '',
    attLocationCode2: '',
    attTimeIn: "",
    attTimeOut: "",
    attCode: "",
    attLateTolerance: 5,
    attBreakStart: "00:00:00",
  });
  const [attendanceData, setAttendanceData] = useState({});
  const [overtimeLocation, setOvertimeLocation] = useState(default_location);
  const [workLocation, setWorkLocation] = useState(default_location);
  const [workLocation2, setWorkLocation2] = useState(default_location);
  const [distanceText, setDistanceText] = useState(null);
  const [distance, setDistance] = useState(null);
  const [distanceText2, setDistanceText2] = useState(null);
  const [distance2, setDistance2] = useState(null);
  const today = new Date();
  const token = user.token;
  const employee = user.info;

  const [isAttached, setIsAttached] = useState(false);
  const [attendanceType, setAttendanceType] = useState(null);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [attachmentName, setAttachmentName] = useState('nama-file.jpg');


  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString = atob(dataURI.split(',')[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }



  const openCamera = jenisAbsen => {
    // console.log('open camera');
    setAttendanceType(jenisAbsen);
    setCameraOpen(!cameraOpen);
    setAttachment(null);

    //open camera
    //setelah foto, buat nama surat-keterangan-sakit-nama-karyawan-tanggal.jpg
  };

  const getPhoto = async photo => {
    // console.log(photo);
    // const blob = await (await fetch(photo)).blob(); 
    const blob = await fetch(photo).then(it => it.blob())
    // console.log('blobb', blob)
    const file = new File([blob], 'foto-absen.jpg', {type:"image/jpeg", lastModified:new Date(), base64: photo});
    // console.log(URL.createObjectURL(blob))
    // console.log(file)
    file.uri = URL.createObjectURL(blob);
    setIsAttached(true);
    setAttachment(file);
    // console.log(attachment)
  };


  const callAPIMasuk = () => {
    // console.log(`absen masuk karyawan id ${employee.id} tanggal ${tahun_bulan_tanggal(today)} time in: ${jam_menit_detik()}`);
    let time_in = jam_menit_detik();
    let date = tahun_bulan_tanggal(today);
    // console.log(location);
    const data = {
      employee_id: employee.id,
      date: date,
      time_in_schedule: scheduleData.attTimeIn,
      late_tolerance: scheduleData.attLateTolerance,
      time_out_schedule: scheduleData.attTimeOut,
      lng_schedule: scheduleData.attLocation[1],
      lat_schedule: scheduleData.attLocation[0],
      lng_schedule_2: scheduleData.attLocation2[1],
      lat_schedule_2: scheduleData.attLocation2[0],
      time_in: time_in,
      time_in_lng: location[1],
      time_in_lat: location[0],
      time_break_start: scheduleData.attBreakStart,
      break_duration: scheduleData.attBreakDuration,
      is_late:
        hmsToSeconds(time_in) > hmsToSeconds(scheduleData.attTimeIn) ? 1 : 0,
    };

    let formData = new FormData();
    let url = HR + '/v1/attendance/employee';

    formData.append('employee_id', data.employee_id);
    formData.append('date', data.date);
    formData.append('time_in_schedule', data.time_in_schedule);
    formData.append('late_tolerance', data.late_tolerance);
    formData.append('time_out_schedule', data.time_out_schedule);
    formData.append('lng_schedule', data.lng_schedule);
    formData.append('lat_schedule', data.lat_schedule);
    formData.append('lng_schedule_2', data.lng_schedule_2);
    formData.append('lat_schedule_2', data.lat_schedule_2);
    formData.append('time_in', data.time_in);
    formData.append('time_in_lng', data.time_in_lng);
    formData.append('time_in_lat', data.time_in_lat);
    formData.append('time_break_start', data.time_break_start);
    formData.append('break_duration', data.break_duration);
    formData.append('is_late', data.is_late);
    formData.append('time_in_img', attachment);
    // console.log(formData)
    Axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => {
        setAttendanceStatus(1);

      }).catch(err => {
        setAttendanceStatus(0);

      });
  };

  const callAPIPulang = () => {
    // console.log(attendance)
    // console.log(`absen pulang karyawan id ${employee.id} tanggal ${tahun_bulan_tanggal(today)} time out: ${jam_menit_detik()}`);
    let time_out = jam_menit_detik();
    let date = tahun_bulan_tanggal(today);

    const data = {
      attendance_id: attendance.id,
      time_out: time_out,
      // is_overtime: hmsToSeconds(time_out) > (hmsToSeconds(attendance.time_out_schedule) + (60 * 20)) ? 1 : 0,
      is_out_early:
        hmsToSeconds(time_out) < hmsToSeconds(attendance.time_out_schedule)
          ? 1
          : 0,
      time_out_lng: location[1],
      time_out_lat: location[0],
      time_out_img: "",
      date: date,
    };
    //jika waktu sekarang kurang dari jadwal pulang
    // console.log(data)
    API.addTimeOut(token, data)
      .then((res) => {
        // console.log(res.data)
        setAttendanceStatus(2);
      })
      .catch((err) => {
        // console.log(err.response)
        setAttendanceStatus(1);
      });
  };

  const absenMasuk = () => {
    // Checking if the task i am going to create already exist and running, which means that the foreground is also running.
    let dist = getDistance(
      [scheduleData.attLocation[0], scheduleData.attLocation[1]],
      [location[0], location[1]],
    );
    //jika lokasi user tidak dalam radius lokasi kehadiran 1
    if (employee.attendance_with_radius && dist > scheduleData.attRadius / 2) {
      // console.log(employee.attendance_with_radius);
      console.log(scheduleData)
      let dist2 = getDistance(
        [scheduleData.attLocation2[0], scheduleData.attLocation2[1]],
        [location[0], location[1]],
      );
      console.log(dist2);
      if (scheduleData.attLocationName2 !== null) {
        //check dulu apakah ada lokasi keduanya?
        //jika ada lokasi kehadiran lain,
        //check lagi jika lokasi user tidak dalam lokasi kehadiran 2
        // console.log('ada lokasi lain');
        if (
          employee.attendance_with_radius &&
          dist2 > scheduleData.attRadius2 / 2
        ) {
          alert('Gagal, Kamu harus berada di lokasi kehadiran.');
        } else {
          callAPIMasuk();
        }
      } else {
        // console.log('tidak ada lokasi lain');
        alert('Gagal, Kamu harus berada di lokasi kehadiran.');
      }
    } else {
      // console.log('sip', dist);
      callAPIMasuk();
    }
  };

  const absenPulang = async () => {
    let dist = getDistance(
      [scheduleData.attLocation[0], scheduleData.attLocation[1]],
      [location[0], location[1]],
    );

    if (employee.attendance_with_radius && dist > scheduleData.attRadius / 2) {
      let dist2 = getDistance(
        [scheduleData.attLocation2[0], scheduleData.attLocation2[1]],
        [location[0], location[1]],
      );

      if (employee.attendance_with_radius && dist2 > scheduleData.attRadius2 / 2) {
        alert('Gagal', 'Kamu harus berada di lokasi kehadiran.');
      } else {
        callAPIPulang();
      }
    } else {
      callAPIPulang();
    }
  };


  // useEffect(() => {
  //   //check apakah ada jadwal hari ini
  //   API.checkTodayScheduleOfEmployee(
  //     token,
  //     employee.id,
  //     tahun_bulan_tanggal(today)
  //   )
  //     .then((res) => {
  //       // console.log('ada jadwal')
  //       console.log(res.data);
  //       setIsHaveScheduleToday(true);
  //       setWorkLocation([
  //         parseFloat(res.data.latitude),
  //         parseFloat(res.data.longitude),
  //       ]);
  //       setWorkLocation2([
  //         res.data.location_2_latitude
  //             ? parseFloat(res.data.location_2_latitude)
  //             : 0,
  //         res.data.location_2_longitude
  //           ? parseFloat(res.data.location_2_longitude)
  //           : 0,
  //       ]);
  //       setScheduleData({
  //         attLocation: [
  //           parseFloat(res.data.latitude),
  //           parseFloat(res.data.longitude),
  //         ],
  //         attRadius: parseInt(res.data.radius),
  //         attLocationName: res.data.location_name,
  //         attLocationCode: res.data.location_code,
  
  //         attLocation2: [
  //           res.data.location_2_latitude
  //             ? parseFloat(res.data.location_2_latitude)
  //             : DEFAULT_LATITUDE,
  //           res.data.location_2_longitude
  //             ? parseFloat(res.data.location_2_longitude)
  //             : DEFAULT_LONGITUDE,
  //         ],
 
  //         attRadius2: res.data.location_2_radius ? parseInt(res.data.location_2_radius) : 50,
 
  //         attLocationName2: res.data.location_2_name,
  //         attLocationCode2: res.data.location_2_code,
  //         attTimeIn: res.data.time_in,
  //         attTimeOut: res.data.time_out,
  //         attType: "shift",
  //         attCode: res.data.shift_code,
  //         attLateTolerance: res.data.late_tolerance,
  //         attBreakStart: res.data.break_start,
  //         attBreakDuration: res.data.break_duration,
  //       });
  //       //check status kehadiran, apakah sudah absen atau belum
  //       API.checkTodayAttendanceOfEmployee(
  //         token,
  //         employee.id,
  //         tahun_bulan_tanggal(today)
  //       )
  //         .then((res) => {
  //           // console.log(res.data);
  //           setAttendance(res.data);
  //           setAttendanceStatus(1);
  //         })
  //         .catch((err) => {
  //           // console.log(err.response.data.message);
  //           setAttendance(null);
  //           setAttendanceStatus(0);

  //           // console.log(err.response.data.message);
  //         });
  //     })
  //     .catch((err) => {
  //       // console.log(err.response)
  //       setIsHaveScheduleToday(false);
  //     });

  //   API.isEmployeeHaveOvertimeToday(
  //     token,
  //     employee.id,
  //     tahun_bulan_tanggal(today)
  //   )
  //     .then((res) => {
  //       // console.log(res.data)
  //       setOvertimeLocation([
  //         parseFloat(res.data.overtime_location_lat),
  //         parseFloat(res.data.overtime_location_lon),
  //       ]);
  //       setOvertimeData({
  //         attLocation: [
  //           parseFloat(res.data.overtime_location_lat),
  //           parseFloat(res.data.overtime_location_lon),
  //         ],
  //         attRadius: parseInt(res.data.radius),
  //         attLocationName: res.data.overtime_location_name,
  //         attLocationCode: res.data.overtime_location_code,
  //         attTimeIn: res.data.start_from,
  //         attTimeOut: res.data.ends_on,
  //         attType: "overtime",
  //         attCode: "LMBR",
  //         // attDayType: res.data.overtime_day_type,
  //         // attLateTolerance: res.data.late_tolerance,
  //         // attBreakStart: res.data.time_break_start,
  //         // attBreakDuration: res.data.break_duration,
  //       });
  //       //check status kehadiran, apakah sudah absen lembur atau belum

  //       // habis ashar
  //       API.checkAttendanceOvertimeOfEmployee(
  //         token,
  //         employee.id,
  //         tahun_bulan_tanggal(today)
  //       )
  //         .then((res) => {
  //           // console.log(res.data);
  //           setOvertimeAttendance(res.data);
  //         })
  //         .catch((err) => {
  //           // console.log('belum absen lembur')
  //           console.log(err.response.data.message);
  //           setOvertimeAttendance(null);

  //           // console.log(err.response.data.message);
  //         });
  //     })
  //     .catch((err) => {
  //       // console.log(err)
  //       setOvertimeData(null);
  //     });
  // }, [attendanceStatus]);

  useEffect(() => {
    console.log(
    'Call API to Get Today Schedule, Today Attendance, Yesterday Attendance',
    );
    API.getMyTodayAttendanceData(token, employee.id, tahun_bulan_tanggal(today))
    .then(res => {
        setAttendanceData(res.data);
        const {schedule, attendance, yesterday_attendance} = res.data;

          if (!schedule) {
            //jika schedule null/tidak ada jadwal
            if (!yesterday_attendance) {
              //tidak ada data absen kemarin
              // return 'Libur! Tidak ada jadwal kerja hari ini.';
              setIsHaveScheduleToday(false);
            } else {
              //ada absen hari kemarin
              setAttendance(yesterday_attendance);

              // console.log(
              //   'yesterday_attendance tidak ada jadwal :',
              //   yesterday_attendance,
              // );
              if (!yesterday_attendance.time_out) {
                //absen kemarin belum absen pulang
                // return 'belum absen pulang';
                //setSchedule jadwal absen kemarin
                setWorkLocation([
                  parseFloat(yesterday_attendance.lat_schedule),
                  parseFloat(yesterday_attendance.lng_schedule),
                ]);
                setWorkLocation2([
                  yesterday_attendance.lat_schedule_2
                      ? parseFloat(yesterday_attendance.lat_schedule_2)
                      : parseFloat(yesterday_attendance.lat_schedule),
                  yesterday_attendance.lng_schedule_2
                      ? parseFloat(yesterday_attendance.lng_schedule_2)
                      : parseFloat(yesterday_attendance.lng_schedule),
                ]);
                setScheduleData({
                  attLocation: [
                    yesterday_attendance.lat_schedule
                      ? parseFloat(yesterday_attendance.lat_schedule)
                      : 0,
                    yesterday_attendance.lng_schedule
                      ? parseFloat(yesterday_attendance.lng_schedule)
                      : 0,
                  ],
                  attLocation2: [
                    yesterday_attendance.lat_schedule_2
                      ? parseFloat(yesterday_attendance.lat_schedule_2)
                      : parseFloat(yesterday_attendance.lat_schedule),
                    yesterday_attendance.lng_schedule_2
                      ? parseFloat(yesterday_attendance.lng_schedule_2)
                      : parseFloat(yesterday_attendance.lng_schedule),
                  ],
                  attRadius: yesterday_attendance.radius || 50,
                  attRadius2: yesterday_attendance.location_2_radius || 50,
                  attLocationName:
                    yesterday_attendance.location_name || 'Lokasi Absen',
                  attLocationCode: yesterday_attendance.location_code || '--',
                  attLocationName2:
                    yesterday_attendance.location_2_name || 'Lokasi Absen 2',
                  attLocationCode2:
                    yesterday_attendance.location_2_code || '--',
                  attTimeIn: yesterday_attendance.time_in_schedule,
                  attTimeOut: yesterday_attendance.time_out_schedule,
                  attType: 'shift',
                  attCode: yesterday_attendance.shift_code || '--',
                  attLateTolerance: yesterday_attendance.late_tolerance,
                  attBreakStart: yesterday_attendance.time_break_start,
                  attBreakDuration: yesterday_attendance.break_duration,
                });
                setIsHaveScheduleToday(true);
              } else {
                setIsHaveScheduleToday(false);
              }
            }
          } else {
            // return 'ada jadwal';
            //cek absen kemarin dulu, masih ada atau sudah beres
            if (!yesterday_attendance) {
              //jika kdada absen kemarin
              //setSchedule jadwal hari ini
              setAttendance(attendance ? attendance : null);
              setWorkLocation([
                parseFloat(schedule.latitude),
                parseFloat(schedule.longitude)
              ]);
              setWorkLocation2([
                schedule.location_2_latitude
                    ? parseFloat(schedule.location_2_latitude)
                    : parseFloat(schedule.latitude),
                schedule.location_2_longitude
                    ? parseFloat(schedule.location_2_longitude)
                    : parseFloat(schedule.longitude),
              ]);
              setScheduleData({
                attLocation: [
                  schedule.latitude ? parseFloat(schedule.latitude) : 0,
                  schedule.longitude ? parseFloat(schedule.longitude) : 0,
                ],
                attLocation2: [
                  schedule.location_2_latitude
                    ? parseFloat(schedule.location_2_latitude)
                    : parseFloat(schedule.latitude),
                  schedule.location_2_longitude
                    ? parseFloat(schedule.location_2_longitude)
                    : parseFloat(schedule.longitude),
                ],
                attRadius: parseInt(schedule.radius),
                attRadius2: parseInt(schedule.location_2_radius),
                attLocationName: schedule.location_name,
                attLocationCode: schedule.location_code,
                attLocationName2: schedule.location_2_name,
                attLocationCode2: schedule.location_2_code,
                attTimeIn: attendance
                  ? attendance.time_in_schedule
                  : schedule.time_in,
                attTimeOut: attendance
                  ? attendance.time_out_schedule
                  : schedule.time_out,
                attType: 'shift',
                attCode: schedule.shift_code,
                attLateTolerance: schedule.late_tolerance,
                attBreakStart: schedule.break_start,
                attBreakDuration: schedule.break_duration,
              });
              setIsHaveScheduleToday(true);
            } else {
              //jika ada absen kemarin

              if (!yesterday_attendance.time_out) {
                setAttendance(yesterday_attendance);

                //jika absen kemarin belum absen pulang
                //setSchedule jadwal absen kemarin
                // console.log(
                //   'yesterday_attendance, ada jadwal:',
                //   yesterday_attendance,
                // );
                setWorkLocation([
                  parseFloat(yesterday_attendance.lat_schedule),
                  parseFloat(yesterday_attendance.lng_schedule)
                ]);
                setWorkLocation2([
                  yesterday_attendance.lat_schedule_2
                      ? parseFloat(yesterday_attendance.lat_schedule_2)
                      : parseFloat(yesterday_attendance.lat_schedule),
                  yesterday_attendance.lng_schedule_2
                      ? parseFloat(yesterday_attendance.lng_schedule_2)
                      : parseFloat(yesterday_attendance.lng_schedule),
                ]);
                setScheduleData({
                  attLocation: [
                    yesterday_attendance.lat_schedule
                      ? parseFloat(yesterday_attendance.lat_schedule)
                      : 0,
                    yesterday_attendance.lng_schedule
                      ? parseFloat(yesterday_attendance.lng_schedule)
                      : 0,
                  ],
                  attLocation2: [
                    yesterday_attendance.lat_schedule_2
                      ? parseFloat(yesterday_attendance.lat_schedule_2)
                      : parseFloat(yesterday_attendance.lat_schedule),
                    yesterday_attendance.lng_schedule_2
                      ? parseFloat(yesterday_attendance.lng_schedule_2)
                      : parseFloat(yesterday_attendance.lng_schedule),
                  ],
                  attRadius: yesterday_attendance.radius || 50,
                  attRadius2: yesterday_attendance.location_2_radius || 50,
                  attLocationName:
                    yesterday_attendance.location_name || 'Lokasi Absen',
                  attLocationCode: yesterday_attendance.location_code || '--',
                  attLocationName2:
                    yesterday_attendance.location_2_name || 'Lokasi Absen 2',
                  attLocationCode2:
                    yesterday_attendance.location_2_code || '--',
                  attTimeIn: yesterday_attendance.time_in_schedule,
                  attTimeOut: yesterday_attendance.time_out_schedule,
                  attType: 'shift',
                  attCode: yesterday_attendance.shift_code || '--',
                  attLateTolerance: yesterday_attendance.late_tolerance,
                  attBreakStart: yesterday_attendance.time_break_start,
                  attBreakDuration: yesterday_attendance.break_duration,
                });

                setIsHaveScheduleToday(true);
              } else {
                //jika sudah absen pulang
                //setSchedule jadwal hari ini
                // console.log('jadwal hari ini', schedule);
                setAttendance(attendance ? attendance : null);
                setWorkLocation([
                  parseFloat(schedule.latitude),
                  parseFloat(schedule.longitude)
                ]);
                setWorkLocation2([
                  schedule.location_2_latitude
                      ? parseFloat(schedule.location_2_latitude)
                      : parseFloat(schedule.latitude),
                    schedule.location_2_longitude
                      ? parseFloat(schedule.location_2_longitude)
                      : parseFloat(schedule.longitude),
                ]);
                setScheduleData({
                  attLocation: [
                    schedule.latitude ? parseFloat(schedule.latitude) : 0,
                    schedule.longitude ? parseFloat(schedule.longitude) : 0,
                  ],
                  attLocation2: [
                    schedule.location_2_latitude
                      ? parseFloat(schedule.location_2_latitude)
                      : parseFloat(schedule.latitude),
                    schedule.location_2_longitude
                      ? parseFloat(schedule.location_2_longitude)
                      : parseFloat(schedule.longitude),
                  ],
                  attRadius: parseInt(schedule.radius),
                  attRadius2: parseInt(schedule.location_2_radius),
                  attLocationName: schedule.location_name,
                  attLocationCode: schedule.location_code,
                  attLocationName2: schedule.location_2_name,
                  attLocationCode2: schedule.location_2_code,
                  attTimeIn: attendance
                    ? attendance.time_in_schedule
                    : schedule.time_in,
                  attTimeOut: attendance
                    ? attendance.time_out_schedule
                    : schedule.time_out,
                  attType: 'shift',
                  attCode: schedule.shift_code,
                  attLateTolerance: schedule.late_tolerance,
                  attBreakStart: schedule.break_start,
                  attBreakDuration: schedule.break_duration,
                });
                setIsHaveScheduleToday(true);
              }
            }
          }
    })
    .catch(err => {
        console.error(err);
    });


   
  }, [attendanceStatus]);

  useEffect(() => {
    //dapatkan posisi user setiap ... detik sekali
    const interval = setInterval(() => {
      // console.log('This will run every second!');
      if ("geolocation" in navigator) {
        // console.log('geolocation available');
        navigator.geolocation.getCurrentPosition((position) => {
          // console.log(position.coords.latitude)
          // console.log(position.coords.longitude)
        //   console.log(position)
          const coords = [position.coords.latitude, position.coords.longitude];

          if (isArraySame(coords, location)) {
            // console.log('sama')
            return;
          } else {
            // console.log('posisi berubah')
            setLocation(coords);
            setDistance(getDistance(workLocation, coords));
            setDistance2(getDistance(workLocation2, coords));

            setTime(jam_menit_detik());
          }
        });
      } else {
        setLocation(workLocation);

        // console.log('geolocation not available')
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [location, workLocation]);

  return (
    <>
      <PageHeader
        title="Rekam Kehadiran"
        subtitle={props.user.client_id}
        name={props.user.name}
        photo={iconUser}
        mobileTitle="Rekam Kehadiran"
      />
      <RecordAttendanceWrapper>
        {(() => {
          if (employee.need_attendance === 1) {
            // console.log('perlu absen')
            //check apakah ada jadwal
            if (isHaveScheduleToday) {
              // console.log('ada jadwal')
              if (cameraOpen) {
                if (attachment !== null) {
                  // alert('ada gambar');
                  return (
                    <div style={styles.container}>
                      <div style={styles.preview}>
                        <img
                          src={URL.createObjectURL(attachment)} style={{maxWidth: '100%', maxHeight: '100%'}}
                        />
                      </div>
                      <div style={styles.imageControl}>
                        <button
                          style={{backgroundColor: '#aaa',
                          color: '#fff',
                          borderRadius: 5,
                          width: 150,
                          height: 40,
                          paddingHorizontal: 50,
                          alignItems: 'center',
                          justifyContent: 'center',
                          textAlign: 'center',}}
                          onClick={() => setAttachment(null)}
                        >
                          Ulang
                        </button>
                        <button
                          style={styles.imageButton}
                          onClick={() => {
                            setAttachmentName("Foto Absen");
                            attendanceType === "Absen Masuk"
                              ? absenMasuk()
                              : absenPulang();
                            setCameraOpen(!cameraOpen);
                          }}
                        > 
                            {attendanceType}
                        </button>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <GetPicture
                      getPhoto={getPhoto}
                      openCamera={openCamera}
                    />
                  );
                }
              } else {
                return (
                  <AttendanceMap
                    schedule={scheduleData}
                    time={time}
                    attendance={attendance}
                    overtime={overtimeData}
                    userLocation={location}
                    zoom={14}
                    distance={distance}
                    distance2={distance2}
                    absenMasuk={absenMasuk}
                    absenPulang={absenPulang}
                    openCamera={openCamera}
                    attendanceData={attendanceData}
                  />
                );
              }
            } else {
              //tidak ada jadwal
              if (overtimeData) {
                // console.log('ada lembur')
                return (
                  <>
                    <h3>Libur! Tapi ada jadwal lembur hari ini.</h3>
                    <Link to="/attendance/record/overtime" className="back">
                      Absen Lembur &rsaquo;
                    </Link>
                  </>
                );
              } else {
                return (
                  <>
                    <h3>Libur! Tidak ada jadwal hari ini.</h3>
                    <Link to="/attendance" className="back">
                      &lsaquo; kembali
                    </Link>
                  </>
                );
              }
            }
          } else {
            // console.log('tidak perlu absen')
            return (
              <>
                <h3>Kamu tidak perlu melakukan absensi.</h3>
                <Link to="/attendance" className="back">
                  &lsaquo; kembali
                </Link>
              </>
            );
          }
        })()}
      </RecordAttendanceWrapper>
    </>
  );
};

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    maxWidth: '100%',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: 'black',
  },
  preview: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    // maxHeight: '100%',
    maxWidth: '100vw',
  },
  imageControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    left: 0,
    justifyContent: 'space-evenly',
    paddingHorizontal: 20,
    backgroundColor: 'black',
    // maxWidth: '100%',
    padding: 20,
  },

  imageButton: {
    // flex: 1,
    backgroundColor: 'var(--header-page)',
    color: '#fff',
    borderRadius: 5,
    width: 150,
    height: 40,
    paddingHorizontal: 50,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  not_found_container: {
    paddingHorizontal: 25,
    paddingVertical: 10,
    height: '100vh',
  },
  illustration: {
    marginBottom: 10,
  },
}

const reduxState = (state) => ({
  isLogin: state.isLogin,
  user: state.user,
  isLoading: state.isLoading,
});

const reduxDispatch = (dispatch) => ({
  loading: (data) => dispatch(setLoading(data)),
});
export default connect(reduxState, reduxDispatch)(RecordAttendance);
