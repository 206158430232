export let fieldsPerDatePerEmployee = [

    {   control: 'select',
        options:  [
            { key: '-- Pilih Lokasi/Area Kerja --', value: '' },

        ],
        label: 'Lokasi/Area Kerja',
        name: 'work_location',        
    },
    {   control: 'select',
        options:  [
            { key: '-- Pilih Lokasi/Area Kerja 2 --', value: '' },

        ],
        label: 'Lokasi/Area Kerja 2',
        name: 'work_location_2',        
    },
    {   control: 'select',
        options:  [
            { key: '-- Pilih Jam Kerja / Shift --', value: '' },

        ],
        label: 'Jam Kerja / Shift',
        name: 'work_shift'
    },
];