import React from 'react'
import { Formik, Form } from "formik";
import { AutoCompleteSelect, FormControl } from '../../../../components';
import {getStyle} from '../../../../utils/helpers/errorMessage'
import API from '../../../../config/api';
import { tahun_bulan_tanggal } from '../../../../utils/helpers/date';
import * as Yup from "yup";


const schemaValidation = Yup.object().shape({
  loan_id: Yup.object()
      .shape({
          label: Yup.string(),
          value: Yup.string()
      })
      .nullable()
      .required("Pinjaman Tidak Boleh Kosong"),

 
});




const InstallmentForm = ({loanOptions, token, closeModal, isAddOrEdit, initialValues}) => {
  

  const handleSubmit = (values, { resetForm, setSubmitting }) => {

    
    values.loan_id = values.loan_id.value
    
    // values = JSON.stringify(values)
    // console.log("handleSubmit values", values);
    if(isAddOrEdit === 'add'){

      API.addLoanInstallmentEmployee(token, values).then(res => {
        // console.log(res)
        // console.log(res.data.message);
        swal({
            title: res.data.status,
            text: res.data.message,
            icon: "success",
        });
      return closeModal();

        
      }).catch(err => {
          console.log(err.response);
          swal({
              title: err.status,
              // text: err.response.data.message,
              icon: "error",
          });


      });
    }else {
      // console.log('edit')
      API.editLoanInstallmentEmployee(token, values).then(res => {
        // console.log(res);
        swal({
            title: res.data.status,
            text: res.data.message,
            icon: "success",
        });
        return closeModal();


      }).catch(err => {
          // console.log(err);
          swal({
              title: err.status,
              text: err.message,
              icon: "error",
          });
          return closeModal();


      });
    }
  

 
  };
    return (
      <Formik
          initialValues={initialValues}
          onSubmit={(values, props) => handleSubmit(values, props)}
          validationSchema={() => schemaValidation}
          >
      {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            isSubmitting
          }) => (
            <div className="modal-body">

            <Form>
            
                <AutoCompleteSelect
                    id="loan_id"
                    name="loan_id"
                    label="Pinjaman"
                    placeholder="Pilih Pinjaman..."
                    options={loanOptions}
                    value={values.loan_id}
                    isMulti={false}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.loan_id}
                    error={errors.loan_id}
                    isClearable={true}
                    backspaceRemovesValue={true}
                />

                <p style={{fontSize: '9px', fontWeight: 'bold', fontStyle: 'italic', padding: '0 50px'}}>*angsuran pinjaman yang dipilih akan berkurang 1x</p>

              <button type="submit" className="add-button add-bulk-schedule" disabled={isSubmitting}>
                <h3>Simpan</h3>
              </button>
            </Form>
            </div>
          )}
        </Formik>
    );
}

export default InstallmentForm

