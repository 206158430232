import React, { useEffect, useRef, useState } from 'react'
import { CircleMarker, MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { iconLeft, iconUser } from '../../../../assets'
import { Col, FilterMonth, FilterYear, Gap, Icon, PageContentMenu, PageHeader, Row } from '../../../../components'
import API from '../../../../config/api'
import { getDistance } from '../../../../utils/helpers/attendance'
import { apakahHariMinggu, bulan_indo, format_tanggal_indo, getDaysInMonth, nama_hari, tahun_bulan_tanggal, time_ago } from '../../../../utils/helpers/date'
import { AvatarImage, Content, Employee, FilterGroup, HeaderGroup, ListEmployee, ListGroup, ListInfo, ListInfoText, ListInfoTextSpan, ListInfoTitle, SmallAvatar } from './location-detail-elements'

const date = new Date();
const DEFAULT_LATITUDE = -3.339414495831897;
const DEFAULT_LONGITUDE = 114.60118618319521;
const default_location = [DEFAULT_LATITUDE, DEFAULT_LONGITUDE]

const EmployeeLocationDetail = (props) => {
    const division = props.location.state;
    const [groups, setGroups] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(0);
    const [group, setGroup] = useState(null);
    const [workLocation, setWorkLocation] = useState(null);
    const [employeeLocation, setEmployeeLocation] = useState(null);
    const [centerMap, setCenterMap] = useState([DEFAULT_LATITUDE, DEFAULT_LONGITUDE]);
    const markerRef = useRef(null);
    function ChangeView({ center, zoom }) {
        const map = useMap();
        // console.log(map)
        map.setView(center, zoom);
        return null;

    }

    const handleClickEmpLoc = (lat, long) => {
        // setIsHovered(id)

        setCenterMap([lat, long]);
        // console.log('lat', lat)
        // console.log('long', long)
        markerRef.current.openPopup()
    }
    const token = props.user.token;
    useEffect(() => {
        //get division by client_id
        // console.log('render pertama kali')
        API.getTeamGroupByDivisionID(token, division.id).then((res) => {
            // console.log(res)
            setGroups(res.data)


        }).catch(err => {
            console.log(err.response);
            // console.log(err.response.data.message);
        })
    }, [])


    useEffect(() => {
        // console.log(division)
        const data = {
            division_id: division.id,
            group_id: selectedGroup,
            date: tahun_bulan_tanggal(date),
        }
        // console.log(`request attendance group id ${selectedGroup} di tanggal ${tahun_bulan_tanggal(date)}`)
        API.getEmployeeLocation(token, data).then(res => {
            // console.log(res.data.data)
            setEmployeeLocation(res.data.data.employee_locations)
            setWorkLocation(res.data.data.work_locations)

        }).catch(err => {
            console.log(err)
        })
    }, [selectedGroup])






    const handleChangeGroup = (group) => {
        setSelectedGroup(group.id)
        setGroup(group)
    }

    const fillBlueOptions = { fillColor: 'blue' }



    return (
        <>
            <PageHeader
                title={`Lokasi Divisi ${division.name}`}
                subtitle={props.user.client_id}
                name={props.user.name}
                photo={iconUser}
            />
            <Gap height={20} />
            <HeaderGroup>
                <div>
                    <Link to='/employee/location' className="back-button" >
                        <Icon icon={iconLeft} color="#fff" />
                        <p>Back</p>
                    </Link>
                </div>

                <ListGroup >
                    <FilterGroup selectedGroup={selectedGroup == 0 ? 'active' : ''} onClick={() => handleChangeGroup({ id: 0, public_holiday_is_off: 0 })}>
                        Semua
                    </FilterGroup>
                    {/* <p className={`filter-group ${selectedGroup == 0 ? 'active' : ''}`} onClick={() => handleChangeGroup({ id: 0, public_holiday_is_off: 0 })}>Semua</p> */}
                    {groups && groups.map(group => (
                        <FilterGroup selectedGroup={selectedGroup == group.id ? 'active' : ''} onClick={() => handleChangeGroup(group)}>
                            {group.name}
                        </FilterGroup>
                        // <p key={group.name} className={`filter-group ${selectedGroup == group.id ? 'active' : ''}`} onClick={() => handleChangeGroup(group)}>
                        //     {group.name}
                        // </p>
                    ))
                    }
                </ListGroup>
            </HeaderGroup>
            <Content>
                <MapContainer center={centerMap} zoom={10} animate={true} scrollWheelZoom={false} id="attendance-map">
                    <ChangeView center={centerMap} zoom={10} />

                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {workLocation && workLocation.map(workLoc => (
                        <CircleMarker key={workLoc.latitude} center={[Number(workLoc.latitude), Number(workLoc.longitude)]} pathOptions={fillBlueOptions} radius={workLoc.radius_attendance} key={`loc-${workLoc.name}`}>
                            <Popup>{workLoc.name}</Popup>
                        </CircleMarker>
                    ))}
                    {employeeLocation && employeeLocation.map(empLoc => (
                        <Marker position={[empLoc.latnow ? empLoc.latnow : 0, empLoc.longnow ? empLoc.longnow : 0]} key={empLoc.employee_id} ref={markerRef}>

                            <Popup id={empLoc.id}>
                                {`${empLoc.employee_name} | ${empLoc.position_name}`}
                                <br />
                                {workLocation && workLocation.map(workLoc => {

                                    // let distance = getDistance([workLoc.latitude, workLoc.longitude], [empLoc.latnow, empLoc.longnow]);
                                    return <div key={workLoc.name} dangerouslySetInnerHTML={{ __html: `<br />${Math.abs(getDistance([workLoc.latitude, workLoc.longitude], [empLoc.latnow, empLoc.longnow])) > 999 ? Math.sign(getDistance([workLoc.latitude, workLoc.longitude], [empLoc.latnow, empLoc.longnow])) * ((Math.abs(getDistance([workLoc.latitude, workLoc.longitude], [empLoc.latnow, empLoc.longnow])) / 1000).toFixed(1)) + ' KM' : Math.sign(getDistance([workLoc.latitude, workLoc.longitude], [empLoc.latnow, empLoc.longnow])) * Math.abs(getDistance([workLoc.latitude, workLoc.longitude], [empLoc.latnow, empLoc.longnow])).toFixed(0) + ' meter'} dari ${workLoc.name}` }} />

                                })}
                            </Popup>
                        </Marker>
                    ))}


                    {/* <CircleMarker center={schedule.attLocation} pathOptions={distance <= schedule.attRadius ? greenOptions : redOptions} radius={schedule.attRadius}>
                    <Popup>{schedule.attLocationName} | {distanceText} dari lokasimu</Popup>
                </CircleMarker>
                <Marker position={userLocation} ref={markerRef}>
                <Popup>
                    Lokasimu. <br /> {distanceText} dari lokasi absen.

                </Popup>
                </Marker> */}

                </MapContainer>
                <ListEmployee >
                    <p>Karyawan : {employeeLocation && employeeLocation.length}</p><br />
                    <p>Terlacak: {employeeLocation && employeeLocation.filter(empL => empL.longnow !== null).length}</p>


                    <Employee>
                        {
                            employeeLocation && employeeLocation.map(empL => empL.longnow && (
                                <div className="list-item" key={`loc-${empL.employee_id}`} onClick={() => handleClickEmpLoc(empL.latnow, empL.longnow)}>
                                    <SmallAvatar >
                                        <AvatarImage src={`/images/avatar.jpg`} alt="avatar" style={{ borderRadius: '50%' }} />
                                    </SmallAvatar>
                                    <ListInfo>
                                        <ListInfoTitle>{empL.employee_name}</ListInfoTitle>
                                        <ListInfoText>{empL.position_name} <ListInfoTextSpan>- {empL.division_name}</ListInfoTextSpan></ListInfoText>
                                    </ListInfo>
                                    <div className="list-right-info">
                                        {time_ago(empL.updated_at)}
                                    </div>
                                </div>

                            ))
                        }
                    </Employee>


                </ListEmployee>
            </Content >

        </>
    )
}

const reduxState = (state) => ({
    isLogin: state.isLogin,
    user: state.user,
    isLoading: state.isLoading

})


const reduxDispatch = (dispatch) => ({
    loading: (data) => dispatch(setLoading(data)),



})
export default connect(reduxState, reduxDispatch)(EmployeeLocationDetail)
