import React from 'react'

const OvertimeDetail = (props) => {
    return (
        <div>
            detail lembur, tampilan surat perintah lembur
        </div>
    )
}

export default OvertimeDetail
