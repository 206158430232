import React, { useEffect, useState } from 'react';
import { FormControl } from '../../../../components';
import { Form, Formik } from 'formik';
import { getStyle } from '../../../../utils/helpers/errorMessage';
import { Auth } from '../../../../config/api/ResourceURL';
import API from '../../../../config/api';
import Kapal from '../../../../assets/image/kapal.svg';
import swal from 'sweetalert';
import * as Yup from 'yup';
import { iconDelete1, iconChecklist, iconDeleteRed, iconChecklistRed } from '../../../../assets';
import { Action, ActionContainer, CallToAction, EditButton, FormContainer, FormContent, Image, SaveButton, SectionContent, SectionHeader, SectionWrapper, Subsection, SubTitle, Title } from '../HomePage-elements';

const token = localStorage.getItem('token');

const LayananSection = (props) => {
    const { itemFeature, item, edit, initialValuesLayanan, schemaValidationLayanan, setLoading, getDataMainSection } = props;
    const [message, setMessage] = useState('');
    const [isLayanan, setIsLayanan] = useState(false);
    const [isEditLayanan, setIsEditLayanan] = useState(false);
    const [isDisabled, setIsDisable] = useState(false);
    const [isSaveDisabled, setIsSaveDisable] = useState(false);
    const handleLayanan = (values) => {
        console.log('initialValuesLayanan', values)
        const formData = new FormData();
        formData.append(`title`, values?.title);
        formData.append(`subtitle`, values?.title);
        formData.append(`name_section`, "Layanan Section");
        formData.append(`page_type`, "Home");

        API.EditHomePageMainSection(token, formData, item.id)
            .then(res => {

            })
            .catch(err => {
                console.error(err.message);
                setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                swal({
                    title: err.response?.status || 'Error',
                    text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                    icon: "error",
                });
            });
    };
    const handleSubmit = (values, { resetForm, setSubmitting }) => {
        console.log('initialValuesLayanan', values)
        setLoading(true);
        handleLayanan(values)
        const formData = new FormData();
        for (let i = 0; i < values?.mainData.length; i++) {
            formData.append(`title[${i}]`, values?.mainData[i].title);
            formData.append(`id[${i}]`, values?.mainData[i].id);
            formData.append(`image[${i}]`, values?.mainData[i].image);
            formData.append(`subtitle[${i}]`, 111);
            formData.append(`name_section[${i}]`, 'Layanan Feature');
            formData.append(`page_type[${i}]`, 'Home');
            formData.append(`call_to_action[${i}]`, 1);
            formData.append(`button_text[${i}]`, values?.mainData[i].button_text);
            formData.append(`link[${i}]`, values?.mainData[i].link);
            for (let sub = 0; sub < values?.mainData[i]?.subsection.length; sub++) {
                formData.append(`sub_title[${i}][${sub}]`, values?.mainData[i]?.subsection[sub]?.title);
                formData.append(`sub_id[${i}][${sub}]`, values?.mainData[i]?.subsection[sub]?.id);
            }
        }

        API.EditHomePageMainSection(token, formData, values.id)
            .then(res => {
                setMessage('Data Berhasil Diperbarui');
                swal({
                    title: 'Berhasil',
                    text: 'Layanan Section Berhasil Diubah',
                    icon: "success",
                });
                setIsEditLayanan(false);
                getDataMainSection();
                setSubmitting(false);
                setLoading(false);
                setIsDisable(false);
            })
            .catch(err => {
                console.error(err.message);
                setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                swal({
                    title: err.response?.status || 'Error',
                    text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                    icon: "error",
                });
                setIsEditLayanan(false);
                setLoading(false);
                setIsDisable(false);
            });
    };

    const handleAddMainData = (setFieldValue, values) => {
        const newMainData = { title: '', subtitle: '' };
        setFieldValue('mainData', [...values.mainData, newMainData]);
        setIsDisable(true);
        setIsSaveDisable(true);
    };

    const handleAddSubData = (setFieldValue, values, index) => {
        const newSubData = { title: '', subtitle: '' };
        const updatedSubsection = [...(values.mainData[index]?.subsection || [])];
        updatedSubsection.push(newSubData);
        const updatedMainData = [...values.mainData];
        updatedMainData[index] = {
            ...updatedMainData[index],
            subsection: updatedSubsection,
        };
        setFieldValue('mainData', updatedMainData);
        setIsDisable(true);
        setIsSaveDisable(true);
        setIsSaveDisable(true);
    };

    const handleSaveMainSection = (values, index) => {
        console.log('values', values)
        setLoading(true);
        const formData = new FormData();
        formData.append('name_section', "Layanan Feature");
        formData.append('title', values?.mainData[values?.mainData?.length - 1]?.title);
        formData.append('subtitle', values?.mainData[values?.mainData?.length - 1]?.title);
        formData.append('button_text', values?.mainData[values?.mainData?.length - 1]?.button_text);
        formData.append('link', values?.mainData[values?.mainData?.length - 1]?.link);
        formData.append('image', values?.mainData[values?.mainData?.length - 1]?.image);
        API.addHomePageMainSection(token, formData, values?.mainData[index]?.id)
            .then(res => {
                console.log('res', res?.data?.data?.id)
                values.mainData[values.mainData.length - 1].id = res?.data?.data?.id;
                values.mainData[values.mainData.length - 1].image = res?.data?.data?.image;
                values.mainData[values.mainData.length - 1].subtitle = res?.data?.data?.subtitle;
                values.mainData[values.mainData.length - 1].subsection = [];
                setMessage('Data Dasar Berhasil Ditambahkan');
                swal({
                    title: 'Berhasil',
                    text: `Sub Section Berhasil Ditambah`,
                    icon: "success",
                });
                getDataMainSection();
                setIsDisable(false)
                setIsSaveDisable(false)
                setLoading(false);
            })
            .catch(err => {
                console.error(err.message);
                setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                swal({
                    title: err.response?.status || 'Error',
                    text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                    icon: "error",
                });
                setLoading(false);
            });
    }

    const handleSaveSubSection = (values, index, id, subIndex) => {
        console.log('values', values, initialValuesLayanan)
        setLoading(true);
        if (id) {
            const formData = new FormData();
            formData.append('title', values?.mainData[index]?.subsection[subIndex].title);
            formData.append('subtitle', values?.mainData[index]?.subsection[subIndex].title);

            API.EditHomePageSubSection(token, formData, id)
                .then(res => {
                    setMessage('Data Dasar Berhasil Ditambahkan');
                    values.mainData[values.mainData.length - 1].subsection[values.mainData[values.mainData.length - 1].subsection.length - 1].id = res?.data?.data?.id;
                    swal({
                        title: 'Berhasil',
                        text: `Sub Section Berhasil Ditambah`,
                        icon: "success",
                    });
                    getDataMainSection();
                    setIsDisable(false)
                    setIsSaveDisable(false)
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err.message);
                    setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                    swal({
                        title: err.response?.status || 'Error',
                        text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                        icon: "error",
                    });
                    setLoading(false);
                });
        } else {
            const formData = new FormData();
            formData.append('title', values?.mainData[index]?.subsection[values?.mainData[index]?.subsection?.length - 1].title);
            formData.append('subtitle', values?.mainData[index]?.subsection[values?.mainData[index]?.subsection?.length - 1].title);
            API.addHomePageSubSection(token, formData, values?.mainData[index]?.id)
                .then(res => {
                    setMessage('Data Dasar Berhasil Ditambahkan');
                    values.mainData[values.mainData.length - 1].subsection[values.mainData[values.mainData.length - 1].subsection.length - 1].id = res?.data?.data?.id;
                    console.log('values1123', values)
                    swal({
                        title: 'Berhasil',
                        text: `Sub Section Berhasil Ditambah`,
                        icon: "success",
                    });
                    getDataMainSection();
                    setIsDisable(false)
                    setIsSaveDisable(false)
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err.message);
                    setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                    swal({
                        title: err.response?.status || 'Error',
                        text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                        icon: "error",
                    });
                    setLoading(false);
                });
        }
    }

    const handleRemoveMainData = (setFieldValue, values, index, id) => {
        const updatedMainData = values.mainData.filter((_, idx) => idx !== index);
        setFieldValue('mainData', updatedMainData);
        if (updatedMainData.length === 0) setIsDisable(false);

        if (id) {
            API.deletHomePageMainSection(token, id)
                .then(res => {
                    setMessage('Sub section berhasil dihapus');
                    swal({
                        title: 'Berhasil',
                        text: `Sub section berhasil dihapus`,
                        icon: "success",
                    });
                    getDataMainSection();
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err.message);
                    setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                    swal({
                        title: err.response?.status || 'Error',
                        text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                        icon: "error",
                    });
                    setLoading(false);
                });
        } else {
            setIsDisable(false)
            setIsSaveDisable(false)
        }
    };

    const handleRemoveSubData = (setFieldValue, values, index, subIndex, id) => {
        const updatedSubData = values.mainData[index]?.subsection.filter((_, idx) => idx !== subIndex);
        setFieldValue(`mainData[${index}].subsection`, updatedSubData);
        if (updatedSubData.length === 0) setIsDisable(false);

        if (id) {
            API.deletHomePageSubSection(token, id)
                .then(res => {
                    setMessage('Sub section berhasil dihapus');
                    swal({
                        title: 'Berhasil',
                        text: `Sub section berhasil dihapus`,
                        icon: "success",
                    });
                    getDataMainSection();
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err.message);
                    setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                    swal({
                        title: err.response?.status || 'Error',
                        text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                        icon: "error",
                    });
                    setLoading(false);
                });
        } else {
            setIsDisable(false)
            setIsSaveDisable(false)
        }
    };

    return (
        <div>
            <SectionWrapper>
                <Formik
                    enableReinitialize
                    initialValues={initialValuesLayanan}
                    onSubmit={handleSubmit}
                    validationSchema={schemaValidationLayanan}
                >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        isSubmitting
                    }) => (
                        <Form>
                            <SectionHeader onClick={() => setIsLayanan(!isLayanan)}>
                                Layanan Pelanggan
                                {isEditLayanan ? (
                                    <div>
                                        <EditButton
                                            style={{ marginRight: "10px" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsEditLayanan(false);
                                                // setIsDisable(false)
                                                // setIsSaveDisable(false)
                                            }}
                                        >
                                            Cancel
                                        </EditButton>
                                        <SaveButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsEditLayanan(true);
                                            }}
                                            type="submit"
                                            disabled={isSubmitting || isSaveDisabled}
                                            isSaveDisabled={isSaveDisabled}
                                        >
                                            Save
                                        </SaveButton>
                                    </div>
                                ) : (
                                    <SaveButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsEditLayanan(true);
                                            setIsLayanan(true);
                                            edit(item, 'Layanan');
                                        }}
                                    >
                                        Edit
                                    </SaveButton>
                                )}
                            </SectionHeader>
                            <SectionContent isOpen={isLayanan}>
                                {isEditLayanan ? (
                                    <FormContainer className="left-form" >
                                        <FormControl control="input" type="text" label="Title" name="title"
                                            style={getStyle(errors, touched, 'title')}
                                        />
                                        {values.mainData?.map((item, index) => (
                                            <FormContent key={index}>
                                                <ActionContainer>
                                                    {
                                                        values.mainData?.length > 1 ? <Action onClick={() => handleRemoveMainData(setFieldValue, values, index, item?.id)}>
                                                            <img
                                                                src={iconDelete1}
                                                                width={"20px"}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </Action> : ""
                                                    }
                                                    {item?.id ? "" : <Action onClick={() => handleSaveMainSection(values, index)}>
                                                        <img
                                                            src={iconChecklist}
                                                            width={"20px"}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </Action>}
                                                </ActionContainer>
                                                <FormControl
                                                    control="file"
                                                    label={`image ${index + 1}`}
                                                    name={`mainData[${index}].image`}
                                                    style={getStyle(errors, touched, `mainData[${index}].image`)}
                                                    fileURL={item?.image || Kapal}
                                                />
                                                <FormControl
                                                    control="input"
                                                    type="text"
                                                    label={`Title ${index + 1}`}
                                                    name={`mainData[${index}].title`}
                                                    style={getStyle(errors, touched, `mainData[${index}].title`)}
                                                    value={item.title}
                                                    onChange={(e) => setFieldValue(`mainData[${index}].title`, e.target.value)}
                                                />
                                                <FormControl
                                                    control="input"
                                                    type="text"
                                                    label={`Button Text ${index + 1}`}
                                                    name={`mainData[${index}].button_text`}
                                                    style={getStyle(errors, touched, `mainData[${index}].button_text`)}
                                                    value={item.button_text}
                                                    onChange={(e) => setFieldValue(`mainData[${index}].button_text`, e.target.value)}
                                                />
                                                <FormControl
                                                    control="input"
                                                    type="text"
                                                    label={`Link ${index + 1}`}
                                                    name={`mainData[${index}].link`}
                                                    style={getStyle(errors, touched, `mainData[${index}].link`)}
                                                    value={item.link}
                                                    onChange={(e) => setFieldValue(`mainData[${index}].link`, e.target.value)}
                                                />
                                                <div style={{ backgroundColor: "#e0e3e5", borderRadius: "10px", padding: "20px", alignContent: "center" }}>
                                                    {values?.mainData[index]?.subsection?.map((subItem, subIndex) => (
                                                        <div key={subIndex}>
                                                            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                                                <div style={{ display: "flex", justifyContent: "center", width: "80%" }}>
                                                                    <ActionContainer style={{ justifyContent: "start" }}>
                                                                        <label style={{ marginRight: "10px" }}>Sub Title {subIndex + 1}</label>
                                                                        <img
                                                                            src={iconDeleteRed}
                                                                            width={"20px"}
                                                                            style={{ cursor: 'pointer', marginRight: "5px" }}
                                                                            onClick={() => handleRemoveSubData(setFieldValue, values, index, subIndex, subItem?.id)}
                                                                        />
                                                                        {subItem?.id ? "" : <img
                                                                            src={iconChecklistRed}
                                                                            width={"20px"}
                                                                            style={{ cursor: 'pointer', marginRight: "5px" }}
                                                                            onClick={() => handleSaveSubSection(values, index, subItem?.id, subIndex)}
                                                                        />}
                                                                    </ActionContainer>
                                                                </div>
                                                            </div>
                                                            <FormControl
                                                                control="input"
                                                                type="text"
                                                                name={`mainData[${index}].subsection[${subIndex}].title`}
                                                                style={getStyle(errors, touched, `mainData[${index}].subsection[${subIndex}].title`)}
                                                                value={subItem.title}
                                                                onChange={(e) => setFieldValue(`mainData[${index}].subsection[${subIndex}].title`, e.target.value)}
                                                            />
                                                        </div>
                                                    ))}
                                                    {
                                                        isDisabled == false ? <button type="button" className="save-button" onClick={() => handleAddSubData(setFieldValue, values, index)}>
                                                            + Sub Data
                                                        </button> : ""
                                                    }
                                                </div>
                                            </FormContent>
                                        ))}
                                        {
                                            isDisabled == false ?
                                                <SaveButton
                                                    type="button"
                                                    onClick={() => handleAddMainData(setFieldValue, values)}
                                                    style={{ width: "100%", marginTop: "10px" }}
                                                >
                                                    Add Main Data
                                                </SaveButton> : ""
                                        }
                                    </FormContainer>
                                ) : (
                                    <div className="section-data">
                                        {console.log("item", itemFeature)}
                                        <div style={{ backgroundColor: "#E0E5E5", borderRadius: "10px", padding: "20px" }}>
                                            <Title>Title</Title>
                                            <SubTitle>{item?.title}</SubTitle>
                                        </div>
                                        <Subsection>
                                            <ul>
                                                {itemFeature?.map((data, index) => (
                                                    <li key={index}>
                                                        <Title>{data?.title}</Title>
                                                        <Image src={data?.image || Kapal} alt="Layanan Image" />
                                                        <Title>Call To Action</Title>
                                                        <CallToAction href={data?.link}>{data?.button_text} – {data?.link}</CallToAction>
                                                        <Title>Feature</Title>
                                                        <div style={{ marginLeft: "40px" }}>
                                                            <ul>
                                                                {
                                                                    data?.subsection?.map((subItem, index) => (
                                                                        <li key={index}>{subItem?.title}</li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Subsection>
                                    </div>
                                )}
                            </SectionContent>
                        </Form>
                    )}
                </Formik>
            </SectionWrapper>
        </div>
    );
};

export default LayananSection;
