import { hmsToSeconds, secondsToHMS } from "./date";

const attendanceText = (current_time, data) => {
  // console.log('current_time', current_time);
  function z(n) {
    return (n < 10 ? '0' : '') + n;
  }
  let minimal_jam_kerja = 60 * 60 * 8; // 8 jam
  // console.log('minimal_jam_kerja default', minimal_jam_kerja);
  let time = hmsToSeconds(current_time);
  const { schedule, attendance, yesterday_attendance } = data;
  // console.log('schedule', schedule);
  // console.log('attendance', attendance);
  // console.log('yesterday_attendance', yesterday_attendance);
  if (!schedule) {
    //jika schedule null/tidak ada jadwal
    if (!yesterday_attendance) {
      //tidak ada data absen kemarin
      return 'Libur! Tidak ada jadwal kerja hari ini.';
    } else {
      //ada absen hari kemarin
      if (!yesterday_attendance.time_out) {
        //absen kemarin belum absen pulang
        // return 'belum absen pulang';
        let jam_masuk = yesterday_attendance.time_in_schedule
          ? hmsToSeconds(yesterday_attendance.time_in_schedule)
          : null;
        let absen_masuk = hmsToSeconds(yesterday_attendance.time_in);
        let jam_pulang = hmsToSeconds(yesterday_attendance.time_out_schedule);
        let absen_pulang = yesterday_attendance.time_out
          ? hmsToSeconds(yesterday_attendance.time_out)
          : null;

        //cek apakah jam sekarang sudah masuk jam pulang?
        if (time >= jam_pulang) {
          // disini cek per case
          // return jam_pulang;
          // if (jam_masuk > jam_pulang) {
          //   //jika jam masuk lebih dari jam pulang
          //   return 'Terima kasih! Selamat bekerja.';
          // } else {
          return 'Yeay, jam kerja sudah selesai! Tapi sebelum pulang absen keluar dulu, yaa.. ';
          // }
        } else {
          // belum masuk jam pulang
          //cek durasi kerja apakah sudah 8 jam dari awal masuk?, jika iya maka boleh absen bulik
          //dapatkan durasi kerja dari awal absen masuk sampai jam sekarang

          // let duration =
          //   jam_pulang > jam_masuk
          //     ? absen_pulang - absen_masuk
          //     : 60 * 60 * 24 - absen_masuk + absen_pulang;

          // jika jam masuk lebih dari jam pulang

          if (
            absen_masuk >=
            hmsToSeconds(yesterday_attendance.time_break_start) +
            yesterday_attendance.break_duration * 60
          ) {
            // return 'sudah lewat jam istirahat';
            return 'Selamat bekerja kembali...';
          } else {
            // console.log(secondsToHMS(time - absen_masuk));
            // return 'belum lewat jam istirahat';
            return 'Terima kasih! Selamat bekerja.';
          }
        }
      } else {
        return 'Libur! Tidak ada jadwal kerja hari ini.';
      }
    }
  } else {
    // return 'ada jadwal';

    if (!attendance) {
      // return 'belum absen hari ini';
      //cek absen kemarin
      if (!yesterday_attendance) {
        //tidak ada absen kemarin
        // jadwal hari ini
        //cek apakah jam sekarang sudah dekat jam masuk?
        // let jam_masuk = hmsToSeconds(schedule.time_in);
        // let satu_jam = 60 * 60;
        // if (time >= jam_masuk || time >= jam_masuk - satu_jam) {
        //   return 'Sudah masuk jam kerja, segera lakukan presensi kehadiran.';
        // } else {
        //   return 'Kamu punya jadwal kerja hari ini, jangan lupa melakukan presensiii.';
        // }
        let jam_masuk = hmsToSeconds(schedule.time_in);
        let jam_pulang = hmsToSeconds(schedule.time_out);

        //cek apakah jam sekarang sudah dekat jam masuk?
        //waktu sebelum jam kerja berapa
        let waktuSisa = time - jam_masuk;
        let secs = Math.abs(waktuSisa);
        let jam = z((secs / 3600) | 0);
        jam = jam > 0 ? jam + ' jam ' : '';
        let menit = z(((secs % 3600) / 60) | 0);
        menit = menit > 0 ? menit + ' menit ' : '';
        let detik = z(secs % 60) + ' detik ';

        if (waktuSisa < 0) {
          // return 'belum masuk jam kerja';
          //jika waktu sisa lebih dari 1 jam
          if (secs > 60 * 60) {
            return 'Kamu punya jadwal kerja hari ini, jangan lupa melakukan presensi.';
          } else {
            // return 'waktu sisa kurang dari 1 jam';
            return (
              <p>
                <span style={{ fontWeight: 'bold' }}>{jam + menit + detik}</span>
                lagi jam kerjamu. Tapi kamu sudah bisa untuk melakukan absensi.
              </p>
            );
          }
        } else {
          let sisa_toleransi = schedule.late_tolerance * 60 - secs;
          let jamTelat = z((Math.abs(sisa_toleransi) / 3600) | 0);
          let menitTelat = z(((Math.abs(sisa_toleransi) % 3600) / 60) | 0);
          jamTelat = jamTelat > 0 ? jamTelat + ' jam ' : '';
          menitTelat = menitTelat > 0 ? menitTelat + ' menit.' : '';
          let jumlahTelat = jamTelat + menitTelat;
          // return sisa_toleransi;
          if (sisa_toleransi < 0) {
            // return 'Toleransi habis';
            if (time <= (jam_pulang - (60 * 60))) {
              // console.log('belum masuk jam pulang')
              return (
                <p>
                  Toleransi keterlambatan habis. Segera lakukan absen masuk, kamu
                  sudah telat{menitTelat ? '' : '.'}
                  <span style={{ fontWeight: 'bold' }}> {jumlahTelat}</span>
                </p>
              );


            } else {
              return (
                <p>
                  Kamu dianggap mangkir hari ini karena tidak melakukan absen masuk sampai jam kerja selesai.
                </p>
              );


            }
          } else {
            // return 'Masih ada toleransi';
            let textSudahMasuk = `Jam kerja sudah masuk ${jam + menit}${menit ? 'yang lalu' : ''
              }.`;
            return `${textSudahMasuk} ${menit ? '' : ''
              }Segera lakukan absen masuk sebelum jam ${secondsToHMS(
                jam_masuk + schedule.late_tolerance * 60,
              )}.`;
          }
        }
      } else {
        //ada absen kemarin
        let jam_masuk = hmsToSeconds(yesterday_attendance.time_in_schedule);
        let jam_pulang = hmsToSeconds(yesterday_attendance.time_out_schedule);

        if (!yesterday_attendance.time_out) {
          //durasi kerja, tombol absen keluar bila masuk jam keluar
          //absen kemarin belum absen pulang
          // return 'belum absen pulang';

          //cek apakah jam sekarang sudah masuk jam pulang?
          if (time >= jam_pulang) {
            // disini cek per case
            // return jam_pulang;
            return 'Yeay, jam kerja sudah selesai! Tapi sebelum pulang absen keluar dulu, yaa.. ';
          } else {
            // belum masuk jam pulang

            if (
              hmsToSeconds(yesterday_attendance.time_in) >=
              hmsToSeconds(yesterday_attendance.time_break_start) +
              yesterday_attendance.break_duration * 60
            ) {
              // return 'sudah lewat jam istirahat';
              return 'Selamat bekerja kembali...';
            } else {
              // return 'belum lewat jam istirahat';
              return 'Terima kasih! Selamat bekerja.';
              // console.log(secondsToHMS(time - absen_masuk));
            }
          }
        } else {
          //absen kemarin sudah absen keluar
          // jadwal hari ini
          jam_masuk = hmsToSeconds(schedule.time_in);

          //cek apakah jam sekarang sudah dekat jam masuk?
          //waktu sebelum jam kerja berapa
          let waktuSisa = time - jam_masuk;
          let secs = Math.abs(waktuSisa);
          let jam = z((secs / 3600) | 0);
          jam = jam > 0 ? jam + ' jam ' : '';
          let menit = z(((secs % 3600) / 60) | 0);
          menit = menit > 0 ? menit + ' menit ' : '';
          let detik = z(secs % 60) + ' detik ';

          if (waktuSisa < 0) {
            // return 'belum masuk jam kerja';
            //jika waktu sisa lebih dari 1 jam
            if (secs > 60 * 60) {
              return 'Kamu punya jadwal kerja hari ini, jangan lupa melakukan presensi.';
            } else {
              // return 'waktu sisa kurang dari 1 jam';
              return (
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    {jam + menit + detik}
                  </span>
                  lagi jam kerjamu. Tapi kamu sudah bisa untuk melakukan
                  absensi.
                </p>
              );
            }
          } else {
            let sisa_toleransi = schedule.late_tolerance * 60 - secs;
            let jamTelat = z((Math.abs(sisa_toleransi) / 3600) | 0);
            let menitTelat = z(((Math.abs(sisa_toleransi) % 3600) / 60) | 0);
            jamTelat = jamTelat > 0 ? jamTelat + ' jam ' : '';
            menitTelat = menitTelat > 0 ? menitTelat + ' menit.' : '';
            let jumlahTelat = jamTelat + menitTelat;
            // return sisa_toleransi;
            if (sisa_toleransi < 0) {
              // return 'Toleransi habis';
              if (time <= (jam_pulang - (60 * 60))) {
                // console.log('belum masuk jam pulang')
                return (
                  <p>
                    Toleransi keterlambatan habis. Segera lakukan absen masuk, kamu
                    sudah telat{menitTelat ? '' : '.'}
                    <span style={{ fontWeight: 'bold' }}> {jumlahTelat}</span>
                  </p>
                );


              } else {
                return (
                  <p>
                    Kamu dianggap mangkir hari ini karena tidak melakukan absen masuk sampai jam kerja selesai.
                  </p>
                );


              }

            } else {
              // return 'Masih ada toleransi';
              let textSudahMasuk = `Jam kerja sudah masuk ${jam + menit}${menit ? 'yang lalu' : ''
                }.`;
              return `${textSudahMasuk} ${menit ? '' : ''
                }Segera lakukan absen masuk sebelum jam ${secondsToHMS(
                  jam_masuk + schedule.late_tolerance * 60,
                )}.`;
            }
          }
        }
      }
    } else {
      // return 'sudah absen';
      if (!attendance.time_out) {
        // return 'belum absen pulang';

        let absen_masuk = hmsToSeconds(attendance.time_in);
        let jam_masuk = hmsToSeconds(attendance.time_in_schedule);
        let jam_pulang = hmsToSeconds(attendance.time_out_schedule);
        let absen_pulang = attendance.time_out
          ? hmsToSeconds(attendance.time_out)
          : time;
        if (time >= jam_pulang) {
          //jika waktu sekarang sudah lebih dari jam pulang
          // disini cek per case
          // return jam_pulang;
          if (jam_masuk > jam_pulang) {
            //jika jam masuk lebih dari jam pulang
            return 'Terima kasih! Selamat bekerja.';
          } else {
            return 'Yeay, jam kerja sudah selesai! Tapi sebelum pulang absen keluar dulu, yaa.. ';
          }
        } else {
          // belum masuk jam pulang
          let duration =
            jam_pulang > jam_masuk
              ? absen_pulang - absen_masuk
              : 60 * 60 * 24 - absen_masuk + absen_pulang;
          minimal_jam_kerja =
            jam_pulang > jam_masuk
              ? jam_pulang - jam_masuk
              : 60 * 60 * 24 - jam_masuk + jam_pulang;
          // console.log(
          //   'minimal_jam_kerja sesuai jadwal',
          //   secondsToHMS(minimal_jam_kerja),
          // );
          // console.log('duration', secondsToHMS(duration));
          if (duration >= minimal_jam_kerja) {
            // console.log('sudah lebih dari jam kerja');
            return 'Belum masuk jam pulang, tapi kamu sudah bisa absen keluar karena sudah memenuhi minimal jam kerja.';
          } else {
            // console.log('belum memenuhi minimal jam kerja');
            if (
              time >=
              hmsToSeconds(attendance.time_break_start) +
              attendance.break_duration * 60
            ) {
              // return 'sudah lewat jam istirahat';
              return 'Jam istirahat sudah habis, selamat bekerja kembali...';
            } else {
              // return 'belum lewat jam istirahat';
              return 'Terima kasih! Selamat bekerja.';
              // console.log(secondsToHMS(time - absen_masuk));
            }
          }
        }
      } else {
        // return 'sudah absen pulang';
        return 'Terima kasih! selamat istirahat';
      }
    }
  }
};

const overtimeText = (current_time, schedule, attendance) => {
  schedule.attTimeIn = (schedule.attTimeIn == '00:00:00') ? '24:00:00' : schedule.attTimeIn;
  function z(n) { return (n < 10 ? '0' : '') + n; }
  // let secs = Math.abs(current_time);
  // let jam = z(secs/3600 |0);
  // let menit = z((secs%3600) / 60 |0);
  // let detik = z(secs%60) + ' detik';
  if (attendance == null) { //jika belum absen
    // return "attendance text belum absen"
    //apabila jam sekarang belum jam kerja
    let waktuSisa = hmsToSeconds(current_time) - hmsToSeconds(schedule.attTimeIn);
    // console.log(waktuSisa);
    if (waktuSisa < 0) {
      // return 'belum masuk jam kerja';
      let secs = Math.abs(waktuSisa)
      let jam = z(secs / 3600 | 0);
      jam = jam > 0 ? jam + ' jam ' : '';
      let menit = z(secs % 3600 / 60 | 0);
      menit = menit > 0 ? menit + ' menit ' : '';
      let detik = z(secs % 60) + ' detik';


      //jika waktu sisa lebih dari 5 menit
      if (secs > (60 * 5)) {
        // return 'waktu sisa lebih dari 5 menit';
        return `<b>${jam + menit + detik}</b> sebelum masuk jadwal lembur. Jangan lupa untuk melakukan absen lembur.`;

      } else {
        // return 'waktu sisa kurang dari 5 menit';
        return `<b>${jam + menit + detik}</b> lagi jam lemburmu. Tapi kamu sudah bisa untuk melakukan absensi.`;

      }

    } else {
      //apabila masuk jam kerja
      // return 'sudha masuk jam kerja';
      let secs = Math.abs(waktuSisa)
      let jam = z(secs / 3600 | 0);
      jam = jam > 0 ? jam + ' jam ' : '';
      let menit = z(secs % 3600 / 60 | 0);
      menit = menit > 0 ? ' ' + menit + ' menit ' : '';
      let detik = z(secs % 60) + ' detik';
      let sisa_toleransi = (schedule.attLateTolerance * 60) - (secs);
      let jamTelat = z(Math.abs(sisa_toleransi) / 3600 | 0);
      let menitTelat = z(Math.abs(sisa_toleransi) % 3600 / 60 | 0);
      jamTelat = jamTelat > 0 ? jamTelat + ' jam ' : '';
      menitTelat = menitTelat > 0 ? menitTelat + ' menit.' : '';
      let jumlahTelat = jamTelat + menitTelat;


      //pemberitahuan jam kerja sudah lewat, tapi masih ada late tolerance
      if (hmsToSeconds(current_time) <= (hmsToSeconds(schedule.attTimeIn) + (schedule.attLateTolerance * 60))) {
        // return 'jam kerja sudah lewat tapi masih ada toleransi telat';
        let textSudahMasuk = `Jam lembur sudah masuk<b>${jam + menit} ${menit ? ' yang lalu' : ''}.</b>`
        return `${textSudahMasuk} ${menit ? '' : '<br />'} Segera lakukan absen masuk lembur sebelum jam ${secondsToHMS(hmsToSeconds(schedule.attTimeIn) + (schedule.attLateTolerance * 60))}.`;
      } else {
        //apabila jam kerja sudah lewat & toleransi telat sudah habis
        //apabila masuk jam istirahat
        if (hmsToSeconds(current_time) >= (hmsToSeconds(schedule.attBreakStart) + (schedule.attBreakDuration * 60))) {
          // return 'masuk jam istirahat'

          if (hmsToSeconds(current_time) >= (hmsToSeconds(schedule.attTimeOut) - (60 * 5))) {
            return `Kamu <b>tidak melakukan absen masuk lembur</b> sampai jam lembur selesai.`
          } else {
            return `Sudah lewat jam istirahat lembur. Kenapa belum melakukan absen masuk lembur?`
          }
        } else {
          return `Toleransi keterlambatan habis. Segera lakukan absen masuk lembur, kamu sudah telat${menitTelat ? '' : '.'} <b>${jumlahTelat}</b>`;

        }

      }


    }

  } else { //jika sudah absen
    // console.log(attendance)
    // console.log(schedule)
    if (attendance.time_out !== null) {
      return `Terima kasih, selamat istirahat!`
    } else {
      attendance.ends_on = (attendance.ends_on == '00:00:00') ? '24:00:00' : attendance.ends_on;

      // console.log(attendance)
      // return "attendance text sudah absen"
      if (hmsToSeconds(current_time) < (hmsToSeconds(attendance.time_break_start))) { // belum masuk jam istirahat
        return `Selamat Bekerja!`;
      } else {

        let sisaPulang = hmsToSeconds(attendance.ends_on) - hmsToSeconds(current_time);
        // console.log(sisaPulang)
        // sisaPulang = z(sisaPulang%3600 / 60 |0);
        if (sisaPulang > 0) {
          if (sisaPulang <= (60 * 15)) { //jika sisaPulang kurang dari 15 menit
            // return `${z(sisaPulang%3600 / 60 |0)} menit lagi lemburmu selesai. <br /> <b>${secondsToHMS(hmsToSeconds(current_time) - (hmsToSeconds(attendance.time_in)) - (attendance.break_duration * 60))}</b>`
            return `${z(sisaPulang % 3600 / 60 | 0)} menit lagi lemburmu selesai.`
          } else {

            if (hmsToSeconds(current_time) >= (hmsToSeconds(attendance.time_break_start) + (attendance.break_duration * 60))) { // lewat jam istirahat
              return 'Selamat Bekerja Kembalii!';

            } else {
              //apabila belum lewat jam istirahat

              //check apakah absennya di atas jam istirahat
              if (hmsToSeconds(attendance.time_in) >= (hmsToSeconds(attendance.time_break_start))) {
                return `Absen pada saat jam istirahat. Durasi lembur tidak akan berjalan pada saat jam istirahat`;

              } else {
                return `Sudah masuk jam istirahat. Istirahat dulu, yuk!`
              }




            }

          }

        } else {
          return `Saatnya pulang. Tapi sebelum pulang absen keluar dulu, ya... <br /> <b>${secondsToHMS(hmsToSeconds(attendance.ends_on) - (hmsToSeconds(attendance.time_in)) - (attendance.break_duration * 60))}</b>`
        }




      }

    }


  }

}

const buttonAttendanceHomeScreen = (current_time, data, SPButton, history) => {
  // console.log('current_timeee', current_time);
  function z(n) {
    return (n < 10 ? '0' : '') + n;
  }
  let time = hmsToSeconds(current_time);

  const { schedule, attendance, yesterday_attendance } = data;
  // console.log('scheduleee', schedule);
  // console.log('attendanceee', attendance);
  // console.log('yesterday_attendanceee', yesterday_attendance);

  if (!schedule) {
    //jika schedule null/tidak ada jadwal
    if (!yesterday_attendance) {
      //tidak ada data absen kemarin
      // return 'Libur! Tidak ada jadwal kerja hari ini.';
      return <SPButton onClick={() => history.push('/schedule')}>Lihat Jadwal</SPButton>
    } else {
      //ada absen hari kemarin
      if (!yesterday_attendance.time_out) {
        //absen kemarin belum absen pulang
        // return 'belum absen pulang';
        let jam_masuk = hmsToSeconds(yesterday_attendance.time_in_schedule);
        let absen_masuk = hmsToSeconds(yesterday_attendance.time_in);
        let jam_pulang = hmsToSeconds(yesterday_attendance.time_out_schedule);
        let absen_pulang = yesterday_attendance.time_out
          ? hmsToSeconds(yesterday_attendance.time_out)
          : time;
        let duration =
          jam_pulang > jam_masuk
            ? absen_pulang - absen_masuk
            : 60 * 60 * 24 - absen_masuk + absen_pulang;
        //cek apakah jam sekarang sudah masuk jam pulang?
        if (time >= jam_pulang) {
          // disini cek per case
          //jika waktu sekarang sudah lebih dari jam pulang
          // disini cek per case
          // return jam_pulang;
          // if (jam_masuk > jam_pulang) {
          //   //jika jam masuk lebih dari jam pulang, tampil durasi, bukan absen pulang
          //   return (
          //     <SPButton>{secondsToHMS(duration)}</SPButton>
          //   );
          //   return (
          //     <SPButton>
          //       {secondsToHMS(duration - 60 * 60 * 24)}
          //     </SPButton>
          //   );
          // } else {
          return <SPButton onClick={() => history.push('/attendance/record')} >Absen Pulang</SPButton>

          // }
        } else {
          // belum masuk jam pulang

          return <SPButton>{secondsToHMS(duration)}</SPButton>;
        }
      } else {
        // return <Text>Libur! Tidak ada jadwal kerja hari ini.</Text>;
        return <SPButton onClick={() => history.push('/schedule')} >Lihat Jadwal</SPButton>

      }
    }
  } else {
    // return 'ada jadwal';

    if (!attendance) {
      // return 'belum absen hari ini';
      //cek absen kemarin
      if (!yesterday_attendance) {
        //tidak ada absen kemarin
        // jadwal hari ini
        //cek apakah jam sekarang sudah dekat jam masuk?
        // let jam_masuk = hmsToSeconds(schedule.time_in);
        // let satu_jam = 60 * 60;
        // if (time >= jam_masuk || time >= jam_masuk - satu_jam) {
        //   return 'Sudah masuk jam kerja, segera lakukan presensi kehadiran.';
        // } else {
        //   return 'Kamu punya jadwal kerja hari ini, jangan lupa melakukan presensiii.';
        // }
        let jam_masuk = hmsToSeconds(schedule.time_in);
        let jam_pulang = hmsToSeconds(schedule.time_out);

        //cek apakah jam sekarang sudah dekat jam masuk?
        //waktu sebelum jam kerja berapa
        let waktuSisa = time - jam_masuk;
        let secs = Math.abs(waktuSisa);
        let jam = z((secs / 3600) | 0);
        jam = jam > 0 ? jam + ' jam ' : '';
        let menit = z(((secs % 3600) / 60) | 0);
        menit = menit > 0 ? menit + ' menit ' : '';
        let detik = z(secs % 60) + ' detik ';

        if (waktuSisa < 0) {
          // return 'belum masuk jam kerja';
          //jika waktu sisa lebih dari 1 jam
          if (secs > 60 * 60) {
            return <SPButton onClick={() => history.push('/schedule')}>Lihat Jadwal</SPButton>

          } else {
            // return 'waktu sisa kurang dari 1 jam';
            return <SPButton className="sp-button" onClick={() => history.push('/attendance/record')}>Absen Masuk</SPButton>
          }
        } else {
          let sisa_toleransi = schedule.late_tolerance * 60 - secs;
          let jamTelat = z((Math.abs(sisa_toleransi) / 3600) | 0);
          let menitTelat = z(((Math.abs(sisa_toleransi) % 3600) / 60) | 0);
          jamTelat = jamTelat > 0 ? jamTelat + ' jam ' : '';
          menitTelat = menitTelat > 0 ? menitTelat + ' menit.' : '';
          let jumlahTelat = jamTelat + menitTelat;
          // return sisa_toleransi;
          if (sisa_toleransi < 0) {
            // return 'Toleransi habis';
            if (time <= (jam_pulang - (60 * 60))) {
              // console.log('belum masuk jam pulang')
              return <SPButton className="sp-button waktu-kerja-dan-toleransi-lewat" onClick={() => history.push('/attendance/record')}>Absen Masuk</SPButton>


            } else {
              return <SPButton onClick={() => history.push('/schedule')}>Lihat Jadwal</SPButton>



            }
          } else {
            // return 'Masih ada toleransi';
            return <SPButton className="sp-button" onClick={() => history.push('/attendance/record')}>Absen Masuk</SPButton>
          }
        }

      } else {
        //ada absen kemarin
        let jam_masuk = hmsToSeconds(yesterday_attendance.time_in_schedule);
        let absen_masuk = hmsToSeconds(yesterday_attendance.time_in);
        let jam_pulang = hmsToSeconds(yesterday_attendance.time_out_schedule);
        let absen_pulang = yesterday_attendance.time_out
          ? hmsToSeconds(yesterday_attendance.time_out)
          : time;
        let duration =
          jam_pulang > jam_masuk
            ? absen_pulang - absen_masuk
            : 60 * 60 * 24 - absen_masuk + absen_pulang;

        if (!yesterday_attendance.time_out) {
          //durasi kerja, tombol absen keluar bila masuk jam keluar
          //absen kemarin belum absen pulang
          // return 'belum absen pulang';

          //cek apakah jam sekarang sudah masuk jam pulang?
          if (time >= jam_pulang) {
            // disini cek per case
            return <SPButton onClick={() => history.push('/attendance/record')}>Absen Pulang</SPButton>

            // if (jam_masuk > jam_pulang) {
            //   //jika jam masuk lebih dari jam pulang, tampil durasi, bukan absen pulang
            //   // return (
            //   //   <SPButton>{secondsToHMS(duration)}</SPButton>
            //   // );
            //   return (
            //     <SPButton>
            //       {secondsToHMS(duration - 60 * 60 * 24)}
            //     </SPButton>
            //   );
            // } else {
            //   return (
            //     <Button
            //       text="Absen Pulang"
            //       onPress={() =>
            //         navigation.navigate('Kehadiran', {
            //           screen: 'RecordAttendance',
            //           initial: false,
            //         })
            //       }
            //     />
            //   );
            // }
          } else {
            return (
              <SPButton>{secondsToHMS(duration)}</SPButton>
            );
          }
        } else {
          //absen kemarin sudah absen keluar
          // jadwal hari ini
          jam_masuk = hmsToSeconds(schedule.time_in);

          //cek apakah jam sekarang sudah dekat jam masuk?
          //waktu sebelum jam kerja berapa
          let waktuSisa = time - jam_masuk;
          let secs = Math.abs(waktuSisa);
          let jam = z((secs / 3600) | 0);
          jam = jam > 0 ? jam + ' jam ' : '';
          let menit = z(((secs % 3600) / 60) | 0);
          menit = menit > 0 ? menit + ' menit ' : '';
          let detik = z(secs % 60) + ' detik ';


          if (waktuSisa < 0) {
            // return 'belum masuk jam kerja';
            //jika waktu sisa lebih dari 1 jam
            if (secs > 60 * 60) {
              // return 'Kamu punya jadwal kerja hari ini, jangan lupa melakukan presensi.';
              return <SPButton onClick={() => history.push('/schedule')}>Lihat Jadwal</SPButton>

            } else {
              // return 'waktu sisa kurang dari 1 jam';
              // return (
              //   <Text>
              //     <Text style={{fontWeight: 'bold'}}>
              //       {jam + menit + detik}
              //     </Text>
              //     lagi jam kerjamu. Tapi kamu sudah bisa untuk melakukan
              //     absensi.
              //   </Text>
              // );
              return <SPButton className="sp-button" onClick={() => history.push('/attendance/record')}>Absen Masuk</SPButton>

            }
          } else {
            let sisa_toleransi = schedule.late_tolerance * 60 - secs;
            let jamTelat = z((Math.abs(sisa_toleransi) / 3600) | 0);
            let menitTelat = z(((Math.abs(sisa_toleransi) % 3600) / 60) | 0);
            jamTelat = jamTelat > 0 ? jamTelat + ' jam ' : '';
            menitTelat = menitTelat > 0 ? menitTelat + ' menit.' : '';
            let jumlahTelat = jamTelat + menitTelat;
            // return sisa_toleransi;
            if (sisa_toleransi < 0) {
              // return 'Toleransi habis';
              // return 'Toleransi habis';
              if (time <= (jam_pulang - (60 * 60))) {
                // console.log('belum masuk jam pulang')
                return <SPButton className="sp-button waktu-kerja-dan-toleransi-lewat" onClick={() => history.push('/attendance/record')}>Absen Masuk</SPButton>



              } else {
                return <SPButton onClick={() => history.push('/attendance/history')}>Riwayat Kehadiran</SPButton>



              }
            } else {
              // return 'Masih ada toleransi';
              return <SPButton className="sp-button" onClick={() => history.push('/attendance/record')}>Absen Masuk</SPButton>
            }
            // return <Text>Sudah lewat jam kerja</Text>;


          }
        }
      }
    } else {
      // ini tes
      // return 'belum absen pulang';
      let absen_masuk = hmsToSeconds(attendance.time_in);
      let jam_masuk = hmsToSeconds(attendance.time_in_schedule);
      let jam_pulang = hmsToSeconds(attendance.time_out_schedule);
      let absen_pulang = attendance.time_out
        ? hmsToSeconds(attendance.time_out)
        : time;
      let duration =
        jam_pulang > jam_masuk
          ? absen_pulang - absen_masuk
          : 60 * 60 * 24 - absen_masuk + absen_pulang;
      if (!attendance.time_out) {
        if (time >= jam_pulang) {
          //jika waktu sekarang sudah lebih dari jam pulang
          // disini cek per case
          // return jam_pulang;
          if (jam_masuk > jam_pulang) {
            //jika jam masuk lebih dari jam pulang
            return (
              <SPButton>
                {secondsToHMS(duration - 60 * 60 * 24)}
              </SPButton>
            );
          } else {
            return <SPButton onClick={() => history.push('/attendance/record')}>Absen Pulang</SPButton>

          }
        } else {
          // belum masuk jam pulang

          let minimal_jam_kerja =
            jam_pulang > jam_masuk
              ? jam_pulang - jam_masuk
              : 60 * 60 * 24 - jam_masuk + jam_pulang;
          // console.log(
          //   'minimal_jam_kerja sesuai jadwal',
          //   secondsToHMS(minimal_jam_kerja),
          // );
          // console.log('duration', secondsToHMS(duration));
          if (duration >= minimal_jam_kerja) {
            // console.log('sudah lebih dari jam kerja');
            return <SPButton onClick={() => history.push('/attendance/record')}>Absen Pulang</SPButton>

          } else {
            // console.log('belum memenuhi minimal jam kerja');
            return (
              <SPButton>{secondsToHMS(duration)}</SPButton>
            );
          }
        }
      } else {
        // return 'sudah absen pulang';
        // return 'Terima kasih! selamat istirahat';
        return <SPButton>{secondsToHMS(duration)}</SPButton>;
      }
    }
  }

}

function toRadian(degree) {
  return degree * Math.PI / 180;
}

function getDistance(origin, destination) {
  // console.log(destination)
  // return distance in meters
  let lon1 = toRadian(origin[1]),
    lat1 = toRadian(origin[0]),
    lon2 = toRadian(destination[1]),
    lat2 = toRadian(destination[0]);

  let deltaLat = lat2 - lat1;
  let deltaLon = lon2 - lon1;

  let a = Math.pow(Math.sin(deltaLat / 2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon / 2), 2);
  let c = 2 * Math.asin(Math.sqrt(a));
  let EARTH_RADIUS = 6371;
  let distance = c * EARTH_RADIUS * 1000
  return distance
  // return Math.abs(distance) > 999 ? Math.sign(distance)*((Math.abs(distance)/1000).toFixed(1)) + ' KM' : Math.sign(distance)*Math.abs(distance).toFixed(0) + ' meter'
  // return km.toFixed(1) + ' KM';



}


export {
  attendanceText,
  buttonAttendanceHomeScreen,
  getDistance,
  overtimeText
}