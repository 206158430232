import React, { useEffect, useState } from 'react';
import { FormControl } from '../../../../components';
import { Form, Formik } from 'formik';
import { getStyle } from '../../../../utils/helpers/errorMessage';
import { Auth } from '../../../../config/api/ResourceURL';
import API from '../../../../config/api';
import swal from 'sweetalert';
import Kapal from '../../../../assets/image/kapal.svg';
import * as Yup from 'yup';
import { iconDelete1, iconChecklist, iconDeleteRed, iconChecklistRed } from '../../../../assets'; // Ensure icons are imported correctly
import { ActionContainer, CallToAction, EditButton, Image, SaveButton, SaveButtonDisable, SectionContent, SectionHeader, SectionWrapper, Subsection, SubTitle, Title } from '../HomePage-elements';
const token = localStorage.getItem('token');

const ContactSection = (props) => {
    const { item, edit, initialValuesContact, schemaValidationContact, setLoading, getDataMainSection } = props;
    const [message, setMessage] = useState('');
    const [isContact, setIsContact] = useState(false);
    const [isEditContact, setIsEditContact] = useState(false);
    const [isDisable, setIsDisable] = useState(false); // New state
    const [isSaveDisable, setIsSaveDisable] = useState(false); // New state

    const handleSubmit = (values, { resetForm, setSubmitting }) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('name_section', "Contact Section");
        formData.append('page_type', 'Home');
        formData.append('subtitle', values.subtitle);
        for (let i = 0; i < values?.subData.length; i++) {
            formData.append(`sub_title[${[i]}]`, values.subData[i].title);
            formData.append(`sub_subtitle[${[i]}]`, values.subData[i].subtitle);
            formData.append(`sub_image[${[i]}]`, values.subData[i].image);
            formData.append(`sub_id[${[i]}]`, values.subData[i].id);
            formData.append(`sub_link[${[i]}]`, values.subData[i].link || "");
        }
        API.EditHomePageMainSection(token, formData, values.id)
            .then(res => {
                setMessage('Data Dasar Berhasil Ditambahkan');
                swal({
                    title: 'Berhasil',
                    text: `Contact Section Berhasil Diubah`,
                    icon: "success",
                });
                setIsEditContact(false);
                setSubmitting(false);
                getDataMainSection();
                setLoading(false);
                setIsDisable(false); // Re-enable save button after submission
            })
            .catch(err => {
                console.error(err.message);
                setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                swal({
                    title: err.response?.status || 'Error',
                    text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                    icon: "error",
                });
                setIsEditContact(false);
                setLoading(false);
                setIsDisable(false); // Re-enable save button after error
            });
    };

    const handleAddSubData = (setFieldValue, values, id) => {
        const newSubData = { title: '', subtitle: '' };
        setFieldValue('subData', [...values.subData, newSubData]); // Add a new empty subData
        setIsDisable(true);
        setIsSaveDisable(true);
    };

    const handleSaveSubSection = (values) => {
        setLoading(true);
        const formData = new FormData();
        formData.append('title', values?.subData[values?.subData?.length - 1].title);
        formData.append('subtitle', values?.subData[values?.subData?.length - 1].subtitle);
        formData.append('image', values?.subData[values?.subData?.length - 1].image);
        formData.append('link', values?.subData[values?.subData?.length - 1].link);
        API.addHomePageSubSection(token, formData, initialValuesContact.id)
            .then(res => {
                setMessage('Data Dasar Berhasil Ditambahkan');
                values.subData[values.subData.length - 1].id = res?.data?.data?.id;
                swal({
                    title: 'Berhasil',
                    text: `Sub Section Berhasil Ditambah`,
                    icon: "success",
                });
                getDataMainSection();
                setIsSaveDisable(false)
                setIsDisable(false)
                setLoading(false);
            })
            .catch(err => {
                console.error(err.message);
                setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                swal({
                    title: err.response?.status || 'Error',
                    text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                    icon: "error",
                });
                setLoading(false);
            });
    }

    const handleRemoveSubData = (setFieldValue, values, index, id) => {
        const updatedSubData = values.subData.filter((_, idx) => idx !== index);
        setFieldValue('subData', updatedSubData); // Remove subData at the specified index
        if (updatedSubData.length === 0) setIsDisable(false); // Re-enable save button if no sub data left

        if (id) {
            API.deletHomePageSubSection(token, id)
                .then(res => {
                    setMessage('Sub section berhasil dihapus');
                    swal({
                        title: 'Berhasil',
                        text: `Sub section berhasil dihapus`,
                        icon: "success",
                    });
                    getDataMainSection();
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err.message);
                    setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                    swal({
                        title: err.response?.status || 'Error',
                        text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                        icon: "error",
                    });
                    setLoading(false);
                });
        } else {
            setIsDisable(false)
            setIsSaveDisable(false)
        }
    };

    return (
        <div>
            <SectionWrapper>
                <Formik
                    enableReinitialize
                    initialValues={initialValuesContact}
                    onSubmit={handleSubmit}
                    validationSchema={schemaValidationContact}
                >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        isSubmitting
                    }) => (
                        <Form>
                            <SectionHeader onClick={() => setIsContact(!isContact)}>
                                Contact
                                {
                                    isEditContact ?
                                        <div>
                                            <EditButton style={{ marginRight: "10px" }}
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevents the section from toggling when clicking the button
                                                    setIsEditContact(false);
                                                }}
                                            >
                                                Cancel
                                            </EditButton>
                                            <SaveButton
                                                onClick={(e) => {
                                                    e.stopPropagation(); // Prevents the section from toggling when clicking the button
                                                    setIsEditContact(true);
                                                }}
                                                type="submit"
                                                disabled={isSubmitting || isSaveDisable}
                                                isSaveDisabled={isSaveDisable}
                                            >
                                                Save
                                            </SaveButton>
                                        </div> : <SaveButton
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevents the section from toggling when clicking the button
                                                setIsEditContact(true);
                                                setIsContact(true);
                                                edit(item, 'Contact');
                                            }}
                                        >
                                            Edit
                                        </SaveButton>
                                }
                            </SectionHeader>
                            <SectionContent isOpen={isContact}>
                                {
                                    isEditContact ?
                                        <div className="left-form">
                                            <FormControl
                                                control="input"
                                                type="text"
                                                label="Title"
                                                name="title"
                                                style={getStyle(errors, touched, 'title')}
                                            />
                                            <FormControl
                                                control="input"
                                                type="text"
                                                label="Subtitle"
                                                name="subtitle"
                                                style={getStyle(errors, touched, 'subtitle')}
                                            />
                                            <div style={{ backgroundColor: "#e0e3e5", borderRadius: "10px", padding: "20px", alignContent: "center" }}>
                                                {values.subData?.map((item, subIndex) => (
                                                    <div key={subIndex}>
                                                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                                            <div style={{ display: "flex", justifyContent: "center", width: "80%" }}>
                                                                <ActionContainer style={{ justifyContent: "start" }}>
                                                                    <label style={{ marginRight: "10px" }}>Image {subIndex + 1}</label>
                                                                    <img
                                                                        src={iconDeleteRed}
                                                                        width={"20px"}
                                                                        style={{ cursor: 'pointer', marginRight: "5px" }}
                                                                        onClick={() => handleRemoveSubData(setFieldValue, values, subIndex, item?.id)}
                                                                    />
                                                                    {item?.id ? "" :
                                                                        <img
                                                                            src={iconChecklistRed}
                                                                            width={"20px"}
                                                                            style={{ cursor: 'pointer', marginRight: "5px" }}
                                                                            onClick={() => handleSaveSubSection(values)}
                                                                        />}
                                                                </ActionContainer>
                                                            </div>
                                                        </div>
                                                        {console.log('initialValuesContact', initialValuesContact)}
                                                        <FormControl
                                                            control="file"
                                                            name={`subData[${subIndex}].image`}
                                                            fileURL={item.image || Kapal}
                                                            style={getStyle(errors, touched, `subData[${subIndex}].image`)}
                                                        />
                                                        <FormControl
                                                            control="input"
                                                            type="text"
                                                            label={`Title ${subIndex + 1}`}
                                                            name={`subData[${subIndex}].title`}
                                                            style={getStyle(errors, touched, `subData[${subIndex}].title`)}
                                                            value={item.title}
                                                            onChange={(e) => setFieldValue(`subData[${subIndex}].title`, e.target.value)}
                                                        />
                                                        <FormControl
                                                            control="input"
                                                            type="text"
                                                            label={`Subtitle ${subIndex + 1}`}
                                                            name={`subData[${subIndex}].subtitle`}
                                                            style={getStyle(errors, touched, `subData[${subIndex}].subtitle`)}
                                                            value={item.subtitle}
                                                            onChange={(e) => setFieldValue(`subData[${subIndex}].subtitle`, e.target.value)}
                                                        />
                                                        <FormControl
                                                            control="input"
                                                            type="text"
                                                            label={`Link ${subIndex + 1}`}
                                                            name={`subData[${subIndex}].link`}
                                                            style={getStyle(errors, touched, `subData[${subIndex}].link`)}
                                                            value={item.link}
                                                            onChange={(e) => setFieldValue(`subData[${subIndex}].link`, e.target.value)}
                                                        />
                                                    </div>
                                                ))}
                                                {isDisable ? "" :
                                                    <button type="button" className="save-button" onClick={() => handleAddSubData(setFieldValue, values)}>
                                                        + Sub Data
                                                    </button>}
                                            </div>
                                        </div> : <>
                                            <div style={{ backgroundColor: "#E0E5E5", borderRadius: "10px", padding: "20px" }}>
                                                <Title>Title</Title>
                                                <SubTitle>{item?.title}</SubTitle>
                                                <Title>SubTitle</Title>
                                                <SubTitle>{item?.subtitle}</SubTitle>
                                            </div>
                                            <Subsection>
                                                <ul>
                                                    {item?.subsection?.map((subItem, index) => (
                                                        <li key={index}>
                                                            <Title>Image</Title>
                                                            <Image src={subItem?.image || Kapal} alt="Contact Image" />
                                                            <Title>{subItem?.title}</Title>
                                                            <SubTitle>{subItem.subtitle}</SubTitle>
                                                            {subItem?.link ?
                                                                <>
                                                                    <Title>Call To Action</Title>
                                                                    <CallToAction href={subItem?.link}>{subItem?.link}</CallToAction>
                                                                </> : ""}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Subsection>
                                        </>
                                }
                            </SectionContent>
                        </Form>
                    )}
                </Formik>
            </SectionWrapper>
        </div>
    );
}

export default ContactSection;
