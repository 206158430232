import React, { useEffect, useState } from 'react';
import { FormControl } from '../../../../components';
import { Form, Formik } from 'formik';
import { getStyle } from '../../../../utils/helpers/errorMessage';
import { Auth } from '../../../../config/api/ResourceURL';
import API from '../../../../config/api';
import Kapal from '../../../../assets/image/kapal.svg';
import swal from 'sweetalert';
import * as Yup from 'yup';
import { iconDelete1, iconChecklist, iconDeleteRed, iconChecklistRed } from '../../../../assets';
import { Action, ActionContainer, CallToAction, EditButton, FormContainer, FormContent, Image, SaveButton, SectionContent, SectionHeader, SectionWrapper, Subsection, SubTitle, Title } from '../HomePage-elements';

const token = localStorage.getItem('token');

const InformationSection = (props) => {
    const { item, edit, initialValuesInformation, schemaValidationInformation, setLoading, getDataMainSection } = props;
    const [message, setMessage] = useState('');
    const [isInformation, setIsInformation] = useState(false);
    const [isEditInformation, setIsEditInformation] = useState(false);
    const [isDisabled, setIsDisable] = useState(false);
    const [isSaveDisabled, setIsSaveDisable] = useState(false);

    const handleSubmit = (values, { resetForm, setSubmitting }) => {
        console.log('initialValuesInformation', values)
        setLoading(true);
        // handleInformation(values)
        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('name_section', "Information Section");
        formData.append('page_type', 'Home');
        formData.append('subtitle', values.title);
        for (let i = 0; i < values?.subData.length; i++) {
            formData.append(`sub_title[${[i]}]`, values.subData[i].title);
            formData.append(`sub_subtitle[${[i]}]`, values.subData[i].subtitle);
            formData.append(`sub_id[${[i]}]`, values.subData[i].id);
            formData.append(`sub_image[${[i]}]`, values.subData[i].image);
        }

        API.EditHomePageMainSection(token, formData, values.id)
            .then(res => {
                setMessage('Data Berhasil Diperbarui');
                swal({
                    title: 'Berhasil',
                    text: 'Information Section Berhasil Diubah',
                    icon: "success",
                });
                setIsEditInformation(false);
                getDataMainSection();
                setSubmitting(false);
                setLoading(false);
                setIsDisable(false);
            })
            .catch(err => {
                console.error(err.message);
                setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                swal({
                    title: err.response?.status || 'Error',
                    text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                    icon: "error",
                });
                setIsEditInformation(false);
                setLoading(false);
                setIsDisable(false);
            });
    };

    const handleAddMainData = (setFieldValue, values) => {
        const newMainData = { title: '', subtitle: '' };
        setFieldValue('subData', [...values?.subData, newMainData]);
        setIsDisable(true);
        setIsSaveDisable(true);
    };

    const handleSaveMainSection = (values, index) => {
        console.log('values', values)
        setLoading(true);
        const formData = new FormData();
        formData.append('title', values?.subData[values?.subData?.length - 1]?.title);
        formData.append('subtitle', values?.subData[values?.subData?.length - 1]?.title);
        formData.append('button_text', values?.subData[values?.subData?.length - 1]?.button_text);
        formData.append('link', values?.subData[values?.subData?.length - 1]?.link);
        formData.append('image', values?.subData[values?.subData?.length - 1]?.image);
        API.addHomePageSubSection(token, formData, values?.id)
            .then(res => {
                console.log('res', res?.data?.data?.id)
                values.subData[values.subData.length - 1].id = res?.data?.data?.id;
                values.subData[values.subData.length - 1].image = res?.data?.data?.image;
                values.subData[values.subData.length - 1].subtitle = res?.data?.data?.subtitle;
                values.subData[values.subData.length - 1].subsection = [];
                setMessage('Data Dasar Berhasil Ditambahkan');
                swal({
                    title: 'Berhasil',
                    text: `Sub Section Berhasil Ditambah`,
                    icon: "success",
                });
                getDataMainSection();
                setIsDisable(false)
                setIsSaveDisable(false)
                setLoading(false);
            })
            .catch(err => {
                console.error(err.message);
                setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                swal({
                    title: err.response?.status || 'Error',
                    text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                    icon: "error",
                });
                setLoading(false);
            });
    }


    const handleRemoveMainData = (setFieldValue, values, index, id) => {
        const updatedMainData = values?.subData.filter((_, idx) => idx !== index);
        setFieldValue('subData', updatedMainData);
        if (updatedMainData.length === 0) setIsDisable(false);

        if (id) {
            API.deletHomePageSubSection(token, id)
                .then(res => {
                    setMessage('Sub section berhasil dihapus');
                    swal({
                        title: 'Berhasil',
                        text: `Sub section berhasil dihapus`,
                        icon: "success",
                    });
                    getDataMainSection();
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err.message);
                    setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                    swal({
                        title: err.response?.status || 'Error',
                        text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                        icon: "error",
                    });
                    setLoading(false);
                });
        } else {
            setIsDisable(false)
            setIsSaveDisable(false)
        }
    };


    return (
        <div>
            <SectionWrapper>
                <Formik
                    enableReinitialize
                    initialValues={initialValuesInformation}
                    onSubmit={handleSubmit}
                    validationSchema={schemaValidationInformation}
                >
                    {({
                        values,
                        errors,
                        touched,
                        setFieldValue,
                        isSubmitting
                    }) => (
                        <Form>
                            <SectionHeader onClick={() => setIsInformation(!isInformation)}>
                                Information
                                {isEditInformation ? (
                                    <div>
                                        <EditButton
                                            style={{ marginRight: "10px" }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsEditInformation(false);
                                                // setIsDisable(false)
                                                // setIsSaveDisable(false)
                                            }}
                                        >
                                            Cancel
                                        </EditButton>
                                        <SaveButton
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setIsEditInformation(true);
                                            }}
                                            type="submit"
                                            disabled={isSubmitting || isSaveDisabled}
                                            isSaveDisabled={isSaveDisabled}
                                        >
                                            Save
                                        </SaveButton>
                                    </div>
                                ) : (
                                    <SaveButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setIsEditInformation(true);
                                            setIsInformation(true);
                                            edit(item, 'Information');
                                        }}
                                    >
                                        Edit
                                    </SaveButton>
                                )}
                            </SectionHeader>
                            <SectionContent isOpen={isInformation}>
                                {isEditInformation ? (
                                    <FormContainer className="left-form" >
                                        <FormControl control="input" type="text" label="Title" name="title"
                                            style={getStyle(errors, touched, 'title')}
                                        />
                                        {values.subData?.map((item, index) => (
                                            <FormContent key={index}>
                                                <ActionContainer>
                                                    {
                                                        values.subData?.length > 1 ? <Action onClick={() => handleRemoveMainData(setFieldValue, values, index, item?.id)}>
                                                            <img
                                                                src={iconDelete1}
                                                                width={"20px"}
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                        </Action> : ""
                                                    }
                                                    {item?.id ? "" : <Action onClick={() => handleSaveMainSection(values, index)}>
                                                        <img
                                                            src={iconChecklist}
                                                            width={"20px"}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </Action>}
                                                </ActionContainer>
                                                <FormControl
                                                    control="file"
                                                    label={`image ${index + 1}`}
                                                    name={`subData[${index}].image`}
                                                    style={getStyle(errors, touched, `subData[${index}].image`)}
                                                    fileURL={item?.image || Kapal}
                                                />
                                                <FormControl
                                                    control="input"
                                                    type="text"
                                                    label={`Title ${index + 1}`}
                                                    name={`subData[${index}].title`}
                                                    style={getStyle(errors, touched, `subData[${index}].title`)}
                                                    value={item.title}
                                                    onChange={(e) => setFieldValue(`subData[${index}].title`, e.target.value)}
                                                />
                                                <FormControl
                                                    control="input"
                                                    type="text"
                                                    label={`Sub Title ${index + 1}`}
                                                    name={`subData[${index}].subtitle`}
                                                    style={getStyle(errors, touched, `subData[${index}].subtitle`)}
                                                    value={item.subtitle}
                                                    onChange={(e) => setFieldValue(`subData[${index}].subtitle`, e.target.value)}
                                                />
                                            </FormContent>
                                        ))}
                                        {
                                            isDisabled == false ?
                                                <SaveButton
                                                    type="button"
                                                    onClick={() => handleAddMainData(setFieldValue, values)}
                                                    style={{ width: "100%", marginTop: "10px" }}
                                                >
                                                    Add Main Data
                                                </SaveButton> : ""
                                        }
                                    </FormContainer>
                                ) : (
                                    <div className="section-data">
                                        <div style={{ backgroundColor: "#E0E5E5", borderRadius: "10px", padding: "20px" }}>
                                            <Title>Title</Title>
                                            <SubTitle>{item?.title}</SubTitle>
                                        </div>
                                        <Subsection>
                                            <ul>
                                                {item?.subsection?.map((data, index) => (
                                                    <li key={index}>
                                                        <Title>Image</Title>
                                                        <Image src={data?.image || Kapal} alt="Information Image" />
                                                        <Title>Title</Title>
                                                        <SubTitle>{data?.title}</SubTitle>
                                                        <Title>SubTitle</Title>
                                                        <SubTitle>{data?.subtitle}</SubTitle>
                                                    </li>
                                                ))}
                                            </ul>
                                        </Subsection>
                                    </div>
                                )}
                            </SectionContent>
                        </Form>
                    )}
                </Formik>
            </SectionWrapper>
        </div>
    );
};

export default InformationSection;
