import React from 'react'

const LeaveDetail = (props) => {
    return (
        <div>
            detail cuti, tampilan surat perintah cuti
        </div>
    )
}

export default LeaveDetail
