import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import BasicInformation from './BasicInformation'
import DetailPayroll from './DetailPayroll'
import EmployeeDetailAttendance from './EmployeeDetailAttendance'
import EmployeeLogActivities from './EmployeeLogActivities'
import HeaderDetail from './header'
import HistoryAssignment from './HistoryAssignment'
import UsedAsset from './UsedAsset'
import API from '../../../../config/api'

const DetailEmployee = (props) => {
    // console.log(props)
    const { employee } = props.location.state
    const [pageName, setPageName] = useState('Informasi Dasar');
    const [location, setLocation] = useState('/employee/detail/basic-information');
    const [dataDasar, setDataDasar] = useState([]);
    const [dataContact, setDataContact] = useState([]);
    const [dataFamilie, setDataFamilie] = useState([]);
    const [dataEducation, setDataEducation] = useState([]);
    const [dataWorkExperience, setDataWorkExperience] = useState([]);
    const setPageDetail = (e) => {
        e.stopPropagation();
        const link = e.target.getAttribute('data');
        const text = e.target.getAttribute('text');
        setPageName(text);
        setLocation(link);

    }
    const getDataDasar = () => {
        API.getEmployeePersonalDetail(token, employee?.id).then((res) => {
            setDataDasar(res?.data);

        }).catch(err => {
            // console.log(err.response.data.message);
            // console.log(err);
        })
    }
    const getDataContact = () => {
        API.getEmployeeContact(token, employee?.id).then((res) => {
            setDataContact(res?.data);

        }).catch(err => {
            // console.log(err.response.data.message);
            // console.log(err);
        })
    }
    const getDataFamilie = () => {
        API.getEmployeeFamilie(token, employee?.id).then((res) => {
            setDataFamilie(res?.data);

        }).catch(err => {
            // console.log(err.response.data.message);
            // console.log(err);
        })
    }
    const getDataEducation = () => {
        API.getEmployeeEducation(token, employee?.id).then((res) => {
            setDataEducation(res?.data);

        }).catch(err => {
            // console.log(err.response.data.message);
            // console.log(err);
        })
    }
    const getDataWorkExperience = () => {
        API.getEmployeeWorkExperience(token, employee?.id).then((res) => {
            setDataWorkExperience(res?.data);

        }).catch(err => {
            // console.log(err.response.data.message);
            // console.log(err);
        })
    }
    let element;

    switch (pageName) {
        case 'Informasi Dasar':
            element = <BasicInformation employee={employee} dataDasar={dataDasar} dataContact={dataContact} dataFamilie={dataFamilie} dataEducation={dataEducation} dataWorkExperience={dataWorkExperience} getDataDasar={getDataDasar} getDataContact={getDataContact} getDataFamilie={getDataFamilie} getDataEducation={getDataEducation} getDataWorkExperience={getDataWorkExperience} />;
            break;

        case 'Kehadiran':
            element = <EmployeeDetailAttendance employee={employee} />
            break;

        case 'Penugasan':
            element = <HistoryAssignment employee={employee} />
            break;

        case 'Payroll':
            element = <DetailPayroll employee={employee} />
            break;

        case 'Aset':
            element = <UsedAsset employee={employee} />
            break;

        case 'Riwayat Aktivitas':
            element = <EmployeeLogActivities employee={employee} />
            break;

        default:
            break;
    }
    const token = props.user.token;


    useEffect(() => {
        getDataDasar();
        getDataContact();
        getDataFamilie();
        getDataEducation();
        getDataWorkExperience();
    }, [])
    return (
        <>
            <HeaderDetail setPageDetail={setPageDetail} location={location} pageName={pageName} employee={employee} dataDasar={dataDasar} />
            {element}
        </>
    )
}

const reduxState = (state) => ({
    isLogin: state.isLogin,
    user: state.user,
    isLoading: state.isLoading

})


const reduxDispatch = (dispatch) => ({
    loading: (data) => dispatch(setLoading(data)),



})
export default connect(reduxState, reduxDispatch)(DetailEmployee)