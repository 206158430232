import React from 'react'

const GetAccount = () => {
    return (
        <div>
            get Account Page
        </div>
    )
}

export default GetAccount
