import React, { useEffect, useState } from 'react'
import { Col, FormControl, Gap, Row } from '../../../components'
import { ContainerCompanyInfo, ContainerSettingPPh21 } from './setting.elements'
import { Form, Formik } from 'formik'
import * as Yup from "yup";
import API from '../../../config/api'
import { getStyle } from '../../../utils/helpers/errorMessage';
import FormikContainer from '../../../components/atoms/Form/SampleForm';
import { Auth } from '../../../config/api/ResourceURL';
import axios from 'axios';





const CompanyInfoSetting = ({initialValues, isAddOrEdit, token, schemaValidation, reloadPage}) => {

    const [loading, setLoading] = useState(false);

    const handleSubmit = (values, { resetForm, setSubmitting }) => {

    
        // data = JSON.stringify(data)
        const data = {
            name: values.name,
            short_name: values.short_name,
            description: values.description,
            email: values.email,
            website: values.website,
            telp: values.telp,
            business_fields: values.business_fields,
            address: values.address,
            logo: values.logo,
        }
        console.log(data)
        let url = Auth + '/client/company-info';

        
       
    
        if(isAddOrEdit == 'edit'){
            // console.log('edit');
            
            let formData = new FormData();
            formData.append('_method', 'PUT');
            formData.append('name', data.name);
            formData.append('short_name', data.short_name);
            formData.append('description', data.description);
            formData.append('email', data.email);
            formData.append('website', data.website);
            formData.append('telp', data.telp);
            formData.append('business_fields', data.business_fields);
            formData.append('address', data.address);
            formData.append('logo', data.logo);
            // console.log(formData);
            axios
            .post(url, formData, {
                headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                console.log(res)
                // console.log(res.data.message);
                swal({
                    title: res.data.status,
                    text: res.data.message,
                    icon: "success",
                });
                window.location.reload();

            })
            .catch(err => {
                // console.log(err.response);
                console.log(err.response);
                swal({
                    title: err.status,
                    // text: err.response.data.message,
                    icon: "error",
                });
            });
        }else {
            // console.log('add')
            let formData = new FormData();


            formData.append('name', data.name);
            formData.append('short_name', data.short_name);
            formData.append('description', data.description);
            formData.append('email', data.email);
            formData.append('website', data.website);
            formData.append('telp', data.telp);
            formData.append('business_fields', data.business_fields);
            formData.append('address', data.address);
            formData.append('logo', data.logo);
            axios
            .post(url, formData, {
                headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                // console.log(res)
                // console.log(res.data.message);
                swal({
                    title: res.data.status,
                    text: res.data.message,
                    icon: "success",
                });
                window.location.reload();

            })
            .catch(err => {
                // console.log(err.response);
                console.log(err.response);
                swal({
                    title: err.status,
                    // text: err.response.data.message,
                    icon: "error",
                });
            });
        }
        
        // // resetForm();
        // setSubmitting(false);
        // reloadPage();
        // console.log('simpan pengaturan informasi perusahaan')
    
        };
    return (
        <ContainerCompanyInfo>
            <h3>Sesuaikan Pengaturan Informasi Perusahaan</h3>
            <hr />
            <Gap height={30} />
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values, props) => handleSubmit(values, props)}
                validationSchema={() => schemaValidation}
                >
            {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting
                }) => (

                    <Form className="form">
                    <div className="left-form">
        
                    <FormControl control="input" type="text" label="Nama Perusahaan" name="name"
                            style={getStyle(errors, touched, 'name')}
                        />
                    <FormControl control="input" type="text" label="Nama Pendek Perusahaan" name="short_name"
                            style={getStyle(errors, touched, 'short_name')}
                        />
                    <FormControl control="input" type="text" label="Bidang Usaha" name="business_fields"
                            style={getStyle(errors, touched, 'business_fields')}
                        />
                    <FormControl
                        control='textarea'
                        label='Tentang Perusahaan'
                        name='description'
                        style={getStyle(errors, touched, 'description')}

                    />
                    <FormControl
                            control='textarea'
                            label='Alamat Perusahaan'
                            name='address'
                            style={getStyle(errors, touched, 'address')}

                        />
                   
                    </div>
                    <div className="right-form">
                       

                        <FormControl control="input" type="text" label="Email Perusahaan" name="email"
                                style={getStyle(errors, touched, 'email')}
                            />
                        <FormControl control="input" type="text" label="Website Perusahaan" name="website"
                                style={getStyle(errors, touched, 'website')}
                            />
                        <FormControl control="input" type="text" label="Telepon Perusahaan" name="telp"
                                style={getStyle(errors, touched, 'telp')}
                            />
                            
                        <FormControl control="file" label="Logo" name="logo" fileURL={isAddOrEdit === 'edit' ? initialValues.logo : null}
                                style={getStyle(errors, touched, 'logo')}
                            />
                            
                           
                      
                        <button type="submit" className="save-button" disabled={isSubmitting}>
                            Simpan
                        </button>
                    
                    </div>
                    
                    </Form>
                )}
            </Formik>
            
        </ContainerCompanyInfo>
    )
    
}

export default CompanyInfoSetting
