import React, { useEffect } from 'react'
import { FormControl } from '../../../../components'
import { Form, Formik } from 'formik';
import { getStyle } from '../../../../utils/helpers/errorMessage';
import axios from 'axios';
import { Auth } from '../../../../config/api/ResourceURL';
import API from '../../../../config/api';
import Kapal from '../../../../assets/image/kapal.svg';
import swal from 'sweetalert';
import { useState } from 'react';
import { CallToAction, EditButton, Image, SaveButton, SectionContent, SectionHeader, SectionWrapper, SubTitle, Title } from '../HomePage-elements';
const token = localStorage.getItem('token');
const AboutSection = (props) => {
    const { item, edit, initialValuesAbout, schemaValidationAbout, setLoading, getDataMainSection } = props;
    const [message, setMessage] = useState('');
    const [isAbout, setIsAbout] = useState(false);
    const [isEditAbout, setIsEditAbout] = useState(false);
    const handleSubmit = (values, { resetForm, setSubmitting }) => {
        setLoading(true)

        // data = JSON.stringify(data)
        const data = {
            title: values.title,
            subtitle: values.subtitle,
            description: values.description,
            button_text: values.button_text,
            link: values.link,
            image: values.image,
        }
        console.log(data)
        let url = Auth + '/client/company-infoaasas';



        let formData = new FormData();


        formData.append('title', data.title);
        formData.append('name_section', "About Section");
        formData.append('call_to_action', 1);
        formData.append('page_type', 'Home');
        formData.append('subtitle', data.subtitle);
        formData.append('description', data.description);
        formData.append('button_text', data.button_text);
        formData.append('link', data.link);
        formData.append('image', data.image);
        API.EditHomePageMainSection(token, formData, initialValuesAbout.id)
            .then(res => {
                setMessage('Data Dasar Berhasil Ditambahkan');
                swal({
                    title: 'Berhasil',
                    text: `About Section Berhasil Diubah`,
                    icon: "success",
                });
                setIsEditAbout(false);
                setSubmitting(false);
                getDataMainSection()
                setLoading(false)
            })
            .catch(err => {
                console.log(err.message);
                setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                swal({
                    title: err.response?.status || 'Error',
                    text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                    icon: "error",
                });
                setIsEditAbout(false)
                setLoading(false)
            });

    };

    useEffect(() => {

    }, []);
    return (
        <SectionWrapper>
            <Formik
                enableReinitialize
                initialValues={initialValuesAbout}
                onSubmit={(values, props) => handleSubmit(values, props)}
                validationSchema={() => schemaValidationAbout}
            >
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting
                }) => (
                    <Form >
                        <SectionHeader onClick={() => setIsAbout(!isAbout)}>
                            About
                            {
                                isEditAbout ?
                                    <div>
                                        <EditButton style={{ marginRight: "10px" }}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevents the section from toggling when clicking the button
                                                setIsEditAbout(false);
                                            }}
                                        >
                                            Cancel
                                        </EditButton>
                                        <SaveButton
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevents the section from toggling when clicking the button
                                                setIsEditAbout(true);
                                            }}
                                            type='submit'
                                            disabled={isSubmitting}>
                                            Save
                                        </SaveButton>
                                    </div>

                                    : <SaveButton
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevents the section from toggling when clicking the button
                                            setIsEditAbout(true);
                                            setIsAbout(true);
                                            edit(item, 'About');
                                        }}
                                    >
                                        Edit
                                    </SaveButton>
                            }
                        </SectionHeader>
                        <SectionContent isOpen={isAbout}>
                            {
                                isEditAbout ?

                                    <div className="left-form">

                                        <FormControl control="file" label="image" name="image" fileURL={initialValuesAbout.image || Kapal}
                                            style={getStyle(errors, touched, 'image')}
                                        />
                                        <FormControl control="input" type="text" label="Title" name="title"
                                            style={getStyle(errors, touched, 'title')}
                                        />
                                        <FormControl control="input" type="text" label="subtitle" name="subtitle"
                                            style={getStyle(errors, touched, 'subtitle')}
                                        />
                                        <FormControl control="input" type="text" label="description" name="description"
                                            style={getStyle(errors, touched, 'description')}
                                        />
                                        <FormControl control="input" type="text" label="button_text" name="button_text"
                                            style={getStyle(errors, touched, 'button_text')}
                                        />
                                        <FormControl control="input" type="text" label="link" name="link"
                                            style={getStyle(errors, touched, 'link')}
                                        />
                                    </div>
                                    :
                                    <>
                                        <Title>Image</Title>
                                        <Image src={item?.image || Kapal} alt="About Image" />
                                        <Title>Title</Title>
                                        <SubTitle>{item?.title}</SubTitle>
                                        <Title>SubTitle</Title>
                                        <SubTitle>{item?.subtitle}</SubTitle>
                                        <Title>Descriptipon</Title>
                                        <SubTitle>{item?.description}</SubTitle>
                                        <Title>Call To Action</Title>
                                        <CallToAction href={item?.link}>{item?.button_text} – {item?.link}</CallToAction>
                                    </>
                            }
                        </SectionContent>
                    </Form>
                )}
            </Formik>
        </SectionWrapper >
    )
}

export default AboutSection
