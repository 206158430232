import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HRDashboardContainer = styled.div`
  display: grid;
  grid-template-areas:
    "pemberitahuan pemberitahuan pemberitahuan info-kehadiran"
    "karyawan karyawan karyawan rekap-cuti"
    "karyawan-by-gender karyawan-by-status-nikah karyawan-by-status-kontrak rekap-cuti"
    "karyawan-by-cabang karyawan-by-cabang karyawan-by-status-kontrak karyawan-request"
    "karyawan-by-cabang karyawan-by-cabang karyawan-by-status-kontrak karyawan-request"
    "karyawan-by-cabang karyawan-by-cabang karyawan-kontrak-berakhir buat-tugas-pengumuman"
    "kehadiran kehadiran kehadiran kosong"
    "overview-kehadiran overview-kehadiran list-telat list-tidak-hadir"
    "overview-kehadiran overview-kehadiran list-telat list-tidak-hadir"
    "overview-kehadiran overview-kehadiran list-telat list-tidak-hadir"
    /* "belum-absen karyawan-lembur rank-kehadiran rank-kehadiran" */
    "karyawan-lembur pulang-awal rank-kehadiran rank-kehadiran"
    "karyawan-lembur pulang-awal rank-kehadiran rank-kehadiran"
    "quick-links-1 quick-links-2 calendar calendar"

                        ;
  grid-template-columns: 1fr 1fr 1fr minmax(175px, 255px);
  grid-gap: 20px;
  /* grid-template-rows: 1fr; */
  grid-auto-rows: minmax(90px, 90px);
  /* margin-top: 15px; */

  overflow: hidden;
  @media (max-width: 1125px) {
    grid-template-areas:
    "pemberitahuan pemberitahuan pemberitahuan info-kehadiran"
    "karyawan karyawan karyawan rekap-cuti"
    "karyawan-by-gender karyawan-by-gender karyawan-by-gender rekap-cuti"
    "karyawan-by-status-nikah karyawan-by-status-nikah karyawan-by-status-nikah karyawan-kontrak-berakhir"
    "karyawan-by-status-kontrak karyawan-by-status-kontrak karyawan-by-status-kontrak karyawan-by-status-kontrak"
    "karyawan-by-cabang karyawan-by-cabang karyawan-by-cabang karyawan-by-cabang"
    "karyawan-by-cabang karyawan-by-cabang karyawan-by-cabang karyawan-by-cabang"
    "karyawan-by-cabang karyawan-by-cabang karyawan-by-cabang karyawan-by-cabang"
    "karyawan-request karyawan-request buat-tugas-pengumuman buat-tugas-pengumuman"
    "karyawan-request karyawan-request kehadiran kehadiran"
    "karyawan-request karyawan-request kehadiran kehadiran"
    "kosong kosong kosong kosong"
    "overview-kehadiran overview-kehadiran overview-kehadiran list-telat"
    "overview-kehadiran overview-kehadiran overview-kehadiran list-telat"
    "overview-kehadiran overview-kehadiran overview-kehadiran list-telat"
    "overview-kehadiran overview-kehadiran overview-kehadiran list-telat"
    "rank-kehadiran  rank-kehadiran rank-kehadiran rank-kehadiran"
    "rank-kehadiran  rank-kehadiran rank-kehadiran rank-kehadiran"
    "list-tidak-hadir list-tidak-hadir list-tidak-hadir list-tidak-hadir"
    "list-tidak-hadir list-tidak-hadir list-tidak-hadir list-tidak-hadir"
    "list-tidak-hadir list-tidak-hadir list-tidak-hadir list-tidak-hadir"
    "karyawan-lembur karyawan-lembur pulang-awal pulang-awal"
    "karyawan-lembur karyawan-lembur pulang-awal pulang-awal"
                        ;
                        overflow-y: scroll;
  height: 91%;

  /* Menghilangkan scrollbar di Firefox */
  scrollbar-width: none;

  /* Menghilangkan scrollbar di Chrome, Edge, Safari */
  &::-webkit-scrollbar {
    display: none;
  }
    }
  @media screen and (max-width: 500px) {
        grid-template-areas:
            'user-profil'
            'user-profil'
            'info-kehadiran'
            'pemberitahuan'
            'pemberitahuan'
            'request-menu'
            'request-menu'
            'rekap-cuti'
            'rekap-cuti'
            'karyawan'
            'karyawan-by-gender'
            'karyawan-by-status-nikah'
            'karyawan-by-status-kontrak'
            'karyawan-kontrak-berakhir'
            'karyawan-kontrak-berakhir'
            'karyawan-by-cabang'
            'karyawan-by-cabang'
            'karyawan-by-cabang'
            'karyawan-by-cabang'
            'karyawan-request'
            'karyawan-request'
            'karyawan-request'
            'buat-tugas-pengumuman'
            'kehadiran'
            'kehadiran'
            'overview-kehadiran'
            'overview-kehadiran'
            'overview-kehadiran'
            'overview-kehadiran'
            'overview-kehadiran'
            'list-telat'
            'list-telat'
            'list-telat'
            'list-telat'
            'list-tidak-hadir'
            'list-tidak-hadir'
            'list-tidak-hadir'
            'list-tidak-hadir'
            'list-tidak-hadir'
            'rank-kehadiran'
            'rank-kehadiran'
            'rank-kehadiran'
            'karyawan-lembur'
            'karyawan-lembur'
            'pulang-awal'
            'pulang-awal'
            

        ;
        margin: 0 auto;
        margin-bottom: 55px;
        /* align-content: center; */
        justify-content: center;
        gap: 0;
        grid-template-columns: 1fr;
        width: 100%;
        overflow-y: auto;
        height: 91%;
        /* grid-auto-rows: 200px; */
        /* overflow-x: hidden; */
        /* background-color: var(--primary-color); */


  }
`;


export const LeaveRecap = styled.div`
  grid-area: rekap-cuti;
  overflow-y: auto;
  background-color: #fff;
`;

export const SectionKaryawanTitle = styled.div`
      grid-area: karyawan;
  margin-top: 70px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #FF4136; */
  @media (max-width: 1125px) {
    margin-top: 97px;
    } 
    @media (max-width: 500px) {
    grid-area: karyawan;
    margin-top: 10px;
    padding: 20px;
   }
`;



export const KaryawanByGender = styled.div`
    grid-area: karyawan-by-gender;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    p {
      font-size: 14px;
      color: var(--text);
    }
 @media (max-width: 1125px) {
    margin-top: 14px;
    height: 84px;
    }

    @media (max-width: 500px) {
      margin-top: 0px;
    grid-area: karyawan-by-gender;
    height: 92px;
   }
`;

export const KaryawanByStatusNikah = styled.div`
    grid-area: karyawan-by-status-nikah;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    
    p {
      font-size: 14px;
      color: var(--text);
    }
    @media screen and (max-width: 500px) {
      display: karyawan-by-status-nikah;
      margin-top: 15px;
      height: 92px;
    }
`;

export const KaryawanByStatusKontrak = styled.div`
    grid-area: karyawan-by-status-kontrak;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;

    div {
      margin-bottom: 18px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: 28px;
        


      }
      
      p {
        margin-top: -5px;
        font-size: 12px;
        border-bottom: 1px solid var(--background-color);
        color: var(--text);
      }

    }
    @media screen and (max-width: 1125px) {
     display: none;
    }
    @media screen and (max-width: 500px) {
      display: none;
    }
`;

export const KaryawanByStatusKontrakResponsive = styled.div`
display: none;
   @media (max-width: 1125px) {
    display: grid;
    grid-area: karyawan-by-status-kontrak;
    background-color: #fff;
    width: 100%;
    h3{
      text-align: center;
    }
      
      p {
        margin-top: -5px;
        font-size: 12px;
        border-bottom: 1px solid var(--background-color);
        color: var(--text);
      }
   }
   @media (max-width: 500px) {
    margin-top: 30px;
    grid-area: karyawan-by-status-kontrak;
    height: 100px;
   }
`;
export const EmployeeStatusContract = styled.div`
  @media (max-width: 1125px) {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    padding-left: 20px;
  }
`
export const KaryawanKontrakBerakhir = styled(Link)`
    grid-area: karyawan-kontrak-berakhir;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;    
    align-items: center;
    padding: 7px 5px;
    text-decoration: none;
    h2 {
      color: #fa7d00;
      font-size: 26px;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
    }
 
    p {
      font-size: 13px;
      color: var(--text);

    }
    height: 95px;
    @media screen and (max-width: 1125px) {
      height: 76px;
    }

    @media screen and (max-width: 500px) {
     margin-top: 50px;
     height: 100px;
    }
`;

export const ContentSectionKaryawan = styled.div`
  width: ${props => (props.full ? '100%' : '80%')};/* Centering chart */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
    h4{
      text-align: center;
    }
    p{
      text-align: center;
    }
    div{
      margin-right: 20px;
    }
    canvas {
    width: 100% !important;  /* Paksa canvas untuk mengisi lebar kontainer */
    height: 100% !important; /* Paksa canvas untuk mengisi tinggi kontainer */
  }
  /* Media queries */
  @media (max-width: 768px) {
    width: 100%;

  }
`;

export const TitleSectionKaryawan = styled.h3`
  font-size: 15px;
  color: ${props => props.color || 'var(--text)'};
  margin-bottom: 2px;
  padding-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .link {
    font-size: 11px;
    color: var(--text);
  }
  @media (max-width: 1125px) {
    display: inline;
  }
`;

export const DoughnutContainer = styled.div`
  width: 100%;
  max-width: 400px; /* Ukuran maksimal diperbesar */
  height: 400px; /* Tinggi diperbesar */
  display: flex;
  justify-content: center;
  align-items: center;

  canvas {
    width: 100% !important;  /* Paksa canvas untuk mengisi lebar kontainer */
    height: 100% !important; /* Paksa canvas untuk mengisi tinggi kontainer */
  }

  @media (max-width: 1125px) {
    max-width: 300px;
    height: 300px;
  }

  @media (max-width: 500px) {
    max-width: 300px;
    height: 300px;
  }
`;

export const DoughnutContainerMobile = styled.div`
  width: 100%;
  max-width: 400px; /* Ukuran maksimal diperbesar */
  height: 400px; /* Tinggi diperbesar */
  display: none;
  justify-content: center;
  align-items: center;

  canvas {
    width: 100% !important;  /* Paksa canvas untuk mengisi lebar kontainer */
    height: 100% !important; /* Paksa canvas untuk mengisi tinggi kontainer */
  }

  @media (max-width: 1286px) {
    max-width: 300px;
    height: 300px;
    display: flex;
  }

  @media (max-width: 500px) {
    max-width: 300px;
    height: 300px;
  }
`;

export const DiagramContainer = styled.div`
 @media (max-width: 1286px) {
    display: none;
  }
`;
export const DiagramContainerMobile = styled.div`
  width: 100%;
  max-width: 400px; /* Ukuran maksimal diperbesar */
  height: 400px;
  justify-content: center;
  align-items: center;
display: none;
  canvas {
    width: 100% !important;  /* Paksa canvas untuk mengisi lebar kontainer */
    height: 100% !important; /* Paksa canvas untuk mengisi tinggi kontainer */
  }

  @media (max-width: 1421px) {
    max-width: 300px;
    height: 300px;
    display: flex;
  }

  @media (max-width: 500px) {
    max-width: 300px;
    height: 300px;
    display: flex;
  }
`;




export const KaryawanByCabangResponsive = styled.div`
    display: none;
    @media (max-width: 1125px) {
      height: 350px;
      grid-area: karyawan-by-cabang;
    background-color: #fff; 
    /* position: relative; */
    display: flex;
    align-items: center;
    flex-direction: column;
    }
    @media screen and (max-width: 500px) {
      padding-top: 0px;
      height: 350px;
    }
`;

export const KaryawanByCabang = styled.div`
   grid-area: karyawan-by-cabang;
    background-color: #fff; 
    /* position: relative; */
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 330px;
    @media (max-width: 1125px) {
      display: none;
    }
`;


export const ListRequestKaryawan = styled.div`
  grid-area: karyawan-request;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 12px;
 
  p {
    font-size: 12px;
    padding: 5px 0;
    color: var(--text);
  }
  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--background-color);

    /* background-color: #aaa; */
    
  }
  @media screen and (max-width: 1125px) {
    margin-top: 40px;
    height: 200px;
    padding: 30px;
  }

  @media screen and (max-width: 500px) {
    margin-top: 30px;
  }
`;

export const RequestMenu = styled.div`
    grid-area: request-menu;
    display: none;
    overflow: hidden;
    


    @media screen and (max-width: 500px) {
        /* background-color: transparent; */
        display:flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100px;
        margin-top: 60px;
        /* padding-top: 15px; */
    }

`;

export const IconRequestMenu = styled.svg`

`;

export const RequestDetail = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    /* margin-top: 5px; */
    /* position: relative; */
    width: 90%;
    margin: 0 auto;

    &::-webkit-scrollbar {
            display: none;
    }

    @media screen and (max-width: 500px) {
        overflow-x: auto;

    }
`;

export const LinkRequestMenu = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background-color: #FFF;
  height: 100%;
  min-width: calc(50% - 40px);
  margin-left: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: #222;
  &:first-child {
      margin-left: 0;
      
  }
  h3 {
    font-size: 14px;
    padding: 0 5px;
  }
  img {
    height: 50px;
    width: 30px;
  }
  .cuti {
        color: #aaa;

    }
    .izin {
        color: #123;

    }
    .pinjaman {
        color: #321;
    }
    .sakit {
        color: #453;
    }
    .telat {
        color: #ec2;
    }
            

    
`;

export const RequestTitle = styled.div`
    display: none;
    @media screen and (max-width: 500px) {
        margin: 0 20px;
        color: white;
        display: flex;
        justify-content: space-between;
    }
`;


export const BuatTugasKaryawan = styled.div`
    grid-area: buat-tugas-pengumuman;
    display: flex;
    background-color: #fff;
    flex-direction: column;
    /* padding: 0 10px; */
   height: 108px;
    h3 {
      font-size: 15px;
      border-bottom: 1px solid var(--background-color);
      background-color: var(--primary-color);
      border-radius: 3px;
      text-align: center;
      color: var(--white);
      padding: 5px;

    }
    div {
      display: flex;
      background-color: var(--white);
      justify-content: space-evenly;
      font-size: 13px;
      .button-link {
        font-weight: bold;
        margin-top: 15px;
        padding: 7px 10px;
        border: 1px solid var(--primary-color);
        border-radius: 3px;
        /* background-color: var(--primary-color-hover); */
        color: var(--primary-color);
        text-decoration: none;
        &:hover {
        background-color: var(--primary-color-hover);
        border: 1px solid var(--primary-color-hover);
        color: var(--white);



        }

      }

    }
    @media screen and (max-width: 1125px) {
    margin-top: 40px;
    height: 100px;
  }
    @media screen and (max-width: 500px) {
        height: 100px;
        margin-top: 15px;
        /* display: none; */

    }
`;


export const KehadiranText = styled.div`
    grid-area: kehadiran;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: salmon; */
    height: 25px;
    margin-top: 20px;
    /* margin-top: 15px; */
    @media screen and (max-width: 1125px) {
      margin-top: 70px;
    }
    @media screen and (max-width: 500px) {
      /* display: none; */
      margin-top: 20px;
      padding: 20px;
    }
`;


export const OverviewKehadiran = styled.div`
    grid-area: overview-kehadiran;
    background-color: #fff;
    /* height: 400px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: -40px;
    height: 400px;
    box-sizing: border-box;
    /* position: relative; */
    h3 {
      font-size: 15px;
      border-bottom: 1px solid var(--background-color);
      background-color: var(--green);
      border-radius: 3px;
      text-align: center;
      color: var(--white);
      width: 100%;
      padding: 7px 0;

    }
    div {
      margin-top: 7px;
      /* margin-bottom: 10px; */
    }
    div.total-kehadiran {
      /* background-color: #aaa; */
      position: relative;
      margin-left: auto;
      top: -35px;
      right: 30px;
      h4 {
        font-size: 26px;
        color: var(--black);
        span {
          font-size: 16px;
          font-weight: normal;
        }
      }
      p {
        color: var(--text);
        text-align: center;
        font-size: 14px;
      }

    }
    @media screen and (max-width: 1125px) {
      height: 400px;
      margin-top: -100px;
    }
`;

export const ListTidakHadir = styled.div`
    grid-area: list-tidak-hadir;
    background-color: #fff;
    /* height: 400px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    margin-top: -40px;
    height: 400px;
    box-sizing: border-box;
    /* position: relative; */
    h3 {
      font-size: 15px;
      border-bottom: 1px solid var(--background-color);
      background-color: var(--black);
      border-radius: 3px;
      text-align: center;
      color: var(--white);
      width: 100%;
      padding: 7px 0;

    }
    h5{
      font-size: 1px;
    }
 
    @media screen and (max-width: 1125px) {
      /* display: none; */
      height: 390px;
      margin-top: -100px;
    }
    @media screen and (max-width: 770px) {
      /* display: none; */
      height: 350px;
      margin-top: -100px;
    }
    @media screen and (max-width: 500px) {
      /* display: none; */
      margin-top: -40px;
      height: 400px;
    }

`;

export const ListTelat = styled.div`
    grid-area: list-telat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    margin-top: -40px;
    height: 400px;
    box-sizing: border-box;
    /* position: relative; */
    h3 {
      font-size: 15px;
      border-bottom: 1px solid var(--background-color);
      background-color: var(--red);
      border-radius: 3px;
      text-align: center;
      color: var(--white);
      width: 100%;
      padding: 7px 0;

    }

    @media (max-width: 1125px) {
     margin-top : -100px;
    }
    @media screen and (max-width: 500px) {
      /* display: none; */
      margin-top: -120px;
      /* width: ; */
    }
`;

export const RankKehadiran = styled.div`
    grid-area: rank-kehadiran;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 5px;
    width: 100%;
    height: 180px;
    box-sizing: border-box;
    position: unset;
    margin-top: 50px;
    h3 {
      font-size: 15px;
      border-bottom: 1px solid var(--background-color);
      background-color: var(--primary-color);
      border-radius: 3px;
      text-align: center;
      color: var(--white);
      width: 100%;
      padding: 7px 0;

    }
    @media (max-width: 1125px) {
      margin-top: -100px;
    }

    @media screen and (max-width: 500px) {
      /* display: none; */
      margin-top: -50px;
      
    }
`;

export const PulangAwal = styled.div`
    grid-area: pulang-awal;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    background-color: var(--white);
    border-radius: 5px;
    width: 100%;
    height: 180px;
    box-sizing: border-box;
    position: unset;
    margin-top: 50px;
    h3 {
      font-size: 15px;
      border-bottom: 1px solid var(--background-color);
      background-color: orange;
      border-radius: 3px;
      text-align: center;
      color: var(--white);
      width: 100%;
      padding: 7px 0;

    }
    @media (max-width: 1125px) {
      margin-top: 0px;
    }
    @media (max-width: 770px) {
      margin-top: -50px;
    }
    @media screen and (max-width: 500px) {
      /* display: none; */
      margin-top: -60px;
    }
`;

export const KaryawanLembur = styled.div`
    grid-area: karyawan-lembur;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--white);
    border-radius: 5px;
    width: 100%;
    height: 180px;
    box-sizing: border-box;
    margin-top: 50px;
    h3 {
      font-size: 15px;
      border-bottom: 1px solid var(--background-color);
      background-color: var(--green);
      border-radius: 3px;
      text-align: center;
      color: var(--white);
      width: 100%;
      padding: 7px 0;
    }
    @media (max-width: 1125px) {
      margin-top: 0px;
    }
    @media (max-width: 770px) {
      margin-top: -50px;
    }
    @media (max-width: 500px) {
      margin-top: -100px;
    }
`;

export const SmallAvatar = styled.div`
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--white);
  border: 2px solid var(--black);
`;

export const AvatarImage = styled.img`

  height: 35px;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 50%;
`;


export const Container = styled.div`

`;

// Judul
export const Title = styled.h3`
color: white;
margin: 0;
`;

// Container untuk daftar kartu
export const CardContainer = styled.div`
display: flex;
overflow: hidden;
justify-content: space-between;
padding: 10px 0;
gap: 8px;
width: 100%;
/* position: relative; */
@media (max-width: 500px) {
  display: none;
}
`;
export const CardContainerMobile = styled.div`
display: none;
@media (max-width: 500px) {
  display: flex;
overflow: hidden;
justify-content: space-between;
padding: 10px 0;
gap: 8px;
width: 100%;
}
/* position: relative; */
`;

// Tombol geser
export const ArrowButton = styled.button`

border: none;
cursor: pointer;
color: blue;
font-size: 18px;
`;

// Card untuk setiap karyawan
export const EmployeeCard = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
min-width: 80px;
height: 100px;
border: 2px solid ${(props) => props.borderColor || 'black'};
border-radius: 8px;
background-color: ${(props) => props.bgColor || 'white'};
color: ${(props) => props.color || 'black'};
`;

// Nama karyawan
export const EmployeeName = styled.div`
margin-top: 5px;
font-size: 12px;
font-weight: bold;
text-align: center;
`;

// Dot indikator halaman
export const IndicatorContainer = styled.div`
display: flex;
gap: 4px;
margin-top: 10px;
@media (max-width: 500px) {
  display: none;
}
`;

export const IndicatorContainerMobile = styled.div`
display: none;
@media (max-width: 500px) {
  display: flex;
gap: 4px;
margin-top: 10px;
}
`;


export const IndicatorDot = styled.div`
width: 8px;
height: 8px;
background-color: ${(props) => (props.active ? '#004b93' : '#ccc')};
border-radius: 50%;
`;

export const SlideItem = styled.div`
  width: 60px;
  height: 20px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border: 2px solid ${props => props.borderColor || "#000"};
  margin-right: 5px;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 5px;
  }

  p {
    font-size: 12px;
    color: #333;
    text-align: center;
  }
  background-color:${props => props.globalIndex == 0 ? "var(--primary-color)" : props.globalIndex == 1 ? "var(--green)" : props.globalIndex == 2 ? "var(--yellow)" : ""}  !important;
  border: 2px solid ${props => props.globalIndex == 0 ? "var(--primary-color)" : props.globalIndex == 1 ? "var(--green)" : props.globalIndex == 2 ? "var(--yellow)" : ""} !important;
  img{
    border: 3px solid ${props => props.globalIndex == 0 ? "var(--white)" : props.globalIndex == 1 ? "var(--white)" : props.globalIndex == 2 ? "var(--white)" : "var(--secondary-color)"};
  }
  p{
    color: ${props => props.globalIndex == 0 ? "var(--white)" : props.globalIndex == 1 ? "var(--white)" : props.globalIndex == 2 ? "var(--white)" : "var(--black)"} !important;
    font-weight: bold;
  }
  .slide-item-rank {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
  border-radius: 7px;
  background-color: var(--white);
  height: 75px !important;
  width: 65px !important;
  border: 2px solid var(--black) !important;
}
`;

export const ListSlide = styled.div`
display: flex;
justify-content: center;
align-items: center;
`


