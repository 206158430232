import axios from "axios";
import authHeader from "../Auth/header";
import Delete from "../Delete";
import Get from "../Get";
import Post from "../Post";
import Put from "../Put";
// const token = localStorage.getItem('token');

export const getHRDashboard = () => Get('hr', 'dashboard', token);

export const getBranch = (token, page, per_page, search = '') => Get('hr', `v1/branches?page=${page}&per_page=${per_page}&search=${search}`, token);
export const getDivision = (token, page = '', per_page = '', search = '') => Get('hr', `v1/divisions?page=${page}&per_page=${per_page}&search=${search}`, token);
export const getAllDivision = (token) => Get('hr', `v1/all-divisions`, token);
export const getPosition = (token, page = '', per_page = '', search = '') => Get('hr', `v1/positions?page=${page}&per_page=${per_page}&search=${search}`, token);
export const getTeamGroup = (token, page = '', per_page = '', search = '') => Get('hr', `v1/team-groups?page=${page}&per_page=${per_page}&search=${search}`, token);
export const getWorkLocation = (token, page = '', per_page = '', search = '') => Get('hr', `v1/work-locations?page=${page}&per_page=${per_page}&search=${search}`, token);
export const getWorkShift = (token, page = '', per_page = '', search = '') => Get('hr', `v1/work-shifts?page=${page}&per_page=${per_page}&search=${search}`, token);
export const getHoliday = (token, page, per_page, search = '') => Get('hr', `v1/holidays?page=${page}&per_page=${per_page}&search=${search}`, token);
export const getTeamGroupByDivisionID = (token, id) => Get('hr', `v1/team-groups/division/${id}`, token);
export const getEmployee = (token, page = '', per_page = '', search = '', division) => Get('hr', `v1/employees?page=${page}&per_page=${per_page}&search=${search}&division=${division}`, token);
export const getEmployeePersonalDetail = (token, employee_id) => Get('hr', `v1/employees-personal-detail/show/${employee_id}`, token);
export const getEmployeeContact = (token, employee_id) => Get('hr', `v1/employees-contact/show/${employee_id}`, token);
export const getEmployeeFamilie = (token, employee_id) => Get('hr', `v1/employees-familie/show/${employee_id}`, token);
export const getEmployeeEducation = (token, employee_id) => Get('hr', `v1/employees-education/show/${employee_id}`, token);
export const getEmployeeWorkExperience = (token, employee_id) => Get('hr', `v1/employees-work-experience/show/${employee_id}`, token);
export const getEmployeeByTeamGroupID = (token, id) => Get('hr', `v1/employees/team-group/${id}`, token);
export const getEmployeeByEmail = (token, email) => Get('hr', `v1/employee/${email}`, token);
export const getWorkShiftByTeamGroupID = (token, id) => Get('hr', `v1/work-shifts/team-group/${id}`, token);
export const getAllWorkLocation = (token) => Get('hr', `v1/work-locations/all`, token);
export const getGroupScheduleInfo = (token) => Get('hr', `v1/team-groups/schedule-info`, token);
export const getScheduleByTeamGroupID = (token, id, date) => Get('hr', `v1/schedules/team-group/${id}/${date}`, token);
export const getEmployeeScheduleAtMonth = (token, id, date) => Get('hr', `v1/schedules/employee/${id}/${date}/web`, token);
export const getHolidaysAtMonth = (token, date) => Get('hr', `v1/holidays/${date}`, token);
export const isDateHoliday = (token, date) => Get('hr', `v1/holiday/${date}`, token);
export const checkTodayScheduleOfEmployee = (token, id, date) => Get('hr', `v1/schedule/employee/${id}/${date}`, token);
export const checkTodayAttendanceOfEmployee = (token, id, date) => Get('hr', `v1/attendance/employee/${id}/${date}`, token);
export const checkAttendanceOvertimeOfEmployee = (token, id, date) => Get('hr', `v1/attendance/overtime/${id}/${date}`, token);
export const getEmployeeOvertimeRequest = (token, id, date, type) => Get('hr', `v1/overtimes/employee/${id}/${date}/${type}`, token);
export const getSetupOvertime = (token, group_id) => Get('hr', `v1/setup-overtime/${group_id}`, token);
export const getOvertimeSchemes = (token, group_id, overtime_day_type) => Get('hr', `v1/overtime-schemes/${group_id}/${overtime_day_type}`, token);
export const getBasicSalaryAndWorkDayInWeek = (token, position_id, group_id) => Get('hr', `v1/basic-salary-and-work-day/${position_id}/${group_id}`, token);
export const getAllOvertimeDayTypes = (token) => Get('hr', `v1/overtime-day-types/all`, token);
export const getAllLeaveTypes = (token) => Get('hr', `v1/leave-types/all`, token);
export const getSetupLeave = (token) => Get('hr', `v1/setup-leaves`, token);
export const getSetupLeaveGroup = (token, group_id, employee_id, year) => Get('hr', `v1/setup-leaves/group/${group_id}/${employee_id}/${year}`, token);
export const getSetupLoan = (token) => Get('hr', `v1/setup-loans`, token);
export const getSetupBPJS = (token) => Get('hr', `v1/setup-bpjs`, token);
export const getSetupPPh21KPP = (token) => Get('hr', `v1/setup-kpp-pph21`, token);
export const getSetupPPh21 = (token) => Get('hr', `v1/setup-pph21`, token);
export const getPTKPBiayaJabatanTarifPPh21 = (token) => Get('hr', `v1/pph21/ptkp-biaya-jabatan-tarif`, token);
export const getEmployeeLeaveHistories = (token, id, date, type) => Get('hr', `v1/leaves/employee/${id}/${date}/${type}`, token);
export const isEmployeeHaveOvertimeToday = (token, id, date) => Get('hr', `v1/overtime/employee/${id}/${date}`, token);
export const getRecapAttendance = (token, id, year) => Get('hr', `v1/attendances/recap/${id}/${year}`, token);
export const getRecapLeave = (token, id, group_id, year) => Get('hr', `v1/leaves/recap/${id}/${group_id}/${year}`, token);
export const getAllEmployee = (token) => Get('hr', `v1/all-employees`, token);
export const getAllViolation = (token) => Get('hr', `v1/all-violations`, token);
export const getAllEmployeeLoans = (token) => Get('hr', `v1/all-loans`, token);
export const getAllLoanInstallment = (token) => Get('hr', `v1/all-installments`, token);
export const getWarningLetter = (token, page = '', per_page = '', search = '', periode, division, month) => Get('hr', `v1/warning-letters?page=${page}&per_page=${per_page}&search=${search}&periode=${periode}&division=${division}&month=${month}`, token);
export const getEmployeeLoan = (token, page = '', per_page = '', search = '', year, division, status, month) => Get('hr', `v1/loans?page=${page}&per_page=${per_page}&search=${search}&year=${year}&division=${division}&status=${status}&month=${month}`, token);
export const getEmployeeLoanInstallment = (token, page = '', per_page = '', search = '', year, division, month) => Get('hr', `v1/installments?page=${page}&per_page=${per_page}&search=${search}&year=${year}&division=${division}&month=${month}`, token);
export const getEmployeeLeave = (token, page = '', per_page = '', search = '', periode, division, status, month) => Get('hr', `v1/leaves?page=${page}&per_page=${per_page}&search=${search}&periode=${periode}&division=${division}&status=${status}&month=${month}`, token);
export const getEmployeeOvertime = (token, page = '', per_page = '', search = '', year, division, status, month) => Get('hr', `v1/overtimes?page=${page}&per_page=${per_page}&search=${search}&year=${year}&month=${month}&division=${division}&status=${status}`, token);
export const getEmployeeOvertimeAttendance = (token, year, division, month) => Get('hr', `v1/overtime-attendances?year=${year}&month=${month}&division=${division}`, token);
export const getMyTodayAttendanceData = (token, id, date) => Get('hr', `v1/my-today-attendance/${id}/${date}`, token);

export const getReportAttendance = (token, data) => Post('hr', `v1/report/attendances`, data, token);
export const getEmployeeLocation = (token, data) => Post('hr', `v1/employee/get-location`, data, token);

export const getHomePage = (token) => Get('hr', `home-page`, token);

export const addBranch = (token, data) => Post('hr', 'v1/branches', data, token);
export const addDivision = (token, data) => Post('hr', 'v1/divisions', data, token);
export const addPosition = (token, data) => Post('hr', 'v1/positions', data, token);
export const addTeamGroup = (token, data) => Post('hr', 'v1/team-groups', data, token);
export const addWorkLocation = (token, data) => Post('hr', 'v1/work-locations', data, token);
export const addWorkShift = (token, data) => Post('hr', 'v1/work-shifts', data, token);
export const addHoliday = (token, data) => Post('hr', 'v1/holidays', data, token);
export const addEmployee = (token, data) => Post('hr', 'v1/employees', data, token);
export const addEmployeePersonalDetail = (token, data, employee_id) => Post('hr', `v1/employees-personal-detail/create/${employee_id}`, data, token);
export const addEmployeeContact = (token, data, employee_id) => Post('hr', `v1/employees-contact/create/${employee_id}`, data, token);
export const addEmployeeFamilie = (token, data, employee_id) => Post('hr', `v1/employees-familie/create/${employee_id}`, data, token);
export const addEmployeeEducation = (token, data, employee_id) => Post('hr', `v1/employees-education/create/${employee_id}`, data, token);
export const addEmployeeWorkExperience = (token, data, employee_id) => Post('hr', `v1/employees-work-experience/create/${employee_id}`, data, token);
export const addSchedule = (token, data) => Post('hr', 'v1/schedules', data, token);
export const copySchedule = (token, data) => Post('hr', 'v1/copy-schedules', data, token);
export const addTimeIn = (token, data) => Post('hr', `v1/attendance/employee`, data, token);
export const addTimeOut = (token, data) => Put('hr', `v1/attendance/employee`, data, token);
export const addSetupOvertime = (token, data) => Post('hr', `v1/setup-overtime`, data, token);
export const addOvertimeSchemes = (token, data) => Post('hr', `v1/overtime-schemes`, data, token);
export const addSetupLeave = (token, data) => Post('hr', `v1/setup-leave`, data, token);
export const addSetupLoan = (token, data) => Post('hr', `v1/setup-loan`, data, token);
export const addSetupBPJS = (token, data) => Post('hr', `v1/setup-bpjs`, data, token);
export const addSetupPPh21KPP = (token, data) => Post('hr', `v1/setup-kpp-pph21`, data, token);
export const addSetupPPh21 = (token, data) => Post('hr', `v1/setup-pph21`, data, token);
export const addLeaveRequest = (token, data) => Post('hr', `v1/leaves`, data, token);
export const addOvertimeRequest = (token, data) => Post('hr', `v1/overtimes`, data, token);
export const addOvertimeIn = (token, data) => Post('hr', `v1/overtime/employee`, data, token);
export const addOvertimeOut = (token, data) => Put('hr', `v1/overtime/employee`, data, token);
export const addAttendanceByAdmin = (token, data) => Post('hr', 'v1/attendances', data, token);
export const addWarningLetter = (token, data) => Post('hr', 'v1/warning-letters', data, token);
export const addLoanEmployee = (token, data) => Post('hr', 'v1/loans', data, token);
export const addLoanInstallmentEmployee = (token, data) => Post('hr', 'v1/installments', data, token);
export const addLeaveEmployee = (token, data) => Post('hr', 'v1/leaves', data, token);
export const addOvertimeEmployee = (token, data) => Post('hr', 'v1/add-overtime', data, token);
export const addHomePageSubSection = (token, data, id) => Post('hr', `v1/home-page/sub-section/${id}/create`, data, token);
export const addHomePageMainSection = (token, data, id) => Post('hr', `v1/home-page/main-section/create`, data, token);

export const deleteBranch = (token, id) => Delete('hr', `v1/branches/${id}`, token);
export const deleteDivision = (token, id) => Delete('hr', `v1/divisions/${id}`, token);
export const deletePosition = (token, id) => Delete('hr', `v1/positions/${id}`, token);
export const deleteTeamGroup = (token, id) => Delete('hr', `v1/team-groups/${id}`, token);
export const deleteWorkLocation = (token, id) => Delete('hr', `v1/work-locations/${id}`, token);
export const deleteWorkShift = (token, id) => Delete('hr', `v1/work-shifts/${id}`, token);
export const deleteHoliday = (token, id) => Delete('hr', `v1/holidays/${id}`, token);
export const deleteEmployee = (token, id) => Delete('hr', `v1/employees/${id}`, token);
export const deleteEmployeeEducation = (token, id) => Delete('hr', `v1/employees-education/${id}`, token);
export const deleteEmployeeWorkExperience = (token, id) => Delete('hr', `v1/employees-work-experience/${id}`, token);
export const deleteSchedule = (token, id) => Delete('hr', `v1/schedules/${id}`, token);
export const deleteScheduleEmployeeAtMonth = (token, id, date) => Delete('hr', `v1/schedules/${id}/${date}`, token);
export const deleteLeaveRequest = (token, id) => Delete('hr', `v1/leave/${id}`, token);
export const deleteOvertimeRequest = (token, id) => Delete('hr', `v1/overtime/${id}`, token);
export const deleteAttendance = (token, id) => Delete('hr', `v1/attendance/${id}`, token);
export const deleteWarningLetter = (token, id) => Delete('hr', `v1/warning-letters/${id}`, token);
export const deleteLoanEmployee = (token, id) => Delete('hr', `v1/loans/${id}`, token);
export const deleteEmployeeLoanInstallment = (token, id) => Delete('hr', `v1/installments/${id}`, token);
export const deleteLeaveEmployee = (token, id) => Delete('hr', `v1/leaves/${id}`, token);
export const deleteOvertimeEmployee = (token, id) => Delete('hr', `v1/overtimes/${id}`, token);

export const deletHomePageSubSection = (token, id) => Delete('hr', `v1/home-page/sub-section/${id}/delete`, token);
export const deletHomePageMainSection = (token, id) => Delete('hr', `v1/home-page/main-section/${id}/delete`, token);

export const editBranch = (token, data) => Put('hr', `v1/branches/${data.id}`, data, token);
export const editDivision = (token, data) => Put('hr', `v1/divisions/${data.id}`, data, token);
export const editPosition = (token, data) => Put('hr', `v1/positions/${data.id}`, data, token);
export const editTeamGroup = (token, data) => Put('hr', `v1/team-groups/${data.id}`, data, token);
export const editWorkLocation = (token, data) => Put('hr', `v1/work-locations/${data.id}`, data, token);
export const editWorkShift = (token, data) => Put('hr', `v1/work-shifts/${data.id}`, data, token);
export const editHoliday = (token, data) => Put('hr', `v1/holidays/${data.id}`, data, token);
export const editEmployee = (token, data) => Put('hr', `v1/employees/${data.id}`, data, token);
export const editEmployeePersonalDetail = (token, data, id) => Post('hr', `v1/employees-personal-detail/update/${id}`, data, token);
export const editEmployeeContact = (token, data, id) => Post('hr', `v1/employees-contact/update/${id}`, data, token);
export const editEmployeeFamilie = (token, data, id) => Post('hr', `v1/employees-familie/update/${id}`, data, token);
export const editEmployeeEducation = (token, data, id) => Post('hr', `v1/employees-education/update/${id}`, data, token);
export const editEmployeeWorkExperience = (token, data, id) => Post('hr', `v1/employees-work-experience/update/${id}`, data, token);
export const editSchedule = (token, data) => Put('hr', `v1/schedules/${data.id}`, data, token);
export const editSetupOvertime = (token, data) => Put('hr', `v1/setup-overtime/${data.id}`, data, token);
export const editOvertimeSchemes = (token, data) => Put('hr', `v1/overtime-schemes/${data.id}`, data, token);
export const editSetupLeave = (token, data) => Put('hr', `v1/setup-leave/${data.id}`, data, token);
export const editSetupLoan = (token, data) => Put('hr', `v1/setup-loan/${data.id}`, data, token);
export const editSetupBPJS = (token, data) => Put('hr', `v1/setup-bpjs/${data.id}`, data, token);
export const editSetupPPh21KPP = (token, data) => Put('hr', `v1/setup-kpp-pph21/${data.id}`, data, token);
export const editSetupPPh21 = (token, data) => Put('hr', `v1/setup-pph21/${data.id}`, data, token);
export const editAttendance = (token, data) => Put('hr', `v1/attendances/${data.id}`, data, token);
export const editWarningLetter = (token, data) => Put('hr', `v1/warning-letters/${data.id}`, data, token);
export const editLoanEmployee = (token, data) => Put('hr', `v1/loans/${data.id}`, data, token);
export const editLoanInstallmentEmployee = (token, data) => Put('hr', `v1/installments/${data.id}`, data, token);
export const editLeaveEmployee = (token, data) => Put('hr', `v1/leaves/${data.id}`, data, token);
export const editOvertimeEmployee = (token, data) => Put('hr', `v1/overtimes/${data.id}`, data, token);

export const EditHomePageMainSection = (token, data, id) => Post('hr', `v1/home-page/main-section/${id}/update`, data, token);
export const EditHomePageSubSection = (token, data, id) => Post('hr', `v1/home-page/sub-section/${id}/update`, data, token);


// export const userData = (token, page, per_page, search = '') => Get('auth', `client/my-users?page=${page}&per_page=${per_page}&search=${search}`, token)
// export const userLogin = (data) => Post('auth', 'client-user/login', data);
// export const userRegister = (data) => Post('auth', 'client-user/register', data);
// export const userLogout = (token) => Get('auth', 'client-user/logout', token);
// export const userData = (token, page, per_page, search = '') => Get('auth', `client/my-users?page=${page}&per_page=${per_page}&search=${search}`, token)
// export const userCreate = (token, data) => Post('auth', 'client/my-users', data, token);
// export const userEdit = (token, data) => Put('auth', `client/my-users/${data.id}`, data, token);
// export const userDelete = (token, id) => Delete('auth', `client/my-users/${id}`, token)

export const getEmployeeChart = (token, data, year, selectedDate) => Get('hr', `v1/dashboard-hr/${data.id}/${year}/${data.group_id}?tanggal=${selectedDate}`, token);