import { Circle, Line } from './loading.elements';


const Loading = () => {
    return (
        <>
             <Circle />
             <Line />
        </>
       
    )
}



export default Loading