import React from 'react'
import { Icon } from '..'
import { Col, Row } from '../..'
import { iconLeft } from '../../../assets'
import LeaveBlanko from './LeaveBlanko'

const Blanko = ({company, value, setPageName, type, selectedYear = ''}) => {
    //blanko lembur dan cuti
    switch (type) {
        case 'overtime':
            return (
                <>
                    <div onClick={() => setPageName("Laporan Pengajuan Lembur Disetujui & Tidak Disetujui")} className="back-button" >                    
                        <Icon icon={iconLeft} color="#fff" />
                        <p>Back</p>
                    </div>
                    <div onClick={() => window.print()} className="print-button" style={{marginLeft: '5px', padding: '0 10px', height: '35px', border: '1px solid #eee'}}><span style={{fontSize: '22px', marginRight: '3px'}}>&#128424;</span>Print</div>
                    <div>
                        
                        blanko lembur {value.id === 0 ? 'kosong' : 'detail'}
                    </div>
                    
                </>
            )
            
            break;
        case 'leave':
            return (
                <>
                <Row>
                    <Col>
                        <div onClick={() => setPageName("Laporan Pengajuan Cuti Disetujui & Tidak Disetujui")} className="back-button" >                    
                            <Icon icon={iconLeft} color="#fff" />
                            <p>Back</p>
                        </div>
                    </Col>
                    <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <div onClick={() => window.print()} className="print-button" style={{marginLeft: '5px', padding: '0 10px', height: '35px', border: '1px solid #eee'}}><span style={{fontSize: '22px', marginRight: '3px'}}>&#128424;</span>Print</div>
                    </Col>
                </Row>
                    <div>
                        {
                            value.id === 0 ? 'kosong' : <LeaveBlanko company={company} pageName={"Blanko Cuti"} selectedYear={selectedYear} value={value} />
                        }
                    </div>
                    
                </>
            )
        default:
            break;
    }
    
}

export default Blanko
