import React from 'react'
import { Formik, Form } from "formik";
import { AutoCompleteSelect, FormControl } from '../../../../components';
import {getStyle} from '../../../../utils/helpers/errorMessage'
import API from '../../../../config/api';
import { tahun_bulan_tanggal } from '../../../../utils/helpers/date';
import * as Yup from "yup";
import { HR } from '../../../../config/api/ResourceURL';
import axios from 'axios';


const schemaValidation = Yup.object().shape({
  leave_no: Yup.string().required('Tidak boleh kosong'),
  leave_type: Yup.object()
      .shape({
          label: Yup.string(),
          value: Yup.string()
      })
      .nullable()
      .required("Jenis Cuti Tidak Boleh Kosong"),
  employee_id: Yup.object()
      .shape({
          label: Yup.string(),
          value: Yup.string()
      })
      .nullable()
      .required("Karyawan Tidak Boleh Kosong"),
  started_date: Yup.string().nullable().required('Tidak boleh kosong'),
  end_date: Yup.string().nullable().required('Tidak boleh kosong'),
  address_during_leave: Yup.string().required('Tidak boleh kosong'),
  contact_during_leave: Yup.string().required('Tidak boleh kosong'),
  approver_id: Yup.object()
      .shape({
          label: Yup.string(),
          value: Yup.string()
      })
      .nullable()
      .required("Yang Menyetujui Tidak Boleh Kosong"),

});




const LeaveForm = ({employeeOptions, leaveTypeOptions, token, closeModal, isAddOrEdit, initialValues}) => {
  

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    // console.log('tes')
    // values.started_date = tahun_bulan_tanggal(values.started_date)
    // values.end_date = tahun_bulan_tanggal(values.end_date)
    // console.log(values)
  
    const data = {
      leave_no: values.leave_no,
      employee_id : values.employee_id.value,
      leave_type : values.leave_type.value,
      request_date_start : values.request_date_start,
      request_date_end : values.request_date_end,
      approved_date_start : values.started_date,
      periode : new Date().getFullYear(),
      approved_date_end : values.end_date,
      employee_note : values.employee_note,
      address_during_leave : values.address_during_leave,
      contact_during_leave : values.contact_during_leave,
      approver_id : values.approver_id.value,
      approver_note : values.approver_note,
      attachment : values.attachment,
      status : 1,
    }
    // console.log(data)
    let url = HR + '/v1/leaves';

  
 

  if(isAddOrEdit == 'edit'){
      // console.log('edit');
      
      let formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('id', initialValues.id);
      formData.append('leave_no', data.leave_no);
      formData.append('employee_id', data.employee_id);
      formData.append('leave_type', data.leave_type);
      formData.append('periode', data.periode);
      formData.append('request_date_start', data.request_date_start);
      formData.append('request_date_end', data.request_date_end);
      formData.append('approved_date_start', data.approved_date_start);
      formData.append('approved_date_end', data.approved_date_end);
      formData.append('employee_note', data.employee_note);
      formData.append('address_during_leave', data.address_during_leave);
      formData.append('contact_during_leave', data.contact_during_leave);
      formData.append('approver_id', data.approver_id);
      formData.append('approver_note', data.approver_note);
      formData.append('attachment', data.attachment);
      formData.append('status', data.status);
      // console.log(formData);
      axios
      .post(`${url}/${initialValues.id}`, formData, {
          headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          },
      })
      .then(res => {
        swal({
          title: "Berhasil",
          text: res.data.message,
          icon: "success",
      });
      
        return closeModal();


      }).catch(err => {
          // console.log(err);
          swal({
              title: "Gagal",
              text: err.message,
              icon: "error",
          });
          return closeModal();
      });
  }else {
      // console.log('add')
      let formData = new FormData();
      // formData.append('tes', 'tes')

      formData.append('leave_no', data.leave_no);
      formData.append('employee_id', data.employee_id);
      formData.append('leave_type', data.leave_type);
      formData.append('request_date_start', data.request_date_start);
      formData.append('request_date_end', data.request_date_end);
      formData.append('approved_date_start', data.approved_date_start);
      formData.append('periode', data.periode);
      formData.append('approved_date_end', data.approved_date_end);
      formData.append('employee_note', data.employee_note);
      formData.append('address_during_leave', data.address_during_leave);
      formData.append('contact_during_leave', data.contact_during_leave);
      formData.append('approver_id', data.approver_id);
      formData.append('approver_note', data.approver_note);
      formData.append('attachment', data.attachment);
      formData.append('status', data.status);
      // console.log('form data', formData);
      axios
      .post(url, formData, {
          headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
          },
      })
      .then(res => {
          // console.log(res)
          // console.log(res.data.message);
          swal({
            title: "Berhasil",
            text: res.data.message,
            icon: "success",
        });
        resetForm();
        setSubmitting(false);
        return closeModal();

        
      }).catch(err => {
          console.log(err.response);
          swal({
              title: "Gagal",
              // text: err.response.data.message,
              icon: "error",
          });

          return closeModal();

      });
  }
  
    // values.started_date = new Date(values.started_date)
    // values.end_date = new Date(values.end_date)


  };
    return (
      <Formik
          initialValues={initialValues}
          onSubmit={(values, props) => handleSubmit(values, props)}
          validationSchema={() => schemaValidation}
          >
      {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            isSubmitting
          }) => (
            <div className="modal-body">

            <Form>
                <FormControl control="input" type="text" label="Nomor Cuti" name="leave_no"
                    style={getStyle(errors, touched, 'leave_no')} placeholder="Nomor Cuti"
                />
              <AutoCompleteSelect
                id="employee_id"
                name="employee_id"
                label="Karyawan"
                placeholder="Pilih Karyawan..."
                options={employeeOptions}
                value={values.employee_id}
                isMulti={false}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                touched={touched.employee_id}
                error={errors.employee_id}
                isClearable={true}
                backspaceRemovesValue={true}
              />
              <AutoCompleteSelect
                id="leave_type"
                name="leave_type"
                label="Jenis Cuti"
                placeholder="Pilih Jenis Cuti..."
                options={leaveTypeOptions}
                value={values.leave_type}
                isMulti={false}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                touched={touched.leave_type}
                error={errors.leave_type}
                isClearable={true}
                backspaceRemovesValue={true}
              />
              <FormControl
                control="input"
                type="date"
                name="started_date"
                // value={values.request_date_start}
                label='Mulai Dari Tanggal'
                style={getStyle(errors, touched, 'started_date')}
                
              />
              <FormControl
                control="input"
                name="end_date"
                type="date"
                // value={values.request_date_end}
                label='Sampai Tanggal'
                style={getStyle(errors, touched, 'end_date')}
                
              />

              <FormControl
                control='textarea'
                label='Keterangan Karyawan'
                placeholder="Keterangan Karyawan"
                name='employee_note'
                style={getStyle(errors, touched, 'employee_note')}

              />
                
              <FormControl
                control='textarea'
                label='Alamat Selama Cuti'
                placeholder="Alamat Selama Cuti"
                name='address_during_leave'
                style={getStyle(errors, touched, 'address_during_leave')}

              />
              <FormControl control="input" type="text" label="Kontak Selama Cuti" name="contact_during_leave"
                    style={getStyle(errors, touched, 'contact_during_leave')} placeholder="Kontak Selama Cuti"
                />
              

              <AutoCompleteSelect
                id="approver_id"
                name="approver_id"
                label="Yang Menyetujui"
                placeholder="Pilih Yang Menyetujui..."
                options={employeeOptions}
                value={values.approver_id}
                isMulti={false}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                touched={touched.approver_id}
                error={errors.approver_id}
                isClearable={true}
                backspaceRemovesValue={true}
              />

              <FormControl
                control='textarea'
                label='Catatan yg Menyetujui'
                placeholder="Catatan yg Menyetujui"
                name='approver_note'
                style={getStyle(errors, touched, 'approver_note')}

              />
              

              <FormControl control="file" label="Lampiran" name="attachment" fileURL={isAddOrEdit === 'edit' ? initialValues.attachment : null}
                  style={getStyle(errors, touched, 'attachment')}
              />
              

              <button type="submit" className="add-button add-bulk-schedule" disabled={isSubmitting}>
                <h3>Simpan</h3>
              </button>
            </Form>
            </div>
          )}
        </Formik>
    );
}

export default LeaveForm

