import React, { useEffect, useState } from 'react'
import { Formik, Form } from "formik";
import { AutoCompleteSelect, Button, FormControl } from '../../../../components';
import {getStyle} from '../../../../utils/helpers/errorMessage'
import API from '../../../../config/api';
import { getTotalHours, tahun_bulan_tanggal } from '../../../../utils/helpers/date';
import * as Yup from "yup";


const schemaValidation = Yup.object().shape({
  no: Yup.string().required('Tidak boleh kosong'),
  employee_id: Yup.object()
      .shape({
          label: Yup.string(),
          value: Yup.string()
      })
      .nullable()
      .required("Karyawan Tidak Boleh Kosong"),
  date: Yup.string().nullable().required('Tidak boleh kosong'),
  start_from: Yup.string().required('Tidak boleh kosong'),
  ends_on: Yup.string().required('Tidak boleh kosong'),
  late_tolerance: Yup.string().required('Tidak boleh kosong'),
  desc: Yup.string().required('Tidak boleh kosong'),
  overtime_location: Yup.object()
      .shape({
          label: Yup.string(),
          value: Yup.string()
      })
      .nullable()
      .required("Lokasi Lembur Tidak Boleh Kosong"),
  approved_by: Yup.object()
      .shape({
          label: Yup.string(),
          value: Yup.string()
      })
      .nullable()
      .required("Yang Menyetujui Tidak Boleh Kosong"),
  paid_by: Yup.string().nullable().required('Tidak boleh kosong'),


});




const OvertimeForm = ({employeeOptions, workLocationOptions, token, closeModal, isAddOrEdit, initialValues}) => {

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    
      //check apakah tanggal yang dipilih punya jadwal? jika tidak maka lemburnya adalah lembur di hari libur
      //check apakah tanggal yang dipilih hari libur? jika iya maka lemburnya adalah lembur di hari libur
      //check apakah hari liburnya adalah hari libur jam kerja terpendek, jika iya maka jenis lemburnya adlaah lembur di hari libur jam kerja terpendek
    // console.log(values)
    values.employee_id = values.employee_id.value
    values.overtime_location = values.overtime_location.value
    values.approved_by = values.approved_by.value
    values.total_hours = getTotalHours(values.start_from, values.ends_on);

    if(isAddOrEdit === 'add'){

      API.addOvertimeEmployee(token, values).then(res => {
        // console.log(res)
        // console.log(res.data.message);
        swal({
            title: res.data.status,
            text: res.data.message,
            icon: "success",
        });
        return closeModal();

        
      }).catch(err => {
          // console.log(err.response);
          swal({
              title: err.status,
              // text: err.response.data.message,
              icon: "error",
          });
          return closeModal();


      });
    }else {
      // console.log('edit')
      API.editOvertimeEmployee(token, values).then(res => {
        // console.log(res);
        swal({
            title: res.data.status,
            text: res.data.message,
            icon: "success",
        });
        values.date = values.tanggal
        return closeModal();


      }).catch(err => {
          // console.log(err.response);
          swal({
              title: "Gagal",
              text: err.response.data.message,
              icon: "error",
          });
          return closeModal();


      });
    }

  

    
  }

    return (
      <Formik
          initialValues={initialValues}
          onSubmit={(values, props) => handleSubmit(values, props)}
          validationSchema={() => schemaValidation}
          >
      {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
            isValid,
          }) => (
            <div className="modal-body">

            <Form>
                <FormControl control="input" type="text" label="Nomor Lembur" name="no"
                    style={getStyle(errors, touched, 'no')} placeholder="Nomor Lembur"
                />
                <AutoCompleteSelect
                  id="employee_id"
                  name="employee_id"
                  label="Karyawan"
                  placeholder="Pilih Karyawan..."
                  options={employeeOptions}
                  value={values.employee_id}
                  isMulti={false}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  touched={touched.employee_id}
                  error={errors.employee_id}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
                <FormControl
                    control='input'
                    type="date"
                    label='Tanggal'
                    name='date'
                    style={getStyle(errors, touched, 'date')}
                  />

                <FormControl control="time" label="Jam Mulai" name="start_from" 
                    style={getStyle(errors, touched, 'start_from')}
                />
                
                <FormControl control="time" label="Jam Selesai" name="ends_on" 
                    style={getStyle(errors, touched, 'ends_on')}
                />
                
                <FormControl control="input" type="number" label="Toleransi Telat" name="late_tolerance" 
                    style={getStyle(errors, touched, 'late_tolerance')}
                />

                <FormControl control="input" type="number" label="Durasi Istirahat" placeholder="Kosongi jika tidak ada istirahat" name="break_duration" 
                    style={getStyle(errors, touched, 'break_duration')}
                />
                
                <FormControl
                  control='textarea'
                  label='Keterangan'
                  placeholder="Keterangan Lembur"
                  name='desc'
                  style={getStyle(errors, touched, 'desc')}

                />
                <AutoCompleteSelect
                  id="overtime_location"
                  name="overtime_location"
                  label="Lokasi / Area Lembur"
                  placeholder="Pilih Lokasi / Area..."
                  options={workLocationOptions}
                  value={values.overtime_location}
                  isMulti={false}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  touched={touched.overtime_location}
                  error={errors.overtime_location}
                  isClearable={true}
                  backspaceRemovesValue={true}
                />
              <FormControl
                control='select'
                label='Upah Lembur'
                name='paid_by'
                options={[
                  { key: '-- Pilih Upah Lembur --', value: '' },
                  { key: 'Uang', value: 1 },
                  { key: 'Cuti', value: 2 },
              
              ]}
                style={getStyle(errors, touched, 'paid_by')}

              />
              <AutoCompleteSelect
                id="approved_by"
                name="approved_by"
                label="Yang Menyetujui"
                placeholder="Pilih Yang Menyetujui..."
                options={employeeOptions}
                value={values.approved_by}
                isMulti={false}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                touched={touched.approved_by}
                error={errors.approved_by}
                isClearable={true}
                backspaceRemovesValue={true}
              />

              {/* <button type="submit" className="add-button add-bulk-schedule" disabled={isSubmitting}>
                <h3>Simpan</h3>
              </button> */}
              <div className="modal-footer">
                  <Button buttonFull buttonColor='var(--green)' align="right" buttonHover type="submit" disabled={isSubmitting}>{(isAddOrEdit == 'add') ? 'Tambah' : 'Simpan' }</Button>
              </div>
            </Form>
            </div>
          )}
        </Formik>
    );
}

export default OvertimeForm

