import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { iconAdd, iconLeft, iconUser } from '../../../../assets';
import { Gap, PageHeader, PageContent, Row, Col, Icon, Table, Button, FormControl, ReportHeader, ReportFooter } from '../../../../components'
import API from '../../../../config/api';
import { Formik, Form, getIn } from 'formik'
import Modal from 'react-modal';
import swal from 'sweetalert';
import { useDebounce } from '../../../../utils/helpers/useDebounce';
import { employeeListFields, employeListValidationSchema } from './fields';


Modal.setAppElement('#root');
// const token = localStorage.getItem('token');

function getStyle(errors, touched, fieldName) {
    if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
      return {
        border: '1px solid red',
        borderLeft: '5px solid red'
      }
    }else if(!getIn(errors, fieldName) && getIn(touched, fieldName)){
      return {
        border: '1px solid green',

      }
    }
}


const EmployeeList = (props) => {
    // console.log(props);
    const token = props.user.token;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const perPageList = [
        {label: "10", value: 10},
        {label: "25", value: 25},
        {label: "50", value: 50},
        {label: "Semua", value: 99999},
    ];
    const [totalTableData, setTotalTableData] = useState(0);
    const [position, setPosition] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isAddOrEdit, setIsAddOrEdit] = useState('');
    const [divisionID, setDivisionID] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [formFields, setFormFields] = useState([]);
    const [initialValues, setInitialValues] = useState({});

    const [divisions, setDivisions] = useState([]);

    const [selectedDivision, setSelectedDivision] = useState(0);
    const [divisionName, setDivisionName] = useState('');
    const [pageName, setPageName] = useState("Laporan Daftar Karyawan");

    const debouncedSearchTerm = useDebounce(searchTerm, 800);


  
    const prevButton = () => {
        setCurrentPage(Number(currentPage - 1));
    }
    const nextButton = () => {
        setCurrentPage(Number(currentPage + 1));        

    }
    const pageNumberClick = (e) => {
        // setCurrentPage(e => e.target.id)
        setCurrentPage(Number(e.target.id));
    }
    const changePerPage = (e) => {
        setPerPage(Number(e.target.value))
    }

    
    const handleAdd = () => {
        setIsAddOrEdit('add');
        setInitialValues({
            id: '',
            nik: '',
            name: '',
            email: '',
            division_id: '',
            position_id: '',
            group_id: '',
            employment_status: '',
            start_work: '',
            need_attendance: '',
            attendance_with_radius: '',
            track_location: '',
            
        })
        setModalIsOpen(true);
    }
    
    const handleEdit = (row) => {
        // console.log(row)
        setIsAddOrEdit('edit');
        setInitialValues({
            id: row.id,
            nik: row.nik,
            name: row.name,
            email: row.email,
            division_id: row.division_id,
            position_id: row.position_id,
            group_id: row.group_id,
            employment_status: row.employment_status,
            start_work: row.start_work,
            need_attendance: String(row.need_attendance),
            attendance_with_radius: String(row.attendance_with_radius),
            track_location: String(row.track_location),
            auto_check_attendance: String(row.auto_check_attendance),
        })
        setModalIsOpen(true)
    }
    const handleSubmit = async data => {
        // console.log(data)
        if(isAddOrEdit == 'add') {
            API.addEmployee(token, data).then(res => {
                // console.log(res.data.message);
                swal({
                    title: res.data.status,
                    text: res.data.message,
                    icon: "success",
                });
                setModalIsOpen(false);
                
            }).catch(err => {
                // console.log(err);
                swal({
                    title: err.status,
                    text: err.message,
                    icon: "error",
                });
                setModalIsOpen(false)

            });
        }else {
            // console.log(data);
            API.editEmployee(token, data).then(res => {
                // console.log(res);
                swal({
                    title: res.data.status,
                    text: res.data.message,
                    icon: "success",
                });
                 //apa supaya tertriger use effectnya
                API.getEmployee(token, currentPage, perPage, searchTerm).then((res) => {
                    setTableData(res.data.data)
                    setTotalPage(res.data.last_page);
                    setTotalTableData(res.data.total);
                    setPosition((currentPage - 1) * perPage)
                    setMessage('success get data employee');
                    setLoading(false);
                }).catch(err => {
                    // console.log(err.response.data.message);
                    setTableData(0)
                    setMessage(err.response.data.message);
                    setLoading(false);

                })
                setModalIsOpen(false);
            }).catch(err => {
                // console.log(err);
                swal({
                    title: err.status,
                    text: err.message,
                    icon: "error",
                });
                setModalIsOpen(false)

            });
        }
    }
    const handleDelete = (row) => {
        // console.log(row)
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this employee!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                // console.log('Call API delete Departemen');
                return API.deleteEmployee(token, row.id).then(res => {
                    swal({
                        title: 'Berhasil',
                        text: 'Karyawan berhasil dihapus',
                        icon: "success",
                      });
                    // console.log('call branch Data lagi');
                    //apa supaya tertriger use effectnya
                    API.getEmployee(token, currentPage, perPage, searchTerm).then((res) => {
                        setTableData(res.data.data)
                        setTotalPage(res.data.last_page);
                        setTotalTableData(res.data.total);
                        setPosition((currentPage - 1) * perPage)
                        setMessage('success get data employee');
                        setLoading(false);
                    }).catch(err => {
                        // console.log(err.response.data.message);
                        setTableData(0)
                        setMessage(err.response.data.message);
                        setLoading(false);

                    })
                }).catch(err => {
                    console.log(err);
                    setLoading(false);
                })
            }
            else {
            //   swal("Your imaginary file is safe!");
              setLoading(false);

            }
          });
    }
    const handleDetail = (row) => {
        // console.log(row)
        // console.log(props);
        // const history = props.history;
        // console.log('ke halaman detail')
        //arahkan ke halaman detail
        props.history.push('/employee/detail', {
            employee : row,
        })
    }

    const employeeListColumns = [
        {Header: 'No',
            Cell: (row) => {
                // console.log(row)
                // console.log(row.cell.row.index)
                let startFrom = position + 1;
                return <div>{startFrom +row.cell.row.index}.</div>;
                console.log(row);
                // return <div>{row.cell.row.index+1}.</div>;
                
            }
        },
        {Header: 'Id', accessor: 'id', show: false},
        {Header: 'NIK', accessor: 'nik'},
        {Header: 'Nama', accessor: 'name'},
        {Header: 'Email', accessor: 'email'},
        {Header: 'Departemen', accessor: 'division_name'},
        {Header: 'Jabatan', accessor: 'position_name'},
        {Header: 'Tim/Grup Kerja', accessor: 'group_name'},
        {Header: 'Status', accessor: 'employment_status'},
        {Header: 'Mulai Bekerja', accessor: 'start_work'},
        {Header: 'Perlu Absensi', accessor: 'need_attendance', className:"no-print",
            Cell: (row) => {
                const perluAbsensi = row.cell.row.original.need_attendance;
                return <div>{perluAbsensi ? 'Ya' : 'Tidak'}</div>;
                // return <div>{row.cell.row.index+1}.</div>;
            }
        },
        {Header: 'Lokasi Absensi', accessor: 'attendance_with_radius', className:"no-print",
            Cell: (row) => {
                const lokasiAbsensi = row.cell.row.original.attendance_with_radius;
                return <div>{lokasiAbsensi ? 'Dalam Radius' : 'Bebas'}</div>;
                // return <div>{row.cell.row.index+1}.</div>;
            }
        },
        {Header: 'Lokasi', accessor: 'track_location', className:"no-print",
            Cell: (row) => {
                const lacakLokasi = row.cell.row.original.track_location;
                return <div>{lacakLokasi ? 'Ya' : 'Tidak'}</div>;
                // return <div>{row.cell.row.index+1}.</div>;
            }
        },
        {Header: 'Absen Otomatis Ceklis', accessor: 'auto_check_attendance', className:"no-print",
            Cell: (row) => {
                const autoCheckAttendance = row.cell.row.original.auto_check_attendance;
                return <div>{autoCheckAttendance ? 'Ya' : 'Tidak'}</div>;
                // return <div>{row.cell.row.index+1}.</div>;
            }
        },
        {Header: 'Aksi', className:"no-print",
            Cell: row => (
                <div className="edit-delete-wrapper">
                    <button className="detail-button" onClick={() => {handleDetail(row.row.original)}}>Detail</button> 
                    <button className="edit-button" onClick={() => {handleEdit(row.row.original)}}>Edit</button> 
                    <button className="delete-button" onClick={() => {handleDelete(row.row.original)}}>Delete</button>
                </div>
            )
        },
    ];

    useEffect(() => {
        //get all division
        API.getAllDivision(token).then(res => {
            // console.log('divisi: ', res.data)
            setDivisions(res.data)
            const dataDepartemen = res.data;
            employeeListFields[3].options.length = 1;
            for(let i = 0; i < dataDepartemen.length; i++) {
                
                const departemen = {
                    key: dataDepartemen[i].name, value: dataDepartemen[i].id
                }
                employeeListFields[3].options.push(departemen);
                
            }
            employeeListFields[3].callback = '';
            employeeListFields[3].callback = (selectedDivision) => {
                // console.log(`id departemen : ${selectedDivision}`);
                setDivisionID(selectedDivision);
            }
            // employeeListFields[5].options.length = 1;
            API.getPosition(token, 1, 1000).then((res) => {
                // console.log(res);
                //apabila ada data jabatan
                const data = res.data.data;
                // console.log('jabatan ditemukan');
                //hapus dulu semua pilihan inputan departemen, kecuali yang -- pilih departemen --
                employeeListFields[4].options.length = 1;
                
                // console.log(employeeListFields[0].options);
                //masukkan data jabatan ke pilihan inputannya
                for(let i = 0; i < data.length; i++) {
                
                    const dataJabatan = {
                        key: data[i].name, value: data[i].id
                    }
                    employeeListFields[4].options.push(dataJabatan);
                    
                }
            }).catch(err => {
                setTableData(0);
    
                // console.log(err);
                //apabila tidak ada divisi
                // console.log(err.response.data.message);
                setMessage(err.response.data.message || 'Tambahkan jabatan terlebih dahulu');
            })
            
            // console.log(employeeListFields);
            setFormFields(employeeListFields)

           
        }).catch(err => {
            console.log(err.response.data.message)
            setTableData(0);
            // apabila tidak ada divisi
            // console.log(err);
            setMessage(err.response.data.message || 'Tambahkan departemen terlebih dahulu');
        })
    }, [])


    useEffect(() => {
        
        setPageName(`Laporan Daftar ${selectedDivision === 0 ? 'Semua' : ''} Karyawan ${selectedDivision === 0 ? '' : divisionName}`)
            
        
    }, [divisionName])
    const handleChangeDivision = (division) => {
        setSelectedDivision(division.id)
        setDivisionName(division.name)
        // setGroup(division)
    }
    
    useEffect(() => {
        setTotalPage(0);
        
        if(searchTerm != '')
        {
            setCurrentPage(1);
            setPosition(1);
        }
            API.getEmployee(token, currentPage, perPage, searchTerm, selectedDivision).then((res) => {
                // console.log(res.data.data);
                setTableData(res.data.data)
                setTotalPage(res.data.last_page);
                setTotalTableData(res.data.total);
                setPosition((currentPage - 1) * perPage)
                setMessage('success get data employee');
                setLoading(false);
            }).catch(err => {
                // console.log(err.response.data.message);
                // console.log(err);
                setTableData(0);
                setMessage(err.response.data.message || 'Karyawan tidak ditemukan');
                
                setLoading(false);
            })
    }, [debouncedSearchTerm, currentPage, perPage, searchTerm, selectedDivision])


    useEffect(() => {
       

        API.getTeamGroupByDivisionID(token, divisionID).then(res => {
            // console.log(res);
            //apabila ada data tim/grup dengan divisi yang dipilih
            employeeListFields[5].options.length = 0;
            employeeListFields[5].options.push({key: '-- Pilih Tim/Grup --', value: ''});


            const dataTimGrup = res.data;
            // console.log(dataTimGrup);
            for(let i = 0; i < dataTimGrup.length; i++) {
                
                const inputanTimGrup = {
                    key: dataTimGrup[i].name, value: dataTimGrup[i].id
                }
                employeeListFields[5].options.push(inputanTimGrup);
                
            }
        }).catch(err => {
            employeeListFields[5].options.length = 0;

            const inputanTimGrup = {
                key: 'Tidak ada tim/grup di departemen yang dipilih', value: ''
            }
            employeeListFields[5].options.push(inputanTimGrup);
        })
        //dapatkan jabatan
        
    }, [divisionID])
   

    const pageNumbers = [];
    
    let i = 0;
    do {
        i++;
        // console.log(`halaman sekarang,`, i);
        pageNumbers.push(i);
    } while(i < totalPage)

    const renderPageNumbers = pageNumbers.map(number => {
        return (
          <a
            key={number}
            id={number}
            onClick={pageNumberClick}
            className={currentPage == number ? 'active' : ''}
          >
            {number}
          </a>
        );
      });

    return (
        <>
            <PageHeader
                title="Daftar Karyawan"
                subtitle={props.user.client_id}
                name={props.user.name}
                photo={iconUser}
            />
            <Gap height={20} />
            <Row>
                <Col>
                <Link to='/employee' className="back-button" >                    
                    <Icon icon={iconLeft} color="#fff" />
                    <p>Back</p>
                </Link>
                {/* <button onClick={handleAdd} className="back-button">
                            Back

                </button> */}
                </Col>
                <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginBottom: 5}}>
                        <p className={`filter-group ${selectedDivision == 0 ?'active': ''}`} onClick={() => handleChangeDivision({id: 0})}>Semua</p>    
                        {divisions && divisions.map(division => (
                            <p key={division.name} className={`filter-group ${selectedDivision == division.id ?'active': ''}`} onClick={() => handleChangeDivision(division)}>                    
                            {division.code}
                            </p>
                        ))
                        }
                    </Col>
                <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <div onClick={() => window.print()} className="print-button" style={{marginLeft: '5px', padding: '0 10px', height: '35px', border: '1px solid #eee'}}><span style={{fontSize: '22px', marginRight: '3px'}}>&#128424;</span>Print</div>
                </Col>
            </Row>
            
            <ReportHeader company={props.user.info.company} page={pageName} />
            
            <Row>
                <div style={{overflowX: 'auto', flex:1}}>
                    <div className="table-control">
                        <div className="limit">
                            <span>Show :</span>
                            <select onChange={changePerPage} value={perPage}>
                            {perPageList.map(perPage => {
                                return (
                                <option key={perPage.value} value={perPage.value}>
                                {perPage.label}
                                </option>
                                )
                                })
                            }
                            </select>
                            <span>Entries</span>

                        </div>
                    

                    <div className="search">
                        <input type="text"
                            placeholder="Search here..."
                            value={searchTerm || ''}
                            onChange={e => setSearchTerm(e.target.value)}
                            autoFocus
                        />
                    </div>
                    <button onClick={handleAdd} className="add-button">
                            <Icon icon={iconAdd} color="#fff" />
                            Add New

                    </button>
                    </div>
                 
                 
        
                        
                        {tableData ? 
                        <div className="print-table">
                            <Table type="dataTable" tableData={tableData} tableColumns={employeeListColumns} className="section-to-print" />
                            <ReportFooter className="section-to-print footer" />
                        </div>
                        : <><Gap height={30} /><h2 align="center">{message}</h2></>
                    }
                    
                    </div>
                </Row>
                {/* //jika tidak ada table data sembunyikan */}
    
                {tableData ? 
                    <div className="pagination">
                        <div className="pagination-info">
                            <span>Showing {position + 1} to {( currentPage != totalPage ) ? position + 1 * perPage : totalTableData } of {totalTableData} entries</span> <br />
                        </div>
                        <div className="pagination-control">
                            <button onClick={() => prevButton()} disabled={currentPage <= 1}>&laquo;</button>
                            {renderPageNumbers}
                            <button onClick={() => nextButton()} disabled={currentPage >= totalPage}>&raquo;</button>
    
                        </div>
                    </div>
                        : null
                }
               
               <Modal 
                onRequestClose={() => setModalIsOpen(false)}
                isOpen={modalIsOpen}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                      },
                    content: {
                        border: '1px solid #222',
                        padding:0,
                        top: '25px',
                        left: '400px',
                        right: '400px',
                        bottom: '50px',
                    }
                }}
            >
                <div className="modal-container">
                <div className="modal-header">
                    <h2 className="modal-title">{(isAddOrEdit == 'add') ? 'Tambah' : 'Edit' } Karyawan</h2>
                    <button className="close-modal" onClick={() => setModalIsOpen(false)}>X</button>
                </div>
                <Formik enableReinitialize initialValues={initialValues} validationSchema={employeListValidationSchema} onSubmit={handleSubmit} >
                    {({errors, touched, isValid}) => (
                    <Form>
                        <div className="modal-body">
                            <div className="form-row">
                            {
                                formFields.map(field => (
                                    // console.log(field)
                                    <FormControl key={field.name}
                                        control={field.control}
                                        type={field.type}
                                        label={field.label}
                                        name={field.name}
                                        style={getStyle(errors, touched, field.name)}
                                        options={field.options}
                                        callback={field.callback}
                                    />
                                ))
                            }    
                            </div>  

                        </div>
                        <div className="modal-footer">
                            <Button buttonFull buttonColor='var(--green)' align="right" buttonHover type="submit" disabled={!isValid || props.isLoading} className={props.isLoading ? 'btnLoading' : null}>{(isAddOrEdit == 'add') ? 'Tambah' : 'Edit' }</Button>
                        </div>
                    </Form>
                    )}
                </Formik>

                </div>
            </Modal>
        </>
    )
}

const reduxState = (state) => ({
    isLogin: state.isLogin,
    user: state.user,
    isLoading: state.isLoading

})
  
  
const reduxDispatch = (dispatch) => ({
    loading : (data) => dispatch(setLoading(data)),

    

})
export default connect(reduxState, reduxDispatch)(EmployeeList)