import React from 'react'
import { Formik, Form } from "formik";
import { AutoCompleteSelect, FormControl } from '../../../../components';
import {getStyle} from '../../../../utils/helpers/errorMessage'
import API from '../../../../config/api';
import { tahun_bulan_tanggal } from '../../../../utils/helpers/date';
import * as Yup from "yup";


const schemaValidation = Yup.object().shape({
  no: Yup.string().required('Tidak boleh kosong'),
  employee_id: Yup.object()
      .shape({
          label: Yup.string(),
          value: Yup.string()
      })
      .nullable()
      .required("Karyawan Tidak Boleh Kosong"),
  amount: Yup.string().required('Tidak boleh kosong'),
  reason: Yup.string().required('Tidak boleh kosong'),
  installments: Yup.string().required('Tidak boleh kosong'),
 
});




const LoanForm = ({employeeOptions, token, closeModal, isAddOrEdit, initialValues}) => {
  

  const handleSubmit = (values, { resetForm, setSubmitting }) => {

    
    values.employee_id = values.employee_id.value
    values.status = 1
    values.remaining = values.installments
    values.approved_date = tahun_bulan_tanggal(new Date())
    // values = JSON.stringify(values)
    // console.log("handleSubmit values", values);
    if(isAddOrEdit === 'add'){

      API.addLoanEmployee(token, values).then(res => {
        // console.log(res)
        // console.log(res.data.message);
        swal({
            title: res.data.status,
            text: res.data.message,
            icon: "success",
        });
      return closeModal();

        
      }).catch(err => {
          console.log(err.response);
          swal({
              title: err.status,
              // text: err.response.data.message,
              icon: "error",
          });


      });
    }else {
      // console.log('edit')
      API.editLoanEmployee(token, values).then(res => {
        // console.log(res);
        swal({
            title: res.data.status,
            text: res.data.message,
            icon: "success",
        });
        return closeModal();


      }).catch(err => {
          // console.log(err);
          swal({
              title: err.status,
              text: err.message,
              icon: "error",
          });
          return closeModal();


      });
    }
  

 
  };
    return (
      <Formik
          initialValues={initialValues}
          onSubmit={(values, props) => handleSubmit(values, props)}
          validationSchema={() => schemaValidation}
          >
      {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            isSubmitting
          }) => (
            <div className="modal-body">

            <Form>
                <FormControl control="input" type="text" label="Nomor Surat" name="no"
                    style={getStyle(errors, touched, 'no')} placeholder="Nomor Surat"
                />
              <AutoCompleteSelect
                id="employee_id"
                name="employee_id"
                label="Karyawan"
                placeholder="Pilih Karyawan..."
                options={employeeOptions}
                value={values.employee_id}
                isMulti={false}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                touched={touched.employee_id}
                error={errors.employee_id}
                isClearable={true}
                backspaceRemovesValue={true}
              />
                <FormControl control="format-number" type="text" label="Jumlah Pinjaman" name="amount" placeholder="Jumlah Pinjaman"
                    style={getStyle(errors, touched, 'amount')}
                />
                <FormControl control="input" type="number" label="Cicilan" name="installments" placeholder="Dicicil dalam ... bulan"
                    style={getStyle(errors, touched, 'installments')}
                />
                
                
              
              <FormControl
                control='textarea'
                label='Catatan'
                placeholder="Keterangan"
                name='reason'
                style={getStyle(errors, touched, 'reason')}

              />
             

              <button type="submit" className="add-button add-bulk-schedule" disabled={isSubmitting}>
                <h3>Simpan</h3>
              </button>
            </Form>
            </div>
          )}
        </Formik>
    );
}

export default LoanForm

