import React from 'react'

const HistoryAssignment = () => {
    return (
        <div>
            Riwayat penugasan
        </div>
    )
}

export default HistoryAssignment
