import React from 'react'

const AssetDashboard = () => {
    return (
        <div>
            asset dashboard
        </div>
    )
}

export default AssetDashboard
