import styled from "styled-components";

export const HomePageContainer = styled.div`
@media (max-width: 770px) {
  height: 850px;
}
`;

export const SectionWrapper = styled.div`
  border: 1px solid #eaeaea;
  margin-bottom: 1px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

export const SectionHeader = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const SectionContent = styled.div`
  padding: 20px;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
`;

export const Title = styled.h3`
  color: #333;
  font-size: 20px;
  margin: 12px 0;
`;

export const SubTitle = styled.p`
  color: #666;
  font-size: 16px;
  margin: 8px 0;
`;

export const Image = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 10px;
  margin: 16px 0;
`;

export const CallToAction = styled.a`
  color: #0070f3;
  font-size: 16px;
  text-decoration: none;
  margin-top: 1px;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }
`;

export const EditButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  padding: 10px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
color: black;
  &:hover {
    background-color: #f1f1f1;
    border-color: #ccc;
  }
`;

export const SaveButton = styled.button`
  background-color: ${(props) => (props.isSaveDisabled ? 'gray' : '#00008B')};
  border: 1px solid #eaeaea;
  padding: 10px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
color: white;
  &:hover {
    background-color: ${(props) => (props.isSaveDisabled ? 'gray' : '#123499')};
    border-color: #ccc;
  }
`;

export const Subsection = styled.div`
  margin-left: 40px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;


export const FormContainer = styled.div`
background-color: #F5F5F5;
`;

export const FormContent = styled.div`
margin-top: 10px;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
`
export const Action = styled.div`
  border-radius: 5px;
  background-color: #00008B;
  width: 10%;
  display: flex;
  justify-content: center;
  height: 35px;
  margin-right: 10px;
  cursor: pointer;
`