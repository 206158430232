import React, { useState } from 'react'
import { CallToAction, EditButton, Image, SaveButton, SectionContent, SectionHeader, SectionWrapper, SubTitle, Title } from '../HomePage-elements';
import Kapal from '../../../../assets/image/kapal.svg'
import { Form, Formik } from 'formik';
import { FormControl } from '../../../../components';
import { getStyle } from '../../../../utils/helpers/errorMessage';
import API from '../../../../config/api';
import swal from 'sweetalert';
const token = localStorage.getItem('token');
const HeroSection = (props) => {
    const { item, edit, initialValuesHero, schemaValidationHero, setLoading, getDataMainSection } = props;
    const [isHero, setIsHero] = useState(false);
    const [isEditHero, setIsEditHero] = useState(false);
    const [message, setMessage] = useState('');
    const handleSubmit = (values, { resetForm, setSubmitting }) => {
        setLoading(true)

        // data = JSON.stringify(data)
        const data = {
            title: values.title,
            subtitle: values.subtitle,
            button_text: values.button_text,
            link: values.link,
            image: values.image,
        }
        console.log(data)



        let formData = new FormData();


        formData.append('title', data.title);
        formData.append('name_section', "Hero Section");
        formData.append('call_to_action', 1);
        formData.append('page_type', 'Home');
        formData.append('subtitle', data.subtitle);
        formData.append('button_text', data.button_text);
        formData.append('link', data.link);
        formData.append('image', values.image);
        API.EditHomePageMainSection(token, formData, initialValuesHero.id)
            .then(res => {
                setMessage('Data Dasar Berhasil Ditambahkan');
                swal({
                    title: 'Berhasil',
                    text: `Hero Section Berhasil Diubah`,
                    icon: "success",
                });
                setIsEditHero(false)
                setSubmitting(false);
                getDataMainSection()
                setLoading(false)
            })
            .catch(err => {
                console.log(err.message);
                setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                swal({
                    title: err.response?.status || 'Error',
                    text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                    icon: "error",
                });
                setIsEditHero(false)
                setLoading(false)
            });
        // if (isAddOrEdit == 'edit') {
        //     // console.log('edit');

        //     let formData = new FormData();
        //     formData.append('_method', 'PUT');
        //     formData.append('name', data.name);
        //     formData.append('short_name', data.short_name);
        //     formData.append('description', data.description);
        //     formData.append('email', data.email);
        //     formData.append('website', data.website);
        //     formData.append('telp', data.telp);
        //     formData.append('business_fields', data.business_fields);
        //     formData.append('address', data.address);
        //     formData.append('logo', data.logo);
        //     // console.log(formData);
        //     axios
        //         .post(url, formData, {
        //             headers: {
        //                 'Content-Type': 'multipart/form-data',
        //                 Authorization: `Bearer ${token}`,
        //             },
        //         })
        //         .then(res => {
        //             console.log(res)
        //             // console.log(res.data.message);
        //             swal({
        //                 title: res.data.status,
        //                 text: res.data.message,
        //                 icon: "success",
        //             });
        //             window.location.reload();

        //         })
        //         .catch(err => {
        //             // console.log(err.response);
        //             console.log(err.response);
        //             swal({
        //                 title: err.status,
        //                 // text: err.response.data.message,
        //                 icon: "error",
        //             });
        //         });
        // } else {
        //     // console.log('add')
        //     let formData = new FormData();


        //     formData.append('name', data.name);
        //     formData.append('short_name', data.short_name);
        //     formData.append('description', data.description);
        //     formData.append('email', data.email);
        //     formData.append('website', data.website);
        //     formData.append('telp', data.telp);
        //     formData.append('business_fields', data.business_fields);
        //     formData.append('address', data.address);
        //     formData.append('logo', data.logo);
        //     axios
        //         .post(url, formData, {
        //             headers: {
        //                 'Content-Type': 'multipart/form-data',
        //                 Authorization: `Bearer ${token}`,
        //             },
        //         })
        //         .then(res => {
        //             // console.log(res)
        //             // console.log(res.data.message);
        //             swal({
        //                 title: res.data.status,
        //                 text: res.data.message,
        //                 icon: "success",
        //             });
        //             window.location.reload();

        //         })
        //         .catch(err => {
        //             // console.log(err.response);
        //             console.log(err.response);
        //             swal({
        //                 title: err.status,
        //                 // text: err.response.data.message,
        //                 icon: "error",
        //             });
        //         });
        // }

        // // resetForm();
        // setSubmitting(false);
        // reloadPage();
        // console.log('simpan pengaturan informasi perusahaan')

    };

    return (
        <SectionWrapper>
            <Formik
                enableReinitialize
                initialValues={initialValuesHero}
                onSubmit={(values, props) => handleSubmit(values, props)}
                validationSchema={() => schemaValidationHero}
            >
                {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting
                }) => (
                    <Form >
                        <SectionHeader onClick={() => setIsHero(!isHero)}>
                            Hero
                            {
                                isEditHero ?
                                    <div>
                                        <EditButton style={{ marginRight: "10px" }}
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevents the section from toggling when clicking the button
                                                setIsEditHero(false);
                                            }}
                                        >
                                            Cancel
                                        </EditButton>
                                        <SaveButton
                                            onClick={(e) => {
                                                e.stopPropagation(); // Prevents the section from toggling when clicking the button
                                                setIsEditHero(true);
                                            }}
                                            type='submit'
                                            disabled={isSubmitting}>
                                            Save
                                        </SaveButton>
                                    </div>

                                    : <SaveButton
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevents the section from toggling when clicking the button
                                            setIsEditHero(true);
                                            setIsHero(true);
                                            edit(item, 'Hero');
                                        }}
                                    >
                                        Edit
                                    </SaveButton>
                            }
                        </SectionHeader>
                        <SectionContent isOpen={isHero}>
                            {
                                isEditHero ?

                                    <div className="left-form">

                                        <FormControl control="file" label="image" name="image" fileURL={initialValuesHero.image || Kapal}
                                            style={getStyle(errors, touched, 'image')}
                                        />
                                        <FormControl control="input" type="text" label="Title" name="title"
                                            style={getStyle(errors, touched, 'title')}
                                        />
                                        <FormControl control="input" type="text" label="subtitle" name="subtitle"
                                            style={getStyle(errors, touched, 'subtitle')}
                                        />
                                        <FormControl control="input" type="text" label="button_text" name="button_text"
                                            style={getStyle(errors, touched, 'button_text')}
                                        />
                                        <FormControl control="input" type="text" label="link" name="link"
                                            style={getStyle(errors, touched, 'link')}
                                        />


                                    </div>
                                    :
                                    <>
                                        <Title>Image</Title>
                                        <Image src={item?.image || Kapal} alt="Hero Image" />
                                        <Title>Title</Title>
                                        <SubTitle>{item?.title}</SubTitle>
                                        <Title>SubTitle</Title>
                                        <SubTitle>{item?.subtitle}</SubTitle>
                                        <Title>Call To Action</Title>
                                        <CallToAction href={item?.link}>{item?.button_text} – {item?.link}</CallToAction>
                                    </>
                            }
                        </SectionContent>
                    </Form>
                )}
            </Formik>
        </SectionWrapper >
    )
}

export default HeroSection
