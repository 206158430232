import React from 'react'
import { useHistory } from 'react-router-dom';
import { hmsToSeconds, secondsToHMS } from '../../../utils/helpers/date';
import { AttendanceRecordButton } from './attendance.elements';




const AttendanceButton = (current_time, data, absenMasuk, absenPulang, openCamera) => {
    // console.log(schedule)
    // console.log(attendance)
    // console.log(current_time)
    const history = useHistory();

    const renderButton = () => {
        // console.log(schedule)
        function z(n) {
            return (n < 10 ? '0' : '') + n;
          }
          let minimal_jam_kerja = 60 * 60 * 8; // 8 jam
          // console.log('minimal_jam_kerja default', minimal_jam_kerja);
          let time = hmsToSeconds(current_time);
          const {schedule, attendance, yesterday_attendance} = data;
          // console.log('schedule', schedule);
          // console.log('attendance', attendance);
          // console.log('yesterday_attendance', yesterday_attendance);
          if (!schedule) {
            //jika schedule null/tidak ada jadwal
            if (!yesterday_attendance) {
              //tidak ada data absen kemarin
              return <AttendanceRecordButton onClick={() => history.push('/schedule')}>Lihat Jadwal</AttendanceRecordButton>
            } else {
              //ada absen hari kemarin
              if (!yesterday_attendance.time_out) {
                //absen kemarin belum absen pulang
                // return 'belum absen pulang';
                let jam_masuk = yesterday_attendance.time_in_schedule
                  ? hmsToSeconds(yesterday_attendance.time_in_schedule)
                  : null;
                let absen_masuk = hmsToSeconds(yesterday_attendance.time_in);
                let jam_pulang = hmsToSeconds(yesterday_attendance.time_out_schedule);
                let absen_pulang = yesterday_attendance.time_out
                  ? hmsToSeconds(yesterday_attendance.time_out)
                  : time;
                let duration =
                    jam_pulang > jam_masuk
                      ? absen_pulang - absen_masuk
                      : 60 * 60 * 24 - absen_masuk + absen_pulang;
                minimal_jam_kerja =
                    jam_pulang > jam_masuk
                      ? jam_pulang - jam_masuk
                      : 60 * 60 * 24 - jam_masuk + jam_pulang;
        
                //cek apakah jam sekarang sudah masuk jam pulang?
                if (time >= jam_pulang) {
                  // disini cek per case
                  // return jam_pulang;
                  // if (jam_masuk > jam_pulang) {
                  //   //jika jam masuk lebih dari jam pulang
                  //   return 'Terima kasih! Selamat bekerja.';
                  // } else {
                    return <AttendanceRecordButton onClick={() => absenPulang()}>Absen Pulang</AttendanceRecordButton>

                  // }
                } else {
                  // belum masuk jam pulang
                  return <AttendanceRecordButton>{secondsToHMS(duration)}</AttendanceRecordButton>;
                }
              } else {
                return <AttendanceRecordButton onClick={() => history.push('/schedule')}>Lihat Jadwal</AttendanceRecordButton>
            }
            }
          } else {
            // return 'ada jadwal';
        
            if (!attendance) {
              // return 'belum absen hari ini';
              //cek absen kemarin
              if (!yesterday_attendance) {
                //tidak ada absen kemarin
                // jadwal hari ini
                //cek apakah jam sekarang sudah dekat jam masuk?
                // let jam_masuk = hmsToSeconds(schedule.time_in);
                // let satu_jam = 60 * 60;
                // if (time >= jam_masuk || time >= jam_masuk - satu_jam) {
                //   return 'Sudah masuk jam kerja, segera lakukan presensi kehadiran.';
                // } else {
                //   return 'Kamu punya jadwal kerja hari ini, jangan lupa melakukan presensiii.';
                // }
                let jam_masuk = hmsToSeconds(schedule.time_in);
                let jam_pulang = hmsToSeconds(schedule.time_out);
        
                //cek apakah jam sekarang sudah dekat jam masuk?
                //waktu sebelum jam kerja berapa
                let waktuSisa = time - jam_masuk;
                let secs = Math.abs(waktuSisa);
                let jam = z((secs / 3600) | 0);
                jam = jam > 0 ? jam + ' jam ' : '';
                let menit = z(((secs % 3600) / 60) | 0);
                menit = menit > 0 ? menit + ' menit ' : '';
                let detik = z(secs % 60) + ' detik ';
        
                if (waktuSisa < 0) {
                  // return 'belum masuk jam kerja';
                  //jika waktu sisa lebih dari 1 jam
                  if (secs > 60 * 60) {
                    return <AttendanceRecordButton onClick={() => history.push('/schedule')}>Lihat Jadwal</AttendanceRecordButton>

                  } else {
                    // return 'waktu sisa kurang dari 1 jam';
                    return <AttendanceRecordButton onClick={() => openCamera("Absen Masuk")}>Absen Masuk</AttendanceRecordButton>

                  }
                } else {
                  let sisa_toleransi = schedule.late_tolerance * 60 - secs;
                  let jamTelat = z((Math.abs(sisa_toleransi) / 3600) | 0);
                  let menitTelat = z(((Math.abs(sisa_toleransi) % 3600) / 60) | 0);
                  jamTelat = jamTelat > 0 ? jamTelat + ' jam ' : '';
                  menitTelat = menitTelat > 0 ? menitTelat + ' menit.' : '';
                  let jumlahTelat = jamTelat + menitTelat;
                  // return sisa_toleransi;
                  if (sisa_toleransi < 0) {
                    // return 'Toleransi habis';
                    if(time <= (jam_pulang - (60 * 60))){
                      // console.log('belum masuk jam pulang')
                        return <AttendanceRecordButton className="waktu-kerja-dan-toleransi-lewat" onClick={() => openCamera("Absen Masuk")}>Absen Masuk</AttendanceRecordButton>
                          
                          
                      }else {
                        return <AttendanceRecordButton onClick={() => history.push('/schedule')}>Lihat Jadwal</AttendanceRecordButton>      
      
                      }
                  } else {
                    // return 'Masih ada toleransi';
                    return <AttendanceRecordButton onClick={() => openCamera("Absen Masuk")}>Absen Masuk</AttendanceRecordButton>

                  }
                }
              } else {
                //ada absen kemarin
                let jam_masuk = hmsToSeconds(yesterday_attendance.time_in_schedule);
                let absen_masuk = hmsToSeconds(yesterday_attendance.time_in);
                let jam_pulang = hmsToSeconds(yesterday_attendance.time_out_schedule);
                let absen_pulang = yesterday_attendance.time_out
                  ? hmsToSeconds(yesterday_attendance.time_out)
                  : time;
                let duration =
                    jam_pulang > jam_masuk
                        ? absen_pulang - absen_masuk
                        : 60 * 60 * 24 - absen_masuk + absen_pulang;
                minimal_jam_kerja =
                    jam_pulang > jam_masuk
                        ? jam_pulang - jam_masuk
                        : 60 * 60 * 24 - jam_masuk + jam_pulang;
                if (!yesterday_attendance.time_out) {
                  //durasi kerja, tombol absen keluar bila masuk jam keluar
                  //absen kemarin belum absen pulang
                  // return 'belum absen pulang';
        
                  //cek apakah jam sekarang sudah masuk jam pulang?
                  if (time >= jam_pulang) {
                    // disini cek per case
                    // return jam_pulang;
                    return <AttendanceRecordButton onClick={() => absenPulang()}>Absen Pulang</AttendanceRecordButton>

                  } else {
                    // belum masuk jam pulang        
                    return <AttendanceRecordButton>{secondsToHMS(duration)}</AttendanceRecordButton>;

                  }
                } else {
                  //absen kemarin sudah absen keluar
                  // jadwal hari ini
                  jam_masuk = hmsToSeconds(schedule.time_in);
        
                  //cek apakah jam sekarang sudah dekat jam masuk?
                  //waktu sebelum jam kerja berapa
                  let waktuSisa = time - jam_masuk;
                  let secs = Math.abs(waktuSisa);
                  let jam = z((secs / 3600) | 0);
                  jam = jam > 0 ? jam + ' jam ' : '';
                  let menit = z(((secs % 3600) / 60) | 0);
                  menit = menit > 0 ? menit + ' menit ' : '';
                  let detik = z(secs % 60) + ' detik ';
        
                  if (waktuSisa < 0) {
                    // return 'belum masuk jam kerja';
                    //jika waktu sisa lebih dari 1 jam
                    if (secs > 60 * 60) {
                        return <AttendanceRecordButton onClick={() => history.push('/schedule')}>Lihat Jadwal</AttendanceRecordButton>

                    } else {
                      // return 'waktu sisa kurang dari 1 jam';
                      return <AttendanceRecordButton onClick={() => openCamera("Absen Masuk")}>Absen Masuk</AttendanceRecordButton>

                    }
                  } else {
                    let sisa_toleransi = schedule.late_tolerance * 60 - secs;
                    let jamTelat = z((Math.abs(sisa_toleransi) / 3600) | 0);
                    let menitTelat = z(((Math.abs(sisa_toleransi) % 3600) / 60) | 0);
                    jamTelat = jamTelat > 0 ? jamTelat + ' jam ' : '';
                    menitTelat = menitTelat > 0 ? menitTelat + ' menit.' : '';
                    let jumlahTelat = jamTelat + menitTelat;
                    // return sisa_toleransi;
                    if (sisa_toleransi < 0) {
                      // return 'Toleransi habis';
                      if(time <= (jam_pulang - (60 * 60))){
                          // console.log('belum masuk jam pulang')
                          return <AttendanceRecordButton className="waktu-kerja-dan-toleransi-lewat" onClick={() => openCamera("Absen Masuk")}>Absen Masuk</AttendanceRecordButton>
                              
                          }else {
                            return <AttendanceRecordButton onClick={() => history.push('/schedule')}>Lihat Jadwal</AttendanceRecordButton>     
          
                          }
                    
                    } else {
                      // return 'Masih ada toleransi';
                      return <AttendanceRecordButton onClick={() => openCamera("Absen Masuk")}>Absen Masuk</AttendanceRecordButton>

                    }
                  }
                }
              }
            } else {
              // return 'sudah absen';
              if (!attendance.time_out) {
                // return 'belum absen pulang';
        
                let absen_masuk = hmsToSeconds(attendance.time_in);
                let jam_masuk = hmsToSeconds(attendance.time_in_schedule);
                let jam_pulang = hmsToSeconds(attendance.time_out_schedule);
                let absen_pulang = attendance.time_out
                  ? hmsToSeconds(attendance.time_out)
                  : time;
                let duration =
                    jam_pulang > jam_masuk
                        ? absen_pulang - absen_masuk
                        : 60 * 60 * 24 - absen_masuk + absen_pulang;
                minimal_jam_kerja =
                    jam_pulang > jam_masuk
                        ? jam_pulang - jam_masuk
                        : 60 * 60 * 24 - jam_masuk + jam_pulang;
                if (time >= jam_pulang) {
                  //jika waktu sekarang sudah lebih dari jam pulang
                  // disini cek per case
                  // return jam_pulang;
      
                  if (jam_masuk > jam_pulang) {
                    //jika jam masuk lebih dari jam pulang
                    return <AttendanceRecordButton>{secondsToHMS(duration)}</AttendanceRecordButton>;

                  } else {
                    return <AttendanceRecordButton onClick={() => absenPulang()}>Absen Pulang</AttendanceRecordButton>

                  }
                } else {
                  // belum masuk jam pulang
                  let duration =
                    jam_pulang > jam_masuk
                      ? absen_pulang - absen_masuk
                      : 60 * 60 * 24 - absen_masuk + absen_pulang;
                  minimal_jam_kerja =
                    jam_pulang > jam_masuk
                      ? jam_pulang - jam_masuk
                      : 60 * 60 * 24 - jam_masuk + jam_pulang;
                  // console.log(
                  //   'minimal_jam_kerja sesuai jadwal',
                  //   secondsToHMS(minimal_jam_kerja),
                  // );
                  // console.log('duration', secondsToHMS(duration));
                  if (duration >= minimal_jam_kerja) {
                    // console.log('sudah lebih dari jam kerja');
                    return <AttendanceRecordButton onClick={() => absenPulang()}>Absen Pulang</AttendanceRecordButton>

                  } else {
                    // console.log('belum memenuhi minimal jam kerja');
                    return <AttendanceRecordButton>{secondsToHMS(duration)}</AttendanceRecordButton>;

                  }
                }
              } else {
                // return 'sudah absen pulang';
                let absen_masuk = hmsToSeconds(attendance.time_in);
                let jam_masuk = hmsToSeconds(attendance.time_in_schedule);
                let jam_pulang = hmsToSeconds(attendance.time_out_schedule);
                let absen_pulang = attendance.time_out
                  ? hmsToSeconds(attendance.time_out)
                  : time;
                let duration =
                    jam_pulang > jam_masuk
                        ? absen_pulang - absen_masuk
                        : 60 * 60 * 24 - absen_masuk + absen_pulang;
              
                return <AttendanceRecordButton>{secondsToHMS(duration)}</AttendanceRecordButton>;
                // return 'Terima kasih! selamat istirahat';
              }
            }
          }

        // // jika ada lembur
        // if(attendance == null){ //jika belum absen
        //     // console.log('belum absen')
        //     schedule.attTimeOut = (schedule.attTimeOut == '00:00:00') ? '24:00:00' : schedule.attTimeOut;

           
        //     let waktuSisa = hmsToSeconds(current_time) - hmsToSeconds(schedule.attTimeIn);
        //     // console.log(waktuSisa)
        //     if(waktuSisa < 0){
        //         // console.log('belum masuk jam kerja')
        //         let secs = Math.abs(waktuSisa)
        //         //jika waktu sisa lebih dari 5 menit
        //         if(secs > (60 * 5)){
        //             // console.log('waktu sisa lebih dari 5 menit')
        //             return <AttendanceRecordButton onClick={() => history.push('/schedule')}>Lihat Jadwal</AttendanceRecordButton>

        //         }else {
        //             // console.log('waktu sisa kurang dari 5 menit')
        //             // return <AttendanceRecordButton onClick={() => absenMasuk()}>Absen Masuk</AttendanceRecordButton>
        //             return <AttendanceRecordButton onClick={() => openCamera("Absen Masuk")}>Absen Masuk</AttendanceRecordButton>
        //             // return <button onClcik={() => API.addAttendance()}>Absen Masuk</button>



        //         }

        //     }else { //apabila masuk jam kerja
        //         // console.log('sudah masuk jam kerja')
                
        //         //pemberitahuan jam kerja sudah lewat, tapi masih ada late tolerance
        //         if(hmsToSeconds(current_time) <= (hmsToSeconds(schedule.attTimeIn) + (schedule.attLateTolerance * 60))){
        //             // console.log('jam kerja sudah lewat tapi masih ada toleransi telat')
                    
        //             // return <AttendanceRecordButton onClick={() => absenMasuk()} className="waktu-kerja-lewat">Absen Masuk</AttendanceRecordButton>
        //             return <AttendanceRecordButton onClick={() => openCamera("Absen Masuk")} className="waktu-kerja-lewat">Absen Masuk</AttendanceRecordButton>
    
        //         }else { //apabila jam kerja sudah lewat & toleransi telat sudah habis
        //             //cek dulu apakah sudah masuk jam pulang
        //             if(hmsToSeconds(current_time) <= (hmsToSeconds(schedule.attTimeOut) - (60 * 60))){
        //                 // console.log('belum masuk jam pulang')
        //                 // return <AttendanceRecordButton className="waktu-kerja-dan-toleransi-lewat" onClick={() => absenMasuk()}>Absen Masuk</AttendanceRecordButton>
        //                 return <AttendanceRecordButton className="waktu-kerja-dan-toleransi-lewat" onClick={() => openCamera("Absen Masuk")}>Absen Masuk</AttendanceRecordButton>

        //             }else {
        //                 // console.log('sudah masuk jam pulang')
        //                 return <AttendanceRecordButton onClick={() => history.push('/schedule')}>Riwayat Kehadiran</AttendanceRecordButton>


        //             }
    
        //         }
        //     }


        // }else { //jika sudah absen
        //     if(attendance.time_out !== null) {
        //         // console.log('total durasi kerja');
        //         return <AttendanceRecordButton>{`${secondsToHMS(hmsToSeconds(attendance.time_out_schedule) - (hmsToSeconds(attendance.time_in)) - (attendance.break_duration * 60))}`}</AttendanceRecordButton>
        //     }else {
        //         // console.log('sudah absen, tampil durasi kerja');
    
        //         if(hmsToSeconds(current_time) < (hmsToSeconds(attendance.time_break_start))){ 
        //             // console.log('belum masuk istirahat')
        //             return <AttendanceRecordButton>{`${secondsToHMS(hmsToSeconds(current_time) - hmsToSeconds(attendance.time_in))}`}</AttendanceRecordButton>
    
        //         }else { //'masuk jam istirahat'
        //             // console.log('jam istirahat')
        //             if(hmsToSeconds(current_time) >= (hmsToSeconds(attendance.time_break_start) + (attendance.break_duration * 60))) { // lewat jam istirahat
        //                 // console.log('jam istirahat lewat')
        //                 if(hmsToSeconds(current_time) >= (hmsToSeconds(attendance.time_out_schedule))){ // apabila masuk jam pulang 
        //                     // console.log('masuk jam pulang, button pulang')
        //                     return <AttendanceRecordButton onClick={() => absenPulang()}>Absen Pulang</AttendanceRecordButton>
        //                 }else {
        //                     // console.log('durasi kerja setelah jam istirahat')
        //                     if(hmsToSeconds(attendance.time_in) > (hmsToSeconds(attendance.time_break_start) + (attendance.break_duration * 60))){ //jika absen di atas jam istirahat
                                
        //                         return  <AttendanceRecordButton>{`${secondsToHMS(hmsToSeconds(current_time) - (hmsToSeconds(attendance.time_in)))}`}</AttendanceRecordButton>;
        //                     }else {
        //                         return  <AttendanceRecordButton>{`${secondsToHMS(hmsToSeconds(current_time) - (hmsToSeconds(attendance.time_in)) - (attendance.break_duration * 60))}`}</AttendanceRecordButton>;

        //                     }
    
    
    
        //                 }
    
        //             }else { //apabila belum lewat jam istirahat
        //                 // console.log('masih jam istirahat')
        //                 if(hmsToSeconds(attendance.time_break_start) - hmsToSeconds(attendance.time_in) < 0){
        //                     // console.log('minus')
        //                     return <AttendanceRecordButton>00:00:00</AttendanceRecordButton>
    
        //                 }else {
        //                     // console.log('istirahat')
        //                     // berapa jumlah durasi kerja dari awal masuk sebelum jam istirahat
                        
        //                     return <AttendanceRecordButton>{secondsToHMS(hmsToSeconds(attendance.time_break_start) - hmsToSeconds(attendance.time_in))}</AttendanceRecordButton>
        //                     // return <AttendanceRecordButton>{`${secondsToHMS(hmsToSeconds(current_time) - (hmsToSeconds(attendance.time_in)))}`}</AttendanceRecordButton>
    
        //                 }
                        
        //             }
        //         }

        //     }

        // }
    }

    return (
        <>
            {renderButton()}
        </>
    )
}

export default AttendanceButton
