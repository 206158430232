import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { iconAdd, iconLeft, iconUser } from '../../../../assets';
import { Gap, PageHeader, Row, Col, Icon, Table,  FilterYear, FilterMonth, ReportHeader, ReportFooter, Letter } from '../../../../components'
import API from '../../../../config/api';
import Modal from 'react-modal';
import swal from 'sweetalert';
import { useDebounce } from '../../../../utils/helpers/useDebounce';
import LoanForm from './LoanForm';
import NumberFormat from 'react-number-format';
import InstallmentForm from './InstallmentForm';
import { YMdToFormatIndo } from '../../../../utils/helpers/date';


Modal.setAppElement('#root');

const EmployeeLoan = (props) => {

    const token = props.user.token;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const perPageList = [
        {label: "5", value: 5},
        {label: "10", value: 10},
        {label: "25", value: 25},
        {label: "50", value: 50},
    ];
    const [totalTableData, setTotalTableData] = useState(0);
    const [position, setPosition] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isAddOrEdit, setIsAddOrEdit] = useState('');

    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [initialValues, setInitialValues] = useState({});

    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [employeeLoanOptions, setEmployeeLoanOptions] = useState([]);
    const [divisions, setDivisions] = useState([]);

    const year = (new Date()).getFullYear();
    const startYear = (new Date()).getFullYear() - 3;
    const [selectedYear, setSelectedYear] = useState(year);
    const [selectedDivision, setSelectedDivision] = useState(0);
    const [divisionName, setDivisionName] = useState('');
    const [pageName, setPageName] = useState("Laporan Pengajuan Pinjaman Disetujui & Tidak Disetujui");
    const [month, setMonth] = useState(new Date().getMonth())
    const [selectedLoan, setSelectedLoan] = useState({id: 0});
    const allStatus = [
        {id: 0, name: 'Menunggu'},
        {id: 1, name: 'Disetujui'},
        {id: 2, name: 'Ditolak'},
        {id: 5, name: 'Berjalan'}
    ];
    const [status, setStatus] = useState(3);
    const [requestOrInstallment, setRequestOrInstallment] = useState('request');
    const debouncedSearchTerm = useDebounce(searchTerm, 800);

    useEffect(() => {
        //get all employee
        API.getAllEmployee(token).then(res => {
            // setEmployeeOptions(res.data)
            // console.log(res.data)
            let eOptions = [];
            res.data.map(e => {
                eOptions.push({
                    value: e.id, label: e.name
                });
            });
            setEmployeeOptions(eOptions);

        }).catch(err => {
            console.log(err.response.data.message)
        })

        //get all division
        API.getAllDivision(token).then(res => {
            // console.log('divisi: ', res.data)
            setDivisions(res.data)
        }).catch(err => {
            console.log(err.response.data.message)
        })
    }, [])

    useEffect(() => {
        // setCurrentPage(currentPage);
        setTotalPage(0);
        let mnth = parseInt(month) + 1;
        if(requestOrInstallment === 'request'){
            setTableColumns(employeeLoanColumns);

            API.getEmployeeLoan(token, currentPage, perPage, searchTerm, selectedYear, selectedDivision, status, mnth).then((res) => {
                // console.log(res.data);
                setTableData(res.data.data)
                setTotalPage(res.data.last_page);
                setTotalTableData(res.data.total);
                setPosition((currentPage - 1) * perPage)
                setMessage('success get data loan');
                // setLoading(false);
            }).catch(err => {
                // console.log(err.response.data.message);
                // console.log(err);
                setTableData(0);
                setMessage(err.response.data.message || 'Pinjaman Tidak Ditemukan');
                
                // setLoading(false);
            })
        }else {
            //laporan angsuran pinjaman
            setTableColumns(employeeLoanInstallmentColumns);

            //consume api getEmployeeLoanInstallment
            API.getEmployeeLoanInstallment(token, currentPage, perPage, searchTerm, selectedYear, selectedDivision, mnth).then((res) => {
                // console.log(res.data);
                setTableData(res.data.data)
                setTotalPage(res.data.last_page);
                setTotalTableData(res.data.total);
                setPosition((currentPage - 1) * perPage)
                setMessage('success get data loan paying');
                // setLoading(false);
            }).catch(err => {
                console.log(err.response.data.message);
                // console.log(err);
                setTableData(0);
                setMessage(err.response.data.message || 'Pembayaran Pinjaman Tidak Ditemukan');
                
                // setLoading(false);
            })
        }



    }, [debouncedSearchTerm, currentPage, perPage, searchTerm, modalIsOpen, selectedYear, selectedDivision, month, status, requestOrInstallment])
    
    useEffect(() => {
        if(requestOrInstallment === 'installments'){
            setPageName(`Laporan Angsuran Pinjaman ${selectedDivision === 0 ? 'Semua' : ''} Karyawan ${selectedDivision === 0 ? '' : divisionName}`)

            //get all pembayaran pinjmaman
            API.getAllEmployeeLoans(token).then(res => {
                // console.log('pinjaman karyawan: ', res.data)
                let lOptions = [];
                res.data.map(e => {
                    lOptions.push({
                        value: e.id, label: `${e.employee_name} - Sisa ${e.remaining}x Angsuran`
                    });
                });
                setEmployeeLoanOptions(lOptions)
            }).catch(err => {
                setEmployeeLoanOptions([])
                console.log(err.response.data.message)
            })
        }else {
            if(status == 1){
                setPageName(`Laporan Pengajuan Pinjaman Disetujui ${selectedDivision === 0 ? 'Semua' : ''} Karyawan ${selectedDivision === 0 ? '' : divisionName}`)
            }else if(status == 3){
                setPageName(`Laporan Pengajuan Pinjaman Disetujui & Tidak Disetujui ${selectedDivision === 0 ? 'Semua' : ''} Karyawan ${selectedDivision === 0 ? '' : divisionName}`)
    
            }else if(status == 5){
                setPageName(`Laporan Pinjaman Sedang Berjalan ${selectedDivision === 0 ? 'Semua' : ''} Karyawan ${selectedDivision === 0 ? '' : divisionName}`)
    
            }else {
                setPageName(`Laporan Pengajuan Pinjaman Tidak Disetujui ${selectedDivision === 0 ? 'Semua' : ''} Karyawan ${selectedDivision === 0 ? '' : divisionName}`)
                
            }
        }
        
    }, [status, divisionName, requestOrInstallment, isAddOrEdit])
    const handleChangeDivision = (division) => {
        // console.log(division)
        setSelectedDivision(division.id)
        setDivisionName(division.name)
        // setGroup(division)
    }

    const handleAdd = () => {
        setIsAddOrEdit('add')
        if(requestOrInstallment === 'request'){
            setInitialValues({
                no: '',
                employee_id: [],
                amount: '',
                reason: '',
                installments: '',
                
            })
        }else {
            //get all pembayaran pinjmaman
            API.getAllEmployeeLoans(token).then(res => {
                // console.log('pinjaman karyawan: ', res.data)
                let lOptions = [];
                res.data.map(e => {
                    lOptions.push({
                        value: e.id, label: `${e.employee_name} - Sisa ${e.remaining}x Angsuran`
                    });
                });
                setEmployeeLoanOptions(lOptions)
            }).catch(err => {
                setEmployeeLoanOptions([])
                console.log(err.response.data.message)
            })
            setInitialValues({
                loan_id: [],
            })
        }
        
        setModalIsOpen(true)
        
       
    }

    const handleDetail = (row) => {
        //set modal detail open
        console.log('detail', row)
    }

    const handleDelete = (row) => {
        // console.log(row)
        let mnth = parseInt(month) + 1;

        if(requestOrInstallment === 'request'){
            swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this loan data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                    // console.log('Call API delete Departemen');
                    // console.log(currentPage)
                    // console.log(tableData)
                    // console.log(totalPage)
                    if(currentPage === totalPage){ //jika di halaman terakhir
                        // console.log('halaman terakhir')
    
                        return API.deleteLoanEmployee(token, row.id).then(res => {
                            swal({
                                title: 'Berhasil',
                                text: 'Pinjaman Berhasil Dihapus',
                                icon: "success",
                              });
                            // console.log('call branch Data lagi');
                            
                            API.getEmployeeLoan(token, currentPage, perPage, searchTerm, selectedYear, selectedDivision, status, mnth).then((res) => {
                                // console.log(res.data.data);
                                
                                setTableData(res.data.data)
                                setTotalPage(res.data.last_page);
                                setTotalTableData(res.data.total);
                                setPosition((currentPage - 1) * perPage)
                                // console.log('tetap di halaman')
                                setMessage('success get data loans');
                            
                                // setLoading(false);
                            }).catch(err => {
                                // console.log(err.response.data.message);
                                // console.log(err);
                                setCurrentPage(currentPage - 1)
                                // setTableData(0);
                                setMessage(err.response.data.message || 'Pinjaman Tidak Ditemukan');
                                
                                // setLoading(false);
                            })
                            
                            
                        }).catch(err => {
                            console.log(err);
                            // setLoading(false);
                        })
                        
                    }else {
                        // console.log('bukan halaman terakhir')
                        return API.deleteLoanEmployee(token, row.id).then(res => {
                            swal({
                                title: 'Berhasil',
                                text: 'Pinjaman Berhasil Dihapus',
                                icon: "success",
                              });
                            // console.log('call branch Data lagi');
                            
                                API.getEmployeeLoan(token, currentPage, perPage, searchTerm, selectedYear, selectedDivision, status, mnth).then((res) => {
                                    // console.log(res.data.data);
                                    setTableData(res.data.data)
                                    setTotalPage(res.data.last_page);
                                    setTotalTableData(res.data.total);
                                    setPosition((currentPage - 1) * perPage)
                                    setMessage('success get data loans');
                                    // setLoading(false);
                                }).catch(err => {
                                    // console.log(err.response.data.message);
                                    // console.log(err);
                                    setTableData(0);
                                    setMessage(err.response.data.message || 'Pinjaman Tidak Ditemukan');
                                    
                                    // setLoading(false);
                                })
                            
                            
                        }).catch(err => {
                            console.log(err);
                            // setLoading(false);
                        })
                    }
                    
                }
                else {
                //   swal("Your imaginary file is safe!");
                //   setLoading(false);
    
                }
              });
        }else {
            swal({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this loan paying data!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((willDelete) => {
                if (willDelete) {
                    // console.log('Call API delete Departemen');
                    // console.log(currentPage)
                    // console.log(tableData)
                    // console.log(totalPage)
                    if(currentPage === totalPage){ //jika di halaman terakhir
                        // console.log('halaman terakhir')
    
                        return API.deleteEmployeeLoanInstallment(token, row.id).then(res => {
                            swal({
                                title: 'Berhasil',
                                text: 'Pembayaran Pinjaman Berhasil Dihapus',
                                icon: "success",
                              });
                            // console.log('call branch Data lagi');
                            
                                //consume api getEmployeeLoanInstallment
                                API.getEmployeeLoanInstallment(token, currentPage, perPage, searchTerm, selectedYear, selectedDivision, mnth).then((res) => {
                                    // console.log(res.data);
                                    setTableData(res.data.data)
                                    setTotalPage(res.data.last_page);
                                    setTotalTableData(res.data.total);
                                    setPosition((currentPage - 1) * perPage)
                                    setMessage('success get data loan paying');
                                    // setLoading(false);
                                }).catch(err => {
                                    console.log(err.response.data.message);
                                    // console.log(err);
                                    setTableData(0);
                                    setMessage(err.response.data.message || 'Pembayaran Pinjaman Tidak Ditemukan');
                                    
                                    // setLoading(false);
                                })
                            
                            
                        }).catch(err => {
                            console.log(err);
                            // setLoading(false);
                        })
                        
                    }else {
                        // console.log('bukan halaman terakhir')
                        return API.deleteEmployeeLoanInstallment(token, row.id).then(res => {
                            swal({
                                title: 'Berhasil',
                                text: 'Pinjaman Berhasil Dihapus',
                                icon: "success",
                              });
                            // console.log('call branch Data lagi');
                            
                                API.getEmployeeLoanInstallment(token, currentPage, perPage, searchTerm, selectedYear, selectedDivision, mnth).then((res) => {
                                    // console.log(res.data.data);
                                    setTableData(res.data.data)
                                    setTotalPage(res.data.last_page);
                                    setTotalTableData(res.data.total);
                                    setPosition((currentPage - 1) * perPage)
                                    setMessage('success get data loans');
                                    // setLoading(false);
                                }).catch(err => {
                                    // console.log(err.response.data.message);
                                    // console.log(err);
                                    setTableData(0);
                                    setMessage(err.response.data.message || 'Pinjaman Tidak Ditemukan');
                                    
                                    // setLoading(false);
                                })
                            
                            
                        }).catch(err => {
                            console.log(err);
                            // setLoading(false);
                        })
                    }
                    
                }
                else {
                //   swal("Your imaginary file is safe!");
                //   setLoading(false);
    
                }
              });
        }
        
    }

    const handleEdit = (row) => {
        // console.log(row)
        setIsAddOrEdit('edit');
        if(requestOrInstallment === 'request'){
            setInitialValues({
                id: row.id,
                no: row.no,
                employee_id: {label: row.employee_name, value: row.employee_id},
                amount: row.amount,
                reason: row.reason,
                installments: row.installments
              })
        }else {
            setInitialValues({
                id: row.id,
                loan_id: {label: `${row.employee_name} - Sisa ${row.remaining} 3x Angsuran`, value: row.loan_id},
            })
        }
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }
  
    const prevButton = () => {
        setCurrentPage(Number(currentPage - 1));
    }
    const nextButton = () => {
        setCurrentPage(Number(currentPage + 1));        

    }
    const pageNumberClick = (e) => {
        // setCurrentPage(e => e.target.id)
        setCurrentPage(Number(e.target.id));
    }
    const changePerPage = (e) => {
        setPerPage(Number(e.target.value))
    }

    const handleChangeYearFilter = (e) => {
        setSelectedYear(e.target.value);
       
    }

    const handleMonthChange = e => {
        setMonth(e.target.value)
    }

    const printLoanLetter = (loan) => {
        setPageName('print-letter')
        if(loan.id === 0){
            setModalIsOpen(!modalIsOpen)
            // alert('print blankoooo')
        }
        setSelectedLoan(loan)
        
    }

    const handleChangeStatus = (status) => {
        setStatus(status.id)
    }


    const pageNumbers = [];
    
    let i = 0;
    do {
        i++;
        // console.log(`halaman sekarang,`, i);
        pageNumbers.push(i);
    } while(i < totalPage)

    const renderPageNumbers = pageNumbers.map(number => {
        return (
          <a
            key={number}
            id={number}
            onClick={pageNumberClick}
            className={currentPage == number ? 'active' : ''}
          >
            {number}
          </a>
        );
      });

      const employeeLoanColumns = [
        {Header: 'No',
            Cell: (row) => {
                // console.log(row)
                // console.log(row.cell.row.index)
                let startFrom = position + 1;
                return <div>{startFrom +row.cell.row.index}.</div>;
                console.log(row);
                // return <div>{row.cell.row.index+1}.</div>;
                
            }
        },
        {Header: 'Id', accessor: 'id', show: false},
        {Header: 'Alasan', accessor: 'reason', show: false},
        {Header: 'No. Pinjaman', accessor: 'no'},
        {Header: 'Nama', accessor: 'employee_name'},
        {Header: 'Departemen', accessor: 'employee_division'},
        {Header: 'Tim/Grup Kerja', accessor: 'group_name'},
        {Header: 'Jumlah', accessor: 'amount',
        Cell : row => <NumberFormat value={row.value} displayType={'text'} thousandSeparator={'.'} decimalSeparator={false}  prefix={'Rp. '} />},
        {Header: 'Angsuran', accessor: 'installments', Cell: row => {
            const installments = row.cell.row.original.installments;
            const amount = row.cell.row.original.amount;
            
            return <div>
                {installments}x <NumberFormat value={amount / installments} displayType={'text'} thousandSeparator={'.'} decimalSeparator={false}  prefix={''} />
            </div>
        
        }},
        {Header: 'Sisa Angsuran', accessor: 'remaining'},
        {Header: 'Tanggal Disetejui', accessor: 'approved_date'},
        {Header: 'Tanggal Lunas', accessor: 'paid_off_date'},
        {Header: 'Status', accessor: 'status', 
        Cell: (row) => {
            const status = row.cell.row.original.status;
            const remaining = row.cell.row.original.remaining;
            //jika nilainya 1, libur, jika 0, masuk
            return <div>{status === 1 ? (
                remaining === 0 ? (
                  'Lunas'
                ) : (
                  'Sedang Berjalan'
                )
              ) : status === 0 ? (
               'Menunggu Persetujuan'
              ) : (
                'Ditolak'
              )}</div>
            // return <div>{status ? 'Disetujui' : 'Menunggu Persetujuan'}</div>;
            
        }},
        {Header: 'Aksi', className:"no-print",
            Cell: row => (
                <div className="edit-delete-wrapper">
                    <button className="detail-button" onClick={() => {printLoanLetter(row.row.original)}}>Detail</button> 
                    <button className="edit-button" onClick={() => {handleEdit(row.row.original)}}>Edit</button> 
                    <button className="delete-button" onClick={() => {handleDelete(row.row.original)}}>Delete</button>
                </div>
            )
        },
    ];
    const employeeLoanInstallmentColumns = [
        {Header: 'No',
            Cell: (row) => {
                // console.log(row)
                // console.log(row.cell.row.index)
                let startFrom = position + 1;
                return <div>{startFrom +row.cell.row.index}.</div>;
                console.log(row);
                // return <div>{row.cell.row.index+1}.</div>;
                
            }
        },
        {Header: 'Id', accessor: 'id'},
        {Header: 'No. Pinjaman', accessor: 'no'},
        {Header: 'Nama', accessor: 'employee_name'},
        {Header: 'Departemen', accessor: 'employee_division'},
        {Header: 'Tim/Grup Kerja', accessor: 'group_name'},
        {Header: 'Bayar', accessor: 'paying',
        Cell : row => <NumberFormat value={row.value} displayType={'text'} thousandSeparator={'.'} decimalSeparator={false}  prefix={'Rp. '} />},
        {Header: 'Tanggal Bayar', Cell: row => {
            let paying_date = row.cell.row.original.paying_date;
            // paying_date && console.log(paying_date)
            return <div>{`${paying_date && YMdToFormatIndo(paying_date.substring(0, 10))}`}</div>

        }},
        {Header: 'Status',
        Cell: (row) => {
            const nth_payment = row.cell.row.original.nth_payment;
            return <div>{`Pembayaran ke-${nth_payment}`}</div>

            
        }},
        {Header: 'Aksi', className:"no-print",
            Cell: row => (
                <div className="edit-delete-wrapper">
                    <button className="detail-button" onClick={() => {printLoanInstallmentLetter(row.row.original)}}>Detail</button> 
                    {/* <button className="edit-button" onClick={() => {handleEdit(row.row.original)}}>Edit</button>  */}
                    <button className="delete-button" onClick={() => {handleDelete(row.row.original)}}>Delete</button>
                </div>
            )
        },
    ];

    return (
        <>
             <PageHeader
                title="Pinjaman Karyawan"
                subtitle={props.user.client_id}
                name={props.user.name}
                photo={iconUser}
            />
            <Gap height={20} />
            {
                pageName === 'print-letter' ? <>
                <Letter type="loan" company={props.user.info.company} value={selectedLoan} setPageName={setPageName} />
                </> :
           <>
            <Row style={{position: 'relative'}}>
                <Col>
                <Link to='/employee' className="back-button" >                    
                    <Icon icon={iconLeft} color="#fff" />
                    <p>Back</p>
                </Link>
                {/* <button onClick={handleAdd} className="back-button">
                            Back

                </button> */}
                </Col>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5, marginBottom: 5, position: 'absolute', left: '110px'}}>
                        <p className={`filter-group ${requestOrInstallment == 'request' ?'active': ''}`} onClick={() => setRequestOrInstallment('request')}>Pengajuan</p>    
                        <p className={`filter-group ${requestOrInstallment == 'installments' ?'active': ''}`} onClick={() => setRequestOrInstallment('installments')}>Angsuran</p>    
                        
                </div>
                <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginBottom: 5}}>
                        <p className={`filter-group ${selectedDivision == 0 ?'active': ''}`} onClick={() => handleChangeDivision({id: 0})}>Semua</p>    
                        {divisions && divisions.map(division => (
                            <p key={division.name} className={`filter-group ${selectedDivision == division.id ?'active': ''}`} onClick={() => handleChangeDivision(division)}>                    
                            {division.code}
                            </p>
                        ))
                        }
                    </Col>
                {/* filter tahun dan bulan */}
                <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <p style={{fontSize: 14, marginRight: 5}}>Periode : </p>
                    <div style={{marginLeft: 5}}>
                        <FilterYear year={year} startYear={startYear} selectedYear={selectedYear} handleChange={handleChangeYearFilter} />
                    </div>
                    <div style={{marginLeft: 5}}>
                        <FilterMonth handleChange={handleMonthChange} month={month} className="select-month" />
                    </div>
                    <div onClick={() => window.print()} className="print-button" style={{marginLeft: '5px', padding: '0 10px', height: '35px', border: '1px solid #eee'}}><span style={{fontSize: '22px', marginRight: '3px'}}>&#128424;</span>Print</div>
                </Col>
                
            </Row>
            {requestOrInstallment === 'request' &&
                <Row>
                    <p className={`filter-group ${status == 3 ?'active': ''}`} onClick={() => handleChangeStatus({id: 3})}>Semua</p>    
                    {allStatus && allStatus.map(st => (
                        <p key={st.name} className={`filter-group ${status == st.id ?'active': ''}`} onClick={() => handleChangeStatus(st)}>                    
                        {st.name}
                        </p>
                    ))
                    }   
                </Row>
            }
            <ReportHeader company={props.user.info.company} month={month} selectedYear={selectedYear} page={pageName} />
            <Row>
                <div style={{overflowX: 'auto', flex:1}}>
                    <div className="table-control">
                        <div className="limit">
                            <span>Show :</span>
                            <select onChange={changePerPage} value={perPage}>
                            {perPageList.map(perPage => {
                                return (
                                <option key={perPage.value} value={perPage.value}>
                                {perPage.label}
                                </option>
                                )
                                })
                            }
                            </select>
                            <span>Entries</span>

                        </div>
                    

                    <div className="search">
                        <input type="text"
                            placeholder="Search here..."
                            value={searchTerm || ''}
                            onChange={e => setSearchTerm(e.target.value)}
                            autoFocus
                        />
                    </div>
    
                    <button onClick={handleAdd} className="add-button">
                            <Icon icon={iconAdd} color="#fff" />
                            Buat {requestOrInstallment === 'request' ? 'Pinjaman' : 'Pembayaran'}

                    </button>
                    </div>
                 
                 
        
                        
                        {tableData ? 
                        <div className="print-table">
                            <Table type="dataTable" tableData={tableData} tableColumns={tableColumns} />
                            <ReportFooter className="section-to-print footer" />
                        </div>
                        : <><Gap height={30} /><h2 align="center">{message}</h2></>
                    }
                    
                    </div>
                </Row>
                {/* //jika tidak ada table data sembunyikan */}
    
                {tableData ? 
                    <div className="pagination">
                        <div className="pagination-info">
                            <span>Showing {position + 1} to {( currentPage != totalPage ) ? position + 1 * perPage : totalTableData } of {totalTableData} entries</span> <br />
                        </div>
                        <div className="pagination-control">
                            <button onClick={() => prevButton()} disabled={currentPage <= 1}>&laquo;</button>
                            {renderPageNumbers}
                            <button onClick={() => nextButton()} disabled={currentPage >= totalPage}>&raquo;</button>
    
                        </div>
                    </div>
                        : null
                }
                </>
            }
            <Modal 
                onRequestClose={() => setModalIsOpen(false)}
                isOpen={modalIsOpen}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                      },
                    content: {
                        border: '1px solid #222',
                        padding:0,
                        top: '25px',
                        left: '400px',
                        right: '400px',
                        bottom: '50px',
                    }
                }}
            >
                <div className="modal-container">
                <div className="modal-header">
                    <h2 className="modal-title">{(isAddOrEdit == 'add') ? 'Tambah' : 'Edit' } {requestOrInstallment === 'request' ? 'Pinjaman' : 'Pembayaran'}</h2>
                    <div onClick={() => printLoanLetter({id: 0})} className="print-button" style={{ cursor: 'pointer', marginLeft: 'auto', marginRight: '5px'}}><span style={{fontSize: '20px'}}>&#128424;</span></div>
                    <button className="close-modal" onClick={() => setModalIsOpen(false)}>X</button>
                </div>
                    {requestOrInstallment === 'request' ? <LoanForm employeeOptions={employeeOptions} token={token} closeModal={closeModal} isAddOrEdit={isAddOrEdit} initialValues={initialValues} /> : <InstallmentForm loanOptions={employeeLoanOptions} token={token} closeModal={closeModal} isAddOrEdit={isAddOrEdit} initialValues={initialValues} />}
                    
                

                </div>
            </Modal>
        </>
    )
}

const reduxState = (state) => ({
    isLogin: state.isLogin,
    user: state.user,
    isLoading: state.isLoading

})
  
  
const reduxDispatch = (dispatch) => ({
    loading : (data) => dispatch(setLoading(data)),

    

})
export default connect(reduxState, reduxDispatch)(EmployeeLoan)
