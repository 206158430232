import React, { useEffect, useState } from 'react'
import { Formik, Form } from "formik";
import { AutoCompleteSelect, FormControl } from '../../../../components';
import {getStyle} from '../../../../utils/helpers/errorMessage'
import API from '../../../../config/api';
import { tahun_bulan_tanggal } from '../../../../utils/helpers/date';
import * as Yup from "yup";


const schemaValidation = Yup.object().shape({
    no: Yup.string().required('Tidak boleh kosong'),
    employee_id: Yup.object()
        .shape({
            label: Yup.string(),
            value: Yup.string()
        })
        .nullable()
        .required("Karyawan Tidak Boleh Kosong"),
    violation_id: Yup.object()
        .shape({
            label: Yup.string(),
            value: Yup.string()
        })
        .nullable()
        .required("Pelanggaran Tidak Boleh Kosong"),
    // employee_id: Yup.object().nullable().required('Tidak boleh kosong'),
    // violation_id: Yup.object().nullable().required('Tidak boleh kosong'),
    name: Yup.string().required('Tidak boleh kosong'),
    periode: Yup.string().required('Tidak boleh kosong'),
    started_date: Yup.string().nullable().required('Tidak boleh kosong'),
    expired_date: Yup.string().nullable().required('Tidak boleh kosong'),
    note: Yup.string().required('Tidak boleh kosong'),
 
});




const WarningLetterForm = ({employeeOptions, violationOptions, token, closeModal, isAddOrEdit, initialValues}) => {
  

  const handleSubmit = (values, { resetForm, setSubmitting }) => {

    values.started_date = tahun_bulan_tanggal(values.started_date)
    values.expired_date = tahun_bulan_tanggal(values.expired_date)
    
    values.violation_id = values.violation_id.value
    values.employee_id = values.employee_id.value
    // values = JSON.stringify(values)
    // console.log("handleSubmit values", values);
    if(isAddOrEdit === 'add'){
      API.addWarningLetter(token, values).then(res => {
        // console.log(res)
        // console.log(res.data.message);
        swal({
            title: res.data.status,
            text: res.data.message,
            icon: "success",
        });
      return closeModal();
  
        
      }).catch(err => {
          console.log(err.response);
          swal({
              title: err.status,
              // text: err.response.data.message,
              icon: "error",
          });
  
  
      });
    }else {
      
      // console.log('edit')
      API.editWarningLetter(token, values).then(res => {
        // console.log(res);
        swal({
            title: res.data.status,
            text: res.data.message,
            icon: "success",
        });
        return closeModal();


      }).catch(err => {
          // console.log(err);
          swal({
              title: err.status,
              text: err.message,
              icon: "error",
          });
          return closeModal();


      });
    }


  };
    return (
      <Formik
          initialValues={initialValues}
          onSubmit={(values, props) => handleSubmit(values, props)}
          validationSchema={() => schemaValidation}
          enableReinitialize
          >
      {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            isSubmitting
          }) => (
            <div className="modal-body">

            <Form>
                <FormControl control="input" type="text" label="Nomor Surat" name="no"
                    style={getStyle(errors, touched, 'no')} placeholder="Nomor Surat"
                />
                <FormControl control="input" type="text" label="Nama Surat" name="name" placeholder="Nama Surat"
                    style={getStyle(errors, touched, 'name')}
                />
                <FormControl control="input" type="text" label="Periode" name="periode" placeholder="Tahun"
                    style={getStyle(errors, touched, 'periode')}
                />
              <AutoCompleteSelect
                id="employee_id"
                name="employee_id"
                label="Karyawan"
                placeholder="Pilih Karyawan..."
                options={employeeOptions}
                // defaultValue={{ label :values.employee_id, value: values.employee_id}}
                value={values.employee_id}

                // value={{label : initialValues.employee_name, value: values.employee_id}}
                isMulti={false}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                touched={touched.employee_id}
                error={errors.employee_id}
                isClearable={true}
                backspaceRemovesValue={true}
              />
              <AutoCompleteSelect
                id="violation_id"
                name="violation_id"
                label="Pelanggaran"
                placeholder="Pilih Pelanggaran"
                options={violationOptions}
                value={values.violation_id}
                isMulti={false}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                touched={touched.violation_id}
                error={errors.violation_id}
                isClearable={true}
                backspaceRemovesValue={true}
              />
              <FormControl
                control="date"
                name="started_date"
                label='Berlaku Dari Tanggal'
                style={getStyle(errors, touched, 'started_date')}
                
              />
              <FormControl
                control="date"
                name="expired_date"
                label='Berlaku Sampai Tanggal'
                style={getStyle(errors, touched, 'expired_date')}
                
              />
              <FormControl
                control='textarea'
                label='Catatan'
                placeholder="Keterangan"
                name='note'
                style={getStyle(errors, touched, 'note')}

              />
             

              <button type="submit" className="add-button add-bulk-schedule" disabled={isSubmitting}>
                <h3>Simpan</h3>
              </button>
            </Form>
            </div>
          )}
        </Formik>
    );
}

export default WarningLetterForm

