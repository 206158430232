import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { iconAdd, iconLeft, iconUser } from '../../../../assets';
import { Gap, PageHeader, Row, Col, Icon, Table,  FilterYear, FilterMonth, ReportFooter, ReportHeader, Blanko } from '../../../../components'
import API from '../../../../config/api';
import Modal from 'react-modal';
import swal from 'sweetalert';
import { useDebounce } from '../../../../utils/helpers/useDebounce';
import LeaveForm from './LeaveForm';
import NumberFormat from 'react-number-format';
import { tahun_bulan_tanggal, totalDate } from '../../../../utils/helpers/date';
import ChangeRequestStatusForm from './ChangeRequestStatusForm';

Modal.setAppElement('#root');

const EmployeeLeave = (props) => {

    const token = props.user.token;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const perPageList = [
        {label: "5", value: 5},
        {label: "10", value: 10},
        {label: "25", value: 25},
        {label: "50", value: 50},
    ];
    const [totalTableData, setTotalTableData] = useState(0);
    const [position, setPosition] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalChangeRequestStatus, setModalChangeRequestStatus] = useState(false);
    const [isAddOrEdit, setIsAddOrEdit] = useState('');

    const [tableData, setTableData] = useState([]);
    const [initialValues, setInitialValues] = useState({});

    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
    const [divisions, setDivisions] = useState([]);

    const year = (new Date()).getFullYear();
    const startYear = (new Date()).getFullYear() - 3;
    const [selectedYear, setSelectedYear] = useState(year);
    const [selectedDivision, setSelectedDivision] = useState(0);
    const [divisionName, setDivisionName] = useState('');
    const [pageName, setPageName] = useState("Laporan Pengajuan Cuti Disetujui & Tidak Disetujui");
    const [month, setMonth] = useState(new Date().getMonth())
    const [selectedLeave, setSelectedLeave] = useState({id: 0});
    const allStatus = [
        {id: 0, name: 'Menunggu'},
        {id: 1, name: 'Disetujui'},
        {id: 2, name: 'Ditolak'}
    ];
    const [status, setStatus] = useState(3);
    const debouncedSearchTerm = useDebounce(searchTerm, 800);

    useEffect(() => {
        //get all employee
        API.getAllEmployee(token).then(res => {
            // setEmployeeOptions(res.data)
            // console.log(res.data)
            let eOptions = [];
            res.data.map(e => {
                eOptions.push({
                    value: e.id, label: e.name
                });
            });
            setEmployeeOptions(eOptions);

        }).catch(err => {
            console.log(err.response.data.message)
        })
        //get all leave types
        API.getAllLeaveTypes(token).then(res => {
            // setLeaveTypeOptions(res.data)
            // console.log(res.data)
            let lTOptions = [];
            res.data.map(e => {
                lTOptions.push({
                    value: e.id, label: e.name
                });
            });
            setLeaveTypeOptions(lTOptions);

        }).catch(err => {
            console.log(err.response.data.message)
        })

        //get all division
        API.getAllDivision(token).then(res => {
            // console.log('divisi: ', res.data)
            setDivisions(res.data)
        }).catch(err => {
            console.log(err.response.data.message)
        })
    }, [])

    useEffect(() => {
        // setCurrentPage(currentPage);
        setTotalPage(0);
        let mnth = parseInt(month) + 1;
        
        API.getEmployeeLeave(token, currentPage, perPage, searchTerm, selectedYear, selectedDivision, status, mnth).then((res) => {
            // console.log(res.data);
            setTableData(res.data.data)
            setTotalPage(res.data.last_page);
            setTotalTableData(res.data.total);
            setPosition((currentPage - 1) * perPage)
            setMessage('success get data leave');
            // setLoading(false);
        }).catch(err => {
            // console.log(err.response.data.message);
            // console.log(err);
            setTableData(0);
            setMessage(err.response.data.message || 'Data Cuti Tidak Ditemukan');
            
            // setLoading(false);
        })



    }, [debouncedSearchTerm, currentPage, perPage, searchTerm, modalIsOpen, selectedYear, selectedDivision, status, month, modalChangeRequestStatus])
    
    useEffect(() => {
        if(status == 1){
            setPageName(`Laporan Pengajuan Cuti Disetujui ${selectedDivision === 0 ? 'Semua' : ''} Karyawan ${selectedDivision === 0 ? '' : divisionName}`)
        }else if(status == 3){
            setPageName(`Laporan Pengajuan Cuti Disetujui & Tidak Disetujui ${selectedDivision === 0 ? 'Semua' : ''} Karyawan ${selectedDivision === 0 ? '' : divisionName}`)

        }else {
            setPageName(`Laporan Pengajuan Cuti Tidak Disetujui ${selectedDivision === 0 ? 'Semua' : ''} Karyawan ${selectedDivision === 0 ? '' : divisionName}`)
            
        }
    }, [status, divisionName])
    const handleChangeDivision = (division) => {
        setSelectedDivision(division.id)
        setDivisionName(division.name)
        // setGroup(division)
    }
    const printLeaveLetter = (leave) => {
        setPageName('print-blanko')
        if(leave.id === 0){
            setModalIsOpen(!modalIsOpen)
            // alert('print blankoooo')
        }
        setSelectedLeave(leave)
        
    }

    const handleChangeRequestStatus = (row) => {
        // alert('setujui atau tolak')
        console.log(row)
        setSelectedLeave(row)

        // setRequestStatusInitialValues({
        //     id: row.id,
        //     leave_no: row.leave_no,
        //     status: row.status
        //   })
        setInitialValues({
            id: row.id,
            leave_no: row.leave_no ? row.leave_no : '',
            employee_id: {label: row.employee_name, value: row.employee_id},
            leave_type: {label: row.leave_type_name, value: row.leave_type},
            periode: row.periode,
            started_date: row.approved_date_start ? row.approved_date_start : row.request_date_start,
            end_date: row.approved_date_end ? row.approved_date_end : row.request_date_end,
            request_date_start: row.request_date_start,
            request_date_end: row.request_date_end,
            employee_note: row.employee_note ? row.employee_note : "",
            approver_note: row.approver_note ? row.approver_note : "",
            approved_date_start: row.approved_date_start,
            approved_date_end: row.approved_date_end,
            approver_id: row.approver_id ? {label: row.approver_name, value: row.approver_id} : '',
            address_during_leave: row.address_during_leave,
            contact_during_leave: row.contact_during_leave,
            attachment: row.attachment || "",
            status: row.status
        })
        setModalChangeRequestStatus(true)
    }
    const handleAdd = () => {
        setIsAddOrEdit('add')
        setInitialValues({
            employee_id: [],
            leave_no: '',
            leave_type: '',
            periode: '',
            request_date_start: '',
            request_date_end: '',
            employee_note: '',
            approver_note: '',
            approved_date_start: '',
            approved_date_end: '',
            approver_id: '',
            periode: '',
            status: 1,
            address_during_leave: '',
            contact_during_leave: '',
        })
        setModalIsOpen(true)
        
       
    }

    const handleEdit = (row) => {
        // console.log(row)
        setIsAddOrEdit('edit');
        setInitialValues({
            id: row.id,
            leave_no: row.leave_no ? row.leave_no : '',
            employee_id: {label: row.employee_name, value: row.employee_id},
            leave_type: {label: row.leave_type_name, value: row.leave_type},
            periode: row.periode,
            started_date: row.approved_date_start ? row.approved_date_start : row.request_date_start,
            end_date: row.approved_date_end ? row.approved_date_end : row.request_date_end,
            request_date_start: row.request_date_start,
            request_date_end: row.request_date_end,
            employee_note: row.employee_note ? row.employee_note : "",
            approver_note: row.approver_note ? row.approver_note : "",
            approved_date_start: row.approved_date_start,
            approved_date_end: row.approved_date_end,
            approver_id: row.approver_id ? {label: row.approver_name, value: row.approver_id} : '',
            address_during_leave: row.address_during_leave,
            contact_during_leave: row.contact_during_leave,
            attachment: row.attachment || "",
            status: row.status
          })
        setModalIsOpen(true)
    }

    const handleDetail = (row) => {
        //set modal detail open
        console.log('detail', row)
    }

    const handleDelete = (row) => {
        // console.log(row)
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this leave data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                // console.log('Call API delete Departemen');
                // console.log(currentPage)
                // console.log(tableData)
                // console.log(totalPage)
                if(currentPage === totalPage){ //jika di halaman terakhir
                    // console.log('halaman terakhir')

                    return API.deleteLeaveEmployee(token, row.id).then(res => {
                        swal({
                            title: 'Berhasil',
                            text: 'Cuti Berhasil Dihapus',
                            icon: "success",
                          });
                        // console.log('call branch Data lagi');
                        
                            API.getEmployeeLeave(token, currentPage, perPage, searchTerm, selectedYear, selectedDivision).then((res) => {
                                // console.log(res.data.data);
                                
                                setTableData(res.data.data)
                                setTotalPage(res.data.last_page);
                                setTotalTableData(res.data.total);
                                setPosition((currentPage - 1) * perPage)
                                // console.log('tetap di halaman')
                                setMessage('success get data leaves');
                            
                                // setLoading(false);
                            }).catch(err => {
                                // console.log(err.response.data.message);
                                // console.log(err);
                                setCurrentPage(currentPage - 1)
                                // setTableData(0);
                                setMessage(err.response.data.message || 'Cuti Tidak Ditemukan');
                                
                                // setLoading(false);
                            })
                        
                        
                    }).catch(err => {
                        console.log(err);
                        // setLoading(false);
                    })
                    
                }else {
                    // console.log('bukan halaman terakhir')
                    return API.deleteLeaveEmployee(token, row.id).then(res => {
                        swal({
                            title: 'Berhasil',
                            text: 'Cuti Berhasil Dihapus',
                            icon: "success",
                          });
                        // console.log('call branch Data lagi');
                        
                            API.getEmployeeLeave(token, currentPage, perPage, searchTerm, selectedYear, selectedDivision).then((res) => {
                                // console.log(res.data.data);
                                setTableData(res.data.data)
                                setTotalPage(res.data.last_page);
                                setTotalTableData(res.data.total);
                                setPosition((currentPage - 1) * perPage)
                                setMessage('success get data leaves');
                                // setLoading(false);
                            }).catch(err => {
                                // console.log(err.response.data.message);
                                // console.log(err);
                                setTableData(0);
                                setMessage(err.response.data.message || 'Cuti Tidak Ditemukan');
                                
                                // setLoading(false);
                            })
                        
                        
                    }).catch(err => {
                        console.log(err);
                        // setLoading(false);
                    })
                }
                
            }
            else {
            //   swal("Your imaginary file is safe!");
            //   setLoading(false);

            }
          });
    }

    

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const closeModalChangeRequestStatus = () => {
        setModalChangeRequestStatus(false);
    }
  
    const prevButton = () => {
        setCurrentPage(Number(currentPage - 1));
    }
    const nextButton = () => {
        setCurrentPage(Number(currentPage + 1));        

    }
    const pageNumberClick = (e) => {
        // setCurrentPage(e => e.target.id)
        setCurrentPage(Number(e.target.id));
    }
    const changePerPage = (e) => {
        setPerPage(Number(e.target.value))
    }

    const handleChangeYearFilter = (e) => {
        setSelectedYear(e.target.value);
       
    }
    const handleMonthChange = e => {
        setMonth(e.target.value)
    }
    const handleChangeStatus = (status) => {
        setStatus(status.id)
    }


    const pageNumbers = [];
    
    let i = 0;
    do {
        i++;
        // console.log(`halaman sekarang,`, i);
        pageNumbers.push(i);
    } while(i < totalPage)

    const renderPageNumbers = pageNumbers.map(number => {
        return (
          <a
            key={number}
            id={number}
            onClick={pageNumberClick}
            className={currentPage == number ? 'active' : ''}
          >
            {number}
          </a>
        );
      });

      const employeeLeaveColumns = [
        {Header: 'No',
            Cell: (row) => {
                // console.log(row)
                // console.log(row.cell.row.index)
                let startFrom = position + 1;
                return <div>{startFrom +row.cell.row.index}.</div>;
                console.log(row);
                // return <div>{row.cell.row.index+1}.</div>;
                
            }
        },
        {Header: 'Id', accessor: 'id', show: false},
        {Header: 'Lampiran', accessor: 'attachment', show: false},
        {Header: 'No. Cuti', accessor: 'leave_no'},
        {Header: 'Nama', accessor: 'employee_name'},
        {Header: 'Departemen', accessor: 'employee_division'},
        {Header: 'Tim/Grup Kerja', accessor: 'group_name'},
        {Header: 'Jenis Cuti', accessor: 'leave_type_name'},
        {Header: 'Periode', accessor: 'periode'},
        {Header: 'Tanggal Permintaan', accessor: 'request_date_start',
            Cell: (row) => {
                const leave = row.cell.row.original;
                //copy dari pbkm mobile, yang 22 - 23 juni 2021
                return <div>{totalDate(
                    leave.request_date_start,
                    leave.request_date_end,
                  )}</div>
                
            }
        },
        {Header: 'Tanggal Disetujui', accessor: 'approved_date', 
            Cell: (row) => {
                const leave = row.cell.row.original;
                const status = row.cell.row.original.status;
                //copy dari pbkm mobile, yang 22 - 23 juni 2021
                return <>{status === 1 ? <div style={{fontSize: '10px'}}>{leave.approved_date_start && leave.approved_date_end && `${totalDate(
                    leave.approved_date_start,
                    leave.approved_date_end,
                )} | Oleh : ${leave.approver_position}`}</div> : status === 2 ? <div style={{fontSize: '10px'}}>{leave.approved_date_start && leave.approved_date_end && `${totalDate(
                    leave.approved_date_start,
                    leave.approved_date_end,
                )} | Oleh : ${leave.approver_position}`}</div> : status === 3 ? (
                    '-'
                   ) : (
                     ''
                   )}</>
                // return "tes";
                // return <div style={{fontSize: '10px'}}>{leave.approved_date_start && leave.approved_date_end && `${totalDate(
                //     leave.approved_date_start,
                //     leave.approved_date_end,
                // )} | Oleh : ${leave.approver_position}`}</div>
                
            }
        },
        {Header: 'Status', accessor: 'status', 
        Cell: (row) => {
            const status = row.cell.row.original.status;
            //jika nilainya 1, libur, jika 0, masuk
            return <div>{status === 1 ? 'Disetujui Atasan' : status === 2 ? 'Disetujui Div. HR' : status === 3 ? (
               'Ditolak'
              ) : (
                'Menunggu Persutujuan'
              )}</div>
            // return <div>{status ? 'Disetujui' : 'Menunggu Persetujuan'}</div>;
            
        }},
        {Header: 'Aksi', className:"no-print",
            Cell: row => {
                const status = row.cell.row.original.status;
                return (
                <div className="edit-delete-wrapper">
                    <button className="detail-button" onClick={() => {printLeaveLetter(row.row.original)}}>Detail</button> 
                    <button className="primary-button" onClick={() => handleChangeRequestStatus(row.row.original)}>{status === 1 ? 'Batal Setuju' : status === 2 ? 'Batal Setuju' : status === 3 ? (
                       'Setujui'
                      ) : (
                        'Tindak Lanjut'
                      )}</button>

                    <button className="edit-button" onClick={() => {handleEdit(row.row.original)}}>Edit</button> 
                    <button className="delete-button" onClick={() => {handleDelete(row.row.original)}}>Delete</button>
                </div>
                )
            }
        },
    ];
    
    return (
        <>
             <PageHeader
                title="Cuti Karyawan"
                subtitle={props.user.client_id}
                name={props.user.name}
                photo={iconUser}
            />
            <Gap height={20} />
            {
                pageName == 'print-blanko' ? <>
                <Blanko type="leave" company={props.user.info.company} value={selectedLeave} setPageName={setPageName} selectedYear={selectedYear} />
                </> :
           <>
            <Row>
                <Col>
                <Link to='/employee' className="back-button" >                    
                    <Icon icon={iconLeft} color="#fff" />
                    <p>Back</p>
                </Link>
                {/* <button onClick={handleAdd} className="back-button">
                            Back

                </button> */}
                </Col>
                <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginBottom: 5}}>
                        <p className={`filter-group ${selectedDivision == 0 ?'active': ''}`} onClick={() => handleChangeDivision({id: 0})}>Semua</p>    
                        {divisions && divisions.map(division => (
                            <p key={division.name} className={`filter-group ${selectedDivision == division.id ?'active': ''}`} onClick={() => handleChangeDivision(division)}>                    
                            {division.code}
                            </p>
                        ))
                        }
                    </Col>
                {/* filter tahun dan bulan */}
                <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <p style={{fontSize: 14, marginRight: 5}}>Periode : </p>
                    <div style={{marginLeft: 5}}>
                        <FilterYear year={year} startYear={startYear} selectedYear={selectedYear} handleChange={handleChangeYearFilter} />
                    </div>
                    <div style={{marginLeft: 5}}>
                        <FilterMonth handleChange={handleMonthChange} month={month} className="select-month" />
                    </div>
                    <div onClick={() => window.print()} className="print-button" style={{marginLeft: '5px', padding: '0 10px', height: '35px', border: '1px solid #eee'}}><span style={{fontSize: '22px', marginRight: '3px'}}>&#128424;</span>Print</div>
                </Col>
            </Row>
            <Row>
                <p className={`filter-group ${status == 3 ?'active': ''}`} onClick={() => handleChangeStatus({id: 3})}>Semua</p>    
                {allStatus && allStatus.map(st => (
                    <p key={st.name} className={`filter-group ${status == st.id ?'active': ''}`} onClick={() => handleChangeStatus(st)}>                    
                    {st.name}
                    </p>
                ))
                }   
            </Row>
            <ReportHeader company={props.user.info.company} month={month} selectedYear={selectedYear} page={pageName} />
            <Row>
                <div style={{overflowX: 'auto', flex:1}}>
                    <div className="table-control">
                        <div className="limit">
                            <span>Show :</span>
                            <select onChange={changePerPage} value={perPage}>
                            {perPageList.map(perPage => {
                                return (
                                <option key={perPage.value} value={perPage.value}>
                                {perPage.label}
                                </option>
                                )
                                })
                            }
                            </select>
                            <span>Entries</span>

                        </div>
                    

                    <div className="search">
                        <input type="text"
                            placeholder="Search here..."
                            value={searchTerm || ''}
                            onChange={e => setSearchTerm(e.target.value)}
                            autoFocus
                        />
                    </div>
    
                    <button onClick={handleAdd} className="add-button">
                            <Icon icon={iconAdd} color="#fff" />
                            Buat Cuti

                    </button>
                    </div>
                 
                 
        
                        
                        {tableData ? 
                        <div className="print-table">
                            <Table type="dataTable" tableData={tableData} tableColumns={employeeLeaveColumns} className="section-to-print" />
                            <ReportFooter className="section-to-print footer" />
                        </div>
                        : <><Gap height={30} /><h2 align="center">{message}</h2></>
                    }
                    
                    </div>
                </Row>
                {/* //jika tidak ada table data sembunyikan */}
    
                {tableData ? 
                    <div className="pagination">
                        <div className="pagination-info">
                            <span>Showing {position + 1} to {( currentPage != totalPage ) ? position + 1 * perPage : totalTableData } of {totalTableData} entries</span> <br />
                        </div>
                        <div className="pagination-control">
                            <button onClick={() => prevButton()} disabled={currentPage <= 1}>&laquo;</button>
                            {renderPageNumbers}
                            <button onClick={() => nextButton()} disabled={currentPage >= totalPage}>&raquo;</button>
    
                        </div>
                    </div>
                        : null
                }
                </>
            }

            <Modal 
                onRequestClose={() => setModalIsOpen(false)}
                isOpen={modalIsOpen}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                      },
                    content: {
                        border: '1px solid #222',
                        padding:0,
                        top: '25px',
                        left: '400px',
                        right: '400px',
                        bottom: '50px',
                    }
                }}
            >
                <div className="modal-container">
                <div className="modal-header">
                    <h2 className="modal-title">{(isAddOrEdit == 'add') ? 'Tambah' : 'Edit' } Cuti</h2>
                    <div onClick={() => printLeaveLetter({id: 0})} className="print-button" style={{ cursor: 'pointer', marginLeft: 'auto', marginRight: '5px'}}><span style={{fontSize: '20px'}}>&#128424;</span></div>
                    <button className="close-modal" onClick={() => setModalIsOpen(false)}>X</button>
                </div>
                <LeaveForm employeeOptions={employeeOptions} leaveTypeOptions={leaveTypeOptions} token={token} closeModal={closeModal} isAddOrEdit={isAddOrEdit} initialValues={initialValues} />
                

                </div>
            </Modal>
            
            <Modal 
                onRequestClose={() => setModalChangeRequestStatus(false)}
                isOpen={modalChangeRequestStatus}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                      },
                    content: {
                        border: '1px solid #222',
                        padding:0,
                        top: '25px',
                        left: '400px',
                        right: '400px',
                        bottom: '50px',
                    }
                }}
            >
                <div className="modal-container">
                <div className="modal-header">
                    <h2 className="modal-title">Ubah Status Pengajuan Cuti</h2>
                    <div onClick={() => {printLeaveLetter(selectedLeave)
                    setModalChangeRequestStatus(false)}} className="print-button" style={{ cursor: 'pointer', marginLeft: 'auto', marginRight: '5px'}}><span style={{fontSize: '20px'}}>&#128424;</span></div>
                    <button className="close-modal" onClick={() => setModalChangeRequestStatus(false)}>X</button>
                </div>
                <p align="center" style={{padding: '20px'}}>{selectedLeave.group_name} <b><i>{selectedLeave.employee_name}</i></b> mengajukan <b><i>{selectedLeave.leave_type_name}</i></b> untuk tanggal <b><i>{selectedLeave.request_date_start && totalDate(selectedLeave.request_date_start, selectedLeave.request_date_end)}</i></b></p>
                <div align="center">
                    <p>Keterangan: </p> 
                    {selectedLeave.employee_note} <br /><br />
                    <p>Alamat Selama Cuti: </p>
                    {selectedLeave.address_during_leave} <br /><br />
                    <p>Kontak Selama Cuti: </p>
                    {selectedLeave.contact_during_leave}
                </div><br />
                
                <ChangeRequestStatusForm employeeOptions={employeeOptions} token={token} closeModalChangeRequestStatus={closeModalChangeRequestStatus} initialValues={initialValues} />


                </div>
            </Modal>
            
        </>
    )
}

const reduxState = (state) => ({
    isLogin: state.isLogin,
    user: state.user,
    isLoading: state.isLoading

})
  
  
const reduxDispatch = (dispatch) => ({
    loading : (data) => dispatch(setLoading(data)),

    

})
export default connect(reduxState, reduxDispatch)(EmployeeLeave)
