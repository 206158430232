import React from 'react'

const DetailMaster = () => {
    return (
        <div>
            halaman detail dari master yang diklik berdasar id
        </div>
    )
}

export default DetailMaster
