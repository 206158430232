import * as Yup from 'yup'



export const loginDataValidationSchema = Yup.object({
    email: Yup.string().required('Required').email('Invalid email'),
    password: Yup.string().required('Required').min(8),
    repeat_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords harus sama')


});

export const loginDataFields = [
    {
        control: 'input',
        type: 'hidden',
        label: '',
        name: 'name',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Email',
        name: 'email',
        readOnly: true,
    },
    {
        control: 'input',
        type: 'password',
        label: 'Password',
        name: 'password'
    },
    {
        control: 'input',
        type: 'password',
        label: 'Ulang Password',
        name: 'repeat_password'
    },
    {
        control: 'input',
        type: 'hidden',
        label: '',
        name: 'role'
    },

];

export const dataDasarValidationSchema = Yup.object({
    full_name: Yup.string().required('Required'),
    nik: Yup.string().required('Required'),
    nik: Yup.string().required('Required'),
    place_of_birth: Yup.string().required('Required'),
    date_of_birth: Yup.string().required('Required'),
    gender: Yup.string().required('Required'),
    religion: Yup.string().required('Required'),
    blood_type: Yup.string().required('Required'),
    marital_status: Yup.string().required('Required'),


});

export const dataDasarFields = [
    {
        control: 'input',
        type: 'hidden',
        label: '',
        name: 'full_name',
    },
    {
        control: 'input',
        type: 'number',
        label: 'No.KTP',
        name: 'nik',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Tempat Lahir',
        name: 'place_of_birth',
    },
    {
        control: 'input',
        type: 'date',
        label: 'Tanggal Lahir',
        name: 'date_of_birth',
    },
    {
        control: 'select',
        options: [
            { key: '-- Pilih Jenis Kelamin --', value: '' },
            { key: 'Laki-laki', value: 'Laki-laki' },
            { key: 'Perempuan', value: 'Perempuan' },

        ],
        label: 'Jenis Kelamin',
        name: 'gender'
    },
    {
        control: 'select',
        options: [
            { key: '-- Pilih Agama --', value: '' },
            { key: 'Islam', value: 'Islam' },
            { key: 'Kristen Katolik', value: 'Kristen Katolik' },
            { key: 'Hindu', value: 'Hindu' },
            { key: 'Buddha', value: 'Buddha' },
            { key: 'Khonghucu', value: 'Khonghucu' },
        ],
        label: 'Agama',
        name: 'religion'
    },
    {
        control: 'select',
        options: [
            { key: '-- Pilih Agama --', value: '' },
            { key: 'A', value: 'A' },
            { key: 'B', value: 'B' },
            { key: 'AB', value: 'AB' },
            { key: 'O', value: 'O' },
        ],
        label: 'Golongan Darah',
        name: 'blood_type'
    },
    {
        control: 'select',
        options: [
            { key: '-- Pilih Status Pernikahan --', value: '' },
            { key: 'Belum kawin', value: '1' },
            { key: 'Kawin', value: '2' },
            { key: 'Cerai hidup', value: '3' },
            { key: 'Cerai mati', value: '4' },
            { key: 'Janda/duda', value: '5' },
        ],
        label: 'Status Pernikahan',
        name: 'marital_status',
    },
    {
        control: 'file',
        type: 'file',
        label: 'Photo',
        name: 'photo',
    },

];
export const dataContactValidationSchema = Yup.object({
    address_identity_card: Yup.string().required('Required'),
    personal_email: Yup.string().required('Required'),
    work_email: Yup.string().required('Required'),
    personal_phone: Yup.string().required('Required'),
    work_phone: Yup.string().required('Required'),
    darurat_phone_1: Yup.string().required('Required'),
    darurat_phone_2: Yup.string().required('Required'),
    name_darurat_contact_1: Yup.string().required('Required'),
    name_darurat_contact_2: Yup.string().required('Required'),
    relation_darurat_contact_1: Yup.string().required('Required'),
    relation_darurat_contact_2: Yup.string().required('Required'),
    address_darurat_contact_1: Yup.string().required('Required'),
    address_darurat_contact_2: Yup.string().required('Required'),


});

export const dataContactFields = [
    {
        control: 'input',
        type: 'text',
        label: 'Alamat Sesuai KTP',
        name: 'address_identity_card',
    },
    {
        control: 'input',
        type: 'email',
        label: 'Email pribadi',
        name: 'personal_email',
    },
    {
        control: 'input',
        type: 'email',
        label: 'Email Kerja',
        name: 'work_email',
    },

    {
        control: 'input',
        type: 'text',
        label: 'Telepon pribadi',
        name: 'personal_phone',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Telepon Kerja',
        name: 'work_phone',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Nama Darurat 1',
        name: 'name_darurat_contact_1',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Telepon Darurat 1',
        name: 'darurat_phone_1',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Alamat Darurat 1',
        name: 'address_darurat_contact_1',
    },

    {
        control: 'select',
        options: [
            { key: '-- Pilih Relasi --', value: '' },
            { key: 'Pasangan', value: 'Pasangan' },
            { key: 'Orang Tua', value: 'Orang Tua' },
            { key: 'Saudara Kandung', value: 'Saudara Kandung' },
            { key: 'Anak', value: 'Anak' },
            { key: 'Teman', value: 'Teman' },
            { key: 'Rekan Kerja', value: 'Rekan Kerja' },
            { key: 'Wali', value: 'Wali' },
            { key: 'Kerabat', value: 'Kerabat' },
            { key: 'Pasangan Hidup', value: 'Pasangan Hidup' },
            { key: 'Paman/Bibi', value: 'Paman/Bibi' },
            { key: 'Kakek/Nenek', value: 'Kakek/Nenek' },
            { key: 'Mertua/Saudara Ipar', value: 'Mertua/Saudara Ipar' },
        ],
        label: 'Hubungan Darurat 1',
        name: 'relation_darurat_contact_1'
    },
    {
        control: 'input',
        type: 'text',
        label: 'Nama Darurat 2',
        name: 'name_darurat_contact_2',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Telepon Darurat 2',
        name: 'darurat_phone_2',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Alamat Darurat 2',
        name: 'address_darurat_contact_2',
    },

    {
        control: 'select',
        options: [
            { key: '-- Pilih Relasi --', value: '' },
            { key: 'Pasangan', value: 'Pasangan' },
            { key: 'Orang Tua', value: 'Orang Tua' },
            { key: 'Saudara Kandung', value: 'Saudara Kandung' },
            { key: 'Anak', value: 'Anak' },
            { key: 'Teman', value: 'Teman' },
            { key: 'Rekan Kerja', value: 'Rekan Kerja' },
            { key: 'Wali', value: 'Wali' },
            { key: 'Kerabat', value: 'Kerabat' },
            { key: 'Pasangan Hidup', value: 'Pasangan Hidup' },
            { key: 'Paman/Bibi', value: 'Paman/Bibi' },
            { key: 'Kakek/Nenek', value: 'Kakek/Nenek' },
            { key: 'Mertua/Saudara Ipar', value: 'Mertua/Saudara Ipar' },
        ],
        label: 'Hubungan Darurat 2',
        name: 'relation_darurat_contact_2'
    },


];

export const dataFamilieValidationSchema = Yup.object({
    father_name: Yup.string().required('Required'),
    mother_name: Yup.string().required('Required'),
});

export const dataFamilieFields = [
    {
        control: 'input',
        type: 'text',
        label: 'Nama Ayah',
        name: 'father_name',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Nama Ibu',
        name: 'mother_name',
    },

    {
        control: 'input',
        type: 'text',
        label: 'Nama pasangan',
        name: 'couple_name',
    },
    {
        control: 'input',
        type: 'number',
        label: 'BPJS Pasangan',
        name: 'couple_no_bpjs',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Nama Anak ke-1',
        name: 'child_name_1',
    },
    {
        control: 'input',
        type: 'number',
        label: 'BPJS Anak ke-1',
        name: 'child_1_no_bpjs',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Nama Anak ke-2',
        name: 'child_name_2',
    },
    {
        control: 'input',
        type: 'number',
        label: 'BPJS Anak ke-2',
        name: 'child_2_no_bpjs',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Nama Anak ke-3',
        name: 'child_name_3',
    },
    {
        control: 'input',
        type: 'number',
        label: 'BPJS Anak ke-3',
        name: 'child_3_no_bpjs',
    },

];

export const dataEducationValidationSchema = Yup.object({
    degree: Yup.string().required('Required'),
    institute_name: Yup.string().required('Required'),
    major: Yup.string().required('Required'),
    year_start: Yup.string().required('Required'),
    year_out: Yup.string().required('Required'),
});

export const dataEducationFields = [
    {
        control: 'select',
        options: [
            { key: '-- Pilih Pendidikan --', value: '' },
            { key: 'SD', value: 'SD' },
            { key: 'SMP', value: 'SMP' },
            { key: 'SMA', value: 'SMA' },
            { key: 'Diploma III', value: 'Diploma III' },
            { key: 'Diploma IV ', value: 'Diploma IV' },
            { key: 'Serjana (S1)', value: 'Serjana (S1)' },
            { key: 'Magister (S2)', value: 'Magister (S2)' },
            { key: 'Doktor (S3)', value: 'Doktor (S3)' },
        ],
        label: 'Pendidikan',
        name: 'degree',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Nama Tempat Pendidikan',
        name: 'institute_name',
    },

    {
        control: 'input',
        type: 'text',
        label: 'Jurusan',
        name: 'major',
    },
    {
        control: 'input',
        type: 'number',
        label: 'Tahun Masuk',
        name: 'year_start',
    },
    {
        control: 'input',
        type: 'number',
        label: 'Tahun Selesai',
        name: 'year_out',
    },

];

export const dataWorkExperienceValidationSchema = Yup.object({
    company_name: Yup.string().required('Required'),
    position: Yup.string().required('Required'),
    salary: Yup.string().required('Required'),
    year_start: Yup.string().required('Required'),
    year_out: Yup.string().required('Required'),
    reason_out: Yup.string().required('Required'),
});

export const dataWorkExperienceFields = [
    {
        control: 'input',
        type: 'text',
        label: 'Nama Perusahaan',
        name: 'company_name',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Jabatan',
        name: 'position',
    },

    {
        control: 'format-number',
        type: 'number',
        label: 'Pendapatan',
        name: 'salary',
    },
    {
        control: 'input',
        type: 'number',
        label: 'Tahun Masuk',
        name: 'year_start',
    },
    {
        control: 'input',
        type: 'number',
        label: 'Tahun Selesai',
        name: 'year_out',
    },
    {
        control: 'input',
        type: 'text',
        label: 'Alasan Keluar',
        name: 'reason_out',
    }

];