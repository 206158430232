import styled from 'styled-components';

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* background-color: #aaa; */
    @media (max-width: 350px) {
        margin-bottom: 30px;
    }
`;