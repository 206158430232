import { Form, Formik, getIn } from 'formik';
import React, { useEffect, useState } from 'react'
import FormControl from '../../../../components/atoms/Form';
import { WrapperBasicInformation } from './employee-detail.elements';
import * as Yup from 'yup'
import { Button, Gap } from '../../../../components';
import { dataContactFields, dataContactValidationSchema, dataDasarFields, dataDasarValidationSchema, dataEducationFields, dataEducationValidationSchema, dataFamilieFields, dataFamilieValidationSchema, dataWorkExperienceFields, dataWorkExperienceValidationSchema, loginDataFields, loginDataValidationSchema } from './fields';
import Modal from 'react-modal';
import swal from 'sweetalert';
import API from '../../../../config/api';
import { getEmployeeEducation } from '../../../../config/api/HR';
import { iconDelete1 } from '../../../../assets';
import { iconEdit } from '../../../../assets';

Modal.setAppElement('#root');
const token = localStorage.getItem('token');

function getStyle(errors, touched, fieldName) {
    if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
        return {
            border: '1px solid red',
            borderLeft: '5px solid red'
        }
    } else if (!getIn(errors, fieldName) && getIn(touched, fieldName)) {
        return {
            border: '1px solid green',

        }
    }
}





const BasicInformation = (props) => {
    const [formData, setFormData] = useState('');
    const [formFields, setFormFields] = useState([]);
    const [dataDasar, setDatadasar] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [schemaValidation, setSchemaValidation] = useState();
    const [modalFor, setModalFor] = useState('');
    const [isHaveLoginData, setIsHaveLoginData] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isAddOrEdit, setIsAddOrEdit] = useState('');
    const [message, setMessage] = useState('');
    // console.log(props);

    const handleClick = (e) => {
        // console.log(e.target.id);
        setFormData(e.target.id);
    }

    const handleClickDataLogin = (e) => {
        const id = e.target.id;
        setModalFor('Data Login')
        setFormFields(loginDataFields)
        setSchemaValidation(loginDataValidationSchema)
        if (id == 'buatDataLogin') {
            setInitialValues({
                name: props.employee.name,
                email: props.employee.email,
                password: '',
                repeat_password: '',
                role: 3,
            });
            setIsAddOrEdit('add');
            // console.log('tampilkan modal add data login')

        } else {
            setInitialValues({
                name: props.employee.name,
                email: props.employee.email,
                password: '',
                repeat_password: '',
                role: 3,

            });
            setIsAddOrEdit('edit');
            // console.log('tampilkan modal edit data login')

        }
        setModalIsOpen(true);
    }

    const handleClickDataDasar = (e) => {
        console.log('target', e.target)
        setModalFor('Data Dasar')
        setFormFields(dataDasarFields)
        setSchemaValidation(dataDasarValidationSchema)
        if (props?.dataDasar?.id) {
            setInitialValues({
                id: props?.dataDasar?.id,
                nik: props?.dataDasar?.nik,
                full_name: props?.dataDasar?.full_name,
                place_of_birth: props?.dataDasar?.place_of_birth,
                date_of_birth: props?.dataDasar?.date_of_birth,
                gender: props?.dataDasar?.gender,
                religion: props?.dataDasar?.religion,
                blood_type: props?.dataDasar?.blood_type,
                marital_status: props?.dataDasar?.marital_status,
                photo: '',

            });
            setIsAddOrEdit('edit');
        } else {
            setInitialValues({
                nik: '',
                full_name: props.employee.name,
                place_of_birth: '',
                date_of_birth: '',
                gender: '',
                religion: '',
                blood_type: '',
                marital_status: '',
                photo: '',

            });
            setIsAddOrEdit('add');
        }
        setModalIsOpen(true);
    }
    const handleClickDataContact = (e) => {
        console.log('target', e.target)
        setModalFor('Data Contact')
        setFormFields(dataContactFields)
        setSchemaValidation(dataContactValidationSchema)
        if (props?.dataContact?.id) {
            setInitialValues({
                id: props?.dataContact?.id,
                address_identity_card: props?.dataContact?.address_identity_card,
                personal_email: props?.dataContact?.personal_email,
                work_email: props?.dataContact?.work_email,
                personal_phone: props?.dataContact?.personal_phone,
                work_phone: props?.dataContact?.work_phone,
                darurat_phone_1: props?.dataContact?.darurat_phone_1,
                darurat_phone_2: props?.dataContact?.darurat_phone_2,
                name_darurat_contact_1: props?.dataContact?.name_darurat_contact_1,
                name_darurat_contact_2: props?.dataContact?.name_darurat_contact_2,
                relation_darurat_contact_1: props?.dataContact?.relation_darurat_contact_1,
                relation_darurat_contact_2: props?.dataContact?.relation_darurat_contact_2,
                address_darurat_contact_1: props?.dataContact?.address_darurat_contact_1,
                address_darurat_contact_2: props?.dataContact?.address_darurat_contact_2,

            });
            setIsAddOrEdit('edit');
        } else {
            setInitialValues({
                address_identity_card: '',
                personal_email: '',
                work_email: '',
                personal_phone: '',
                work_phone: '',
                darurat_phone_1: '',
                darurat_phone_2: '',
                name_darurat_contact_1: '',
                name_darurat_contact_2: '',
                relation_darurat_contact_1: '',
                relation_darurat_contact_2: '',
                address_darurat_contact_1: '',
                address_darurat_contact_2: '',

            });
            setIsAddOrEdit('add');
        }
        setModalIsOpen(true);
    }
    const handleClickDataFamilie = (e) => {
        console.log('target', e.target)
        setModalFor('Data Familie')
        setFormFields(dataFamilieFields)
        setSchemaValidation(dataFamilieValidationSchema)
        if (props?.dataFamilie?.id) {
            setInitialValues({
                id: props?.dataFamilie?.id,
                father_name: props?.dataFamilie?.father_name,
                mother_name: props?.dataFamilie?.mother_name,
                couple_name: props?.dataFamilie?.couple_name || '',
                child_name_1: props?.dataFamilie?.child_name_1 || '',
                child_name_2: props?.dataFamilie?.child_name_2 || '',
                child_name_3: props?.dataFamilie?.child_name_3 || '',
                couple_no_bpjs: props?.dataFamilie?.couple_no_bpjs || '',
                child_1_no_bpjs: props?.dataFamilie?.child_1_no_bpjs || '',
                child_2_no_bpjs: props?.dataFamilie?.child_2_no_bpjs || '',
                child_3_no_bpjs: props?.dataFamilie?.child_3_no_bpjs || '',
            });
            setIsAddOrEdit('edit');
        } else {
            setInitialValues({
                father_name: '',
                mother_name: '',
                couple_name: '',
                child_name_1: '',
                child_name_2: '',
                child_name_3: '',
                couple_no_bpjs: '',
                child_1_no_bpjs: '',
                child_2_no_bpjs: '',
                child_3_no_bpjs: '',

            });
            setIsAddOrEdit('add');
        }
        setModalIsOpen(true);
    }

    const handleClickEducation = (item) => {
        console.log('target', item)
        setModalFor('Data Education')
        setFormFields(dataEducationFields)
        setSchemaValidation(dataEducationValidationSchema)
        if (item?.id != null) {
            setInitialValues({
                id: item?.id,
                degree: item?.degree,
                institute_name: item?.institute_name,
                major: item?.major,
                year_start: item?.year_start,
                year_out: item?.year_out,
            });
            setIsAddOrEdit('edit');
        } else {
            setInitialValues({
                degree: '',
                institute_name: '',
                major: '',
                year_start: '',
                year_out: '',

            });
            setIsAddOrEdit('add');
        }
        setModalIsOpen(true);
    }
    const handleClickWorkExperience = (item) => {
        console.log('target', item)
        setModalFor('Data Work Experience')
        setFormFields(dataWorkExperienceFields)
        setSchemaValidation(dataWorkExperienceValidationSchema)
        if (item?.id != null) {
            setInitialValues({
                id: item?.id,
                company_name: item?.company_name,
                position: item?.position,
                salary: item?.salary,
                reason_out: item?.reason_out,
                year_start: item?.year_start,
                year_out: item?.year_out,
            });
            setIsAddOrEdit('edit');
        } else {
            setInitialValues({
                company_name: '',
                position: '',
                salary: '',
                year_start: '',
                year_out: '',
                reason_out: '',

            });
            setIsAddOrEdit('add');
        }
        setModalIsOpen(true);
    }

    const handleDelete = (row) => {
        // console.log(row)
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this employee!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    // console.log('Call API delete Departemen');
                    return API.deleteEmployeeEducation(token, row.id).then(res => {
                        swal({
                            title: 'Berhasil',
                            text: 'Education berhasil dihapus',
                            icon: "success",
                        });
                        // console.log('call branch Data lagi');
                        //apa supaya tertriger use effectnya
                        props?.getDataEducation()
                    }).catch(err => {
                        console.log(err);
                        // setLoading(false);
                    })
                }
                else {
                    //   swal("Your imaginary file is safe!");
                    // setLoading(false);

                }
            });
    }
    const handleDeleteWorkExperience = (row) => {
        // console.log(row)
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this employee work experience!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    // console.log('Call API delete Departemen');
                    return API.deleteEmployeeWorkExperience(token, row.id).then(res => {
                        swal({
                            title: 'Berhasil',
                            text: 'Education berhasil dihapus',
                            icon: "success",
                        });
                        // console.log('call branch Data lagi');
                        //apa supaya tertriger use effectnya
                        props?.getDataWorkExperience()
                    }).catch(err => {
                        console.log(err);
                        // setLoading(false);
                    })
                }
                else {
                    //   swal("Your imaginary file is safe!");
                    // setLoading(false);

                }
            });
    }


    const onSubmit = values => {
        // console.log('Form data', values) 
        // console.log('Saved data', JSON.parse(JSON.stringify(values)))

        switch (modalFor) {
            case 'Data Login':
                if (isAddOrEdit == 'add') {
                    // console.log('request untuk menambah data login karyawan')
                    API.userCreate(token, values).then(res => {
                        // console.log(res)
                        setMessage('Data Login Berhasil Ditambahkan');
                        swal({
                            title: 'Berhasil',
                            text: `Data Login ${props.employee.name} Berhasil Ditambahkan`,
                            icon: "success",
                        });
                        setModalIsOpen(false);
                        setModalFor('');
                        setIsHaveLoginData(true);
                    }).catch(err => {
                        console.log(err.message)
                        setMessage(err.message.data.message)
                        swal({
                            title: err.status,
                            text: err.response.data.message,
                            icon: "error",
                        });
                        setModalIsOpen(false);
                        setModalFor('');
                        setIsHaveLoginData(false);

                    });
                } else {
                    // console.log('request untuk mengedit data login karyawan')
                    API.userChangePassword(token, values).then(res => {
                        // console.log(res)

                        swal({
                            title: 'Berhasil',
                            text: `Data Login ${props.employee.name} Berhasil Diubah`,
                            icon: "success",
                        });
                        setModalIsOpen(false);
                        setModalFor('');
                        setIsHaveLoginData(true);
                    }).catch(err => {
                        swal({
                            title: err.status,
                            text: err.response.data.message,
                            icon: "error",
                        });
                        setModalIsOpen(false);
                        setModalFor('');
                        setIsHaveLoginData(false);

                    });
                }
                break;
            case 'Data Dasar':
                event.preventDefault();  // Mencegah reload halaman

                const fileInput = document.querySelector('input[type="file"]');  // Ambil elemen input file

                // Membuat FormData dan menambahkan data yang dibutuhkan
                const formData = new FormData();
                formData.append('photo', fileInput.files[0]);  // Tambahkan file
                formData.append('nik', values.nik);  // Tambahkan field lainnya jika ada
                formData.append('full_name', values.full_name);  // Tambahkan field lainnya jika ada
                formData.append('place_of_birth', values.place_of_birth);  // Tambahkan field lainnya jika ada
                formData.append('date_of_birth', values.date_of_birth);  // Tambahkan field lainnya jika ada
                formData.append('gender', values.gender);  // Tambahkan field lainnya jika ada
                formData.append('religion', values.religion);  // Tambahkan field lainnya jika ada
                formData.append('blood_type', values.blood_type);  // Tambahkan field lainnya jika ada
                formData.append('marital_status', values.marital_status);  // Tambahkan field lainnya jika ada
                if (isAddOrEdit == 'add') {
                    API.addEmployeePersonalDetail(token, formData, props.employee.id)
                        .then(res => {
                            setMessage('Data Dasar Berhasil Ditambahkan');
                            swal({
                                title: 'Berhasil',
                                text: `Data Dasar ${props.employee.name} Berhasil Ditambahkan`,
                                icon: "success",
                            });
                            props?.getDataDasar()
                            setModalIsOpen(false);
                            setModalFor('');
                        })
                        .catch(err => {
                            console.log(err.message);
                            setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                            swal({
                                title: err.response?.status || 'Error',
                                text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                                icon: "error",
                            });
                            setModalIsOpen(false);
                            setModalFor('');
                        });
                } else {
                    formData.append('id', values?.id || '');  // Tambahkan field lainnya jika ada
                    API.editEmployeePersonalDetail(token, formData, props?.dataDasar?.id).then(res => {
                        // console.log(res)

                        swal({
                            title: 'Berhasil',
                            text: `Data Dasar ${props.employee.name} Berhasil Diubah`,
                            icon: "success",
                        });
                        props?.getDataDasar()
                        setModalIsOpen(false);
                        setModalFor('');
                    }).catch(err => {
                        swal({
                            title: err.status,
                            text: err.response.data.message,
                            icon: "error",
                        });
                        setModalIsOpen(false);
                        setModalFor('');

                    });
                }
                break;
            case 'Data Contact':
                if (isAddOrEdit == 'add') {
                    API.addEmployeeContact(token, values, props.employee.id)
                        .then(res => {
                            setMessage('Data Education Berhasil Ditambahkan');
                            swal({
                                title: 'Berhasil',
                                text: `Data Contact ${props.employee.name} Berhasil Ditambahkan`,
                                icon: "success",
                            });
                            props?.getDataContact()
                            setModalIsOpen(false);
                            setModalFor('');
                        })
                        .catch(err => {
                            console.log(err.message);
                            setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                            swal({
                                title: err.response?.status || 'Error',
                                text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                                icon: "error",
                            });
                            setModalIsOpen(false);
                            setModalFor('');
                        });
                } else {
                    API.editEmployeeContact(token, values, props?.dataContact?.id).then(res => {
                        // console.log(res)

                        swal({
                            title: 'Berhasil',
                            text: `Data Contact ${props.employee.name} Berhasil Diubah`,
                            icon: "success",
                        });
                        props?.getDataContact()
                        setModalIsOpen(false);
                        setModalFor('');
                    }).catch(err => {
                        swal({
                            title: err.status,
                            text: err.response.data.message,
                            icon: "error",
                        });
                        setModalIsOpen(false);
                        setModalFor('');

                    });
                }
                break;
            case 'Data Familie':
                if (isAddOrEdit == 'add') {
                    API.addEmployeeFamilie(token, values, props.employee.id)
                        .then(res => {
                            setMessage('Data Education Berhasil Ditambahkan');
                            swal({
                                title: 'Berhasil',
                                text: `Data Familie ${props.employee.name} Berhasil Ditambahkan`,
                                icon: "success",
                            });
                            props?.getDataFamilie()
                            setModalIsOpen(false);
                            setModalFor('');
                        })
                        .catch(err => {
                            console.log(err.message);
                            setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                            swal({
                                title: err.response?.status || 'Error',
                                text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                                icon: "error",
                            });
                            setModalIsOpen(false);
                            setModalFor('');
                        });
                } else {
                    API.editEmployeeFamilie(token, values, props?.dataFamilie?.id).then(res => {
                        // console.log(res)

                        swal({
                            title: 'Berhasil',
                            text: `Data Familie ${props.employee.name} Berhasil Diubah`,
                            icon: "success",
                        });
                        props?.getDataFamilie()
                        setModalIsOpen(false);
                        setModalFor('');
                    }).catch(err => {
                        swal({
                            title: err.status,
                            text: err.response.data.message,
                            icon: "error",
                        });
                        setModalIsOpen(false);
                        setModalFor('');

                    });
                }
                break;
            case 'Data Education':
                if (isAddOrEdit == 'add') {
                    API.addEmployeeEducation(token, values, props.employee.id)
                        .then(res => {
                            setMessage('Data Education Berhasil Ditambahkan');
                            swal({
                                title: 'Berhasil',
                                text: `Data Education ${props.employee.name} Berhasil Ditambahkan`,
                                icon: "success",
                            });
                            props?.getDataEducation()
                            setModalIsOpen(false);
                            setModalFor('');
                        })
                        .catch(err => {
                            console.log(err.message);
                            setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                            swal({
                                title: err.response?.status || 'Error',
                                text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                                icon: "error",
                            });
                            setModalIsOpen(false);
                            setModalFor('');
                        });
                } else {
                    API.editEmployeeEducation(token, values, values?.id).then(res => {
                        // console.log(res)

                        swal({
                            title: 'Berhasil',
                            text: `Data Education ${props.employee.name} Berhasil Diubah`,
                            icon: "success",
                        });
                        props?.getDataEducation()
                        setModalIsOpen(false);
                        setModalFor('');
                    }).catch(err => {
                        swal({
                            title: err.status,
                            text: err.response.data.message,
                            icon: "error",
                        });
                        setModalIsOpen(false);
                        setModalFor('');

                    });
                }
                break;
            case 'Data Work Experience':
                if (isAddOrEdit == 'add') {
                    API.addEmployeeWorkExperience(token, values, props.employee.id)
                        .then(res => {
                            setMessage('Data WorkExperience Berhasil Ditambahkan');
                            swal({
                                title: 'Berhasil',
                                text: `Data WorkExperience ${props.employee.name} Berhasil Ditambahkan`,
                                icon: "success",
                            });
                            props?.getDataWorkExperience()
                            setModalIsOpen(false);
                            setModalFor('');
                        })
                        .catch(err => {
                            console.log(err.message);
                            setMessage(err.response?.data?.message || 'Terjadi kesalahan');
                            swal({
                                title: err.response?.status || 'Error',
                                text: err.response?.data?.message || 'Terjadi kesalahan saat mengunggah data',
                                icon: "error",
                            });
                            setModalIsOpen(false);
                            setModalFor('');
                        });
                } else {
                    API.editEmployeeWorkExperience(token, values, values?.id).then(res => {
                        // console.log(res)

                        swal({
                            title: 'Berhasil',
                            text: `Data WorkExperience ${props.employee.name} Berhasil Diubah`,
                            icon: "success",
                        });
                        props?.getDataWorkExperience()
                        setModalIsOpen(false);
                        setModalFor('');
                    }).catch(err => {
                        swal({
                            title: err.status,
                            text: err.response.data.message,
                            icon: "error",
                        });
                        setModalIsOpen(false);
                        setModalFor('');

                    });
                }
                break;

            default:
                break;
        }
    }


    const loginDataElement = (header, body, footer, status) => (
        <>
            <div>
                <h3>{header}</h3>
            </div>
            <p className={status ? null : "tidak-punya-akun"}>
                {body}
            </p>
            <Gap height={15} />
            <div>
                <button className={status ? 'edit-button' : 'add-button'} id={status ? 'editDataLogin' : 'buatDataLogin'} onClick={handleClickDataLogin}>{footer}</button>
            </div>
        </>
    )



    useEffect(() => {
        API.getEmployeeByEmail(token, props.employee.email).then(res => {
            // console.log(res)
            setIsHaveLoginData(true);
        }).catch(err => {
            // console.log(err.response)
            setIsHaveLoginData(false);

        })

    }, [])
    // informasi dasar karyawan id {props.employee.id}
    function getStyle(errors, touched, fieldName) {
        if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
            return {
                border: '1px solid red',
                borderLeft: '5px solid red'
            }
        } else if (!getIn(errors, fieldName) && getIn(touched, fieldName)) {
            return {
                border: '1px solid green',

            }
        }
    }


    return (
        <>
            <WrapperBasicInformation>


                <div className="data-basic">
                    <div className="header-data">
                        <p>Data Dasar</p>
                        <button type="submit" onClick={handleClickDataDasar} className="edit-button-employee-detail">{props?.dataDasar?.nik ? "Edit" : "Tambah"}</button>
                    </div>

                    <div className="content-data">
                        <div className="data-list-basic">
                            <p>NIK</p>
                            <p>{props.employee.nik}</p>
                        </div>
                        <div className="data-list-basic">
                            <p>No.KTP</p>
                            <p>{props.dataDasar.nik || '-'}</p>
                        </div>
                        <div className="data-list-basic">
                            <p>Nama</p>
                            <p>{props.employee.name || '-'}</p>
                        </div>
                        <div className="data-list-basic">
                            <p>Email</p>
                            <p>{props.employee.email || '-'}</p>
                        </div>
                        <div className="data-list-basic">
                            <p>Jenis Kelamin</p>
                            <p>{props?.dataDasar?.gender || '-'}</p>
                        </div>
                        <div className="data-list-basic">
                            <p>Agama</p>
                            <p>{props?.dataDasar?.religion || '-'}</p>
                        </div>
                        <div className="data-list-basic">
                            <p>Tempat Lahir</p>
                            <p>{props?.dataDasar?.place_of_birth || '-'}</p>
                        </div>
                        <div className="data-list-basic">
                            <p>Tanggal Lahir</p>
                            <p>{props?.dataDasar?.date_of_birth || '-'}</p>
                        </div>
                        <div className="data-list-basic">
                            <p>Gol Darah</p>
                            <p>{props?.dataDasar?.blood_type || '-'}</p>
                        </div>
                        <div className="data-list-basic">
                            <p>Status Pernikahan</p>
                            <p>{props?.dataDasar?.marital_status == 1 ? "Belum Menikah" : props?.dataDasar?.marital_status == 2 ? "Menikah" : props?.dataDasar?.marital_status == 3 ? "Cerai Hidup" : props?.dataDasar?.marital_status == 4 ? "Cerai Mati" : props?.dataDasar?.marital_status == 5 ? "Janda/Duda" : '-'}</p>
                        </div>
                        <div className="data-list-basic">
                            <p>Departemen</p>
                            <p>{props.employee.division_name || '-'}</p>
                        </div>
                        <div className="data-list-basic">
                            <p>Jabatan</p>
                            <p>{props.employee.position_name || '-'}</p>
                        </div>
                        <div className="data-list-basic">
                            <p>Tim/Grup</p>
                            <p>{props.employee.group_name || '-'}</p>
                        </div>
                        <div className="data-list-basic">
                            <p>Status</p>
                            <p>{props.employee.employment_status || '-'}</p>
                        </div>
                        <div className="data-list-basic">
                            <p>Mulai Bekerja</p>
                            <p>{props.employee.start_work || '-'}</p>
                        </div>


                        {/* seperti di halaman dashboard list telat tu */}

                    </div>
                </div>



                <div className="data-contact">
                    <div className="header-data-contact">
                        <p>Data Contact</p>
                        <button type="submit" onClick={handleClickDataContact} className="edit-button-employee-detail">{props?.dataContact?.address_identity_card ? "Edit" : "Tambah"}</button>
                    </div>

                    <div className="content-data">
                        <div className="data-list">
                            <p>Alamat KTP</p>
                            <p>{props?.dataContact?.address_identity_card || '-'}</p>
                        </div>
                        <div className="data-list">
                            <p>Email Pribadi</p>
                            <p>{props?.dataContact?.personal_email || '-'}</p>
                        </div>
                        <div className="data-list">
                            <p>Email Kerja</p>
                            <p>{props?.dataContact?.work_email || '-'}</p>
                        </div>
                        <div className="data-list">
                            <p>Nomor Pribadi</p>
                            <p>{props?.dataContact?.personal_phone || '-'}</p>
                        </div>
                        <div className="data-list">
                            <p>Nomor Kerja</p>
                            <p>{props?.dataContact?.work_phone || '-'}</p>
                        </div>
                        <hr />
                        <div style={{ display: "flex" }}>
                            <div style={{ width: "50%" }}>
                                <p>Kontak Darurat 1</p>
                                <div className="data-list">
                                    <p>Nama</p>
                                    <p>{props?.dataContact?.name_darurat_contact_1 || '-'}</p>
                                </div>
                                <div className="data-list">
                                    <p>Nomor Telepon</p>
                                    <p>{props?.dataContact?.darurat_phone_1 || '-'}</p>
                                </div>
                                <div className="data-list">
                                    <p>Alamat</p>
                                    <p>{props?.dataContact?.address_darurat_contact_1 || '-'}</p>
                                </div>
                                <div className="data-list">
                                    <p>Hubungan</p>
                                    <p>{props?.dataContact?.relation_darurat_contact_1 || '-'}</p>
                                </div>
                            </div>
                            <div style={{ width: "50%" }}>
                                <p>Kontak Darurat 2</p>
                                <div className="data-list">
                                    <p>Nama</p>
                                    <p>{props?.dataContact?.name_darurat_contact_2 || '-'}</p>
                                </div>
                                <div className="data-list">
                                    <p>Nomor Telepon</p>
                                    <p>{props?.dataContact?.darurat_phone_2 || '-'}</p>
                                </div>
                                <div className="data-list">
                                    <p>Alamat</p>
                                    <p>{props?.dataContact?.address_darurat_contact_2 || '-'}</p>
                                </div>
                                <div className="data-list">
                                    <p>Hubungan</p>
                                    <p>{props?.dataContact?.relation_darurat_contact_2 || '-'}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="data-work-experience">
                    <div className="header-data-work-experience">
                        <p>data pengalaman kerja</p>
                        <button type="submit" onClick={handleClickWorkExperience} className="edit-button-employee-detail">Tambah</button>
                    </div>
                    <div className='content-data' >
                        {
                            props?.dataWorkExperience?.map((item, index) => (
                                <div className='data-repeat' key={index}>
                                    <div className='edit-update'>
                                        <p>Pengalaman ke-{index + 1}</p>
                                        <div>
                                            <button type="submit" onClick={() => { handleClickWorkExperience(item) }} style={{ marginRight: "0px", marginTop: '-7px' }}><img src={iconEdit} width={"15px"} /></button>
                                            <button type="submit" onClick={() => { handleDeleteWorkExperience(item) }} style={{ marginRight: "15px", marginTop: '-7px' }}><img src={iconDelete1} width={"15px"} /></button>
                                        </div>
                                    </div>
                                    <div className="data-list">
                                        <p>Nama Perusahaan</p>
                                        <p>{item?.company_name}</p>
                                    </div>
                                    <div className="data-list">
                                        <p>Jabatan</p>
                                        <p>{item?.position}</p>
                                    </div>
                                    <div className="data-list">
                                        <p>Tahun Kerja</p>
                                        <p>{item?.year_start} - {item?.year_out}</p>
                                    </div>
                                    <div className="data-list">
                                        <p>Gaji</p>
                                        <p>{item?.salary}</p>
                                    </div>
                                    <div className="data-list">
                                        <p>Alasan Keluar</p>
                                        <p>{item?.reason_out}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                {/* <div className="data-work-experience">
                    data alamat karyawan
                </div> */}
                <div className="data-family-members">
                    <div className="header-data-familie">
                        <p>Data Familie</p>
                        <button type="submit" onClick={handleClickDataFamilie} className="edit-button-employee-detail">{props?.dataFamilie?.father_name ? "Edit" : "Tambah"}</button>
                    </div>
                    <div className="content-data">
                        <div className="data-list">
                            <p>Nama Bapa</p>
                            <p>{props?.dataFamilie?.father_name || '-'}</p>
                        </div>
                        <div className="data-list">
                            <p>Nama Ibu</p>
                            <p>{props?.dataFamilie?.mother_name || '-'}</p>
                        </div>
                        <div className="data-list">
                            <p>Nama Pasangan</p>
                            <p>{props?.dataFamilie?.couple_name || '-'}</p>
                        </div>
                        <div className="data-list">
                            <p>BPJS Pasangan</p>
                            <p>{props?.dataFamilie?.couple_no_bpjs || '-'}</p>
                        </div>
                        <hr />
                        <div style={{ display: "grid" }}>
                            <div>
                                <p>Anak ke-1</p>
                                <div className="data-list">
                                    <p>Nama</p>
                                    <p>{props?.dataFamilie?.child_name_1 || '-'}</p>
                                </div>
                                <div className="data-list">
                                    <p>BPJS</p>
                                    <p>{props?.dataFamilie?.child_1_no_bpjs || '-'}</p>
                                </div>
                            </div>
                            <div>
                                <p>Anak ke-2</p>
                                <div className="data-list">
                                    <p>Nama</p>
                                    <p>{props?.dataFamilie?.child_name_2 || '-'}</p>
                                </div>
                                <div className="data-list">
                                    <p>BPJS</p>
                                    <p>{props?.dataFamilie?.child_2_no_bpjs || '-'}</p>
                                </div>
                            </div>
                            <div>
                                <p>Anak ke-3</p>
                                <div className="data-list">
                                    <p>Nama</p>
                                    <p>{props?.dataFamilie?.child_name_3 || '-'}</p>
                                </div>
                                <div className="data-list">
                                    <p>BPJS</p>
                                    <p>{props?.dataFamilie?.child_3_no_bpjs || '-'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="data-education-background">
                    <div className="header-data-education-background">
                        <p>Data riwayat pendidikan karyawan</p>
                        <button type="submit" onClick={handleClickEducation} className="edit-button-employee-detail">Tambah</button>
                    </div>
                    <div className="content-data">
                        {
                            props?.dataEducation?.map((item, index) => (
                                <div className='data-repeat' key={index}>
                                    <div>
                                        <div className='edit-update'>
                                            <p>{item?.degree}</p>
                                            <div>
                                                <button type="submit" onClick={() => { handleClickEducation(item) }} style={{ marginRight: "0px", marginTop: '-7px' }}><img src={iconEdit} width={"15px"} /></button>
                                                <button type="submit" onClick={() => { handleDelete(item) }} style={{ marginRight: "15px", marginTop: '-7px' }}><img src={iconDelete1} width={"15px"} /></button>
                                            </div>
                                        </div>
                                        <div className="data-list">
                                            <p>{item?.institute_name}</p>
                                            <p>{item?.major}</p>
                                            <p>({item?.year_start} - {item?.year_out})</p>
                                        </div>
                                    </div>
                                </div>
                                // <div className="data-list" style={{ width: "100%", display: "flex" }} key={index}>
                                //     <p>{item?.degree}</p>
                                //     <p>{item?.institute_name}</p>
                                //     <p style={{ fontWeight: "normal", textAlign: "center" }}>{item?.major}</p>
                                //     <p style={{ fontWeight: "normal" }}>({item?.year_start} - {item?.year_out})</p>
                                //     <button type="submit" onClick={() => { handleClickEducation(item) }} style={{ marginRight: "0px" }}><img src={iconEdit} width={"15px"} /></button>
                                //     <button type="submit" onClick={() => { handleDelete(item) }} style={{ marginRight: "15px" }}><img src={iconDelete1} width={"15px"} /></button>
                                // </div>
                            ))
                        }
                    </div>
                </div>
                {/* <div className="data-work-experience">
                    data pengalaman kerja karyawan
                </div> */}
                <div className="data-login">
                    {
                        //jika tidak ada akun maka tampilkan tmabah akun, jika ada, maka tampilkan dibawah ini
                    }
                    {
                        // isHaveLoginData ?  haveLoginData : notHaveLoginData
                    }
                    {
                        isHaveLoginData ? loginDataElement('Data Login', props.employee.email, 'Atur Ulang Password', true) : loginDataElement('Data Login', 'Karyawan ini belum mempunyai akun login', 'Buatkan Akun', false)
                    }


                </div>

            </WrapperBasicInformation >
            <Modal
                onRequestClose={() => setModalIsOpen(false)}
                isOpen={modalIsOpen}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    },
                    content: {
                        border: '1px solid #222',
                        padding: 0,
                        top: '10vh', // Sesuaikan dengan viewport height
                        left: '2vw', // Sesuaikan dengan viewport width
                        right: '2vw',
                        bottom: '10vh',
                        maxWidth: '90vw', // Membatasi lebar modal agar tidak terlalu besar
                        maxHeight: modalFor == 'Data Education' ? '60vh' : modalFor == 'Data Work Experience' ? '70vh' : modalFor == 'Data Login' ? '40vh' : '80vh', // Membatasi tinggi modal agar tidak keluar dari layar
                        margin: 'auto',
                    },
                }}
            >
                <div className="modal-container">
                    <div className="modal-header">
                        <h2 className="modal-title">{(isAddOrEdit == 'add') ? 'Tambah' : 'Edit'} {modalFor}</h2>
                        <button className="close-modal" onClick={() => setModalIsOpen(false)}>X</button>
                    </div>
                    <Formik enableReinitialize initialValues={initialValues} validationSchema={schemaValidation} onSubmit={onSubmit} >
                        {({ errors, touched, isValid }) => (
                            <Form>
                                <div className="modal-body">
                                    <div className="form-row">
                                        {
                                            formFields.map(field => (
                                                // console.log(field)
                                                <FormControl key={field.name}
                                                    control={field.control}
                                                    type={field.type}
                                                    label={field.label}
                                                    name={field.name}
                                                    style={getStyle(errors, touched, field.name)}
                                                    options={field.options}
                                                    {...field}
                                                />
                                            ))
                                        }
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <Button buttonFull buttonColor='var(--green)' align="right" buttonHover type="submit" disabled={!isValid || props.isLoading} className={props.isLoading ? 'btnLoading' : null}>{(isAddOrEdit == 'add') ? 'Tambah' : 'Edit'}</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </div>
            </Modal>
        </>
    )
}

export default BasicInformation
