import React from 'react'
import { Icon } from '..';
import { iconLeft } from '../../../assets';

const Letter = ({type, value, setPageName, company}) => {
    //blanko lembur dan cuti
    switch (type) {
        case 'loan':
            return (
                <>
                    <div onClick={() => setPageName("Laporan Pengajuan Pinjaman Disetujui & Tidak Disetujui")} className="back-button" >                    
                        <Icon icon={iconLeft} color="#fff" />
                        <p>Back</p>
                    </div>
                    <div>
                        
                        surat pinnjaman {value.id === 0 ? 'kosong' : 'detail'}
                    </div>
                    
                </>
            )
            
            break;
        case 'warning':
            return (
                <>
                    <div onClick={() => setPageName("Laporan Surat Peringatan")} className="back-button" >                    
                        <Icon icon={iconLeft} color="#fff" />
                        <p>Back</p>
                    </div>
                    <div>
                        
                        surat peringatan {value.id === 0 ? 'kosong' : 'detail'}
                    </div>
                    
                </>
            )
        default:
            break;
    }
}

export default Letter
