import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ContainerCompanyInfo = styled.div`
    h3 {
        font-size: 17px;
        padding-left: 20px;
        /* padding-top: 10px; */
        padding-bottom: 10px;
    }
`;
export const ContainerSettingBPJS = styled.div`

    h3 {
        font-size: 17px;
        padding-left: 20px;
        /* padding-top: 10px; */
        padding-bottom: 10px;
    }
    

`
export const ContainerSettingPPh21 = styled.div`


    h3 {
        font-size: 17px;
        padding-bottom: 10px;
        margin-right: 50px;
        border-bottom: 1px solid var(--secondary-color);
        margin-bottom: 10px;

    }

    

`

