import React, { useEffect, useRef, useState } from 'react'
import {
    Col, DashboardHeader,
    Gap, Icon, PageHeader, Row,
    InfoChart, InfoKehadiranUser, InfoTitle,
    SectionInfoCuti,
    SectionInfoKehadiran,
    SectionPemberitahuan,
    SPButton,
    SPDesc,
    SPDetail,
    SPImg,
    SPSubTitle,
    SPTitle,
    UserPhoto,
    UserProfile,
    SectionText,
    SectionLink,
    SectionInfoSakit,

} from '../../../components'
import { connect } from 'react-redux'
import { iconSick, iconUser, iconLate, iconCalendar, notifImg, iconCutTime, iconExchange, iconOverTime, iconLoan, iconEdit, iconLeft, iconRight, Avatar, LoginBg, Logo } from '../../../assets'
import { setLoading } from '../../../config/redux/action'
import CircleChart from '../../../components/atoms/CircleChart'
import { greeting } from '../../../utils/helpers/greeting'
import { HRDashboardContainer, SectionKaryawanTitle, KaryawanByGender, KaryawanByStatusKontrak, KaryawanByStatusNikah, KaryawanByCabang, RequestKaryawan, RequestDetail, BuatTugasKaryawan, KehadiranText, OverviewKehadiran, ListTidakHadir, ListTelat, RankKehadiran, RequestTitle, ListRequestKaryawan, RequestMenu, LinkRequestMenu, TitleSectionKaryawan, ContentSectionKaryawan, KaryawanKontrakBerakhir, KaryawanLembur, PulangAwal, KaryawanByStatusKontrakResponsive, EmployeeStatusContract, DoughnutContainer, KaryawanByCabangResponsive, DiagramContainer, DiagramContainerMobile, SmallAvatar, AvatarImage, SliderContainer, SlideItem, SliderWrapper, Title, ArrowButton, IndicatorDot, IndicatorContainer, Container, CardContainer, EmployeeCard, EmployeeName, ListSlide, CardContainerMobile, IndicatorContainerMobile, DoughnutContainerMobile, LeaveRecap } from './dashboard-hr.elements'
import { Link } from 'react-router-dom'
import { Doughnut, Pie } from 'react-chartjs-2'
// import Swiper core and required components
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import { dataRanking } from './data'
import API from '../../../config/api'
import { format_tanggal_indo, jam_menit_detik, tahun_bulan_tanggal } from '../../../utils/helpers/date'
import { attendanceText, buttonAttendanceHomeScreen } from '../../../utils/helpers/attendance'

// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

Chart.pluginService.register({
    beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
            //Get ctx from string
            var ctx = chart.chart.ctx;

            //Get options from the center object in options
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = centerConfig.fontStyle || 'Arial';
            var txt = centerConfig.text;
            var color = centerConfig.color || '#222';
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
            //Start with a base font of 30px
            ctx.font = "30px " + fontStyle;

            //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = (chart.innerRadius * 2);

            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(newFontSize, elementHeight);

            //Set font settings to draw it correctly.
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
            var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
            ctx.font = fontSizeToUse + "px " + fontStyle;
            ctx.fillStyle = color;

            //Draw text in center
            ctx.fillText(txt, centerX, centerY);
        }

    }


});

const TimeDifference = ({ time1, time2 }) => {
    const [late, setLate] = useState('');

    useEffect(() => {
        calculateDifference(time1, time2);
    }, [time1, time2]);

    const calculateDifference = (time1, time2) => {
        // Convert times to Date objects
        const date1 = new Date(`1970-01-01T${time1}Z`);
        const date2 = new Date(`1970-01-01T${time2}Z`);

        // Calculate difference in milliseconds
        const difference = Math.abs(date2 - date1);

        // Convert difference to seconds, minutes, hours
        const diffSeconds = Math.floor(difference / 1000);
        const diffMinutes = Math.floor(diffSeconds / 60);
        const diffHours = Math.floor(diffMinutes / 60);

        // Determine appropriate display format
        if (diffHours > 0) {
            setLate(`${diffHours} Jam`);
        } else if (diffMinutes > 0) {
            setLate(`${diffMinutes} Menit`);
        } else {
            setLate(`${diffSeconds} Detik`);
        }
    };

    return (
        <div>
            {late}
        </div>
    );
};

const HRDashboard = (props) => {
    const token = props.user.token;
    const email = props.user.email
    const employee = props.user.info
    console.log('user', props.user, employee)
    const today = new Date();
    const [selectedDate, setSelectedDate] = useState(tahun_bulan_tanggal(today));
    const [time, setTime] = useState(jam_menit_detik());
    const [attendanceData, setAttendanceData] = useState({});
    const [dashboardHr, setDashboardHr] = useState({
        employeeDevision: [],
        totalEmploye: 0,
        genderCount: {},
        martialCount: {},
        attendanceRecap: [],
        totalEmploymentStatus: {},
        leaveRecap: [],
        totalCotractEnd: 0,
        totalLeaveRequest: 0,
        totalPermitRequest: 0,
        totalLoanRequest: 0,
        totalOvertimeRequest: 0,
        totalMangkir: 0,
        recapAttendance: [],
        totalSchedule: 0,
        totalAttendance: 0,
        employeeOvertime: [],
        employeOutEarly: [],
        employeeRankAttendance: [],
        totalLate: 0,
        totalOff: 0,
        employeeLate: [],
        lateDivisi: [],
        employeeOff: [],
        offDivisi: []
    });

    const dataKehadiran = {
        labels: ['Hadir', 'Telat', 'Cuti', 'Sakit', 'Izin', 'Off'],
        datasets: [
            {
                data: [27, 4, 2, 3, 1, 3],
                backgroundColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 205, 86, 1)', 'rgba(60, 179, 113, .9)'],

            }
        ]

    };

    let dataAbsensi = dashboardHr?.recapAttendance?.map(item => item.total);
    let labelKehadiran = ['Hadir', 'Telat', 'Cuti', 'Sakit', 'Izin', 'Off'];
    let customLabels = dashboardHr?.recapAttendance?.map((item, index) => `${item?.label}: ${dataAbsensi[index]}`);

    const dataChartKehadiran = {
        labels: customLabels,
        datasets: [
            {
                label: "Ringkasan Kehadiran",
                backgroundColor: [
                    "rgba(60, 179, 113, .9)",
                    "#ff6384",
                    "#36a2eb",
                    "#ffce56",
                    "#cc65fe",
                    "#3d3333",
                ],
                data: dashboardHr?.recapAttendance?.map(item => item.total),
            }
        ]
    };

    const optionsChartKehadiranResponsive = {
        responsive: false,
        maintainAspectRatio: false,
        legend: {
            display: true,
            position: 'bottom',
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return currentValue + ' (' + percentage + '%)';
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        },
        elements: {
            center: {
                text: '70',
                sidePadding: 60
            }
        }
    };

    const optionsChartKehadiran = {
        responsive: false,
        maintainAspectRatio: false,
        legend: {
            display: true,
            position: 'right',
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return currentValue + ' (' + percentage + '%)';
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        },
        elements: {
            center: {
                text: '70',
                sidePadding: 60
            }
        }
    };


    const dataCabang = {
        labels: dashboardHr?.employeeDevision?.map(item => item.name),
        datasets: [
            {
                // label: 'Sales for 2020 (M)',
                data: dashboardHr?.employeeDevision?.map(item => item.count_divisi),
                backgroundColor: ['rgba(54, 162, 235, 1)', 'rgba(255, 205, 86, 1)', 'rgba(60, 179, 113, .9)', 'rgb(220, 20, 60)'],
            }
        ]
    };
    const optionsCabangResponsive = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: true,
            position: 'bottom',
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return currentValue + ' (' + percentage + '%)';
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        },
        elements: {
            center: {
                text: dashboardHr.totalEmploye,
                sidePadding: 60
            }
        }
    };

    const optionsCabang = {
        responsive: true,
        legend: {
            display: true,
            position: 'bottom',
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    var dataset = data.datasets[tooltipItem.datasetIndex];
                    var meta = dataset._meta[Object.keys(dataset._meta)[0]];
                    var total = meta.total;
                    var currentValue = dataset.data[tooltipItem.index];
                    var percentage = parseFloat((currentValue / total * 100).toFixed(1));
                    return currentValue + ' (' + percentage + '%)';
                },
                title: function (tooltipItem, data) {
                    return data.labels[tooltipItem[0].index];
                }
            }
        },
        elements: {
            center: {
                text: dashboardHr.totalEmploye,
                sidePadding: 60
            }
        }
    };
    const { history, isLoading, loading } = props;
    const [title, setTitle] = useState('');
    useEffect(() => {
        setTitle(greeting())
        // console.log(greeting())
        // document.title = "Dashboard | PBKM Web App"
    }, []);

    useEffect(() => {
        loading(true)
        setTimeout(() => {
            loading(false)
        }, 500)
        const interval = setInterval(() => {
            // console.log('This will run every second!');
            setTime(jam_menit_detik());
        }, 1000);

        return () => clearInterval(interval);
    }, [])


    const inputDate = (event) => {
        setSelectedDate(event.target.value);
    };

    console.log('selectedDate', selectedDate)
    useEffect(() => {
        API.getEmployeeChart(token, employee, tahun_bulan_tanggal(today), selectedDate).then((res) => {
            console.log('dataChart', res);
            setDashboardHr(res.data)
            setLoading(false);
        }).catch(err => {
            // console.log(err.response.data.message);
            // console.log(err);
            // setTableData(0);
            // setMessage(err.response.data.message || 'Karyawan tidak ditemukan');

            setLoading(false);
        })
        API.getMyTodayAttendanceData(token, employee.id, tahun_bulan_tanggal(today))
            .then(res => {
                setAttendanceData(res.data);
            })
            .catch(err => {
                console.error(err);
            });
    }, [selectedDate])
    const [activeIndex, setActiveIndex] = useState(0);
    const itemsPerPageMobile = 2;
    const itemsPerPage = 4;
    // Hitung total halaman berdasarkan jumlah data dan jumlah item per halaman
    const totalPages = Math.ceil(dashboardHr?.employeeRankAttendance?.length / itemsPerPage);
    const totalPagesMobile = Math.ceil(dashboardHr?.employeeRankAttendance?.length / itemsPerPageMobile);

    const handleNext = () => {
        if (activeIndex < totalPages - 1) {
            setActiveIndex(activeIndex + 1);
        }
    };

    const handlePrev = () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };

    const handleNextMobile = () => {
        if (activeIndex < totalPagesMobile - 1) {
            setActiveIndex(activeIndex + 1);
        }
    };

    const handlePrevMobile = () => {
        if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
        }
    };
    return (
        <>

            <PageHeader name={props.user.name} photo={iconUser} title={title} pathname={history.location.pathname} />
            <Gap height={20} />

            <HRDashboardContainer totalCuti={dashboardHr?.leaveRecap?.length}>
                <SectionPemberitahuan>
                    <SPDetail>
                        <SPTitle>Informasi & Pemberitahuan</SPTitle>
                        <SPSubTitle>{format_tanggal_indo(today)}, <span>{time}</span></SPSubTitle>
                        <SPDesc>
                            {(() => {
                                if (employee.need_attendance === 1) {
                                    // console.log('perlu absen')
                                    return attendanceText(time, attendanceData)
                                } else {
                                    // console.log('tidak perlu absen')
                                    return <p>Kamu tidak perlu melakukan absensi.</p>
                                }
                            })()}

                        </SPDesc>
                        {/* {
                          isHaveScheduleToday ? buttonPemberitahuanJamKerja(time, schedule.time_in, schedule.late_tolerance, schedule.break_start, schedule.break_duration, schedule.time_out, attendanceStatus, employee.track_location) 
                           : <SPButton to='/schedule'>Lihat Jadwal</SPButton>
                        } */}

                        {(() => {
                            if (employee.need_attendance === 1) {
                                // console.log('perlu absen')
                                //check apakah ada jadwal
                                return buttonAttendanceHomeScreen(time, attendanceData, SPButton, history)
                                // if(isHaveScheduleToday){
                                //     // console.log('ada jadwal')
                                //     return buttonPemberitahuanJamKerja()
                                // }else { //tidak ada jadwal
                                //     if(overtimeData){
                                //         return buttonPemberitahuanJamLembur()


                                //     }else {
                                //         return (
                                //             <SPButton to='/schedule'>Lihat Jadwal</SPButton>
                                //         )
                                //     }

                                // }

                            } else {
                                // console.log('tidak perlu absen')
                                return (
                                    <SPButton to='/schedule'>Lihat Jadwal</SPButton>
                                )
                            }
                        })()}
                    </SPDetail>
                    <SPImg src={notifImg} alt="notif-img" />

                </SectionPemberitahuan>
                <UserProfile>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <UserPhoto>
                            <img src={iconUser} alt="user-photo" className="user-photo" />
                        </UserPhoto>
                    </div>
                    <p className="user-name">{employee?.name}</p>
                    <p className="user-role">{employee?.position_name}</p>
                    {/* <p className="user-role">Lokasi Kerja karyawan</p> */}
                </UserProfile>
                <SectionInfoKehadiran>

                    <InfoKehadiranUser>
                        <div>
                            <p className="text-small primary">Kehadiran</p>
                            <p className="text-big">{dashboardHr?.attendanceRecap[0]?.total_attendance}</p>
                        </div>
                        <div>
                            <p className="text-small primary">Telat</p>
                            <p className="text-big">{dashboardHr?.attendanceRecap[0]?.total_late}</p>
                        </div>
                        <div>
                            <p className="text-small primary">Mangkir</p>
                            <p className="text-big">{dashboardHr?.totalMangkir}</p>
                        </div>
                    </InfoKehadiranUser>

                </SectionInfoKehadiran>
                <LeaveRecap>
                    {
                        dashboardHr?.leaveRecap?.map((lr, i) => (


                            i == 0 ? <SectionInfoCuti to="request/annual-leave">
                                <InfoTitle>
                                    <p className="text-small">{lr?.leave_type_name} terpakai</p>
                                    <p className="text-big">{lr?.total} <span> / {lr?.balance}</span></p>

                                    {/* <button>Ajukan cuti tahunan</button> */}


                                </InfoTitle>
                                <InfoChart>
                                    <CircleChart percentage={((lr?.total / lr?.balance) * 100).toFixed(0)} />
                                </InfoChart>

                            </SectionInfoCuti>

                                :
                                <SectionInfoSakit to="/request/sick-leave">
                                    <InfoTitle>
                                        <p className="text-small">{lr?.leave_type_name} terpakai</p>
                                        <p className="text-big">{lr?.total} <span> / {lr?.balance}</span></p>



                                    </InfoTitle>
                                    <InfoChart>
                                        <CircleChart percentage={((lr?.total / lr?.balance) * 100).toFixed(0)} />
                                    </InfoChart>

                                </SectionInfoSakit>




                        ))
                    }
                </LeaveRecap>
                <SectionKaryawanTitle>
                    <SectionText>Karyawan</SectionText>
                    <SectionLink to="/employee/overview">see all</SectionLink>
                </SectionKaryawanTitle>
                <KaryawanByGender>
                    <TitleSectionKaryawan>
                        Jenis Kelamin
                    </TitleSectionKaryawan>
                    <ContentSectionKaryawan>
                        <div className="male">
                            {/* <Icon icon={iconMale} color="var(--primary-color)" /> */}
                            <h4>{dashboardHr?.genderCount?.male}</h4>
                            <p>Laki-Laki</p>

                        </div>
                        <div className="female">
                            {/* <Icon icon={iconFemale} color="pink" /> */}
                            <h4>{dashboardHr?.genderCount?.female}</h4>
                            <p>Perempuan</p>

                        </div>

                    </ContentSectionKaryawan>

                </KaryawanByGender>
                <KaryawanByStatusNikah>
                    <TitleSectionKaryawan>
                        Status Nikah
                    </TitleSectionKaryawan>
                    <ContentSectionKaryawan>
                        <div>
                            <h4>{dashboardHr?.martialCount?.marry}</h4>
                            <p>Menikah</p>
                        </div>
                        <div>
                            <h4>{dashboardHr?.martialCount?.single}</h4>
                            <p>Single</p>
                        </div>
                    </ContentSectionKaryawan>
                </KaryawanByStatusNikah>
                <KaryawanByStatusKontrak>
                    <TitleSectionKaryawan>
                        Status Bekerja
                    </TitleSectionKaryawan>
                    <div>
                        <div>
                            <h3>{dashboardHr?.totalEmploymentStatus?.tetap}</h3>
                            <p>Tetap</p>
                        </div>
                        <div>
                            <h3>{dashboardHr?.totalEmploymentStatus?.kontrak}</h3>
                            <p>Kontrak</p>
                        </div>
                        <div>
                            <h3>{dashboardHr?.totalEmploymentStatus?.percobaan || 0}</h3>
                            <p>Percobaan</p>
                        </div>
                        <div>
                            <h3>{dashboardHr?.totalEmploymentStatus?.magang || 0}</h3>
                            <p>Magang</p>
                        </div>
                    </div>
                </KaryawanByStatusKontrak>
                <KaryawanByStatusKontrakResponsive>
                    <TitleSectionKaryawan>
                        Status Bekerja
                    </TitleSectionKaryawan>
                    <EmployeeStatusContract>
                        <div>
                            <h3>{dashboardHr?.totalEmploymentStatus?.tetap}</h3>
                            <p>Tetap</p>
                        </div>
                        <div>
                            <h3>{dashboardHr?.totalEmploymentStatus?.kontrak}</h3>
                            <p>Kontrak</p>
                        </div>
                        <div>
                            <h3>{dashboardHr?.totalEmploymentStatus?.percobaan || 0}</h3>
                            <p>Percobaan</p>
                        </div>
                        <div>
                            <h3>{dashboardHr?.totalEmploymentStatus?.magang || 0}</h3>
                            <p>Magang</p>
                        </div>
                    </EmployeeStatusContract>
                </KaryawanByStatusKontrakResponsive>

                <KaryawanKontrakBerakhir to="/employee/expiring-contract">
                    <TitleSectionKaryawan>
                        Kontrak Berakhir
                    </TitleSectionKaryawan>
                    <ContentSectionKaryawan>
                        <h2>{dashboardHr?.totalCotractEnd}</h2>
                        <p>Bulan Ini</p>
                    </ContentSectionKaryawan>
                </KaryawanKontrakBerakhir>

                <KaryawanByCabangResponsive>
                    <TitleSectionKaryawan color="var(--text)">
                        Total Karyawan
                    </TitleSectionKaryawan>
                    <ContentSectionKaryawan>
                        <DoughnutContainer>
                            <Doughnut data={dataCabang} options={optionsCabangResponsive} />
                        </DoughnutContainer>
                    </ContentSectionKaryawan>
                </KaryawanByCabangResponsive>

                <KaryawanByCabang>
                    <TitleSectionKaryawan>
                        Total Karyawan
                    </TitleSectionKaryawan>
                    <div>
                        <DoughnutContainerMobile>
                            <Doughnut data={dataCabang} options={optionsCabang} width={400} height={300} />
                        </DoughnutContainerMobile>
                        <DiagramContainer>
                            <Doughnut data={dataCabang} options={optionsCabang} width={400} height={300} />
                        </DiagramContainer>

                    </div>

                </KaryawanByCabang>
                <ListRequestKaryawan>
                    <TitleSectionKaryawan>Pengajuan Karyawan <Link to="/request" className="link">See All</Link></TitleSectionKaryawan>
                    {/* <div> */}
                    <div>
                        <p>Cuti</p>
                        <h4>{dashboardHr?.totalLeaveRequest}</h4>
                    </div>
                    <div>
                        <p>Izin</p>
                        <h4>{dashboardHr?.totalPermitRequest}</h4>
                    </div>
                    <div>
                        <p>Ubah Data</p>
                        <h4>0</h4>
                    </div>
                    <div>
                        <p>Pinjaman</p>
                        <h4>{dashboardHr?.totalLoanRequest}</h4>
                    </div>
                    <div>
                        <p>Penggantian Biaya</p>
                        <h4>0</h4>
                    </div>
                    <div>
                        <p>Lembur</p>
                        <h4>{dashboardHr?.totalOvertimeRequest}</h4>
                    </div>
                    {/* </div> */}
                </ListRequestKaryawan>
                <RequestMenu>
                    <RequestTitle>
                        <SectionText>Buat Pengajuan</SectionText>
                        <SectionLink to="/request">Riwayat</SectionLink>
                    </RequestTitle>
                    <RequestDetail>
                        <LinkRequestMenu to="/request/leave/annual">
                            <h3>Cuti Tahunan</h3>
                            <Icon icon={iconCalendar} color="#222" />
                        </LinkRequestMenu>
                        <LinkRequestMenu to="/request/leave/sick">
                            <h3>Cuti Sakit</h3>
                            <Icon icon={iconSick} color="#222" />
                        </LinkRequestMenu>
                        <LinkRequestMenu to="/permit/late">
                            <h3>Izin Telat</h3>
                            <Icon icon={iconLate} color="#222" />
                        </LinkRequestMenu>
                        <LinkRequestMenu to="/permit/out-early">
                            <h3>Izin Pulang Duluan</h3>
                            <Icon icon={iconCutTime} color="#222" />
                        </LinkRequestMenu>

                        <LinkRequestMenu to="/request/loan">
                            <h3>Pinjaman</h3>
                            <Icon icon={iconLoan} color="#222" />
                        </LinkRequestMenu>
                        <LinkRequestMenu to="/permit/switch-shift">
                            <h3>Tukar Shift</h3>
                            <Icon icon={iconExchange} color="#222" />
                        </LinkRequestMenu>
                        <LinkRequestMenu to="/request/overtime">
                            <h3>Lembur</h3>
                            <Icon icon={iconOverTime} color="#222" />

                        </LinkRequestMenu>
                        <LinkRequestMenu to="/request/edit-personal-data">
                            <h3>Ubah Data</h3>
                            <Icon icon={iconEdit} color="#222" />

                        </LinkRequestMenu>

                    </RequestDetail>
                </RequestMenu>
                <BuatTugasKaryawan>
                    <h3>
                        Sampaikan Sesuatu
                    </h3>
                    {/* <p>Sampaikan tugas atau pengumuman kepada karyawan</p> */}
                    <div>
                        <Link to="/create-announcement" className="button-link">Pengumuman</Link>
                        <Link to="/create-task" className="button-link">Tugas</Link>
                    </div>
                </BuatTugasKaryawan>
                <KehadiranText>
                    <SectionText>Kehadiran</SectionText>
                    {/* <SectionLink to="/attendance/overview">see detail</SectionLink> */}
                    <input type="date" value={selectedDate} onChange={inputDate} />
                </KehadiranText>
                <OverviewKehadiran>
                    <h3>
                        Ringkasan Kehadiran
                    </h3>
                    <div>
                        <DoughnutContainerMobile>
                            <Pie data={dataChartKehadiran} options={optionsChartKehadiranResponsive} width={400} height={300} />
                        </DoughnutContainerMobile>
                        <DiagramContainer>
                            <Pie data={dataChartKehadiran} options={optionsChartKehadiran} width={400} height={300} />
                        </DiagramContainer>

                    </div>
                    <div style={{ width: "100%", textAlign: "right", marginRight: "80px" }}>
                        <h4>{dashboardHr?.totalAttendance} <span> / {dashboardHr?.totalSchedule}</span></h4>
                        <p>Absensi</p>
                    </div>
                </OverviewKehadiran>
                <ListTelat>
                    <h3>
                        Karyawan Telat
                    </h3>

                    <div className="wrapper-list">
                        <div className="ringkasan-list">
                            <div className="total-list">
                                <h2>{dashboardHr?.totalLate}</h2>
                            </div>
                            <div className="detail-list">
                                {dashboardHr?.lateDivisi?.map((item, index) => (
                                    <div className=
                                        {
                                            item?.name == "Umum & Sumber Daya" ? "umum-jumlah" :
                                                item?.name == "Operasional & Pemanduaan" ? "operasional-jumlah" :
                                                    item?.name == "Keuangan" ? "keuangan-jumlah" :
                                                        item?.name == "Direksi" ? "keuangan-jumlah" : ""
                                        } key={index}>
                                        <h4>{item.late_count}</h4>
                                        <p>{item.name}</p>
                                    </div>
                                ))}

                            </div>
                        </div>
                        {dashboardHr?.employeeLate?.map((item, index) => (
                            <div className="list-item" key={index}>
                                <SmallAvatar >
                                    <AvatarImage src={`/images/avatar.jpg`} alt="avatar" style={{ borderRadius: '50%' }} />
                                </SmallAvatar>
                                <div className="list-info">
                                    <h5>{item?.name}</h5>
                                    <p className='text-overflow'>{item.position} <span>- {item.division}</span></p>
                                </div>
                                <div className="list-right-info">
                                    <TimeDifference time1={item.time_in_schedule} time2={item.time_in} />
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* photo, nama, jabatan, divisi, jumlah telat */}

                </ListTelat>
                <ListTidakHadir>
                    <h3>
                        Karyawan Tidak Hadir
                    </h3>
                    <div className="wrapper-list">
                        <div className="ringkasan-list">
                            <div className="total-list">
                                <h2>{dashboardHr?.totalOff}</h2>
                            </div>
                            <div className="detail-list">
                                {dashboardHr?.offDivisi?.map((item, index) => (
                                    <div className=
                                        {
                                            item?.name == "Umum & Sumber Daya" ? "umum-jumlah" :
                                                item?.name == "Operasional & Pemanduaan" ? "operasional-jumlah" :
                                                    item?.name == "Keuangan" ? "keuangan-jumlah" :
                                                        item?.name == "Direksi" ? "keuangan-jumlah" : ""
                                        } key={index}>
                                        <h4>{item.total_count}</h4>
                                        <p>{item.name}</p>
                                    </div>
                                ))}

                            </div>
                        </div>
                        {dashboardHr?.employeeOff?.map((item, index) => (
                            <div className="list-item" style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'start',
                                paddingLeft: '8px',
                                cursor: 'pointer',
                                maxWidth: '100%',
                                height: '50px',
                                borderBottom: '1px solid var(--background-color)',
                                overflow: 'hidden' // Mencegah konten melampaui batas elemen
                            }}>
                                <SmallAvatar >
                                    <AvatarImage src={`/images/avatar.jpg`} alt="avatar" style={{ borderRadius: '50%' }} />
                                </SmallAvatar>
                                <div className="list-info" style={{
                                    marginLeft: '8px',
                                    flexGrow: 1, // Membuat elemen ini mengambil ruang yang tersedia
                                    overflow: 'hidden' // Mencegah konten melampaui batas elemen
                                }}>
                                    <h5 style={{
                                        fontSize: '14px',
                                        whiteSpace: 'nowrap', // Mencegah teks membungkus ke baris berikutnya
                                        overflow: 'hidden', // Menyembunyikan teks yang melampaui lebar elemen
                                        textOverflow: 'ellipsis' // Menampilkan "..." di akhir teks yang terpotong
                                    }} className='text-overflow-tidak-hadir'>
                                        {item?.name}
                                    </h5>
                                    <p className='text-overflow-tidak-hadir'>
                                        {item.position} <span>- {item.division}</span>
                                    </p>
                                </div>
                                <div className="list-right-info" style={{
                                    marginLeft: item.type === 'Off' ? 'auto' : '8px',
                                    marginRight: item.type === 'Off' ? '-15px' : '8px',
                                    fontSize: '11px',
                                    color: 'red',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    minWidth: '50px',
                                    maxWidth: '150px'
                                }}>
                                    {item.type === 'Cuti Tahunan' ? 'Tahunan' :
                                        item.type === 'Cuti Sakit' ? 'Sakit' :
                                            item.type === 'Cuti Besar' ? 'Besar' :
                                                item.type === 'Cuti Melahirkan' ? 'Melahirkan' :
                                                    item.type === 'Cuti Nikah' ? 'Nikah' :
                                                        item.type === 'Cuti Menikahkan Anak' ? 'Menikahkan Anak' :
                                                            item.type === 'Cuti Khitanan Anak' ? 'Khitanan Anak' :
                                                                item.type === 'Cuti Istri Melahirkan Atau Keguguran' ? 'Istri Melahirkan Atau Keguguran' :
                                                                    item.type === 'Cuti Tahunan' ? 'Tahunan' :
                                                                        item.type === 'Cuti Meluarga Meninggal' ? 'Meluarga Meninggal' :
                                                                            item.type}
                                </div>

                            </div>

                        ))}

                    </div>
                    {/* photo, nama, jabatan, divisi, off/cuti/sakit/izin */}


                </ListTidakHadir>
                <RankKehadiran>
                    <h3>Ranking Kedisiplinan Kehadiran</h3>
                    <CardContainer>
                        <ArrowButton left onClick={handlePrev}>{"<"}</ArrowButton>
                        <ListSlide>
                            {dashboardHr?.employeeRankAttendance?.slice(activeIndex * itemsPerPage, (activeIndex + 1) * itemsPerPage).map((user, index) => {
                                // Hitung indeks global berdasarkan halaman
                                const globalIndex = activeIndex * itemsPerPage + index;

                                // Tentukan kelas berdasarkan globalIndex
                                const itemClass = globalIndex === 0
                                    ? "highlighted-item"
                                    : globalIndex === 1
                                        ? "second-item"
                                        : globalIndex === 2
                                            ? "third-item"
                                            : "default-item";

                                return (
                                    <SlideItem key={user.id} className="slide-item" globalIndex={globalIndex}>
                                        <img src={`/images/avatar.jpg`} alt="photo" />
                                        <p>{user.name}</p>
                                    </SlideItem>
                                );
                            })}
                        </ListSlide>
                        <ArrowButton onClick={handleNext}>{">"}</ArrowButton>
                    </CardContainer>
                    <CardContainerMobile>
                        <ArrowButton left onClick={handlePrevMobile}>{"<"}</ArrowButton>
                        <ListSlide>
                            {dashboardHr?.employeeRankAttendance?.slice(activeIndex * itemsPerPageMobile, (activeIndex + 1) * itemsPerPageMobile).map((user, index) => {
                                // Hitung indeks global berdasarkan halaman
                                const globalIndex = activeIndex * itemsPerPageMobile + index;

                                // Tentukan kelas berdasarkan globalIndex
                                const itemClass = globalIndex === 0
                                    ? "highlighted-item"
                                    : globalIndex === 1
                                        ? "second-item"
                                        : globalIndex === 2
                                            ? "third-item"
                                            : "default-item";

                                return (
                                    <SlideItem key={user.id} className="slide-item" globalIndex={globalIndex}>
                                        <img src={`/images/avatar.jpg`} alt="photo" />
                                        <p>{user.name}</p>
                                    </SlideItem>
                                );
                            })}
                        </ListSlide>
                        <ArrowButton onClick={handleNextMobile}>{">"}</ArrowButton>
                    </CardContainerMobile>
                    <IndicatorContainer>
                        {Array.from({ length: totalPages }).map((_, index) => (
                            <IndicatorDot key={index} active={index === activeIndex} />
                        ))}
                    </IndicatorContainer>
                    <IndicatorContainerMobile>
                        {Array.from({ length: totalPagesMobile }).map((_, index) => (
                            <IndicatorDot key={index} active={index === activeIndex} />
                        ))}
                    </IndicatorContainerMobile>
                </RankKehadiran>
                <KaryawanLembur>
                    <h3>Karyawan Lembur</h3>
                    <div className="avatar-group">
                        <div className="hidden-avatars">{dashboardHr?.employeeOvertime?.length}</div>
                        {dashboardHr?.employeeOvertime?.slice(0, 7).map((item, index) => (
                            <SmallAvatar >
                                <AvatarImage src={`/images/avatar.jpg`} alt="avatar" style={{ borderRadius: '50%' }} />
                            </SmallAvatar>
                        ))}
                    </div>
                </KaryawanLembur>
                <PulangAwal>
                    <h3>Pulang Duluan</h3>
                    <div className="avatar-group">
                        <div className="hidden-avatars"> {dashboardHr?.employeOutEarly?.length}</div>

                        {dashboardHr?.employeOutEarly?.slice(0, 7).map((item, index) => (
                            <SmallAvatar >
                                <AvatarImage src={`/images/avatar.jpg`} alt="avatar" style={{ borderRadius: '50%' }} />
                            </SmallAvatar>
                        ))}
                    </div>
                </PulangAwal>
            </HRDashboardContainer >
        </>
    )
}

const reduxState = (state) => ({
    isLogin: state.isLogin,
    user: state.user,
    isLoading: state.isLoading

})


const reduxDispatch = (dispatch) => ({
    loading: (data) => dispatch(setLoading(data)),



})
export default connect(reduxState, reduxDispatch)(HRDashboard)