import React from 'react'
import { connect } from 'react-redux'
import { iconUser } from '../../../assets'
import { Gap, PageHeader, Row } from '../../../components'

const HRReport = (props) => {
    return (
        <>
            <PageHeader
                title="Laporan"
                subtitle={props.user.client_id}
                name={props.user.name}
                photo={iconUser}
            />
            <Gap height={20} />
            <Row>
                
            </Row>
        </>
    )
}

const reduxState = (state) => ({
    isLogin: state.isLogin,
    user: state.user,
    isLoading: state.isLoading

})
  
  
const reduxDispatch = (dispatch) => ({
    loading : (data) => dispatch(setLoading(data)),

    

})
export default connect(reduxState, reduxDispatch)(HRReport)