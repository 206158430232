import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { iconLeft, iconUser, iconAdd } from '../../../../assets'
import { Col, FilterMonth, FilterYear, Gap, Icon, PageContentMenu, PageHeader, Row, FormControl, Button, ReportFooter, ReportHeader } from '../../../../components'
import API from '../../../../config/api'
import { apakahHariMinggu, bulan_indo, format_tanggal_indo, getDatesBetweenDates, getDaysInMonth, nama_hari, tahun_bulan_tanggal } from '../../../../utils/helpers/date'
import { Formik, Form, getIn, setIn } from 'formik'
import Modal from 'react-modal'
import swal from 'sweetalert'
import * as Yup from 'yup';

Modal.setAppElement('#root');
// const token = localStorage.getItem('token');

function getStyle(errors, touched, fieldName) {
    if (getIn(errors, fieldName) && getIn(touched, fieldName)) {
      return {
        border: '1px solid red',
        borderLeft: '5px solid red'
      }
    }else if(!getIn(errors, fieldName) && getIn(touched, fieldName)){
      return {
        border: '1px solid green',

      }
    }
}

const formFields = [
    {
        control: 'time',
        label: 'Jam Masuk',
        name: 'time_in',
    },
    {
        control: 'time',
        label: 'Jam Pulang',
        name: 'time_out',
    },
    {   
        control: 'radio',
        options:  [
                    
                    { key: 'Telat', value: '1' },
                    { key: 'Tidak', value: '0' },
                    
                    
                ],
        label: 'Apakah telat?',
        name: 'is_late',
        type: 'radio'
        // callback: 
    },
    
];

const schemaValidation = Yup.object({
    //   division_id: Yup.string().required('Required'),
        time_in: Yup.string().required('Required'),
        time_out: Yup.string().required('Required'),
        is_late: Yup.number().required('Required'),
     
      
});

const date = new Date();

const AttendanceReport = (props) => {
    const division = props.location.state;
    const [groups, setGroups] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [attendances, setAttendances] = useState([]);
    const [schedules, setSchedules] = useState([]);
    const [holidays, setHolidays] = useState([]);
    const [leaves, setLeaves] = useState([]);
    const [overtimes, setOvertimes] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(0);
    const [group, setGroup] = useState(null);
    const [groupName, setGroupName] = useState("");
    const [month, setMonth] = useState(date.getMonth());
    const year = (new Date()).getFullYear();
    const startYear = (new Date()).getFullYear() - 3;
    const [selectedYear, setSelectedYear] = useState(year);
    const [days, setDays] = useState(getDaysInMonth(selectedYear, parseInt(month)));
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isAddOrEdit, setIsAddOrEdit] = useState('');
    const [initialValues, setInitialValues] = useState({});
    const [attendanceOfEmployee, setAttendanceOfEmployee] = useState({});


    const token = props.user.token;


    const attendancePerEmployeePerDate = (e, date) => {
        // console.log(e);
        setAttendanceOfEmployee(e);
        // console.log(date)
        
        const found = attendances.find(el => (el.date === tahun_bulan_tanggal(date) && el.employee_id === e.id));
        if(!found){
            setIsAddOrEdit('add')
            // console.log('add')
            setInitialValues({
                employee_id: e.id,
                date: tahun_bulan_tanggal(date),
                time_in: '',
                time_out: '',
                is_late: '',
            })
            
        }else {
            // console.log('edit')
            setInitialValues({
                id: found.id,
                time_in: found.time_in,
                time_out: found.time_out,
                is_late: `${found.is_late}`,
            })
            setIsAddOrEdit('edit')

            // console.log(found)
        }
      

        let dayName = date.getDay();
        dayName = nama_hari(dayName);
      
        const day = format_tanggal_indo(date);
        // console.log(day);
        // day = dd+'/'+mm+'/'+yyyy;
        // console.log(day);
        // tampilkan modal
      
        // const found = scheduleData.find(el => (el.date === tahun_bulan_tanggal(date) && el.employee_id === employeeData.id));

        // setInitialValues({
        //     employee_id : employeeData.id,
        //     group_id: group.id,
        //     date: tanggal_bulan_tahun(date),
        //     type: 'per date'
        // })
      
        // if(found){
            // if(found.employee_id === employeeData.id){
                // setIsAddOrEdit('edit')
                // const work_shift = found.work_shift_id;
                // const work_location = found.work_location_id;
                // const work_location_2_id = found.work_location_2_id;
                // setInitialValues({
                //     id : found.id,
                //     work_location: work_location,
                //     work_location_2: work_location_2_id,
                //     work_shift: work_shift,
                    

                   
                // })
               
            // }
        // }
        setModalIsOpen(true);
    }

    const handleDeleteAttendance = () => {
        // console.log('delete kehadiran in');

        const {id} = initialValues;
        
        // console.log(id);
        API.deleteAttendance(token, id).then(res => {
            // console.log(res);
            swal({
                title: 'Success',
                text: res,
                icon: "success",
              });
            setModalIsOpen(false)

        }).catch(err => {
            console.log(err.response)

        })
    }

    
    const handleSubmit = async data => {
        // console.log(data)
        if(isAddOrEdit == 'add') {
            API.addAttendanceByAdmin(token, data).then(res => {
                // console.log(res.data.message);
                swal({
                    title: res.data.status,
                    text: res.data.message,
                    icon: "success",
                });
                setModalIsOpen(false);
                
            }).catch(err => {
                // console.log(err);
                swal({
                    title: err.status,
                    text: err.message,
                    icon: "error",
                });
                setModalIsOpen(false)

            });
        }else {
            // console.log(data);
            API.editAttendance(token, data).then(res => {
                // console.log(res);
                swal({
                    title: res.data.status,
                    text: res.data.message,
                    icon: "success",
                });
                setModalIsOpen(false);
            }).catch(err => {
                // console.log(err);
                swal({
                    title: err.status,
                    text: err.message,
                    icon: "error",
                });
                setModalIsOpen(false)

            });
        }
    }
    useEffect(() => {
        //get division by client_id
        // console.log('render pertama kali')
        API.getTeamGroupByDivisionID(token, division.id).then((res) => {
            // console.log(res)
            setGroups(res.data)
            
            
        }).catch(err => {
            console.log(err.response);
            // console.log(err.response.data.message);
        })
    }, [])


    useEffect(() => {
        let mnth = parseInt(month) + 1;
        const periode = `${selectedYear}-${mnth}`;
        const data = {
            division_id: division.id,
            group_id: selectedGroup,
            date: periode
        }
        // console.log(`request attendance group id ${selectedGroup} di periode ${periode}`)
        API.getReportAttendance(token, data).then(res => {
            // console.log(res.data.data)
            setEmployees(res.data.data.employees)
            setAttendances(res.data.data.attendances)
            setHolidays(res.data.data.holidays)
            setLeaves(res.data.data.leaves)
            setSchedules(res.data.data.schedules)
            setOvertimes(res.data.data.overtimes)
        }).catch(err => {
            console.log(err)
        })
    }, [month, selectedYear, selectedGroup, modalIsOpen])

    
    const handleMonthChange = e => {
        // console.log(e);
        setMonth(e.target.value);
        setDays(getDaysInMonth(year, parseInt(e.target.value)))

       
    }

    const handleChangeYearFilter = (e) => {
        setSelectedYear(e.target.value);
       
    }

    const handleChangeGroup = (group) => {
        setSelectedGroup(group.id)
        setGroup(group)
        setGroupName(group.name)
    }

    const showAttendance = (employeeID, date, type, name = '') => {
        // console.log(date)        
        const schedule = schedules.find(el => (el.date === date && el.employee_id === employeeID));
        if(schedule){ // ada jadwal
            const melakukanAbsen = attendances.find(el => (el.date === date && el.employee_id === employeeID)) && schedules.find(el => (el.date === date && el.employee_id === employeeID));
            if(melakukanAbsen){
                const late = attendances.find(el => (el.date === date && el.employee_id === employeeID && el.is_late === 1)) && schedules.find(el => (el.date === date && el.employee_id === employeeID)); //ada jadwal tapi absen masuk telat kuning
                
                if(late){
                    // return `<span style="color: #FFC000; font-size: 15px;">&#10004;</span>`;
                    // return 'tlt';
                    return `<i style="color: #FFC000; font-size: 15px;">&#10004;</i>`;
                    
                }else {
                    return `<i style="color: #00A36C; font-size: 15px;">&#10004;</i>`;
                    // return 'ok';
                    // return '&#10004;'
                }
            }else { //ada jadwal tapi tidak absen masuk x merah
                // return 'x';
                return '&#128683;'
                // return `<span style="font-size:12px">&#128683;</span>`;
            }
            //check lagi apakah di jadwal itu ada absen

        }else { // tidak ada jadwal
            //tapi absen
            const melakukanAbsen = attendances.find(el => (el.date === date && el.employee_id === employeeID));
            if(melakukanAbsen){
                const late = attendances.find(el => (el.date === date && el.employee_id === employeeID && el.is_late === 1)); //ada jadwal tapi absen masuk telat kuning
                if(late){
                    // return `<span style="color: #FFC000; font-size: 15px;">&#10004;</span>`;
                    // return 'tlt';
                    return `<i style="color: #FFC000; font-size: 15px;">&#10004;</i>`;
                    
                }else {
                    return `<i style="color: #00A36C; font-size: 15px;">&#10004;</i>`;
                    // return 'ok';
                    // return '&#10004;'
                }
            }else { //ada jadwal tapi tidak absen masuk x merah
                // return 'x';
                // return '&#128683;'
                return '-';
                // return `<span style="font-size:12px">&#128683;</span>`;
            }
            
            //tidak ada jadwal bulat abu-abu

        }
    }
 

    
    return (
        <>
             <PageHeader
                title={`Kehadiran Divisi ${division.name}`}
                subtitle={props.user.client_id}
                name={props.user.name}
                photo={iconUser}
            />
            <Gap height={20} />
            <Row>
                <Col>
                    <Link to='/employee/attendance' className="back-button" >                    
                        <Icon icon={iconLeft} color="#fff" />
                        <p>Back</p>
                    </Link>
                </Col>
                
                {/* filter tahun dan bulan */}
                <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <p style={{fontSize: 14, marginRight: 5}}>Tahun : </p>
                    <div style={{marginLeft: 5}}>
                        <FilterYear year={year} startYear={startYear} selectedYear={selectedYear} handleChange={handleChangeYearFilter} />
                    </div>

                </Col>
            </Row>
           <Row>
            <Col style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: 10, marginBottom: 5}}>
                <p className={`filter-group ${selectedGroup == 0 ?'active': ''}`} onClick={() => handleChangeGroup({id: 0, public_holiday_is_off: 0})}>Semua</p>    
                {groups && groups.map(group => (
                    <p key={group.name} className={`filter-group ${selectedGroup == group.id ?'active': ''}`} onClick={() => handleChangeGroup(group)}>                    
                    {group.name}
                    </p>
                ))
                }
            </Col>
            <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 10, marginBottom: 5}}>
                <div onClick={() => alert('export')} className="export-button" style={{marginRight: '5px', padding: '0 10px', height: '35px', border: '1px solid #eee'}}><span style={{fontSize: '22px', marginRight: '3px'}}>&#128194;</span>Export</div>
                <div onClick={() => window.print()} className="print-button" style={{marginRight: '5px', padding: '0 10px', height: '35px', border: '1px solid #eee'}}><span style={{fontSize: '22px', marginRight: '3px'}}>&#128424;</span>Print</div>
            </Col>
           </Row>
           <ReportHeader company={props.user.info.company} month={month} selectedYear={selectedYear} page={`Laporan Kehadiran ${groupName ? groupName + ' | ' : ''} ${division.name}`} />

            <div className="print-this">
            <div style={{overflowX: 'auto'}}>
                <table className="schedule-table">
                <thead>
                    <tr>
                        <th rowSpan="3">No.</th>
                        <th rowSpan="3">Nama.</th>
                        <th colSpan={days.length + 7}>
    
                            <FilterMonth handleChange={handleMonthChange} month={month} className="schedule-month" />

                
                        </th>
                    </tr>
                    <tr>
                        {
                            days.map((day, index) => (
                                <td key={`hari-${index}`} style={{maxWidth: '3px', overflow: 'hidden', fontSize: '10px'}} className={
                                    // jika tanggal merah tidak masuk, tambahkan class minggu
                                //    group.public_holiday_is_off == 1 && apakahHariMinggu(day.getDay()) && 'minggu'
                                    (() => {
                                        if(group){if (group.public_holiday_is_off == 1) {
                                            // console.log(holidays)
                                            if(holidays){if (holidays.filter(e => e.date === tahun_bulan_tanggal(day)).length > 0) {
                                                return 'libur'
                                            }
                                            if(tahun_bulan_tanggal(day) === tahun_bulan_tanggal(new Date())){
                                                return 'hari-ini'
                                            }
                                            
                                            return apakahHariMinggu(day.getDay()) && 'minggu'
                                        }}}else {
                                            if(tahun_bulan_tanggal(day) === tahun_bulan_tanggal(new Date())){
                                                return 'hari-ini'
                                            }
                                            return apakahHariMinggu(day.getDay()) && 'minggu'
                                        }
                                    })()
                                
                                }>
                                    {nama_hari(day.getDay())}</td>
                                // console.log(day.getDay())
                                ))
                                
                            }
                            <th className="recap-col">Normal</th>
                            <th className="recap-col">Riil</th>
                            <th className="recap-col">Mangkir</th>
                            <th className="recap-col">Telat</th>
                            <th className="recap-col">Cuti</th>
                            <th className="recap-col">Lembur</th>
                            <th className="recap-col">D. Luar</th>
                    </tr>
                    
                    <tr>
                        {
                            days.map((day, index) => (
                                <td key={`tanggal-${index}`} style={{maxWidth: '3px', textAlign: 'center', fontSize: '10px'}} className={        
                                  // jika tanggal merah tidak masuk, tambahkan class minggu
                                //    group.public_holiday_is_off == 1 && apakahHariMinggu(day.getDay()) && 'minggu'
                                   (() => {
                                        if(group) {if (group.public_holiday_is_off == 1) {
                                            // console.log(holidays)
                                            if(holidays){if (holidays.filter(e => e.date === tahun_bulan_tanggal(day)).length > 0) {
                                                return 'libur'
                                            }
                                            if(tahun_bulan_tanggal(day) === tahun_bulan_tanggal(new Date())){
                                                return 'hari-ini'
                                            }
                                            return apakahHariMinggu(day.getDay()) && 'minggu'
                                        }}}else {
                                            if(tahun_bulan_tanggal(day) === tahun_bulan_tanggal(new Date())){
                                                return 'hari-ini'
                                            }
                                            return apakahHariMinggu(day.getDay()) && 'minggu'

                                        }
                                    })()
                                }
                                >
                                        {1 +index}</td>
                                // console.log(day.getDay())
                                ))
                                
                            }
                            <th className="recap-col">Hari</th>
                            <th className="recap-col">Hari</th>
                            <th className="recap-col">Hari</th>
                            <th className="recap-col">Kali</th>
                            <th className="recap-col">Hari</th>
                            <th className="recap-col">Kali</th>
                            <th className="recap-col">Hari</th>
                    </tr>
                    </thead>
                    <tbody>
                      
                        {
                            employees != null ? employees.map((e, index) => (
                                <tr key={`karyawan-${e.name}`}>
                                    <td key={`karyawan-${index}`} style={{textAlign: 'center'}}>{1 + index}</td>
                                    <td className="schedule-employee-name" key={`karyawan-id${e.id}`}>
                                        {e.name}
                                    </td>
                                    {days.map((day, index) => (
                                        <td key={`jadwal-karyawan-${index}`}className={
                                            // jika tanggal merah tidak masuk, tambahkan class minggu
                                            //check apabila hari libur tidak masuk, jika true
                                            //tandai hari minggu
                                            //tandai tanggal merah
                                            // group.public_holiday_is_off == 1 ? apakahHariMinggu(day.getDay()) ? 'schedule-employee-date hasTooltip minggu' : 'schedule-employee-date hasTooltip' : 'schedule-employee-date hasTooltip'
                                            (() => {
                                                if(group){if (group.public_holiday_is_off == 1) {
                                                    // console.log(holidays)
                                                    if(holidays){if (holidays.filter(e => e.date === tahun_bulan_tanggal(day)).length > 0) {
                                                        return 'schedule-employee-date hasTooltip libur'
                                                    }
                                                    if(tahun_bulan_tanggal(day) === tahun_bulan_tanggal(new Date())){
                                                        return 'hari-ini'
                                                    }
                                                    return apakahHariMinggu(day.getDay()) ? 'schedule-employee-date hasTooltip minggu' : 'schedule-employee-date hasTooltip'
                                                }else {
                                                    return 'schedule-employee-date hasTooltip'
                                                }}
                                            }else {
                                                if(tahun_bulan_tanggal(day) === tahun_bulan_tanggal(new Date())){
                                                    return 'hari-ini'
                                                }
                                                return apakahHariMinggu(day.getDay()) && 'minggu'

                                            }})()
                                        }
                                        onClick={() => attendancePerEmployeePerDate(e, day)}>
                                       {
                                            (schedules.length >= 1) ? <p style={{textAlign: 'center'}} dangerouslySetInnerHTML={{__html: showAttendance(e.id, tahun_bulan_tanggal(day), 'table') }} /> : null
                                            // (schedules.length >= 1) ? showAttendance(e.id, tahun_bulan_tanggal(day), 'table') : null
                                        }
                                        
                                        
                                        </td>
                                        

                                    ))}
                                    <td className="recap-col">{schedules.filter(x => x.employee_id === e.id).length}</td>
                                    <td className="recap-col">{
                                    attendances.filter(el => (el.employee_id === e.id)).length
                                    }</td>
                                    <td className="recap-col">{schedules.filter(x => x.employee_id === e.id).length -
                                    attendances.filter(el => (el.employee_id === e.id)).length
                                    }</td>
                                    <td className="recap-col">{ attendances.filter(el => (el.employee_id === e.id && el.is_late === 1)).length
                                    }</td>
                                    <td className="recap-col">{leaves.filter(el => (el.employee_id === e.id && el.status === 1)).length}</td>
                                    <td className="recap-col">{overtimes.filter(el => (el.employee_id === e.id && el.status === 1)).length}</td>
                                    <td className="recap-col">-</td>
                                </tr>
                            )) : <tr><td colSpan={2 + days.length} style={{textAlign: 'center'}}>Tidak ada karyawan</td></tr>
                        }
                    </tbody>
                </table>
            </div>
            </div>
            <div>
                {
                    (new Date().getMonth() == month ? <>
                        <h3 align="center" style={{margin: '30px 0'}}>{`${nama_hari(date.getDay())}, ${format_tanggal_indo(date)}`}</h3>
                        <div className="wrapper-recap-report">
                            <div className="recap-report">
                                <div className="header-recap-report">
                                    Belum absen
                                </div>
                                <div className="body-recap-report">
                                    <div>
                                        {
                                            attendances.filter(el => (el.date === tahun_bulan_tanggal(date))) && schedules.filter(el => (el.date === tahun_bulan_tanggal(date))).length
                                        }
                                  
                                    </div>
                                </div>
                            </div>
                            <div className="recap-report">
                                <div className="header-recap-report">
                                    Telat
                                </div>
                                <div className="body-recap-report">
                                    <div>
                                    { attendances.filter(el => el.is_late === 1 && el.date === tahun_bulan_tanggal(date)).length
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className="recap-report">
                                <div className="header-recap-report">
                                    Cuti
                                </div>
                                <div className="body-recap-report">
                                    <div>
                                    {
                                        
                                        leaves.filter(el => {
                                            let tanggal_mulai = el.approved_date_start
                                            let tanggal_selesai = el.approved_date_end
                                            // console.log('tanggal muali', tanggal_mulai)
                                            // console.log('tanggal selesai', tanggal_selesai)
                                            
                                            
                                            let dates = getDatesBetweenDates(tanggal_mulai, tanggal_selesai)
                                            // console.log('dates', dates)
                                            // console.log('date', date)
                                            let tanggal = dates.filter(dt => tahun_bulan_tanggal(dt) === tahun_bulan_tanggal(date))
                                            
                                            // console.log('tanggal', tanggal)
                                            // console.log(tahun_bulan_tanggal(date))
                                            if(tanggal.length > 0){
                                                return (el.status === 1 && tahun_bulan_tanggal(date) === tahun_bulan_tanggal(tanggal[0]))
                                            }else {
                                                return 0;
                                            }

                                        }).length
                                        
                                    }
                                    </div>
                                </div>
                            </div>
                            <div className="recap-report">
                                <div className="header-recap-report">
                                    Lembur
                                </div>
                                <div className="body-recap-report">
                                    <div>
                                    { overtimes.filter(el => el.date ===  tahun_bulan_tanggal(date)).length
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> : <ReportFooter />)
                    // console.log(new Date().getMonth())
                    // console.log(month)
                }
                
            </div>
            <Modal 
                onRequestClose={() => setModalIsOpen(false)}
                isOpen={modalIsOpen}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                      },
                    content: {
                        border: '1px solid #222',
                        padding:0,
                        top: '25px',
                        left: '400px',
                        right: '400px',
                        bottom: '50px',
                    }
                }}
            >
                <div className="modal-container">
                <div className="modal-header">
                    <h2 className="modal-title">{(isAddOrEdit == 'add') ? 'Tambah' : 'Edit' } Kehadiran {attendanceOfEmployee.name}</h2>
                    <button className="close-modal" onClick={() => setModalIsOpen(false)}>X</button>
                </div>
                <Formik enableReinitialize initialValues={initialValues} validationSchema={schemaValidation} onSubmit={handleSubmit} >
                    {({errors, touched, isValid}) => (
                        
                    <Form>
                        
                        <div className="modal-body">
                            <div className="form-row">
                            {
                                formFields.map(field => (
                                    // console.log(field)
                                    <FormControl key={field.name}
                                        control={field.control}
                                        type={field.type}
                                        label={field.label}
                                        name={field.name}
                                        style={getStyle(errors, touched, field.name)}
                                        options={field.options}
                                        // callback={field.callback}
                                    />
                                ))
                            }    
                            </div>  

                        </div>
                        <div className="modal-footer" style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {isAddOrEdit == 'edit' && 
                            <div onClick={handleDeleteAttendance} className='delete-button'>Hapus</div>
                            }
                            <Button buttonFull buttonColor='var(--green)' align="right" buttonHover type="submit" disabled={!isValid || props.isLoading} className={props.isLoading ? 'btnLoading' : null}>{(isAddOrEdit == 'add') ? 'Tambah' : 'Edit' }</Button>
                            
                        </div>
                    </Form>
                    )}
                </Formik>

                </div>
            </Modal>
        </>
    )
}

const reduxState = (state) => ({
    isLogin: state.isLogin,
    user: state.user,
    isLoading: state.isLoading

})
  
  
const reduxDispatch = (dispatch) => ({
    loading : (data) => dispatch(setLoading(data)),

    

})
export default connect(reduxState, reduxDispatch)(AttendanceReport)
