import React from 'react'
import { bulan_indo } from '../../../utils/helpers/date'

const ReportHeader = ({company, month = '', selectedYear = '', page}) => {
    return (
        <div className="section-to-print header">
            <img src={company.logo || 'PT. ABC'} alt="logo" width="100px" height="70px" style={{position: 'absolute', left: 0}} className="logo" />
            <div style={{textAlign: 'center', display: 'block', flexDirection: 'column', marginBottom: '15px'}}>

                <h3 style={{ fontSize: '22px', textTransform: 'uppercase'}}>{company.business_fields} <br />{company.name}</h3>
                <p style={{fontSize: '9px'}}><u>{company.address} TELP. {company.telp}, Email: <i>{company.email}</i></u></p>
            </div>
            <hr />
            <p style={{textAlign: 'center', marginTop: '15px', fontWeight: 'bold', fontSize: '20px'}} className="page-title">{page} <br /> {bulan_indo(month)} {selectedYear}</p>
            
        
        </div>
    )
}

export default ReportHeader
