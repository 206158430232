import styled from 'styled-components';

export const HeaderGroup = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: 1140px) {
        display: grid;
    }
`

export const ListGroup = styled.div`
    display: flex;
    overflow: auto;
    width: 700px;
    margin-bottom: 10px;
    @media (max-width: 1140px) {
        margin-bottom: 20px;
        margin-top: 20px;
        width: 100%;

    }
`

export const FilterGroup = styled.p`
    background-color: ${props => props.selectedGroup ? "var(--primary-color)" : "var(--black)"};
    color: var(--white);
    font-size: 13px;
    padding: 5px;
    margin: 0 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    &:hover {
        background-color: var(--primary-color);
    }
`;

export const Content = styled.div`
    display: flex;
    @media (max-width: 1140px) {
        display: grid;
    }
`

export const ListEmployee = styled.div`
  width: 35%;
  background-color:white;
  padding: 5px;
  @media (max-width: 1140px) {
        width: 97%;
        height: 500px;
    }
`
export const SmallAvatar = styled.div`
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
  background-color: var(--white);
  border: 2px solid var(--black);
`;

export const AvatarImage = styled.img`
  width: 35px;
  height: 35px;
  padding: 3px;
  box-sizing: border-box;
  border-radius: 50%;
`;

export const Employee = styled.div`
   @media (max-width: 1140px) {
    overflow-y: auto;
    height: 400px;
   }
   @media (max-width: 770px) {
    overflow-y: auto;
    height: 350px;
   }
`
export const ListInfo = styled.div`
  margin-left: 8px;
  flex-grow: 1; /* Membuat elemen ini mengambil ruang yang tersedia */
  overflow: hidden; /* Mencegah konten melampaui batas elemen */
  width: 100px;
`;

export const ListInfoTitle = styled.h4`
  font-size: 14px;
  white-space: nowrap; /* Mencegah teks membungkus ke baris berikutnya */
  overflow: hidden; /* Menyembunyikan teks yang melampaui lebar elemen */
  text-overflow: ellipsis; /* Menampilkan "..." di akhir teks yang terpotong */
`;

export const ListInfoText = styled.p`
  font-size: 12px;
  color: var(--black);
  white-space: nowrap; /* Mencegah teks membungkus ke baris berikutnya */
  overflow: hidden; /* Menyembunyikan teks yang melampaui lebar elemen */
  text-overflow: ellipsis; /* Menampilkan "..." di akhir teks yang terpotong */
`;

export const ListInfoTextSpan = styled.span`
  font-size: 10px;
`;
 
  
//   .filter - group:hover{
//     background - color: var(--primary - color);
//     cursor: pointer;
// }
  
//   .filter - group.active {
//     background - color: var(--primary - color);

// }