import React from 'react'

const UsedAsset = () => {
    return (
        <div>
            aset yang dipakai sekarang, dan riwayatnya
        </div>
    )
}

export default UsedAsset
