import React from 'react'

const ReportFooter = (props) => {
    return (
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', margin: '0 50px', minWidth: '90%'}} className={props.className}>
            <div style={{ flex: 1}}>
                <p style={{marginBottom: '65px', fontSize: '12.5px'}}>Dibuat oleh,</p>
                <p style={{fontWeight: 'bold', textDecoration: 'underline'}}>Admin Umum</p>
                <p style={{fontSize: '9px'}}>Admin Umum</p>
            </div>
            <div style={{ flex: 1}}>
                <p style={{marginBottom: '65px', fontSize: '12.5px'}}>Diketahui oleh,</p>
                <p style={{fontWeight: 'bold', textDecoration: 'underline'}}>Manajer Umum</p>
                <p style={{fontSize: '9px'}}>Manajer Umum</p>
            </div>
            <div style={{}}>
                <p style={{marginBottom: '65px', fontSize: '12.5px'}}>Disetujui oleh,</p>
                <p style={{fontWeight: 'bold', textDecoration: 'underline'}}>ANGGAN. M SAB</p>
                <p style={{fontSize: '9px'}}>Direktur</p>
            </div>
    </div>
    )
}

export default ReportFooter
