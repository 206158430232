import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from '../TextError'

function Textarea (props) {
  const { label, name, ...rest } = props
  const handleChange = (name, e, setFieldValue) => {
    const value = e.target.value;
    // console.log(props.callback);
    setFieldValue(name, value)
    if(props.callback){
      // console.log(`value ${value}`)
      props.callback(value);

    }
  }
  return (
    <div className='form-control'>
      <label htmlFor={name}>{label}</label>
      {/* <Field id={name} name={name} {...rest} /> */}
      <Field name={name} component="textarea" rows="4">
        {({ form, field }) => {
          const { setFieldValue } = form
          const { value } = field
          return (
            <Field as="textarea" id={name} name={name} {...rest} onChange={e => handleChange(name, e, setFieldValue)} />
            // <Field component="textarea" rows="4" value={""}></Field>

            // <textarea
            //   cols={40}
            //   id={name} name={name} {...rest} onChange={e => handleChange(name, e, setFieldValue)}
            //   rows={20}
            // />
            
          )
        }}
      </Field>
      <ErrorMessage component={TextError} name={name} />
    </div>
  )
}

export default Textarea
