import React, { useEffect, useState } from 'react';
import { Col, Loading, Row } from '../../../components';
import { CallToAction, EditButton, HomePageContainer, Image, SaveButton, SectionContent, SectionHeader, SectionWrapper, Subsection, SubTitle, Title } from './HomePage-elements';
import HeroSection from './section/HeroSection';
import { AboutValidationSchema, AppValidationSchema, companyInfoValidationSchema, ContactValidationSchema, HeroValidationSchema, InformationValidationSchema, KarirValidationSchema, LayananValidationSchema, PergerakanValidationSchema } from './fields';
import API from '../../../config/api';
import Kapal from '../../../assets/image/kapal.svg'
import Pilot from '../../../assets/image/pilot2.jpg'
import { setLoading } from '../../../config/redux/action';
import { connect } from 'react-redux';
import AboutSection from './section/AboutSection';
import PergerakanSection from './section/PergerakanSection';
import LayananSection from './section/LayananSection';
import InformationSection from './section/InformationSection';
import KarirSection from './section/KarirSection';
import AppSection from './section/AppSection';
import ContactSection from './section/ContactSection';


const HomePage = (props) => {
    const token = props?.user?.token;
    const [loading, setLoading] = useState(false);
    const [section, setSction] = useState([]);

    const [initialValuesHero, setInitialValuesHero] = useState({});
    const [schemaValidationHero, setSchemaValidationHero] = useState({});

    const [initialValuesAbout, setInitialValuesAbout] = useState({});
    const [schemaValidationAbout, setSchemaValidationAbout] = useState({});


    const [initialValuesPergerakan, setInitialValuesPergerakan] = useState({});
    const [schemaValidationPergerakan, setSchemaValidationPergerakan] = useState({});

    const [initialValuesLayanan, setInitialValuesLayanan] = useState({});
    const [schemaValidationLayanan, setSchemaValidationLayanan] = useState({});

    const [initialValuesInformation, setInitialValuesInformation] = useState({});
    const [schemaValidationInformation, setSchemaValidationInformation] = useState({});

    const [initialValuesKarir, setInitialValuesKarir] = useState({});
    const [schemaValidationKarir, setSchemaValidationKarir] = useState({});

    const [initialValuesApp, setInitialValuesApp] = useState({});
    const [schemaValidationApp, setSchemaValidationApp] = useState({});

    const [initialValuesContact, setInitialValuesContact] = useState({});
    const [schemaValidationContact, setSchemaValidationContact] = useState({});


    const [isLayanan, setIsLayanan] = useState(false);
    const [isInformasi, setIsInformasi] = useState(false);
    const [isKarir, setIsKarir] = useState(false);
    const [isApp, setIsApp] = useState(false);
    const [isContact, setIsContact] = useState(false);

    const getDataMainSection = () => {
        setLoading(true);
        API.getHomePage(token).then((res) => {
            // console.log('compro', res);
            setSction(res?.data)
            setLoading(false)
        }).catch(err => {
            console.log(err.response.data.message);
            console.log(err);
        })
    }

    const edit = (data, sectionHome) => {
        console.log('data', data)
        const dataMain = [];
        const dataMainInformation = [];
        const subData = [];
        if (data?.subsection) {
            for (let i = 0; i < data.subsection.length; i++) {
                subData.push({
                    title: data.subsection[i].title,
                    id: data.subsection[i].id,
                    subtitle: data.subsection[i].subtitle,
                    image: data.subsection[i].image,
                    call_to_action: data.subsection[i].call_to_action,
                    button_text: data.subsection[i].button_text,
                    link: data.subsection[i].link,
                });
            }
        }
        const nameSectionLayanan = (name) => {
            if (section) {
                for (let i = 0; i < section.length; i++) {
                    section[i]?.name_section == "Layanan Feature" ? dataMain.push({
                        image: section[i].image ? section[i].image : "",
                        title: section[i].title,
                        id: section[i].id,
                        subtitle: section[i].subtitle,
                        button_text: section[i].button_text,
                        link: section[i].link,
                        subsection: section[i].subsection
                    }) : ""
                }
            }
        }
        const nameSectionInformation = (name) => {
            if (section) {
                for (let i = 0; i < section.length; i++) {
                    section[i]?.name_section == "Information Section" ? dataMainInformation.push({
                        image: section[i].image,
                        title: section[i].title,
                        id: section[i].id,
                        subtitle: section[i].subtitle,
                        button_text: section[i].button_text,
                        link: section[i].link,
                        subsection: section[i].subsection
                    }) : ""
                }
            }
        }
        const nameSectionInformasi = (name) => {
            if (section) {
                for (let i = 0; i < section.length; i++) {
                    section[i]?.name_section == name ? dataMain.push({
                        image: section[i].image,
                        title: section[i].title,
                        id: section[i].id,
                        subtitle: section[i].subtitle,
                        button_text: section[i].button_text,
                        link: section[i].link,
                        subsection: section[i].subsection
                    }) : ""
                }
            }
        }
        switch (sectionHome) {
            case 'Hero':
                setInitialValuesHero({
                    id: data?.id || "",
                    name_section: data?.name_section || "",
                    page_type: data?.page_type || "",
                    order_number: data?.order_number || "",
                    title: data?.title || "",
                    subtitle: data?.subtitle || "",
                    image: data?.image || "",
                    call_to_action: data?.call_to_action || "",
                    button_text: data?.button_text || "",
                    content: data?.content || "",
                    link: data?.link || "",
                    image: data?.image || '',
                })
            case 'About':
                setInitialValuesAbout({
                    id: data?.id || "",
                    name_section: data?.name_section || "",
                    page_type: data?.page_type || "",
                    order_number: data?.order_number || "",
                    title: data?.title || "",
                    subtitle: data?.subtitle || "",
                    description: data?.description || "",
                    image: data?.image || "",
                    call_to_action: data?.call_to_action || "",
                    button_text: data?.button_text || "",
                    content: data?.content || "",
                    link: data?.link || "",
                    image: data?.image || '',
                })
            case 'Pergerakan':
                setInitialValuesPergerakan({
                    id: data?.id || "",
                    title: data?.title || "",
                    subtitle: data?.subtitle || "",
                    image: data?.image || "",
                    subData: subData,
                })
            case 'Contact':
                setInitialValuesContact({
                    id: data?.id || "",
                    title: data?.title || "",
                    subtitle: data?.subtitle || "",
                    subData: subData,
                })
            case 'Layanan':
                nameSectionLayanan('Layanan Section');
                setInitialValuesLayanan({
                    title: data?.title || "",
                    mainData: dataMain,
                })
            case 'Information':
                setInitialValuesInformation({
                    id: data?.id || "",
                    title: data?.title || "",
                    subData: subData,
                })
            case 'Karir':
                setInitialValuesKarir({
                    id: data?.id || "",
                    name_section: data?.name_section || "",
                    page_type: data?.page_type || "",
                    order_number: data?.order_number || "",
                    title: data?.title || "",
                    subtitle: data?.subtitle || "",
                    description: data?.description || "",
                    image: data?.image || "",
                    call_to_action: data?.call_to_action || "",
                    button_text: data?.button_text || "",
                    content: data?.content || "",
                    link: data?.link || "",
                    image: data?.image || '',
                })
            case 'App':
                setInitialValuesApp({
                    id: data?.id || "",
                    title: data?.title || "",
                    subtitle: data?.subtitle || "",
                    description: data?.description || "",
                    image: data?.image || "",
                    subData: subData,
                })
            default:
                break;
        }
    }

    useEffect(() => {
        getDataMainSection()
        setSchemaValidationHero(HeroValidationSchema)
        setSchemaValidationAbout(AboutValidationSchema)
        setSchemaValidationPergerakan(PergerakanValidationSchema)
        setSchemaValidationContact(ContactValidationSchema)
        setSchemaValidationLayanan(LayananValidationSchema)
        setSchemaValidationInformation(InformationValidationSchema)
        setSchemaValidationKarir(KarirValidationSchema)
        setSchemaValidationApp(AppValidationSchema)
    }, []);

    const uniqueSections = section?.filter((item, index, self) =>
        index === self.findIndex((t) => t.name_section === item.name_section)
    );

    return (
        <HomePageContainer>
            {
                uniqueSections.map((item, index) => {
                    switch (item?.name_section) {
                        case "Hero Section":
                            return (
                                <HeroSection
                                    key={index}
                                    item={item}
                                    edit={edit}
                                    initialValuesHero={initialValuesHero}
                                    schemaValidationHero={schemaValidationHero}
                                    setLoading={setLoading}
                                    getDataMainSection={getDataMainSection}
                                />
                            );
                        case "About Section":
                            return (
                                <AboutSection
                                    key={index}
                                    item={item}
                                    edit={edit}
                                    initialValuesAbout={initialValuesAbout}
                                    schemaValidationAbout={schemaValidationAbout}
                                    setLoading={setLoading}
                                    getDataMainSection={getDataMainSection}
                                />
                            );
                        case "Pergerakan Section":
                            return (
                                <PergerakanSection
                                    key={index}
                                    item={item}
                                    edit={edit}
                                    initialValuesPergerakan={initialValuesPergerakan}
                                    schemaValidationPergerakan={schemaValidationPergerakan}
                                    setLoading={setLoading}
                                    getDataMainSection={getDataMainSection}
                                />
                            );
                        case "Layanan Section":
                            return (
                                <LayananSection
                                    itemFeature={section?.filter(item => item?.name_section === "Layanan Feature")}
                                    item={item}
                                    edit={edit}
                                    initialValuesLayanan={initialValuesLayanan}
                                    schemaValidationLayanan={schemaValidationLayanan}
                                    setLoading={setLoading}
                                    getDataMainSection={getDataMainSection}
                                />
                            );
                        case "Information Section":
                            return (
                                <InformationSection
                                    item={item}
                                    edit={edit}
                                    initialValuesInformation={initialValuesInformation}
                                    schemaValidationIInformation={schemaValidationInformation}
                                    setLoading={setLoading}
                                    getDataMainSection={getDataMainSection}
                                />
                            );

                        case "Karir Section":
                            return (
                                <KarirSection
                                    key={index}
                                    item={item}
                                    edit={edit}
                                    initialValuesKarir={initialValuesKarir}
                                    schemaValidationKarir={schemaValidationKarir}
                                    setLoading={setLoading}
                                    getDataMainSection={getDataMainSection}
                                />
                            );
                        case "Contact Section":
                            return (
                                <ContactSection
                                    key={index}
                                    item={item}
                                    edit={edit}
                                    initialValuesContact={initialValuesContact}
                                    schemaValidationContact={schemaValidationContact}
                                    setLoading={setLoading}
                                    getDataMainSection={getDataMainSection}
                                />
                            );
                        case "App Section":
                            return (
                                <AppSection
                                    key={index}
                                    item={item}
                                    edit={edit}
                                    initialValuesApp={initialValuesApp}
                                    schemaValidationApp={schemaValidationApp}
                                    setLoading={setLoading}
                                    getDataMainSection={getDataMainSection}
                                />
                            );
                        default:
                            return null;
                    }
                })
            }
            {
                loading && <Loading />
            }
        </HomePageContainer>
    );
};

const reduxState = (state) => ({
    isLogin: state.isLogin,
    user: state.user,
    isLoading: state.isLoading

})


const reduxDispatch = (dispatch) => ({
    loading: (data) => dispatch(setLoading(data)),



})
export default connect(reduxState, reduxDispatch)(HomePage)
