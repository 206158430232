import React from 'react'

const ResetPassword = () => {
    return (
        <div>
            <p>reset password page</p>
        </div>
    )
}

export default ResetPassword
