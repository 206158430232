import styled from 'styled-components';
import Gap from './Gap'
import {Col} from './Col';
import {Row} from './Row';
import {Wrapper, MenuWrapper, HeaderContentContainer, TitleDesktop, TitleMobile, PageContent, PageContentMenu, ContentWrapper} from './Wrapper';

export {Gap, Wrapper, MenuWrapper, HeaderContentContainer, TitleDesktop, TitleMobile, PageContent, PageContentMenu, ContentWrapper, Row, Col};

export const LinkGroup = styled.div`
    /* background-color: #eee; */
`;