import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { iconAdd, iconLeft, iconUser } from '../../../../assets';
import { Gap, PageHeader, Row, Col, Icon, Table,  FilterYear, FilterMonth, ReportHeader, ReportFooter, Letter } from '../../../../components'
import API from '../../../../config/api';
import Modal from 'react-modal';
import swal from 'sweetalert';
import { useDebounce } from '../../../../utils/helpers/useDebounce';
import WarningLetterForm from './WarningLetterForm';


Modal.setAppElement('#root');
// const token = localStorage.getItem('token');

const EmployeeWarningLetter = (props) => {
    const token = props.user.token;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [perPage, setPerPage] = useState(5);
    const perPageList = [
        {label: "5", value: 5},
        {label: "10", value: 10},
        {label: "25", value: 25},
        {label: "50", value: 50},
    ];
    const [totalTableData, setTotalTableData] = useState(0);
    const [position, setPosition] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isAddOrEdit, setIsAddOrEdit] = useState('');
    const [tableData, setTableData] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [employeeOptions, setEmployeeOptions] = useState([]);
    const [divisions, setDivisions] = useState([]);
    const [violationOptions, setViolationOptions] = useState([]);
    const [pageName, setPageName] = useState("Laporan Surat Peringatan Karyawan");
    // const [periode, setPeriode] = useState(new Date().getFullYear());
    const year = (new Date()).getFullYear();
    const startYear = (new Date()).getFullYear() - 3;
    const [selectedYear, setSelectedYear] = useState(year);
    const [month, setMonth] = useState(new Date().getMonth())
    const [selectedDivision, setSelectedDivision] = useState(0);
    const [selectedWarningLetter, setSelectedWarningLetter] = useState({id: 0});
    const [divisionName, setDivisionName] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 800);

    const handleChangeDivision = (division) => {
        setSelectedDivision(division.id)
        setDivisionName(division.name)

    }

    useEffect(() => {
        setPageName(`Laporan Surat Peringatan ${selectedDivision === 0 ? 'Semua' : ''} Karyawan ${selectedDivision === 0 ? '' : divisionName}`)
    }, [divisionName])
    const handleAdd = () => {
        // console.log('tambah')
        setIsAddOrEdit('add')
        setInitialValues({
            no: '',
            employee_id: [],
            name: '',
            periode: '',
            violation_id: [],
            started_date: null,
            expired_date: null,
            note: '',
            
        })
        setModalIsOpen(true)       
    }

    const handleEdit = (row) => {
        // console.log(row)
        setIsAddOrEdit('edit');
        setInitialValues({
            id: row.id,
            no: row.no,
            employee_id: {label: row.employee_name, value: row.employee_id},
            name: row.name,
            periode: row.periode,
            violation_id: {label: row.violation_name, value: row.violation_id},
            started_date: new Date(row.started_date),
            expired_date: new Date(row.expired_date),
            note: row.note
          })
        setModalIsOpen(true)
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }

    const handleDetail = (row) => {
        //set modal detail open
        console.log('detail', row)
    }

    const handleDelete = (row) => {
        // console.log(row)
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this warning letter!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                // console.log('Call API delete Departemen');
                return API.deleteWarningLetter(token, row.id).then(res => {
                    swal({
                        title: 'Berhasil',
                        text: 'Surat Peringatan Berhasil Dihapus',
                        icon: "success",
                      });
                    // console.log('call branch Data lagi');
                    API.getWarningLetter(token, currentPage, perPage, searchTerm, selectedYear, selectedDivision).then((res) => {
                        // console.log(res.data.data);
                        setTableData(res.data.data)
                        setTotalPage(res.data.last_page);
                        setTotalTableData(res.data.total);
                        setPosition((currentPage - 1) * perPage)
                        setMessage('success get data warning letter');
                        // setLoading(false);
                    }).catch(err => {
                        // console.log(err.response.data.message);
                        // console.log(err);
                        setTableData(0);
                        setMessage(err.response.data.message || 'Surat Peringatan Tidak Ditemukan');
                        
                        // setLoading(false);
                    })
                }).catch(err => {
                    console.log(err);
                    // setLoading(false);
                })
            }
            else {
            //   swal("Your imaginary file is safe!");
            //   setLoading(false);

            }
          });
    }
  
    const prevButton = () => {
        setCurrentPage(Number(currentPage - 1));
    }
    const nextButton = () => {
        setCurrentPage(Number(currentPage + 1));        

    }
    const pageNumberClick = (e) => {
        // setCurrentPage(e => e.target.id)
        setCurrentPage(Number(e.target.id));
    }
    const changePerPage = (e) => {
        setPerPage(Number(e.target.value))
    }

    const handleChangeYearFilter = (e) => {
        setSelectedYear(e.target.value);
       
    }
    const handleMonthChange = e => {
        setMonth(e.target.value)
    }

    const pageNumbers = [];
    
    let i = 0;
    do {
        i++;
        // console.log(`halaman sekarang,`, i);
        pageNumbers.push(i);
    } while(i < totalPage)

    const renderPageNumbers = pageNumbers.map(number => {
        return (
          <a
            key={number}
            id={number}
            onClick={pageNumberClick}
            className={currentPage == number ? 'active' : ''}
          >
            {number}
          </a>
        );
      });

      const warningLetterColumns = [
        {Header: 'No',
            Cell: (row) => {
                // console.log(row)
                // console.log(row.cell.row.index)
                let startFrom = position + 1;
                return <div>{startFrom +row.cell.row.index}.</div>;
                console.log(row);
                // return <div>{row.cell.row.index+1}.</div>;
                
            }
        },
        {Header: 'Id', accessor: 'id', show: false},
        {Header: 'Nomor Surat', accessor: 'no'},
        {Header: 'Nama', accessor: 'employee_name'},
        {Header: 'Departemen', accessor: 'employee_division'},
        {Header: 'Tim/Grup Kerja', accessor: 'group_name'},
        {Header: 'Jenis', accessor: 'name'},
        
        {Header: 'Periode', accessor: 'periode'},
        {Header: 'Pelanggaran', accessor: 'violation_name'},
        {Header: 'Tanggal Berlaku', accessor: 'started_date'},
        {Header: 'Tanggal Berakhir', accessor: 'expired_date'},
        {Header: 'Catatan', accessor: 'note'},
        {Header: 'Aksi', className: 'no-print',
            Cell: row => (
                <div className="edit-delete-wrapper">
                    <button className="detail-button" onClick={() => {printWarningLetter(row.row.original)}}>Detail</button> 
                    <button className="edit-button" onClick={() => handleEdit(row.row.original)}>Edit</button> 
                    <button className="delete-button" onClick={() => {handleDelete(row.row.original)}}>Delete</button>
                </div>
            )
        },
    ];
    const printWarningLetter = (warning_letter) => {
        // console.log(WarningLetter)
        setPageName('print-letter')
        if(warning_letter.id === 0){
            setModalIsOpen(!modalIsOpen)
            // alert('print blankoooo')
        }
        setSelectedWarningLetter(warning_letter)
        
    }

    useEffect(() => {
        //pas refactoring nanti, bikin 1 endpoint api untuk halaman ini
        //untuk mendapatkan seluruh karyawan, seluruh violation, seluruh division

        //get all employee
        API.getAllEmployee(token).then(res => {
            // setEmployeeOptions(res.data)
            let eOptions = [];
            res.data.map(e => {
                eOptions.push({
                    value: e.id, label: e.name
                });
            });
            setEmployeeOptions(eOptions);

        }).catch(err => {
            console.log(err.response.data.message)
        })

        //get all violation
        API.getAllViolation(token).then(res => {

            let vioOptions = [];
            res.data.map(vio => {
                vioOptions.push({
                    value: vio.id, label: vio.name
                });
            });
            setViolationOptions(vioOptions)
        }).catch(err => {
            console.log(err.response.data.message)
        })

        //get all division
        API.getAllDivision(token).then(res => {
            // console.log('divisi: ', res.data)
            setDivisions(res.data)
        }).catch(err => {
            console.log(err.response.data.message)
        })
        
    }, [])

    useEffect(() => {
        // setCurrentPage(currentPage);
        setTotalPage(0);
        let mnth = parseInt(month) + 1;

        API.getWarningLetter(token, currentPage, perPage, searchTerm, selectedYear, selectedDivision, mnth).then((res) => {
            // console.log(res.data.data);
            setTableData(res.data.data)
            setTotalPage(res.data.last_page);
            setTotalTableData(res.data.total);
            setPosition((currentPage - 1) * perPage)
            setMessage('success get data warning letter');
            // setLoading(false);
        }).catch(err => {
            // console.log(err.response.data.message);
            // console.log(err);
            setTableData(0);
            setMessage(err.response.data.message || 'Surat Peringatan Tidak Ditemukan');
            
            // setLoading(false);
        })



    }, [debouncedSearchTerm, currentPage, perPage, searchTerm, modalIsOpen, selectedYear, selectedDivision, month])
    
    return (
        <>
             <PageHeader
                title="Surat Peringatan Karyawan"
                subtitle={props.user.client_id}
                name={props.user.name}
                photo={iconUser}
            />
            <Gap height={20} />
            {
                pageName === 'print-letter' ? <>
                <Letter type="warning" company={props.user.info.company} value={selectedWarningLetter} setPageName={setPageName} />
                </> :
           <>
            <Row>
                <Col>
                <Link to='/employee' className="back-button" >                    
                    <Icon icon={iconLeft} color="#fff" />
                    <p>Back</p>
                </Link>
                {/* <button onClick={handleAdd} className="back-button">
                            Back

                </button> */}
                </Col>
                <Col style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginBottom: 5}}>
                        <p className={`filter-group ${selectedDivision == 0 ?'active': ''}`} onClick={() => handleChangeDivision({id: 0})}>Semua</p>    
                        {divisions && divisions.map(division => (
                            <p key={division.name} className={`filter-group ${selectedDivision == division.id ?'active': ''}`} onClick={() => handleChangeDivision(division)}>                    
                            {division.code}
                            </p>
                        ))
                        }
                    </Col>
                {/* filter tahun dan bulan */}
                <Col style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                    <p style={{fontSize: 14, marginRight: 5}}>Periode : </p>
                    <div style={{marginLeft: 5}}>
                        <FilterYear year={year} startYear={startYear} selectedYear={selectedYear} handleChange={handleChangeYearFilter} />
                    </div>
                    <div style={{marginLeft: 5}}>
                        <FilterMonth handleChange={handleMonthChange} month={month} className="select-month" />
                
                    </div>
                    <div onClick={() => window.print()} className="print-button" style={{marginLeft: '5px', padding: '0 10px', height: '35px', border: '1px solid #eee'}}><span style={{fontSize: '22px', marginRight: '3px'}}>&#128424;</span>Print</div>
                </Col>
            </Row>
            <ReportHeader company={props.user.info.company} month={month} selectedYear={selectedYear} page={pageName} />
            <Row>
                <div style={{overflowX: 'auto', flex:1}}>
                    <div className="table-control">
                        <div className="limit">
                            <span>Show :</span>
                            <select onChange={changePerPage} value={perPage}>
                            {perPageList.map(perPage => {
                                return (
                                <option key={perPage.value} value={perPage.value}>
                                {perPage.label}
                                </option>
                                )
                                })
                            }
                            </select>
                            <span>Entries</span>

                        </div>
                    

                    <div className="search">
                        <input type="text"
                            placeholder="Search here..."
                            value={searchTerm || ''}
                            onChange={e => setSearchTerm(e.target.value)}
                            autoFocus
                        />
                    </div>
    
                    <button onClick={handleAdd} className="add-button">
                            <Icon icon={iconAdd} color="#fff" />
                            Buat Surat Peringatan

                    </button>
                    </div>
                 
                 
        
                        
                        {tableData ? 
                        <div className="print-table">
                            <Table type="dataTable" tableData={tableData} tableColumns={warningLetterColumns} />
                            <ReportFooter className="section-to-print footer" />
                        </div>
                        : <><Gap height={30} /><h2 align="center">{message}</h2></>
                    }
                    
                    </div>
                </Row>
                {/* //jika tidak ada table data sembunyikan */}
    
                {tableData ? 
                    <div className="pagination">
                        <div className="pagination-info">
                            <span>Showing {position + 1} to {( currentPage != totalPage ) ? position + 1 * perPage : totalTableData } of {totalTableData} entries</span> <br />
                        </div>
                        <div className="pagination-control">
                            <button onClick={() => prevButton()} disabled={currentPage <= 1}>&laquo;</button>
                            {renderPageNumbers}
                            <button onClick={() => nextButton()} disabled={currentPage >= totalPage}>&raquo;</button>
    
                        </div>
                    </div>
                        : null
                }
                </>
            }
            <Modal 
                onRequestClose={() => setModalIsOpen(false)}
                isOpen={modalIsOpen}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)'
                      },
                    content: {
                        border: '1px solid #222',
                        padding:0,
                        top: '25px',
                        left: '400px',
                        right: '400px',
                        bottom: '50px',
                    }
                }}
            >
                <div className="modal-container">
                <div className="modal-header">
                    <h2 className="modal-title">{(isAddOrEdit == 'add') ? 'Tambah' : 'Edit' } Surat Peringatan</h2>
                    <div onClick={() => printWarningLetter({id: 0})} className="print-button" style={{ cursor: 'pointer', marginLeft: 'auto', marginRight: '5px'}}><span style={{fontSize: '20px'}}>&#128424;</span></div>
                    <button className="close-modal" onClick={() => setModalIsOpen(false)}>X</button>
                </div>
                <WarningLetterForm employeeOptions={employeeOptions} violationOptions={violationOptions} token={token} closeModal={closeModal} isAddOrEdit={isAddOrEdit} initialValues={initialValues} />
                

                </div>
            </Modal>
        </>
    )
}

const reduxState = (state) => ({
    isLogin: state.isLogin,
    user: state.user,
    isLoading: state.isLoading

})
  
  
const reduxDispatch = (dispatch) => ({
    loading : (data) => dispatch(setLoading(data)),

    

})
export default connect(reduxState, reduxDispatch)(EmployeeWarningLetter)
