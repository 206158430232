import React from 'react';
import { Col, Row } from '../..';
import { bulan_indo, YMdToFormatIndo } from '../../../utils/helpers/date';

const LeaveBlanko = ({company, selectedYear, pageName, value}) => {
    console.log(value)
    const Header = ({company, selectedYear, page}) => {
        return (
            <>
            <div className="blanko">
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <img src={company.logo || 'PT. ABC'} alt="logo" width="100px" height="70px" style={{position: 'relative', left: -100}} className="logo" />
                    <div style={{textAlign: 'center', display: 'block', flexDirection: 'column', marginBottom: '15px'}}>
            
                        <h3 style={{ fontSize: '22px', textTransform: 'uppercase'}}>{company.business_fields} <br />{company.name}</h3>
                        <p style={{fontSize: '9px'}}><u>{company.address} TELP. {company.telp}, Email: <i>{company.email}</i></u></p>
                    </div>
                </div>
                <hr />
                <hr />
                <p style={{textAlign: 'center', marginTop: '15px', fontWeight: 'bold', fontSize: '20px', textTransform: 'uppercase', textDecoration: 'underline', marginBottom: '25px'}} className="page-title">{page} Tahun {selectedYear}</p>
                <div style={{display: 'flex', padding: '20px', justifyContent: 'center', flexDirection:'column', margin: '0 auto', maxWidth: '1000px'}}>
                    <div style={{display: 'flex', marginBottom: '15px'}}>
                        <p style={{width: '80px'}}>Nama</p>
                        <div style={{display: 'flex'}}>
                            <div style={{display: 'flex', marginLeft: '100px'}}>:</div>
                            <div style={{borderBottom: '1px solid black', width: '300px', height: '100%', marginLeft: '10px'}}><b><i>{value.employee_name}</i></b></div>
                            <div style={{border: '1px solid black', width: '20px', height: '100%', marginLeft: '10px', marginRight: '10px'}}></div>
                            <p>Surat Izin ditulis tangan sendiri</p>
                        </div>
                    </div>
                    <div style={{display: 'flex', marginBottom: '15px'}}>
                        <p style={{width: '80px'}}>NIK</p>
                        <div style={{display: 'flex'}}>
                            <div style={{display: 'flex', marginLeft: '100px'}}>:</div>
                            <div style={{borderBottom: '1px solid black', width: '300px', height: '100%', marginLeft: '10px'}}><i>{value.employee_nik || 'as'}</i></div>
                            <div style={{border: '1px solid black', width: '20px', height: '100%', marginLeft: '10px', marginRight: '10px'}}></div>
                            <p>Persetujuan Pimpinan bersangkutan</p>
                        </div>
                    </div>
                    <div style={{display: 'flex', marginBottom: '15px'}}>
                        <p style={{width: '80px'}}>Jabatan</p>
                        <div style={{display: 'flex'}}>
                            <div style={{display: 'flex', marginLeft: '100px'}}>:</div>
                            <div style={{borderBottom: '1px solid black', width: '300px', height: '100%', marginLeft: '10px', display: 'flex', alignItems: 'flex-end'}}><i>{value.position_name}</i></div>
                        </div>
                    </div>
                    <div style={{display: 'flex', marginBottom: '15px'}}>
                        <p style={{width: '80px'}}>Keperluan</p>
                        <div style={{display: 'flex'}}>
                            <div style={{display: 'flex', marginLeft: '100px'}}>:</div>
                            <div style={{borderBottom: '1px solid #222', width: '650px', height: '100%', marginLeft: '10px', display: 'flex', alignItems: 'flex-end', color: '#222'}}><i>{value.employee_note}</i></div>
                        </div>
                    </div>
                    <div style={{display: 'flex', marginBottom: '15px'}}>
                        <p style={{width: '80px'}}>Alamat Izin</p>
                        <div style={{display: 'flex'}}>
                            <div style={{display: 'flex', marginLeft: '100px'}}>:</div>
                            <div style={{border: '1px solid #222', width: '630px', height: '60px', marginLeft: '10px', display: 'flex', alignItems: 'flex-end', color: '#222', paddingLeft: '20px', paddingBottom: '50px'}}><i>{value.address_during_leave}</i></div>
                        </div>
                    </div>
                    <div style={{display: 'flex', marginBottom: '15px'}}>
                        <p style={{width: '80px'}}>Lama Izin</p>
                        <div style={{display: 'flex'}}>
                            <div style={{display: 'flex', marginLeft: '100px'}}>:</div>
                            <div style={{borderBottom: '1px solid black', width: '150px', height: '100%', marginLeft: '10px', textAlign: 'center'}}><b><i>{value.approved_date_start ? YMdToFormatIndo(value.approved_date_start) : YMdToFormatIndo(value.request_date_start)}</i></b></div>
                            <p style={{width: '50px', marginLeft: '10px'}}>Sampai</p>
                            <div style={{borderBottom: '1px solid black', width: '150px', height: '100%', marginLeft: '10px', textAlign: 'center'}}><b><i>{value.approved_date_end ? YMdToFormatIndo(value.approved_date_end) : YMdToFormatIndo(value.request_date_end)}</i></b></div>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        Marabahan, ............ {selectedYear}
                    </div>
                    <div style={{display: 'flex', justifyContent: 'space-between', padding: '25px'}}>
                        <div style={{textAlign: 'center'}}>
                            <p style={{fontWeight: 'bold', height: '100px'}}>Pemohon,</p>
                            <p><b><u><i>{value.employee_name}</i></u></b></p>
                            <p><i>Karyawan Cuti</i></p>
                        </div>
                        <div style={{textAlign: 'center'}}>
                            <p style={{fontWeight: 'bold', height: '100px'}}>Menyetujui,</p>
                            <p><b><u><i>{value.approver_name || 'Yang Menyetujui'}</i></u></b></p>
                            <p><i>{value.approver_position || 'Jabatan yang menyetujui'}</i></p>
                        </div>
                    </div>
                    <div style={{display: 'flex', marginBottom: '15px'}}>
                        <p style={{width: '80px'}}>Keterangan</p>
                        <div style={{display: 'flex'}}>
                            <div style={{display: 'flex', marginLeft: '100px'}}>:</div>
                            <div style={{border: '1px solid #222', width: '650px', height: '65px', marginLeft: '10px', display: 'flex', alignItems: 'flex-end', color: '#222'}}><i></i></div>
                        </div>
                    </div>
                </div>
                
            </div>
            </>
        )
        
        }
    return <>
        <Header company={company} selectedYear={selectedYear} page={pageName} />

    </>
  
};

export default LeaveBlanko;

