import Get from './Get';
import Post from './Post';
import Put from './Put';
import Delete from './Delete';
import { userData, userLogin, userLogout, userDelete, userCreate, userEdit, userChangePassword, employeeLoginData, userCompanyInfo } from './Auth';
import {
    getHRDashboard,
    getBranch,
    getDivision,
    getAllDivision,
    getPosition,
    getTeamGroup,
    getTeamGroupByDivisionID,
    getWorkLocation,
    getWorkShift,
    getEmployee,
    getEmployeeByEmail,
    getEmployeeByTeamGroupID,
    getAllWorkLocation,
    getWorkShiftByTeamGroupID,
    getGroupScheduleInfo,
    getScheduleByTeamGroupID,
    getEmployeeScheduleAtMonth,
    getHolidaysAtMonth,
    isDateHoliday,
    checkTodayScheduleOfEmployee,
    checkTodayAttendanceOfEmployee,
    checkAttendanceOvertimeOfEmployee,
    getHoliday,
    getSetupOvertime,
    getEmployeeOvertimeRequest,
    getAllOvertimeDayTypes,
    getOvertimeSchemes,
    getAllLeaveTypes,
    getSetupLeave,
    getSetupLeaveGroup,
    getSetupLoan,
    getSetupBPJS,
    getSetupPPh21KPP,
    getSetupPPh21,
    getPTKPBiayaJabatanTarifPPh21,
    getEmployeeLeaveHistories,
    getBasicSalaryAndWorkDayInWeek,
    isEmployeeHaveOvertimeToday,
    getRecapAttendance,
    getRecapLeave,
    getAllEmployee,
    getAllViolation,
    getAllEmployeeLoans,
    getAllLoanInstallment,
    getReportAttendance,
    getEmployeeLocation,
    getWarningLetter,
    getEmployeeLoan,
    getEmployeeLoanInstallment,
    getEmployeeOvertime,
    getEmployeeOvertimeAttendance,
    getEmployeeLeave,
    getMyTodayAttendanceData,

    addBranch,
    addDivision,
    addPosition,
    addTeamGroup,
    addWorkLocation,
    addWorkShift,
    addHoliday,
    addEmployee,
    addSchedule,
    addTimeIn,
    addTimeOut,
    addSetupOvertime,
    addOvertimeSchemes,
    addSetupLeave,
    addSetupLoan,
    addSetupBPJS,
    addSetupPPh21,
    addSetupPPh21KPP,
    addLeaveRequest,
    addOvertimeRequest,
    addOvertimeIn,
    addOvertimeOut,
    addAttendanceByAdmin,
    addWarningLetter,
    addLoanEmployee,
    addLoanInstallmentEmployee,
    addOvertimeEmployee,
    addLeaveEmployee,

    editBranch,
    editDivision,
    editPosition,
    editTeamGroup,
    editWorkLocation,
    editWorkShift,
    editHoliday,
    editEmployee,
    editSchedule,
    editSetupOvertime,
    editOvertimeSchemes,
    editSetupLeave,
    editSetupLoan,
    editSetupBPJS,
    editSetupPPh21,
    editSetupPPh21KPP,
    editAttendance,
    editWarningLetter,
    editLoanEmployee,
    editLoanInstallmentEmployee,
    editOvertimeEmployee,
    editLeaveEmployee,

    deleteBranch,
    deleteDivision,
    deletePosition,
    deleteTeamGroup,
    deleteWorkLocation,
    deleteWorkShift,
    deleteHoliday,
    deleteEmployee,
    deleteSchedule,
    deleteScheduleEmployeeAtMonth,
    deleteLeaveRequest,
    deleteOvertimeRequest,
    deleteAttendance,
    deleteWarningLetter,
    deleteLoanEmployee,
    deleteEmployeeLoanInstallment,
    deleteOvertimeEmployee,
    deleteLeaveEmployee,

    copySchedule,
    getEmployeeChart,
    addEmployeePersonalDetail,
    getEmployeePersonalDetail,
    editEmployeePersonalDetail,
    getEmployeeContact,
    editEmployeeContact,
    addEmployeeContact,
    getEmployeeFamilie,
    addEmployeeFamilie,
    editEmployeeFamilie,
    getEmployeeEducation,
    addEmployeeEducation,
    editEmployeeEducation,
    deleteEmployeeEducation,
    getEmployeeWorkExperience,
    addEmployeeWorkExperience,
    editEmployeeWorkExperience,
    deleteEmployeeWorkExperience,

    EditHomePageMainSection,
    getHomePage,
    deletHomePageSubSection,
    addHomePageSubSection,
    addHomePageMainSection,
    deletHomePageMainSection,
    EditHomePageSubSection,
} from './HR';

const API = {
    userLogin,
    userLogout,
    userData,
    userCreate,
    userEdit,
    userDelete,
    userChangePassword,
    employeeLoginData,
    getHRDashboard,
    getBranch,
    getDivision,
    getAllDivision,
    getPosition,
    getTeamGroup,
    getTeamGroupByDivisionID,
    getWorkLocation,
    getWorkShift,
    getHoliday,
    getEmployee,
    getEmployeePersonalDetail,
    getEmployeeContact,
    getEmployeeFamilie,
    getEmployeeEducation,
    getEmployeeWorkExperience,
    getEmployeeByEmail,
    getEmployeeByTeamGroupID,
    getWorkShiftByTeamGroupID,
    getAllWorkLocation,
    getGroupScheduleInfo,
    getScheduleByTeamGroupID,
    getEmployeeScheduleAtMonth,
    getHolidaysAtMonth,
    isDateHoliday,
    checkTodayScheduleOfEmployee,
    checkTodayAttendanceOfEmployee,
    checkAttendanceOvertimeOfEmployee,
    getEmployeeOvertimeRequest,
    getSetupOvertime,
    getAllOvertimeDayTypes,
    getOvertimeSchemes,
    getAllLeaveTypes,
    getSetupLeave,
    getSetupLeaveGroup,
    getSetupLoan,
    getSetupBPJS,
    getSetupPPh21KPP,
    getSetupPPh21,
    getPTKPBiayaJabatanTarifPPh21,
    getEmployeeLeaveHistories,
    getBasicSalaryAndWorkDayInWeek,
    isEmployeeHaveOvertimeToday,
    getRecapAttendance,
    getRecapLeave,
    getAllEmployee,
    getAllViolation,
    getAllEmployeeLoans,
    getAllLoanInstallment,
    getReportAttendance,
    getEmployeeLocation,
    getWarningLetter,
    getEmployeeLoan,
    getEmployeeLoanInstallment,
    getEmployeeOvertime,
    getEmployeeOvertimeAttendance,
    getEmployeeLeave,
    getMyTodayAttendanceData,
    getHomePage,

    addBranch,
    addDivision,
    addPosition,
    addTeamGroup,
    addWorkLocation,
    addWorkShift,
    addHoliday,
    addEmployee,
    addEmployeePersonalDetail,
    addEmployeeContact,
    addEmployeeFamilie,
    addEmployeeEducation,
    addEmployeeEducation,
    addEmployeeWorkExperience,
    addSchedule,
    addTimeIn,
    addTimeOut,
    addSetupOvertime,
    addOvertimeSchemes,
    addSetupLeave,
    addSetupLoan,
    addSetupBPJS,
    addSetupPPh21,
    addSetupPPh21KPP,
    addLeaveRequest,
    addOvertimeRequest,
    addOvertimeIn,
    addOvertimeOut,
    addWarningLetter,
    addLoanEmployee,
    addLoanInstallmentEmployee,
    addOvertimeEmployee,
    addLeaveEmployee,
    addHomePageSubSection,
    addHomePageMainSection,

    editBranch,
    editDivision,
    editPosition,
    editTeamGroup,
    editWorkLocation,
    editWorkShift,
    editHoliday,
    editEmployee,
    editEmployeePersonalDetail,
    editEmployeeContact,
    editEmployeeFamilie,
    editEmployeeEducation,
    editEmployeeWorkExperience,
    editSchedule,
    editAttendance,
    editSetupOvertime,
    editOvertimeSchemes,
    editSetupLeave,
    editSetupLoan,
    editSetupBPJS,
    editSetupPPh21,
    editSetupPPh21KPP,
    editWarningLetter,
    editLoanEmployee,
    editLoanInstallmentEmployee,
    editOvertimeEmployee,
    editLeaveEmployee,
    EditHomePageMainSection,
    EditHomePageSubSection,

    deleteBranch,
    deleteDivision,
    deletePosition,
    deleteTeamGroup,
    deleteWorkLocation,
    deleteWorkShift,
    deleteHoliday,
    deleteEmployee,
    deleteEmployeeEducation,
    deleteEmployeeWorkExperience,
    deleteSchedule,
    deleteScheduleEmployeeAtMonth,
    copySchedule,
    deleteLeaveRequest,
    deleteOvertimeRequest,
    deleteAttendance,
    deleteWarningLetter,
    deleteLoanEmployee,
    deleteEmployeeLoanInstallment,
    deleteOvertimeEmployee,
    deleteLeaveEmployee,
    deletHomePageSubSection,
    deletHomePageMainSection,

    addAttendanceByAdmin,
    userCompanyInfo,

    getEmployeeChart
}


export default API;

export * from './Auth'