import { Link } from 'react-router-dom';
import styled from 'styled-components';


export const HeaderDetailEmployee = styled.nav`
    background-color: var(--black);
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    text-align: center;
    font-size: 14px;
    .profile-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: var(--white);
        border: 3px solid var(--background-color);
        position: relative;
        top: -50px;
        margin-bottom: -40px;
    
    }

    .employee-photo {
        width: 75%;
        height: 75%;
    }

    .employee-name {
        /* margin-top: -50px; */
        font-size: 16px;
        color: var(--white);
    }

    .employee-division-group {
        font-size: 11px;
        font-style: italic;
        color: var(--yellow);
        margin-bottom: 50px;
    }

`;


export const DetailEmployeeMenu = styled.div`
    background-color: var(--secondary-color);
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .menu-item  {
        color: var(--white);
        height: 100%;
        line-height: 40px;
        /* margin: 0 5px; */
        cursor: pointer;
        width: 150px;
        
        p {
            /* padding-top: 15px; */
        }
        &.active {
            background-color: var(--black);
            color: var(--white);
            font-weight: bold;
        }
        &:hover {
            background-color: var(--black);
            font-weight: bold;
            color: var(--white);
            cursor: pointer;    
        }
   
    }
    .sub-menu {
        position: relative;
        /* top: 50px; */
        display: flex;
        color: black;
        width: 100%;
        
    }
`;

export const DetailEmployeeOverview = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
        color: white;
        font-size: 8px;
        vertical-align: text-top;
    }
`;



export const WrapperBasicInformation = styled.div`
    background-color: var(--white);
    width: 100%;
    height: 1100px;
    display: grid;
    grid-template-columns: 1fr 1.8fr 1fr;
    grid-template-rows: 1.5fr 2fr 1.5fr 1.8fr;
    grid-template-areas:
        'data-basic data-contact data-family-members'
        'data-login data-contact data-family-members'
        'data-login data-education-background data-work-experience'
        'data-login data-education-background data-work-experience';
    gap: 1.2rem;
    font-family: 'Inter', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 12px;
    color: var(--primary-color);
    padding: 20px;
    box-sizing: border-box;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .data-list, .data-list-education {
        display: flex;
        align-items: center;
        height: 40px;
        padding-left: 10px;
        font-size: 12.5px;
        text-transform: capitalize;
        p {
            margin-top: 5px;
            color: black;
            width: 100%;
        }
        p:last-child {
            width: 140%;
            color: var(--black);
            font-weight: normal;
            margin-right: auto;
        }
    }
    .data-list-basic {
        display: flex;
        align-items: center;
        height: 33px;
        padding-left: 10px;
        padding-right: 3px;
        font-size: 12.5px;
        text-transform: capitalize;
        p {
            margin-top: 5px;
            color: black;
            width: 100%;
        }
        p:last-child {
            width: 140%;
            color: var(--black);
            font-weight: normal;
            margin-right: auto;
        }
    }

    .header-data, .header-data-contact, .header-data-familie, .header-data-education-background, .header-data-work-experience {
        padding: 10px 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        button {
            background-color: white;
            padding: 1.5px 10px;
            font-size: 12px;
            color: var(--black);
        }
    }
    .header-data{
        background-color: var(--secondary-color);
    }
    .header-data-contact{
        background-color: var(--primary-color);
    }
    .header-data-familie{
        background-color: var(--green);
    }
    .header-data-work-experience{
        background-color: var(--red-hover);
    }
    .header-data-education-background{
        background-color: var(--yellow-hover);
    }
    .content-data {
        margin-top: 10px;
        overflow-y: auto;
        max-height: 500px; /* Tambahkan agar bisa di-scroll jika konten melebihi */
    }

    .data-basic {
        grid-area: data-basic;
        display: flex;
        flex-direction: column;
        border: 1px solid var(--background-color);
    }

    .data-basic,.data-contact, .data-family-members, .data-education-background, .data-work-experience {
        border: 1px solid var(--background-color);
    }

    .data-login {
        margin-bottom: 50px;
        grid-area: data-login;
        display: flex;
        flex-direction: column;
        div:first-child {
            border-left: 3px solid var(--primary-color);
            padding: 10px 5px;
            margin: 10px 0;
            color: var(--black);
            font-weight: bold;
            text-transform: capitalize;
        }
        p {
            margin-top: 5px;
            padding-left: 10px;
            font-size: 16px;
        }
        p.tidak-punya-akun {
            color: var(--secondary-color);
            font-weight: normal;
            text-transform: capitalize;
            font-size: 14px;
        }
        button {
            margin-left: 10px;
        }
    }

    .edit-update{
       display: flex;
       justify-content: space-between;
       height: 15px;
    }
    .data-repeat{
         display: grid;
         margin-bottom: 10px;
    }
    @media only screen and (max-width: 1290px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'data-basic data-contact'
            'data-family-members data-education-background'
            'data-login data-work-experience';
        height: auto;
        padding: 10px;
    }
    /* Responsive adjustments */
    @media only screen and (max-width: 950px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            'data-basic'
            'data-contact'
            'data-family-members'
            'data-education-background'
            'data-work-experience'
            'data-login';
        height: auto;
        padding: 10px;
    }

    @media only screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 0.8rem;
        font-size: 13px;
    }

    @media only screen and (max-width: 550px) {
        grid-template-columns: 1fr;
        grid-template-rows: 0.4fr 0.4fr 1fr 0.9 1.2fr 1.2fr 1fr;
        margin-bottom: 50px;
        font-size: 13px;
        height: auto;
        .content-data {
        margin-top: 10px;
        overflow-y: auto;
        max-height: 500px; /* Tambahkan agar bisa di-scroll jika konten melebihi */
    }
    }
`;


