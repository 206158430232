import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DocumentsImg, Logo } from '../../../assets';
import { FooterImage, Navbar, NavBrand, NavBrandLogo, NavBrandName, NavFooter, NavFooterContent, NavFooterDesc, NavFooterLink, NavFooterTitle, NavIcon, NavItem, NavLinks, NavList } from './menu.elements';
import { navAdmin, navHR, navAsset, navEmployee } from './setting';

const Menu = (props) => {
    // console.log(props);

    const location = useLocation();
    const [navs, setNavs] = useState([]);
    
    const {user} = props;
    useEffect(() => {
        switch (user.role) {
            case '0':
                setNavs(navAdmin)
                break;
            case '1':
                setNavs(navHR)
                break;
            case '2':
                setNavs(navAsset);
                break;
            case '3':
                setNavs(navEmployee);
                break;
            default:
                break;
        }
    }, [user.role])
    return (
        <Navbar>
            <NavBrand href="#">
                <NavBrandLogo src={user.info.company.logo || 'PT. ABC'} alt="Logo" />
                <NavBrandName>{user.info.company.short_name || 'PT. ABC'}</NavBrandName>
            </NavBrand>

            <NavList>
                {navs.map(nav => (
                    <NavItem key={nav.href}>
                        <NavLinks to={nav.href} className={`${ location.pathname.split('/')[1] == nav.href.split('/')[1] ? 'active' : '' }`}>
                                <NavIcon icon={nav.icon} className={`${ location.pathname.split('/')[1] == nav.href.split('/')[1] ? 'active' : '' }`} /><span>{nav.text}</span>
                        </NavLinks>
                    </NavItem>
                ))
                }
            </NavList>

            <NavFooter>
                <FooterImage src={DocumentsImg} alt="documents" />
                <NavFooterContent>
                    <NavFooterTitle>PDF Report</NavFooterTitle>
                    <NavFooterDesc>Annual detailed report</NavFooterDesc>
                    <NavFooterLink href="#">Download</NavFooterLink>
                </NavFooterContent>
            </NavFooter>
        </Navbar>
    )
}

//mengambil state yang ada di store, memasukkannya ke state komponen ini
const reduxState = (state) => ({
    isLogin: state.isLogin,
    user: state.user,
    isLoading: state.isLoading
})
  
  
const reduxDispatch = (dispatch) => ({
    setUserData : (data) => dispatch(setUser(data))
    

})
export default connect(reduxState, reduxDispatch)(Menu)
// export default withRouter(connect(reduxState, reduxDispatch)(MainApp))


